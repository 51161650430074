import React, { memo } from "react"

const SingleInput = memo((props) => {
  const { label, value, disabled, type, onChange } = props

  return (
    <div className="partner-card-row">
      <span>{label}</span>
      <div className="flex items-center partner-card-input ">
        <input
          disabled={disabled}
          className="flex w-28 text-center relative left-3 bg-transparent"
          value={value || ''}
          onChange={onChange}
          type={type}
          title={value}
        />
      </div>
    </div>
  )
})
SingleInput.displayName = 'SingleInput'

export default SingleInput