export const getNavbarSelector = (state) => state?.navbar;

export const getRoutesSelector = (state) => state?.navbar?.routes;

export const getTitleSelector = (state) => state?.navbar?.title;

export const getCurrentCampusOrCompanySelector = (state) => {
  let result = { isCentral: state?.navbar?.isCentral };
  if (typeof state?.navbar?.currentCampusOrCompany === 'object') {
    result = { ...result, ...state?.navbar?.currentCampusOrCompany };
  }
  return result;
};

export const getCampusRecentSelector = (state) => state?.navbar?.campusRecent;

export const getCompanySelector = (state) => state?.navbar?.company;

export const getIsCentralSelector = (state) => state?.navbar?.isCentral;

export const getCentralInfoSelector = (state) => state?.navbar?.centralInfo;

export const getIsFinanceSelector = (state) => state?.navbar?.isFinance;

export const getCurrentAcademicYearNameSelector = (state) =>
  state?.navbar?.currentAcademicYearName;

export const getPreviousAcademicYearNameSelector = (state) =>
  state?.navbar?.previousAcademicYearName;

export const getListAcademicYearNameSelector = (state) =>
  state?.navbar?.listAcademicYearName;

export const getCurrentRoutesSelector = (state) => state?.navbar?.routes;

export const getIsGiikiAdminSelector = (state) => state?.navbar?.isGiikiAdmin;
