import { selectStyle } from "entities/select-style";
import React, { memo } from "react";
import Select from 'react-select';

const SingleSelect = memo((props) => {
  const { label, value, options, onChange } = props

  return (
    <div className="principial-card-row">
      <span className="principial-card-row_fix">{label}</span>
      <div>
        <Select
          defaultValue={value}
          styles={selectStyle}
          options={options}
          onChange={onChange}
          customStyle={{ maxWidth: '50%' }}
        />
      </div>
    </div>
  )
})
SingleSelect.displayName = 'SingleSelect'

export default SingleSelect