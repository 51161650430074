import CashierFeeCard from '../CashierFeeCard';

import './scholarship-list.scss';

function ScholarshipList(props) {
  const { listScholarships = [] } = props;
  let totalPriceApprove = 0;
  let totalPriceReject = 0;
  listScholarships.forEach(element => {
    if (element.isApproved) {
      totalPriceApprove += element.price;
    } else {
      totalPriceReject += element.price;
    }
  });

  return (
    <div className="scholarship-list">
      <div className="mt-3">
        <div className="flex items-center justify-between mb-2">
          <p className="scholarship-list-title">Approved</p>
          <p className="scholarship-list-price">₹ {totalPriceApprove}</p>
        </div>
        <div className="">
        {listScholarships.map((data) => {
          return (
            data.isApproved && <CashierFeeCard
              dueDate={data.dueDate}
              key={`${data.id}-${data.title}`}
              isComplete={true}
              title={data.title}
              id={data.id}
              price={data.price}
            />
          );
        })}
        </div>
      </div>

      <div className="mt-3">
        <div className="flex items-center justify-between mb-2">
          <p className="scholarship-list-title">Rejected</p>
          <p className="scholarship-list-price">₹ {totalPriceReject}</p>
        </div>
        <div className=''>
          {listScholarships.map((data) => {
            return (
              !data.isApproved && <CashierFeeCard
                dueDate={data.dueDate}
                key={`${data.id}-${data.title}`}
                isComplete={false}
                title={data.title}
                id={data.id}
                price={data.price}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default ScholarshipList;
