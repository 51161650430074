import { useEffect, useState } from "react";
import SingleCallCard from "@components/single-call-card";

const CallPopup = (props) => {
  const { data, parents, onClickCall } = props
  const [guests, setGuests] = useState([])
  const [others, setOthers] = useState([])

  useEffect(() => {
    const guestIds = JSON.parse(data?.guestIds || null)
    if (guestIds?.length !== 0) {
      if (parents?.length !== 0) {
        const tmpGuest = []
        const tmpOther = []
        for (const parent of parents) {
          if (guestIds.includes(parent.parentUserId)) {
            tmpGuest.push(parent)
          } else tmpOther.push(parent)
        }

        setGuests(tmpGuest)
        setOthers(tmpOther)
      } else {
        setGuests([])
        setOthers([])
      }
    } else {
      setGuests([])
      setOthers(parents)
    }
  }, [data, parents])

  return (
    <div className="w-[20rem] shadow p-3 font-medium bg-white rounded-lg">
      <p className="text-base text-[#212B36]">Call</p>
      <small className="text-xs text-[#637381]">Guests for the event</small>
      <div className="w-full h-fit max-h-[11rem] overflow-y-auto overflow-x-hidden p-3">
        {guests?.map((item, index) => <SingleCallCard key={`guest${index}`} data={item} onClick={() => onClickCall(item)} />)}
      </div>

      <small className="text-xs text-[#637381]">Other contacts</small>
      <div className="w-full h-fit max-h-[6.5rem] overflow-y-auto overflow-x-hidden p-3">
        {others?.map((item, index) => <SingleCallCard key={`other${index}`} data={item} onClick={() => onClickCall(item)} />)}

      </div>
    </div>
  )
}
export default CallPopup;
