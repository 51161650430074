import clsx from 'clsx';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import { email, mainavatar, notification, sms, whatsapp } from '@static/image';
import { Button, Chip, Input, SelectGroup, TextArea } from '@stories/index';
import WorldCard from '@stories/worldcard/worldcard';
import {
  checkErrorMultipleApiFetch,
  checkErrorSingleApi,
} from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import {
  selectStylesWithNoTitle,
  toggleButtonStyles,
} from '../../../../entities/select-style';
import './task-and-reminder-setting.scss';
import centralAdminApi from 'api/central-admin';
import notificationApi from 'api/notification';
import useStorage from 'store/storage';

const deadlineOptions = [
  { value: '1', label: '1 Day' },
  { value: '2', label: '2 Days' },
  { value: '3', label: '3 Days' },
  { value: '4', label: '4 Days' },
  { value: '5', label: '5 Days' },
];

const workloadEstimateOptions = [
  { value: '1', label: '1 hour' },
  { value: '2', label: '2 hours' },
  { value: '3', label: '3 hours' },
  { value: '4', label: '4 hours' },
  { value: '5', label: '5 hours' },
  { value: '6', label: '6 hours' },
  { value: '7', label: '7 hours' },
  { value: '8', label: '8 hours' },
];

const linkedGoalOptions = [{ value: 'reduced contact', label: 'Reduced Contact' }];

const reminderOptions = [
  { value: 'everyday', label: 'Everyday' },
  { value: 'weekly', label: 'Weekly' },
];

export default function TaskAndReminderSetting(props) {
  const campus = useStorage((state) => state.currentCampus);
  const { visible, setVisible, dataSettings, setRefresh } = props;

  const [roles, setRoles] = useState({});
  const [persons, setPersons] = useState({});

  const [settings, setSettings] = useState(dataSettings);
  const [selectedPerson, setSelectedPerson] = useState(dataSettings.userIds);
  const [selectedRoles, setSelectedRoles] = useState(dataSettings.roleNames);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    const roles = centralAdminApi.getRoleOfCompanyOrCampus({ campusId: campus?.isCentral ? undefined : campus?.id })
    const persons = centralAdminApi.getUserOfCompanyOrCampus({ excludeRoleNames: 'Student;Parent' })
    Promise.all([roles, persons])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const listRole = {};
          res[0].data.data.forEach(({ id, name }) => {
            listRole[id] = name;
          });
          setRoles(listRole);

          const listPerson = {};
          res[1].data.data.objects.forEach(({ id, name, photoURL }) => {
            listPerson[id] = { name, photoURL };
          });
          setPersons(listPerson);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error,
        });
        setIsShowToastMessage(true);
      });
  }, []);

  const selectedPersonOrRoles = useMemo(() => {
    if (selectedRoles.length === 0 && selectedPerson.length === 0) return [];
    return [
      ...selectedRoles.map((roleName) => ({
        value: roleName,
        label: roleName,
        isRole: true,
      })),
      ...selectedPerson.map((user) => ({
        value: user?.id ?? user.value,
        label: user?.name ?? user.label,
        photoURL: user.photoURL || mainavatar,
        isRole: false,
      })),
    ];
  }, [selectedRoles, selectedPerson]);

  const personOrRolesOptions = useMemo(() => {
    if (roles.length === 0 && persons.length === 0) return [];
    return [
      ...Object.values(roles).map((val) => ({ value: val, label: val, isRole: true })),
      ...Object.keys(persons).map((key) => ({
        value: key,
        label: persons[key]?.name,
        photoURL: persons[key]?.photoURL || mainavatar,
        isRole: false,
      })),
    ];
  }, [roles, persons]);

  const handleChangeSelectPersonOrRole = useCallback((e) => {
    const users = [];
    const roles = [];
    e.forEach((item) => {
      if (item.isRole) {
        roles.push(item.value);
      } else users.push(item);
    });

    setSelectedRoles(roles);
    setSelectedPerson(users);
  }, []);

  const handleChangeSettings = useCallback((key, value) => {
    setSettings((prev) => ({ ...prev, [key]: value }));
  }, []);

  const formatOptionLabelUser = (props) => {
    const { label, photoURL, isRole } = props;
    return (
      <div className="block">
        <Chip
          label={label}
          image={!isRole ? photoURL || mainavatar : ''}
          symbol={isRole ? label[0] : ''}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  };

  const handleOnClickCancel = () => {
    setVisible(false);
  };
  const handleOnClickSave = async () => {
    setVisible(false);
    const data = [
      {
        ...(({
          task,
          dateCreated,
          dateUpdated,
          taskReminderSettingDefaultId,
          campusId,
          title,
          module,
          ...o
        }) => o)(settings),
        dueDate: +settings.dueDate,
        workloadEstimate: +settings.workloadEstimate,
        roleNames: selectedRoles,
        userIds: ((e) => {
          return e.map((user) => user.value ?? user.id);
        })(selectedPerson),
      },
    ];

    notificationApi.createTaskAndReminder({ campusId: campus?.isCentral ? undefined : campus?.id }, { data })
      .then((res) => {
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
          setToastMessage({
            status: 'success',
            title: 'Task Setting Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setRefresh((prev) => prev + 1);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Task Setting Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <Zoom bottom duration={700} when={visible}>
      <div
        className={clsx(
          'task-and-reminder-setting',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-auto w-32">
            <h2 className="task-and-reminder-setting-name">Task and Reminder Setting</h2>
          </div>
          <button className="mr-5" onClick={() => setVisible(false)}>
            <Close />
          </button>
        </div>
        <hr className="mt-2" />
        <div className="w-full h-[calc(100%-7rem)]">
          <div className="flex-row overflow-y-auto overflow-x-hidden h-full w-full">
            <div>
              <h3 className="t-a-r-setting-heading">1. General Info</h3>
              <p className="t-a-r-setting-content">
                Please enter basic details about the reminder
              </p>
              <div className="mr-5">
                <h4 className="t-a-r-setting-subtitle mb-2">Name</h4>
                <Input
                  disabled={true}
                  name="task-name"
                  value={settings.task}
                  onChange={(e) => handleChangeSettings('task', e.target.value)}
                  customStyle={{
                    width: '100%',
                    height: '2.25rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                />
              </div>
              <div className="mr-5">
                <h4 className="t-a-r-setting-subtitle">Person or Roles</h4>
                <SelectGroup
                  value={selectedPersonOrRoles}
                  options={personOrRolesOptions}
                  isMulti={true}
                  isSearchable={true}
                  styles={selectStylesWithNoTitle}
                  onChange={(e) => {
                    handleChangeSelectPersonOrRole(e);
                  }}
                  formatOptionLabel={formatOptionLabelUser}
                  customStyle={{
                    marginTop: '0.5rem',
                    width: '100%',
                    borderRadius: '0.375rem',
                    fontSize: 14,
                    fontSize: '0.875rem',
                  }}
                />
              </div>
            </div>
            <hr className="mt-4 mr-5" />
            <div>
              <h3 className="t-a-r-setting-heading">2. Task settings </h3>
              <p className="t-a-r-setting-content">
                This is the information available to the users
              </p>
            </div>
            <div className="mr-5">
              <h4 className="t-a-r-setting-subtitle mb-2">Task Description</h4>
              <TextArea
                customStyle={{
                  width: '100%',
                  height: '4.25rem',
                  fontSize: '0.875rem',
                  borderRadius: '0.25rem',
                }}
                value={settings.description ?? ''}
                onChange={(e) => {
                  handleChangeSettings('description', e.target.value);
                }}
              />
            </div>
            <div className="flex flex-wrap justify-between mr-5">
              <div className="t-a-r-setting-left">
                <h4 className="t-a-r-setting-subtitle">Task Deadline</h4>
                <SelectGroup
                  value={{
                    value: settings.dueDate,
                    label: ((dueDate) =>
                      dueDate === 1 ? `${dueDate} Day` : `${dueDate} Days`)(
                        settings.dueDate,
                      ),
                  }}
                  options={deadlineOptions}
                  isSearchable={true}
                  styles={selectStylesWithNoTitle}
                  onChange={(e) => {
                    handleChangeSettings('dueDate', e.value);
                  }}
                  customStyle={{
                    marginTop: '0.5rem',
                    width: '100%',
                    borderRadius: '0.375rem',
                    fontSize: 14,
                    fontSize: '0.875rem',
                  }}
                />
              </div>
              <div className="t-a-r-setting-right">
                <h4 className="t-a-r-setting-subtitle">Workload Estimate</h4>
                <SelectGroup
                  value={{
                    value: settings.workloadEstimate,
                    label: ((workloadEstimate) =>
                      workloadEstimate === 1
                        ? `${workloadEstimate} Hour`
                        : `${workloadEstimate} Hours`)(settings.workloadEstimate),
                  }}
                  options={workloadEstimateOptions}
                  isSearchable={true}
                  styles={selectStylesWithNoTitle}
                  onChange={(e) => {
                    handleChangeSettings('workloadEstimate', e.value);
                  }}
                  customStyle={{
                    marginTop: '0.5rem',
                    width: '100%',
                    borderRadius: '0.375rem',
                    fontSize: 14,
                    fontSize: '0.875rem',
                  }}
                />
              </div>
            </div>
            <div className="flex flex-wrap justify-between mr-5">
              <div className="t-a-r-setting-left">
                <h4 className="t-a-r-setting-subtitle">Linked Goal</h4>
                <SelectGroup
                  value={{
                    value: settings.linkedGoal,
                    label: settings.linkedGoal,
                  }}
                  options={linkedGoalOptions}
                  isSearchable={true}
                  styles={selectStylesWithNoTitle}
                  onChange={(e) => {
                    handleChangeSettings('linkedGoal', e.label);
                  }}
                  customStyle={{
                    marginTop: '0.5rem',
                    width: '100%',
                    borderRadius: '0.375rem',
                    fontSize: 14,
                    fontSize: '0.875rem',
                  }}
                />
              </div>
              <div className="t-a-r-setting-right">
                <h4 className="t-a-r-setting-subtitle">Reminder</h4>
                <SelectGroup
                  value={{
                    value: settings.reminder,
                    label: settings.reminder,
                  }}
                  options={reminderOptions}
                  isSearchable={true}
                  styles={selectStylesWithNoTitle}
                  onChange={(e) => {
                    handleChangeSettings('reminder', e.label);
                  }}
                  customStyle={{
                    marginTop: '0.5rem',
                    width: '100%',
                    borderRadius: '0.375rem',
                    fontSize: 14,
                    fontSize: '0.875rem',
                  }}
                />
              </div>
            </div>
            <div className="mr-5">
              <h4 className="t-a-r-setting-subtitle">Reminder Type</h4>
              <div className="ml-1 mt-2 mr-1 flex justify-between">
                <div
                  onClick={() => {
                    handleChangeSettings('isSendEmail', !settings.isSendEmail);
                  }}
                >
                  <WorldCard
                    img={email}
                    label="Email"
                    size={'small'}
                    isFocus={settings.isSendEmail}
                    customStyleDiv={{ width: '5rem', height: '5rem' }}
                    customStyleImg={{ width: '2rem', height: '2rem' }}
                    customStyleLabel={{ fontSize: '0.75rem' }}
                  />
                </div>
                <div
                  onClick={() => {
                    handleChangeSettings('isSendWhatsapp', !settings.isSendWhatsapp);
                  }}
                >
                  <WorldCard
                    img={whatsapp}
                    label="Whatsapp"
                    size={'small'}
                    isFocus={settings.isSendWhatsapp}
                    customStyleDiv={{ width: '5rem', height: '5rem' }}
                    customStyleImg={{ width: '2rem', height: '2rem' }}
                    customStyleLabel={{ fontSize: '0.75rem' }}
                  />
                </div>
                <div
                  onClick={() => {
                    handleChangeSettings('isSendPhone', !settings.isSendPhone);
                  }}
                >
                  <WorldCard
                    img={sms}
                    label="SMS"
                    size={'small'}
                    isFocus={settings.isSendPhone}
                    customStyleDiv={{ width: '5rem', height: '5rem' }}
                    customStyleImg={{ width: '2rem', height: '2rem' }}
                    customStyleLabel={{ fontSize: '0.75rem' }}
                  />
                </div>
                <div
                  onClick={() => {
                    handleChangeSettings('isSendNotiApp', !settings.isSendNotiApp);
                  }}
                >
                  <WorldCard
                    img={notification}
                    label="Notification"
                    size={'small'}
                    isFocus={settings.isSendNotiApp}
                    customStyleDiv={{ width: '5rem', height: '5rem' }}
                    customStyleImg={{ width: '2rem', height: '2rem' }}
                    customStyleLabel={{ fontSize: '0.75rem' }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center absolute bottom-0 left-7 right-7">
            <Button
              text="Cancel"
              customStyle={toggleButtonStyles.btnWhite}
              onClick={handleOnClickCancel}
            />
            <Button
              text="Save"
              customStyle={toggleButtonStyles.btnBlue}
              onClick={handleOnClickSave}
            />
          </div>
        </div>
      </div>
    </Zoom>
  );
}

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
