import Tippy from '@tippyjs/react/headless'; // different import path!
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { ToastMessageContext } from '../../context/toast-context';
import { selectStyleCardApplication } from '../../entities/select-style';
import { callApi } from '../../helper/call-api';
import { Button, Upload } from '../../stories';
import admissionApi from 'api/admission';

const ProofCardNew = ({
  isParent = false,
  title,
  num,
  nameCard,
  approved,
  image,
  data = [],
  documentId,
  setIsCallAPI = () => { },
  setRefresh = () => { },
}) => {
  const [listInfo, setListInfo] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  const [documentName, setDocumentName] = useState('');
  const [isDocument, setIsDocument] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [imgPreview, setImgPreview] = useState(false);
  const [status, setStatus] = useState(approved);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    setListInfo(data);
    setDocumentName(nameCard);
  }, [data, nameCard]);

  const styles = {
    box: {
      paddingLeft: '0.625rem',
      paddingRight: '0.625rem',
      paddingTop: '0.125rem',
      paddingBottom: '0.125rem',
    },
    approved: {
      background: `rgba(84, 214, 44, 0.16)`,
      color: '#229A16',
    },
    send: {
      background: `#FFF2E2`,
      color: '#F2C94C',
    },
    reject: {
      background: `#FFF2E2`,
      color: '#EB5757',
    },
    upload: {
      background: `#F1F8F5`,
      color: '#4F4F4F',
    },
    select: selectStyleCardApplication,
  };

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      setIsDocument(true);
      formData.set('file', file);
      formData.set('mediaId', file.name);
      setFormData(formData);
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setImgPreview(reader.result);
      };
      e.target.value = null;
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };

  const handleDeleteDocument = () => {
    // let url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/document/${documentId}`;
    // if (isParent) {
    //   url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/document/parent/${documentId}`;
    // }
    // callApi({
    //   method: 'delete',
    //   url: url,
    //   data: data,
    // })
    let api = admissionApi.deleteApplicationDocumentById(documentId);
    if (isParent)
      api = admissionApi.deleteApplicationDocumentParentById(documentId);
    api.then((response) => {
      if (response.data.success) {
        setIsCallAPI((prev) => !prev);
        setRefresh((prev) => !prev);
        setToastMessage({
          status: 'success',
          title: 'Delete Document Successfully',
          message: response.data.message,
        });
        setIsShowToastMessage(true);
      } else {
        setToastMessage({
          status: 'error',
          title: 'Delete Document Failed',
          message: response.data.message,
        });
        setIsShowToastMessage(true);
      }
    })
      .catch((error) => {
        console.log('Delete Document Error', error);
        setToastMessage({
          status: 'error',
          title: 'Delete Document Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  const handleUpdateDocument = async () => {
    if (!isDocument) {
      setToastMessage({
        status: 'warning',
        title: 'Validate',
        message: 'You do not change document information!',
      });
      setIsShowToastMessage(true);
      return;
    }
    let logo = image;
    if (imgPreview) {
      try {
        const resultUploadImage = await callApi({
          method: 'post',
          url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media`,
          data: formData,
          contentType: 'multipart/form-data',
        });
        if (resultUploadImage.data.success) {
          logo = resultUploadImage.data.data;
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Image Failed',
            message: resultUploadImage.data.message,
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Upload Image Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Edit User Error', error);
      }
    }
    const data = {
      name: documentName,
      fileURL: logo,
      info: listInfo,
      status: status,
    };
    // let url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/document/${documentId}`;
    // if (isParent) {
    //   url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/document/parent/${documentId}`;
    // }
    // callApi({
    //   method: 'patch',
    //   url: url,
    //   data: data,
    // })
    let api = admissionApi.updateApplicationDocumentById(data, documentId);
    if (isParent)
      api = admissionApi.updateApplicationDocumentParentById(data, documentId);
    api.then((response) => {
      if (response.data.success) {
        setIsCallAPI((prev) => !prev);
        setRefresh((prevState) => !prevState);
        setFormData(new FormData());
        setIsDocument(false);
        setImgPreview(false);
        setIsEdit(false);
        setToastMessage({
          status: 'success',
          title: 'Update Document Successfully',
          message: response.data.message,
        });
        setIsShowToastMessage(true);
      } else {
        setToastMessage({
          status: 'error',
          title: 'Update Document Failed',
          message: response.data.message,
        });
        setIsShowToastMessage(true);
      }
    })
      .catch((error) => {
        console.log('Update Document Error', error);
        setToastMessage({
          status: 'error',
          title: 'Update Document Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <div className="mb-5">
      {title && <p className="text-xs mb-2">{`${num + 1}. ${title}`}</p>}
      <div
        className="border rounded-lg border-main-gray"
        style={{ width: '100%', height: '13%' }}
      >
        <div className="flex border-b border-main-gray justify-between items-center h-16">
          {!isEdit ? (
            <h2 className="text-2xl ml-5 font-medium">
              {documentName || 'Document Name'}
            </h2>
          ) : (
            // <Select defaultValue={options[0]} styles={styles.select} options={options} />
            <input
              className="border-none outline-none ml-4"
              type="text"
              placeholder="Document Name"
              value={documentName}
              onChange={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setIsDocument(true);
                setDocumentName(e.target.value);
              }}
            />
          )}
          <ApproveDocument
            isParent={isParent}
            approved={status}
            documentId={documentId}
            setRefresh={setRefresh}
            setStatus={setStatus}
          >
            <div
              className="rounded-lg flex items-center justify-center mr-12 text-10px cursor-pointer"
              style={Object.assign(
                status === 'Approved'
                  ? styles.approved
                  : status === 'Send For Approval'
                    ? styles.send
                    : status === 'Upload'
                      ? styles.upload
                      : styles.reject,
                styles.box,
              )}
            >
              {status}
            </div>
          </ApproveDocument>
        </div>
        <div className="flex justify-center mt-5">
          {!isEdit && (
            <img
              className="mr-28"
              style={{ width: '14.125rem', height: '10.063rem' }}
              src={imgPreview || image}
              alt=""
            />
          )}
          {isEdit && (
            <div className="min-w-[50%] max-w-[50%]">
              <div className="flex justify-center mt-5">
                <img
                  style={{ width: '13.563rem', height: '10rem' }}
                  src={imgPreview ? imgPreview : image}
                  alt="uploaded"
                />
              </div>
              <div className={clsx('flex items-center mt-3.5 mb-2 justify-center')}>
                <Upload
                  customStyle={{ borderRadius: '0.25rem' }}
                  text="Upload Image"
                  id="image-upload"
                  accept="image/*"
                  imageChange={setImgPreview}
                  onChange={(e) => {
                    changeImageHandler(e);
                  }}
                />
              </div>
            </div>
          )}
          <div>
            {listInfo.map((item, key) => (
              <React.Fragment key={key}>
                {isEdit && (
                  <div>
                    <input
                      className="text-sm mb-1.5 mt-3 block ml-4 outline-none"
                      value={item.title}
                      placeholder="Title"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDocument(true);
                        setListInfo((prev) => {
                          const info = [...prev];
                          info[key].title = e.target.value;
                          return info;
                        });
                      }}
                    />
                    <input
                      className="text-sm block ml-4 outline-none"
                      style={{ color: '#8C9094' }}
                      value={item.value}
                      placeholder="Value"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        setIsDocument(true);
                        setListInfo((prev) => {
                          const info = [...prev];
                          info[key].value = e.target.value;
                          return info;
                        });
                      }}
                    />
                  </div>
                )}
                {!isEdit && (
                  <div>
                    <p className="text-xs mb-1.5 mt-3">{item.title}</p>
                    <p className="text-xs" style={{ color: '#8C9094' }}>
                      {item.value}
                    </p>
                  </div>
                )}
              </React.Fragment>
            ))}
            {isEdit && (
              <div
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setListInfo((prevListInfo) => {
                    return [...prevListInfo, { title: '', value: '' }];
                  });
                }}
                className="my-4 mr-4 opacity-70 cursor-pointer text-right"
              >
                + Add Infomation
              </div>
            )}
          </div>
        </div>
        {/* <div className="flex justify-center mt-5">
            <img
              style={{ width: '13.563rem', height: '10rem' }}
              src={imgPreview ? imgPreview : uploadFileSVG}
              alt="uploaded"
            />
          </div> */}
        {!isEdit && (
          <div className={clsx('flex items-center mt-3.5 mb-2 justify-end mr-12')}>
            <Button
              onClick={handleDeleteDocument}
              text="Delete"
              buttonStyle="outlined"
              customStyle={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
            />
            <Button
              text="Edit"
              // buttonDisabled={!isEdit}
              customStyle={{
                paddingLeft: '3.438rem',
                paddingRight: '3.438rem',
                marginLeft: '1.25rem',
              }}
              onClick={() => {
                setIsEdit(true);
              }}
            />
          </div>
        )}
        {isEdit && (
          <div className={clsx('flex items-center mt-3.5 mb-2 justify-end mr-12')}>
            <Button
              onClick={() => {
                setRefresh((prev) => !prev);
                setImgPreview(false);
                setIsEdit(false);
              }}
              text="Cancel"
              buttonStyle="outlined"
              customStyle={{ paddingLeft: '2.5rem', paddingRight: '2.5rem' }}
            />
            <Button
              text="Save"
              // buttonDisabled={!isEdit}
              customStyle={{
                paddingLeft: '3.438rem',
                paddingRight: '3.438rem',
                marginLeft: '1.25rem',
              }}
              onClick={handleUpdateDocument}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProofCardNew;

ProofCardNew.propTypes = {
  title: PropTypes.string,
  num: PropTypes.number,
  nameCard: PropTypes.string,
  approved: PropTypes.string,
};

const ApproveDocument = ({
  isParent,
  children,
  approved,
  documentId,
  setRefresh = () => { },
  setStatus = () => { },
}) => {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const handleUpdateStatus = (status) => {
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/document/${documentId}`,
    //   data: { status: status },
    // })
    admissionApi.updateApplicationDocumentById({ status: status }, documentId)
      .then((response) => {
        if (response.data.success) {
          setRefresh((prev) => !prev);
          setStatus(response.data.data.status);
          // console.log('response.data', response.data);
          setToastMessage({
            status: 'success',
            title: 'Update Document Successfully',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update Document Failed',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        console.log('Update Document Error', error);
        setToastMessage({
          status: 'error',
          title: 'Update Document Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  const renderResult = (attrs) => (
    <div className="" tabIndex="-1" {...attrs}>
      {approved === 'Send For Approval' && !isParent && (
        <div className="w-[8rem] h-[6rem] border rounded-lg bg-[#fff] pl-5">
          <div
            onClick={() => {
              handleUpdateStatus('Approved');
            }}
            className="my-2 py-1 cursor-pointer text-[#229A16]"
          >
            Approve
          </div>
          <div
            onClick={() => {
              handleUpdateStatus('Rejected');
            }}
            className="py-1 cursor-pointer text-[#EB5757]"
          >
            Reject
          </div>
        </div>
      )}
    </div>
  );

  return (
    <Tippy
      // visible
      hideOnClick={true}
      delay={[0, 400]}
      offset={[12, 12]}
      placement="bottom-end"
      interactive={true}
      render={renderResult}
    // onHide={() => {}}
    >
      {children}
    </Tippy>
  );
};
