import React from "react";
import ScholarshipInfo from "./scholarship-info";
import { SelectGroup } from "@stories/index";

const PartnerDiscount = (props) => {
  const { partnerNames, isParent, setFeeInfo, admissionFee, data, startOrder, objValueType, onOpenDialog, idxScholarship, setScholarships } = props

  const onChangePartner = (value) => {
    setScholarships(prev => {
      prev[idxScholarship].info.partnerName = value.idOption
      prev[idxScholarship].info.fees = prev[idxScholarship].info.fees.map(fee => ({ ...fee, value: value.value, valueType: value.discountType.includes(objValueType.Amount) ? objValueType.Amount : objValueType.Percentage }))
      return [...prev]
    })
    setFeeInfo(prev => ({ ...prev, ...value }))
  }

  return (
    <>
      <p className="text-sm mt-2">{startOrder}. Select Partner</p>
      <p className="text-sm mb-2">Please select partner school from the list</p>
      <SelectGroup
        classNameGroup='mb-6'
        isMulti={false}
        value={data?.info?.partnerName ? partnerNames.find(item => item.idOption === data.info.partnerName) : null}
        options={partnerNames}
        onChange={onChangePartner}
      />

      <ScholarshipInfo startOrder={startOrder + 1}
        data={data}
        editable={false}
        isParent={isParent}
        admissionFee={admissionFee}
        objValueType={objValueType}
        onOpenDialog={onOpenDialog}
        idxScholarship={idxScholarship}
        setScholarships={setScholarships}
      />

    </>
  )
}

export default PartnerDiscount;