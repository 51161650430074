const SingleDay = (props) => {
  const style = props.style || { date: 'text-[#8C9094] bg-[#919EAB52]', day: 'text-#8C9094] font-medium' }
  const { data, onClick } = props

  return (
    <div className="text-center">
      {data.date ? (
        <button className={`rounded-full border-[0.5px] text-xs w-8 aspect-square font-medium ${style.date}`}
          onClick={() => onClick(data)} >
          {data.date}
        </button>
      ) : (
        <div className='w-8 aspect-square' />
      )}

      <p className={`text-base ${style.day}`}>
        {data.day}
      </p>
    </div>
  )
}

export default SingleDay;