import { EnquiryStatus } from 'entities/data';

const getInitUrls = () => {
  return [
    {
      textDefault: EnquiryStatus.APPLICATION,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.APPLICATION,
    },
    {
      textDefault: EnquiryStatus.SIGNATURE,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.SIGNATURE,
    },
    {
      textDefault: EnquiryStatus.EXAM,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.EXAM,
    },
    {
      textDefault: EnquiryStatus.PRINCIPAL_APPROVAL,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.PRINCIPAL_APPROVAL,
    },
    {
      textDefault: EnquiryStatus.DOCUMENT_VERIFICATION,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.DOCUMENT_VERIFICATION,
    },
    {
      textDefault: EnquiryStatus.FEE_PAYMENT,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.FEE_PAYMENT,
    },
    {
      textDefault: EnquiryStatus.COMPLETE,
      isDone: false,
      isCurrent: false,
      name: EnquiryStatus.COMPLETE,
    },
  ];
};

const mergeCustomStage = ({ initUrls, listStage }) => {
  const result = [];
  let isStartIndex = false;

  for (let stageIndex in listStage) {
    const { middle } = listStage[stageIndex];
    const { textDefault, text } = middle;

    if (textDefault === initUrls[0].textDefault) {
      isStartIndex = true;
      result.push({ ...initUrls[0], name: text });
      continue;
    }

    if (isStartIndex) {
      if (textDefault === initUrls[3].textDefault) {
        result.push({ ...initUrls[3], name: text });
      } else if (textDefault === initUrls[2].textDefault) {
        result.push({ ...initUrls[1], name: EnquiryStatus.SIGNATURE });
        result.push({ ...initUrls[2], name: text });
      } else {
        const { formId, text } = middle;
        const stage = { name: text, formId, isDone: false, isCurrent: false };
        result.push(stage);
      }
    }
  }
  return [...result, ...initUrls.slice(4)];
};

const getListUrl = ({ initUrls, listStage, prefix, currentStep, nextStep }) => {
  const mergeStage = mergeCustomStage({ initUrls, listStage, prefix });

  if (mergeStage.length === 0) return [];

  let isDone = true;
  let isCurrent = false;

  if (currentStep === EnquiryStatus.COMPLETE) {
    for (let stage of mergeStage) {
      stage.isDone = isDone;
      stage.isCurrent = isCurrent;
    }
    return mergeStage;
  }

  // find current stage
  if (currentStep === EnquiryStatus.NEW) {
    mergeStage[0].isCurrent = true;
    return mergeStage;
  }

  for (let stage of mergeStage) {
    const name = stage.textDefault || stage.name;

    if (name === currentStep) {
      if (nextStep) {
        stage.isDone = isDone;
        stage.isCurrent = isCurrent;
        isCurrent = true;
        isDone = false;
        continue;
      } else {
        stage.isDone = false;
        stage.isCurrent = true;
        break;
      }
    }

    if (isCurrent) {
      stage.isCurrent = isCurrent;
      stage.isDone = isDone;
      break;
    }

    stage.isDone = isDone;
    stage.isCurrent = isCurrent;
  }

  return mergeStage;
};

const getListUrlStage = ({ enquiry, stages }) => {
  const currentStep = enquiry.currentStepShowing;
  const nextStep = enquiry.nextStep;
  const initUrls = getInitUrls();
  const result = getListUrl({ initUrls, listStage: stages, currentStep, nextStep });
  return result;
};

const DOCUMENT_STATUS = {
  new: 'new',
  upload: 'Upload',
  waitingApproval: 'Send For Approval',
  approved: 'Approved',
  rejected: 'Rejected',
};

const convertNumberToString2Char = (num) => {
  return String(num).padStart(2, '0');
};

const convertSecondToHourMinuteSecond = (count) => {
  if (!count) return '00:00';

  const minutes = Math.floor(count / 60);
  const seconds = count - minutes * 60;

  return `${convertNumberToString2Char(minutes)}:${convertNumberToString2Char(seconds)}`;
};

export { getInitUrls, getListUrlStage, DOCUMENT_STATUS, convertSecondToHourMinuteSecond };
