import { callApi } from '@helper/call-api';

class AdmissionNoteApi {
  // constructor() {
  //   this.baseUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/notes`;
  // }

  getListNote = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/notes/list`,
      params,
    });
  };

  createNote = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/notes`,
      params,
      data,
    });
  };
}

const admissionNoteApi = new AdmissionNoteApi();
export default admissionNoteApi;
