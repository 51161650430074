import * as _ from 'lodash';
import { isErrorParentInfo, validEmail, validPhone } from '@utils/validate';
import {
  deleteKeysInObject,
  gender,
  keyRelationship,
  languages,
  nationalities,
  occupation,
  relationship,
  sortObj,
  yesNo,
} from '@utils/utils';
import mediaApi from 'api/media';
import authApi from 'api/auth';

const handleSingleObject = (objIndex, listFile, obj, objName) => {
  if (obj?.photoURL && obj?.photoURL?.name) {
    listFile.push(obj.photoURL);
    objIndex[objName] = 'photoURL';
  }
};

const getPhotoIndex = (data) => {
  const objIndex = {};
  const listFile = [];
  Object.keys(data).forEach((item) => {
    if (Array.isArray(data[item])) {
      objIndex[item] = [];
      data[item].forEach((obj, order) => {
        handleSingleObject(objIndex[item], listFile, obj, order);
      });
    } else {
      handleSingleObject(objIndex, listFile, data[item], item);
    }
  });
  return { objIndex, listFile };
};

const promiseUploadPhoto = (file) => {
  const formData = new FormData();
  formData.set('file', file);
  formData.set('mediaId', file.name);
  return mediaApi.uploadMediaAdmission(formData);
};

const syncPhotoUrl = async ({ data, listFile, objIndex }) => {
  try {
    if (listFile.length !== 0) {
      const listRes = await Promise.all(listFile.map((item) => promiseUploadPhoto(item)));
      if (listRes.some((item) => !item.data.success)) {
        return { error: true };
      } else {
        Object.keys(objIndex).forEach((item) => {
          if (objIndex.hasOwnProperty(item)) {
            if (Array.isArray(objIndex[item])) {
              objIndex[item].forEach((fieldName, index) => {
                const res = listRes.shift();
                data[item][index][fieldName] = res.data.data;
              });
            } else {
              const res = listRes.shift();
              data[item][objIndex[item]] = res.data.data;
            }
          }
        });
        return { error: false };
      }
    }
    return { error: false };
  } catch (error) {
    // console.log('Submit Application', error);
    return { error: true };
  }
};

const handleFamily = async ({ listParent, parentUserIdChosen }) => {
  try {
    if (!listParent || listParent.length === 0) return { error: false, listParent: [] };
    const promiseParent = [];
    const indexNewParent = [];
    for (let index in listParent) {
      const parent = listParent[index];
      const { fullName, email, phoneNumber, photoURL, gender, isDelete } = parent;
      if (isDelete) continue;
      let firstName = '';
      let middleName = '';
      let lastName = '';
      if (fullName) {
        const arrayName = parent.fullName.split(' ');
        firstName = arrayName.shift();
        if (arrayName.length !== 0) lastName = arrayName.pop();
        middleName = arrayName.join(' ');
      }
      const dataToCallApi = {
        user: { name: fullName || '', email, phoneNumber, photoURL },
        parent: { firstName, lastName, middleName, email, phoneNumber, gender },
      };
      if (parent.parentUserId) {
        const { parentUserId, gender, email, phoneNumber, photoURL, infoOrigin } = parent;
        const newInfo = {
          fullName: fullName || null,
          gender: gender || null,
          email: email || null,
          photoURL: photoURL || null,
          phoneNumber: phoneNumber || null,
          parentUserId,
        };
        // update parent already exists
        if (!_.isEqual(newInfo, infoOrigin)) {
          const api = authApi.updateParent(dataToCallApi, parentUserId);
          promiseParent.push(api);
        } else {
          promiseParent.push(Promise.resolve({ data: { data: { message: 'Success' } } }));
        }
        if (parent.infoOrigin) delete parent.infoOrigin;
      } else {
        // add new parent
        if (email && phoneNumber) {
          const api = authApi.createParent(dataToCallApi);
          promiseParent.push(api);
          indexNewParent.push(index);
        }
      }
    }
    // handle list promise if need
    // let result = []
    let error = false;
    if (promiseParent.length !== 0) {
      const listRes = await Promise.all(promiseParent);
      for (let res of listRes) {
        if (!res?.data?.data?.message === 'Success') {
          error = true;
          throw Error(res);
        }
      }
      indexNewParent.forEach((item) => {
        listParent[item].parentUserId = listRes[item].data.data.id;
      });
      // listParent.forEach(item => {
      //   const { parentUserId } = item
      //   if (parentUserIdChosen?.current?.hasOwnProperty(parentUserId)) {
      //     if (result.length !== 0) {
      //       const indx = result.findIndex((item1) => item1.parentUserId = parentUserId)
      //       if (indx > -1) {
      //         result.splice(indx, 1)
      //         result.push(item)
      //       }
      //     } else {
      //       result.push(item)
      //     }
      //   } else result.push(item)
      // })
      // error = false;
      return {
        listParent: listParent
          ?.filter((item) => !item.isDelete)
          ?.map((item) => ({
            parentUserId: item.parentUserId,
            occupation: item.occupation,
            relationship: item.relationship,
          })),
        error,
      };
    }
    return {
      error: false,
      listParent: listParent.filter((item) => Object.keys(item).length > 1),
    };
  } catch (error) {
    // console.log('handle family error', error);
    return { error: true, listParent: [] };
  }
};

const syncStringValueSingle = (obj, valid) => {
  for (const [key, value] of Object.entries(obj)) {
    if (!['infoOrigin', 'photoURL'].includes(key) && typeof value === 'object')
      obj[key] = value?.value;
    if (key === 'email' && !validEmail(value)) {
      if (valid) valid = false;
    }
    if (key === 'phoneNumber' && !validPhone(value)) {
      if (valid) valid = false;
    }
  }
  return obj;
};

const syncStringValueAndValidate = (data) => {
  // transform object value to string and check valid
  const result = {};
  let validate = true;
  for (const [key, value] of Object.entries(data)) {
    let sub;
    if (Array.isArray(value)) {
      sub = [];
      value.forEach((item) => {
        sub.push(syncStringValueSingle(item, validate));
      });
    } else {
      sub = syncStringValueSingle(value, validate);
    }
    result[key] = sub;
  }
  return { result, validate };
};

const syncObjValueSingle = (obj) => {
  Object.keys(obj)?.forEach((name) => {
    let tmp;
    switch (name) {
      case 'gender':
        tmp = gender.find((item) => item.value === obj[name]);
        if (tmp) obj[name] = tmp;
        break;
      case 'nationality':
        tmp = nationalities.find((item) => item.value === obj[name]);
        if (tmp) obj[name] = tmp;
        break;
      case 'languageName':
        tmp = languages.find((item) => item.value === obj[name]);
        if (tmp) obj[name] = tmp;
        break;
      case 'occupation':
        tmp = occupation.find((item) => item.value === obj[name]);
        if (tmp) obj[name] = tmp;
        break;
      case 'relationship':
        tmp = relationship.find((item) => item.value === obj[name]);
        if (tmp) obj[name] = tmp;
        break;
      case 'specialEducationProgram':
      case 'seriousDisciplinaryProceedings':
      case 'repeatedOrSkipped':
      case 'schoolTransport':
      case 'schoolLunch':
        tmp = yesNo.find((item) => item.value === obj[name]);
        if (tmp) obj[name] = tmp;
        break;
      default:
        break;
    }
  });
};

const syncObjValue = (data) => {
  // transform string to objValue
  for (const [key, value] of Object.entries(data)) {
    if (Array.isArray(value)) {
      value.forEach((item) => syncObjValueSingle(item));
    } else {
      syncObjValueSingle(value);
    }
  }
};

const isValidParents = (listParent) => {
  if (listParent.length !== 0) {
    for (let parent of listParent) {
      if (parent.isDelete) continue;
      if (isErrorParentInfo(parent)) {
        return false;
      }
    }
  }
  return true;
};

const getDataApi = async ({ info, enquiryId, parentUserIdChosen }) => {
  // sort data
  const dataSorted = sortObj(info);
  // check valid parent info
  if (!isValidParents(dataSorted.parentInfo))
    throw new Error('Invalid family information');
  const { objIndex, listFile } = getPhotoIndex(dataSorted);
  // convert data into object {string: string}
  const { result: data, validate } = syncStringValueAndValidate(
    JSON.parse(JSON.stringify(dataSorted)),
  );
  if (!validate) throw new Error('Please check valid');
  const { error } = await syncPhotoUrl({ data, listFile, objIndex });
  if (error) throw new Error('Submit Photo Failed');
  // handle list parent
  // const { listParent: familyHandled, error: errorFamily } = await handleFamily({
  //   listParent: data.parentInfo,
  //   parentUserIdChosen: parentUserIdChosen,
  // });
  // if (errorFamily) throw new Error('Submit Family Failed');
  const {
    studentInfo,
    addressInfo,
    schoolInfo,
    schoolServices,
    otherQuestions,
    healthInfo,
    languageInfo,
    learningInfo,
  } = data;
  const academicYearId = studentInfo?.year;
  const newStudentInfo = deleteKeysInObject({
    obj: studentInfo,
    listKey: ['grade', 'year'],
  });
  const dataApi = {
    applicationInfo: {
      enquiryId,
      info: newStudentInfo,
      programGradeId: studentInfo?.programGrade,
      academicYearId,
    },
    familyInfo: data.parentInfo,
    additionalInfo: {
      addressInfo,
      healthInfo,
      learningInfo,
      schoolServices,
      otherQuestions,
      schoolInfo: schoolInfo.filter(
        (item) => !item.isDelete && Object.keys(item).length !== 0,
      ),
      languageInfo: languageInfo.filter(
        (item) => !item.isDelete && Object.keys(item).length !== 0,
      ),
    },
  };
  return dataApi;
};

const handleChangeField = ({ setInfo, name, order, objValue, key, value }) => {
  // objValue: object key:value
  setInfo((prev) => {
    if (name === 'studentInfo' && key === 'year')
      return { ...prev, [name]: { ...prev[name], [key]: value, programGrade: null } };
    if (name === 'addressInfo' && key === 'isPrimaryAddress') {
      const tmp = prev[name];
      tmp.forEach((item, index) => {
        if (index === order) {
          item[key] = !item[key];
        } else {
          if (item?.[key]) item[key] = false;
        }
      });
      prev[name][order] = { ...prev[name][order] };
      return { ...prev, [name]: [...prev[name]] };
    }
    if (order >= 0) {
      if (objValue) {
        prev[name][order] = { ...prev[name][order], ...objValue };
      } else {
        prev[name][order] = { ...prev[name][order], [key]: value };
      }
      return { ...prev, [name]: [...prev[name]] };
    }
    return { ...prev, [name]: { ...prev[name], [key]: value } };
  });
};

const handleAddElement = ({ setInfo, name }) => {
  setInfo((prev) => {
    prev[name] = [...prev[name], {}];
    return { ...prev };
  });
};

const handleRemoveElement = ({ setInfo, name, order, parentUserIdChosen }) => {
  setInfo((prev) => {
    // delete parentUserId in parentUserIdChosen
    prev[name][order].isDelete = true;
    if (name === 'parentInfo') {
      const id = prev[name][order]?.parentUserId;
      if (id) delete parentUserIdChosen.current[id];
    }
    return {
      ...prev,
      [name]: [...prev[name]],
    };
  });
};

const getListField = ({ listProgramGrade, listYear }) => ({
  student: [
    { label: 'First Name', name: 'firstName', type: 'input' },
    { label: 'Last Name', name: 'lastName', type: 'input' },
    {
      label: 'Date of Birth',
      name: 'dateOfBirth',
      type: 'date',
    },
    { label: 'Gender', name: 'gender', type: 'select', isMulti: false, data: gender },
    { label: 'Campus', name: 'campus', type: 'input' },
    {
      label: 'Enrollment Year',
      name: 'year',
      type: 'select',
      isMulti: false,
      data: listYear,
    },
    {
      label: 'Grade of Entry',
      name: 'programGrade',
      type: 'new-select',
      isMulti: false,
      data: listProgramGrade,
    },
    {
      label: 'Nationality',
      name: 'nationality',
      type: 'select',
      isMulti: false,
      data: nationalities,
    },
    { label: 'Phone Number', name: 'phoneNumber', type: 'input' },
    { label: 'Email', name: 'email', type: 'input' },
  ],
  parent: [
    { label: 'Full Name', name: 'fullName', type: 'input' },
    { label: 'Gender', name: 'gender', type: 'select', isMulti: false, data: gender },
    {
      label: 'Relation with applicant',
      name: 'relationship',
      type: 'select',
      isMulti: false,
      data: relationship,
    },
    {
      label: 'Occupation',
      name: 'occupation',
      type: 'select',
      isMulti: false,
      data: occupation,
    },
  ],
  address: [
    {
      label: 'Location Name ',
      name: 'locationName',
      type: 'input',
    },
    {
      label: 'Primary Resident Person',
      name: 'primaryResidentPerson',
      type: 'input',
    },
    { label: 'Full Address', name: 'fullAddress', type: 'input' },
    { label: 'Address', name: 'address', type: 'input' },
    { label: 'Block', name: 'block', type: 'input' },
    { label: 'Subdistrict', name: 'subdistrict', type: 'input' },
    { label: 'District', name: 'district', type: 'input' },
    { label: 'Division', name: 'division', type: 'input' },
    { label: 'State', name: 'state', type: 'input' },
    { label: 'Postal Code', name: 'postalCode', type: 'input' },
    { label: 'Country', name: 'country', type: 'input' },
  ],
  school: [
    { label: 'School Name', name: 'schoolName', type: 'input' },
    { label: 'Start Grade', name: 'startGrade', type: 'input' },
    { label: 'End Grade', name: 'endGrade', type: 'input' },
  ],
  health: [
    { label: 'Blood Group', name: 'bloodGroup', type: 'input' },
    { label: 'Known Health Issues', name: 'healthIssues', type: 'input' },
    {
      label: 'Any other history that needs to be informed to the school doctor',
      name: 'anyOtherHistoryThatNeedsToBeInformedToTheSchoolDoctor',
      type: 'input',
    },
    { label: 'Any Food Allergies', name: 'foodAllergies', type: 'input' },
    { label: 'Any Medical Allergies ', name: 'medicalAllergies', type: 'textArea' },
  ],
  learning: [
    {
      label:
        'Has your child ever participated in a special education programmes (i.e gifted and talented, learning difficulty, speech therapy, language therapy or IEP in the last five years and has the child received any support?',
      type: 'button',
      name: 'specialEducationProgram',
      data: yesNo,
    },
    {
      label: 'Has your child ever been subjected to serious disciplinary Procedure? ',
      type: 'button',
      name: 'seriousDisciplinaryProceedings',
      data: yesNo,
    },
    {
      label: 'Has your child ever repeated/ skipped any grade level?',
      type: 'button',
      name: 'repeatedOrSkipped',
      data: yesNo,
    },
    {
      label: 'Please Describe',
      type: 'textArea',
      name: 'desc',
    },
  ],
  schoolServices: [
    {
      label: 'Does your child require School Transport?',
      type: 'button',
      name: 'schoolTransport',
      data: yesNo,
    },
    {
      label: 'Does your child require School Lunch?',
      type: 'button',
      name: 'schoolLunch',
      data: yesNo,
    },
  ],
  otherQuestions: [
    {
      label: 'Where did you hear about us?',
      type: 'input',
      name: 'whereDidYouHearAboutUs',
    },
    {
      label: 'Why did you choose to educate your child in an international School?',
      type: 'input',
      name: 'whyDidYouChooseToEducateYourChildInAnInternationalSchool',
    },
  ],
});

const scroll = () => {
  const ele = document.getElementsByClassName('classForScroll')[0];
  if (ele) {
    window.scrollTo({ top: ele.offsetTop - 0.2 * ele.offsetTop, behavior: 'smooth' });
  }
};

const getPrefixUserName = (relationship) => {
  if (!relationship) return '';
  switch (relationship) {
    case keyRelationship.Father:
    case keyRelationship.Brother:
    case keyRelationship.Grandfather:
      return 'Mr. ';
    case keyRelationship.Sister:
      return 'Ms. ';
    case keyRelationship.Mother:
    case keyRelationship.Grandmother:
      return 'Mrs. ';
    default:
      return '';
  }
};

export {
  scroll,
  handleAddElement,
  handleRemoveElement,
  handleChangeField,
  getDataApi,
  getPhotoIndex,
  syncPhotoUrl,
  handleFamily,
  syncStringValueAndValidate,
  syncObjValue,
  getListField,
  getPrefixUserName,
};
