import React, { useContext, useEffect, useState } from 'react'
import { Zoom, Fade, Slide } from 'react-reveal';
import clsx from 'clsx'
import { phonefill, women } from '../image'
import DialogCall from './dialog-call'
import ButtonBottom from "@components/application-panel/sub-components/button-bottom";
import SingleCall from "@components/application-panel/sub-components/single-call";
import DialogCallEnd from './dialog-call-end'
import knowlarity from 'api/knowlarity'
import { CALL_RESULT_TYPE, CALL_TYPE, EVENT_CALL_TYPE } from '@constant/index'
import admissionCallApi from 'api/admission-call'
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error'
import { ToastMessageContext } from 'context/toast-context'
import ActiveCall from './active-call';
import { getStatus } from '@helper/application-panel';

const CallPick = (props) => {
  const { visible, enquiry, setLabelNum, parents, setVisible } = props
  const [objParents, setObjParents] = useState({})
  const [personCalled, setPersonCalled] = useState({})
  const [callHistory, setCallHistory] = useState([])
  const [eventCall, setEventCall] = useState({})
  const [refresh, setRefresh] = useState(false)
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext)

  const fetchCallHistory = async (applicationParents, signal) => {
    const params = {
      start_time: new Date(new Date().setMonth(new Date().getMonth() - 3)).toISOString(),
      end_time: new Date().toISOString(),
      knowlarity_number: process.env.REACT_APP_KNOWLAIRTY_SR_NUMBER,
      call_type: CALL_TYPE.incoming, // 0 for incoming, 1 for outgoing 
      limit: 100,
    }
    const responses = await Promise.all([
      admissionCallApi.list({
        campusId: enquiry?.campusId,
        enquiryId: enquiry?.id,
        signal
      }),
      ...Object.values(applicationParents)?.map((item) => knowlarity.callLog({
        ...params,
        customer_number: item?.phoneNumber
        // customer_number: '+918297266111'
      }, signal)),
    ])
    const admCall = responses.shift()
    if (checkErrorApiFetch(admCall, setToastMessage, setIsShowToastMessage, '', false)) {
      const admCallHistory = admCall?.data?.data || []
      let callLog = responses.reduce((accumulator, current) => {
        if (typeof current.data === 'string') {
          let data
          eval('data=' + current.data);
          return accumulator.concat(data.objects)
        }
        return accumulator.concat(current.data.objects)
      }, [])
      callLog = callLog.filter(item => Object.keys(applicationParents).includes(item.customer_number))
      callLog = callLog.concat(admCallHistory)
      const sorted = callLog.sort((a, b) => new Date(b.start_time || b.dateCreated).getTime() - new Date(a.start_time || a.dateCreated).getTime());
      const callMissed = callLog.reduce((accumulator, current) => {
        if (current?.agent_number?.startsWith(CALL_RESULT_TYPE['Agent Missed']) || current?.agent_number?.startsWith(CALL_RESULT_TYPE['Customer Missed']))
          return accumulator += 1
        return accumulator
      }, 0)
      setLabelNum(prev => ({ ...prev, call: callMissed }))
      setCallHistory(sorted)
    }
  }

  useEffect(() => {
    const controller = new AbortController();
    const tmp = {}
    if (parents?.length !== 0) {
      parents.forEach(item => tmp[item?.phoneNumber] = item)
    }
    if (enquiry?.id && enquiry?.campusId && Object.keys(tmp).length !== 0) {
      setObjParents(tmp)
      fetchCallHistory(tmp, controller.signal)
    }
    return () => {
      controller.abort();
    }
  }, [refresh, enquiry, parents])

  const onCloseListCall1 = () => {
    setVisible(prev => ({ ...prev, listCall1: false }))
  }

  const onCloseListCall2 = () => {
    setVisible(prev => ({ ...prev, listCall2: false }))
  }

  const onToggleListCall1 = () => {
    setVisible(prev => ({ ...prev, listCall2: false, listCall1: !prev.listCall1 }))
  }

  const onToggleListCall2 = () => {
    setVisible(prev => ({ ...prev, listCall1: false, listCall2: !prev.listCall2 }))
  }

  const onCloseCalling = () => {
    setVisible(prev => ({ ...prev, calling: false }))
  }

  const onOpenCalling = () => {
    setVisible(prev => ({ ...prev, calling: true }))
  }

  const onOpenNote = () => {
    setVisible(prev => ({ ...prev, callNote: true }))
  }

  const onCloseNote = () => {
    setVisible(prev => ({ ...prev, callNote: false }))
  }

  const onPublishCallMissed = async (data) => {
    try {
      console.log('onPublishCallMissed')
      const apiData = {
        enquiryId: enquiry?.id,
        parentId: personCalled?.parentUserId,
        status: getStatus(data?.business_call_type),
      }
      console.log('apiData', apiData)
      const res = await admissionCallApi.create({ campusId: enquiry?.campusId, data: apiData })
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, '', false)) {
        onCloseCalling()
        setToastMessage({ status: 'info', title: 'Missed Call' });
        setIsShowToastMessage(true);
        setRefresh(prev => !prev)
      } else {
        setToastMessage({ status: 'error', title: 'Store History Failed' });
        setIsShowToastMessage(true);
      }
    } catch (error) {
      console.log('error', error)
      setToastMessage({
        status: 'error',
        title: 'Store History Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }

  function getRealtimeData(e, call_id, source) {
    try {
      const data = JSON.parse(e.data)
      if (data.uuid === call_id) {
        const type = data.type
        switch (type) {
          // case EVENT_CALL_TYPE.AGENT_CALL:
          // case EVENT_CALL_TYPE.CUSTOMER_ANSWER:
          //   return setEventCall(data)
          case EVENT_CALL_TYPE.CDR:
            source.close()
            if (data.business_call_type === CALL_RESULT_TYPE.Phone) {
              onCloseCalling()
              onOpenNote()
              return setEventCall(data)
            } else {
              onCloseCalling()
              onPublishCallMissed(data)
            }
            break;
          default:
            break
        }
      }
    } catch (error) {
      console.log('getRealtimeData error', error)
      throw Error(error)
    }
  }

  const onClickCall = async (info) => {
    try {
      onCloseListCall1()
      onCloseListCall2()
      setPersonCalled(info)
      if (!info?.phoneNumber) {
        setToastMessage({
          status: 'error',
          title: 'Phone Number Not Found',
        });
        setIsShowToastMessage(true);
        return
      }
      const resListRegisterNotification = await knowlarity.listRegisterNotification()
      if (resListRegisterNotification?.status !== 200) return
      const isRegistered = resListRegisterNotification?.data?.objects?.some(item => item.k_number === process.env.REACT_APP_KNOWLAIRTY_SR_NUMBER)
      if (!isRegistered) {
        const resRegister = await knowlarity.registerNotification()
        if (resRegister.status !== 200 || !resRegister.data?.message !== 'Successfully registered.') return
      }
      const res = await knowlarity.makeACall(info.phoneNumber)
      // const res = await knowlarity.makeACall('+917799088888')
      if (res?.data?.success) {
        onOpenCalling()
        const call_id = res?.data?.success?.call_id
        const source = knowlarity.streaming()
        source.onmessage = e => getRealtimeData(e, call_id, source);
        source.onerror = (e) => {
          source.close();
        }
      } else {
        onCloseCalling()
        throw Error(res?.data?.error?.message || "Make Outbound Call Failed")
      }
    } catch (err) {
      console.log('onClickCall ', err)
      setToastMessage({
        status: 'error',
        title: 'Make Outbound Call Failed',
        message: err.response?.data?.message || err,
      });
      setIsShowToastMessage(true);
    }
  }

  return (
    <Zoom bottom duration={700} when={visible.call}>
      <div className={clsx('windows-field', visible.call ? 'pointer-events-auto' : 'pointer-events-none hidden')}>
        <div className='pt-5 h-full'>
          <div className='flex items-center justify-between px-5'>
            <div className='flex item'>
              <div
                className='w-10 h-10 mr-3 flex items-center justify-center rounded-lg mb-7'
                style={{ background: '#FFF7CD' }}
              >
                <img src={phonefill} alt='' />
              </div>
              <p className='font-semibold mt-2.5'>My Calls</p>
            </div>
            <button className='w-6 h-6 text-white text-xl flex items-center justify-center rounded-full mb-9'
              onClick={onToggleListCall1}
            >
              <img src={phonefill} alt='' />
            </button>
          </div>

          {visible.calling && (
            <ActiveCall info={personCalled} className='w-[90%] ml-[5%] p-3 mb-3' />
          )}

          {visible.callNote && (
            <DialogCallEnd eventCall={eventCall}
              onClose={onCloseNote}
              personCalled={personCalled}
              enquiry={enquiry}
              setRefresh={setRefresh}
              className='w-[90%] ml-[5%] shadow p-3 rounded-lg mb-3'
            />
          )}

          {callHistory?.length !== 0 ? (
            <div className='h-full mt-0'>
              <p className='text-base font-medium mb-2 px-5'>Call History</p>
              <div className={'w-full overflow-y-auto max-h-[65%] px-5 pt-1'}>
                {callHistory.map((item, index) => {
                  return <SingleCall key={`callHistory${index}`} data={item} className={'mb-5'} applicationParents={objParents} />
                })}
              </div>
            </div>
          ) : (
            <div className={'px-5'}>
              <p className='text-base font-medium'>Upcoming</p>
              <div className='text-center mt-12'>
                <img className='m-auto mb-3' src={women} />
                <p className='text-base font-medium'>You’ve made no calls.</p>
                <p className='text-sm font-medium'>🤔 Maybe it’s a good time to get
                  started.</p>
              </div>
            </div>
          )}

        </div>

        <div className={clsx('application-panel-closed', visible.listCall2 ? 'opacity-1' : 'invisible opacity-0')}
          style={{ transitionDuration: '0.3s' }}
          onClick={onCloseListCall2}>
          <Slide bottom when={visible.listCall2} duration={300}>
            <div className={clsx('shadow bg-white rounded-md p-1', visible.listCall2 ? "block" : "hidden pointer-events-none")}
              style={{
                width: '21.75rem',
                bottom: '4rem',
                right: '20px',
                height: 'fit-content',
                position: "absolute",
              }}
            >
              <p className='my-1 ml-2 text-base font-medium text-[#212B36]'>Call</p>
              <DialogCall
                applicationParents={objParents}
                onClickCall={onClickCall}
              />
            </div>
          </Slide >
        </div>
        <ButtonBottom titleButton={'Call'} onClick={onToggleListCall2} />
        <div className={clsx('application-panel-closed', visible.listCall1 ? 'opacity-1' : 'invisible opacity-0')}
          style={{ transitionDuration: '0.3s' }}
          onClick={onCloseListCall1}>
          <Fade right when={visible.listCall1} duration={500}>
            <div className={clsx('shadow bg-white rounded-md p-1', visible.listCall1 ? "block" : "hidden pointer-events-none")}
              style={{
                width: '308px',
                position: "absolute",
                zIndex: "10",
                top: '7.5rem',
                right: '0.5rem',
                boxSizing: 'border-box'
              }}
            >
              <p className='my-1 ml-2 text-base font-medium text-[#212B36]'>Call</p>
              <DialogCall
                applicationParents={objParents}
                onClickCall={onClickCall}
              />
            </div>
          </Fade >
        </div>

      </div>
    </Zoom>
  )
}

export default CallPick
