import SingleCallCard from "@components/single-call-card";

const DialogCall = (props) => {
  const { applicationParents, onClickCall } = props

  return (
    <div className="w-full max-h-[11rem] overflow-y-auto overflow-x-hidden pt-1 pb-2 px-4">
      {Object.values(applicationParents)?.length !== 0 ? (
        Object.values(applicationParents).map((data, index) => (
          <SingleCallCard key={`applicationParents${index}`} data={data} onClick={() => onClickCall(data)} />
        ))
      ) : <p>No Information</p>}
    </div>
  )
}

export default DialogCall;