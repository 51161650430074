import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { toggleButtonStyles } from '../../../../entities/select-style';
import { Button, Input, ToggleButton } from '../../../../stories';
import { ToastMessageContext } from 'context/toast-context';
import './create-class.scss';
import admissionApi from 'api/admission';
import centralAdminApi from 'api/central-admin';

export default function CreateClassPanel(props) {
  const {
    visible,
    setVisible = () => { },
    classInfo,
    setListGradeProgrammes = () => { },
    handleAddClass = () => { },
    idx,
    setIsRefresh = () => { },
  } = props;
  const [classDetail, setClassDetail] = useState({});
  const [selectNumberOfSections, setSelectNumberOfSections] = useState(0);
  const [selectMaxStudentsPerSection, setSelectMaxStudentsPerSection] = useState(0);
  const [selectMinAge, setSelectMinAge] = useState(0);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const resetHandle = () => {
    const data = {
      ...classInfo.class,
      index: classInfo.index,
      programGradeId: classInfo.programGradeId,
    };
    setSelectNumberOfSections(classInfo?.class?.noSections || 0);
    setSelectMaxStudentsPerSection(classInfo.class.maxStudentsPerSection || 0);
    setSelectMinAge(classInfo.class.minAge || 0);
    setClassDetail(data);
  };

  useEffect(() => {
    resetHandle();
  }, [classInfo]);

  const onClickSaveHandle = () => {
    setListGradeProgrammes((prevState) => {
      const dataUpdate = [...prevState];
      const { index, ...res } = classDetail;
      dataUpdate[classInfo.index].programGrade[idx].class = { ...res };
      return dataUpdate;
    });
    const classInfoUpdate = {
      ...classDetail,
      extraInfo: {
        programName: classInfo?.programName,
        gradeName: classInfo?.gradeName,
      },
    };
    handleAddClass(classInfoUpdate);
    setVisible(false);
  };

  const handleDeleteClass = (classId) => {
    centralAdminApi.deleteClass(classId)
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Disable class successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setIsRefresh((prev) => !prev);
          setVisible(false);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Disable class failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((err) => {
        setToastMessage({
          status: 'error',
          title: 'Disable class failed',
          message: err.data?.message || '',
        });
        setIsShowToastMessage(true);
      });
  };

  const onClickDisable = () => {
    console.log('+++++++++++++');
    if (!classDetail?.id) return;
    centralAdminApi.getClass(classDetail.id)
      .then((res) => {
        console.log('res', res);
        if (res.data?.success) {
          if (res.data?.data?.isHaveEnquiry) {
            setToastMessage({
              status: 'warning',
              title: 'Can Not Disable Class',
              message:
                'There are already enquiries created for this class, you cannot disable it.',
            });
            setIsShowToastMessage(true);
          } else {
            handleDeleteClass(classDetail?.id);
          }
        } else {
          setToastMessage({
            status: 'error',
            title: 'Disable class failed',
            message: res.data?.message || '',
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((err) => {
        setToastMessage({
          status: 'error',
          title: 'Disable class failed',
          message: err.data?.message || '',
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <Fade right duration={500}>
      <div
        style={{
          left: 'unset',
        }}
        className={clsx(
          'create-grade-panel',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-auto w-32">
            <div>
              <h2 className="heading-grade-name text-2xl">
                {classInfo?.gradeName || 'Grade Name'}
              </h2>
              <p className="heading-program-name text-sm">
                {classInfo?.programName || 'Program Name'}
              </p>
            </div>
          </div>
          <button className="mr-5 pb-5" onClick={() => setVisible(false)}>
            <Close />
          </button>
        </div>
        <hr className="mt-2" />
        <div className="h-[calc(100%-7rem)] w-full">
          <div className="flex flex-col justify-between overflow-y-auto overflow-x-hidden h-full w-full">
            <div className="">
              <div>
                <h3 className="body-heading">1. Class info</h3>
                <p className="body-content">
                  Please fill the information below to set the class information
                </p>
                <Input
                  onChange={(e) => {
                    setClassDetail((prevState) => {
                      return {
                        ...prevState,
                        name: e.target.value,
                      };
                    });
                  }}
                  label="Class Name"
                  name="class-name"
                  value={classDetail?.name || ''}
                  customStyle={{
                    width: '26.1875rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                    marginTop: '1rem',
                    paddingLeft: '1.25rem',
                  }}
                />
                <div className="flex flex-wrap mt-4">
                  <Input
                    label="Number of Sections"
                    type="number"
                    customStyle={{
                      width: '12.5rem',
                      height: '2.5rem',
                      fontSize: '0.875rem',
                      borderRadius: '0.5rem',
                      paddingLeft: '1.25rem',
                    }}
                    value={selectNumberOfSections.toString()}
                    onChange={(e) => {
                      setSelectNumberOfSections(+e.target.value);
                      setClassDetail((prevState) => {
                        return {
                          ...prevState,
                          noSections: +e.target.value,
                        };
                      });
                    }}
                  />
                  <div style={{ marginLeft: '1.25rem' }}>
                    <Input
                      label="Max Students per Section"
                      type="number"
                      customStyle={{
                        width: '12.5rem',
                        height: '2.5rem',
                        fontSize: '0.875rem',
                        borderRadius: '0.5rem',
                        paddingLeft: '1.25rem',
                      }}
                      value={selectMaxStudentsPerSection.toString()}
                      onChange={(e) => {
                        setSelectMaxStudentsPerSection(+e.target.value);
                        setClassDetail((prevState) => {
                          return {
                            ...prevState,
                            maxStudentsPerSection: +e.target.value,
                          };
                        });
                      }}
                    />
                  </div>

                  <div className="flex flex-wrap mt-4">
                    <Input
                      label="Minimum Age"
                      type="number"
                      customStyle={{
                        width: '12.5rem',
                        height: '2.5rem',
                        fontSize: '0.875rem',
                        borderRadius: '0.5rem',
                        paddingLeft: '1.25rem',
                      }}
                      value={selectMinAge.toString()}
                      onChange={(e) => {
                        setSelectMinAge(+e.target.value);
                        setClassDetail((prevState) => {
                          return {
                            ...prevState,
                            minAge: +e.target.value,
                          };
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              <div>
                <h3 className="body-heading">2. Grade settings(optional)</h3>
                <p className="body-content">
                  These are optional settings that help with the school process
                </p>
                <GradeSetting
                  checkToggle={classDetail?.isEnforceMinAge}
                  setCheckToggle={(e) => {
                    setClassDetail((prevState) => {
                      return {
                        ...prevState,
                        isEnforceMinAge: e.target.checked,
                      };
                    });
                  }}
                  title="Enforce Min Age"
                  content="This will not let anyone younger to complete application"
                />
                <GradeSetting
                  checkToggle={classDetail?.isEnforceMaxStrength}
                  setCheckToggle={(e) => {
                    setClassDetail((prevState) => {
                      return {
                        ...prevState,
                        isEnforceMaxStrength: e.target.checked,
                      };
                    });
                  }}
                  title="Enforce Max Strength"
                  content="Admissions cannot be converted maximum limit reaches"
                />
                <GradeSetting
                  checkToggle={classDetail?.isWaitingList}
                  setCheckToggle={(e) => {
                    setClassDetail((prevState) => {
                      return {
                        ...prevState,
                        isWaitingList: e.target.checked,
                      };
                    });
                  }}
                  title="Waiting List"
                  content="This will let candidates apply after hitting maximum strength"
                />
              </div>
            </div>

            <div className="">
              {classDetail?.id && (
                <div className="w-[30rem] flex mt-5">
                  <Button
                    text="Disable"
                    className="text-[#D82C0D] bg-white border border-[#D72C0D] w-full inline-block text-center"
                    onClick={onClickDisable}
                  />
                </div>
              )}

              <div className="flex justify-center">
                <Button
                  text="Cancel"
                  customStyle={toggleButtonStyles.btnWhite}
                  onClick={() => {
                    setVisible(false);
                  }}
                />
                <Button
                  text="Save"
                  customStyle={toggleButtonStyles.btnBlue}
                  onClick={onClickSaveHandle}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fade>
  );
}

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};

// const Footer = () => {
//   return (
//     <div className="flex justify-center absolute bottom-0 left-7 right-7">
//       <Button
//         text="Cancel"
//         customStyle={toggleButtonStyles.btnWhite}
//         onClick={() => {}}
//       />
//       <Button text="Save" customStyle={toggleButtonStyles.btnBlue} onClick={() => {}} />
//     </div>
//   );
// };

const GradeSetting = ({ checkToggle, setCheckToggle, title, content }) => {
  return (
    <div className="flex justify-end border rounded-md w-[30rem] h-16 items-center mt-4">
      <div className="flex-1 ml-4">
        <p className="text-sm text-[#00160A] font-semibold">{title}</p>
        <p className="text-xs text-[#637381] font-normal">{content}</p>
      </div>
      <ToggleButton
        name={title}
        value={checkToggle}
        customStyle={{ marginRight: '2rem' }}
        onChange={setCheckToggle}
        inputSize="middle"
        bg="#A2BCB0"
      />
    </div>
  );
};
