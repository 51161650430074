import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function Loading(props) {
  const { size, style } = props
  return (
    <Box sx={{ display: 'flex', ...style }}>
      <CircularProgress size={size} />
    </Box>
  );
}