import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import firebaseConfig from '../../../../firebaseConfig.json';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

// export const messaging = getMessaging();
let messaging;

try {
  messaging = getMessaging();
} catch (error) {
  console.error('Failed to get Firebase Messaging instance:', error);
}
export { messaging }

export const getMessagingToken = (setToastMessage, setIsShowToastMessage) => {
  if (!messaging) return;

  const deviceToken = getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        return currentToken;
      } else {
        setToastMessage({
          status: 'warning',
          title: 'Notification Permission',
          message:
            'Browser notifications have been turned off. Please enable browser notifications! Otherwise, you will not receive any Giiki App notifications. If you want to enable notifications, please click the "Allow" button in the settings and log in again.',
        });
        setIsShowToastMessage(true);
      }
    })
    .catch((err) => {
      setToastMessage({
        status: 'warning',
        title: 'Notification Permission',
        message:
          'Browser notifications have been turned off. Please enable browser notifications! Otherwise, you will not receive any Giiki App notifications. If you want to enable notifications, please click the "Allow" button in the settings and log in again.',
      });
      setIsShowToastMessage(true);
      return 'turnoff';
    });
  return deviceToken;
};

export default firebase;
