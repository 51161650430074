import React, { useContext, useEffect, useState } from 'react';
import { PaymentInputsWrapper, usePaymentInputs } from 'react-payment-inputs';
import images from 'react-payment-inputs/images';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
// import Razorpay from 'razopay';

import { Button } from '../../../../stories';
import { callApi } from '../../../../helper/call-api';
import { checkErrorApiFetch } from '../../../../utils/check-error/api-error';
import { ToastMessageContext } from '../../../../context/toast-context';
import { formatMoney } from '../../../../helper/format-money';
import { EnquiryStatus } from '../../../../entities/data';

export default function Payment(props) {
  const { applicationId, isCompletedOrClosed, enquiry, handleNextStage, handleRefreshAndNext, onClick = () => { } } = props;

  const status = enquiry?.status
  const currentStep = enquiry?.currentStep
  const name = `${enquiry?.info?.firstName} ${enquiry?.info?.lastName}`
  const grade = enquiry?.programGrade?.grade?.code
  const academic = enquiry?.academicYear?.name
  // const fee = enquiry?.programGrade?.programGradeFee?.applicationFee || 0
  const currency = enquiry?.programGrade?.programGradeFee?.currency
  const [payment, setPayment] = useState();
  const [refresh, setRefresh] = useState(false);

  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  // const cardNumberValidator = ({ cardNumber, cardType, errorMessages }) => {
  //   if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard') {
  //     return;
  //   }
  //   return 'Card must be Visa or Mastercard';
  // };
  // const {
  //   wrapperProps,
  //   getCardImageProps,
  //   getCardNumberProps,
  //   meta,
  //   getExpiryDateProps,
  //   getCVCProps,
  // } = usePaymentInputs({
  //   cardNumberValidator,
  // });
  // const [value, setValue] = useState('');
  useEffect(() => {
    applicationId &&
      callApi({
        method: 'get',
        url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/application-payment/${applicationId}`,
        // url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/payment-link/${applicationId}`,
      })
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Payment Info',
            )
          ) {
            const paymentInfo = JSON.parse(res.data.data.info);
            setPayment(paymentInfo);
            // const { short_url } = paymentInfo;
            // window.open(short_url, '_blank');
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Payment Info Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get Payment Info Error', error);
        });
  }, [applicationId, refresh]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleClickPayNow = async () => {
    // const { short_url } = payment;
    // if (!!short_url) {
    //   window.open(short_url, '_blank');
    // }
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      setToastMessage({
        status: 'error',
        title: 'Razorpay SDK failed to load',
      });
      setIsShowToastMessage(true);
      return;
    }
    const options = {
      "key": process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      "amount": payment.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      "currency": "INR",
      "name": name,
      "description": "Application Fee",
      "image": "https://storage.googleapis.com/giiki-bucket/media/$2b$10$3CMz6gAGxOoaYWHeAAmgcezNTmUsSAVU3kkojv9mvz0kg1cPblPjy",
      "order_id": payment.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      "handler": function (response) {
        console.log('response', response)
        setRefresh(prev => !prev)
      },
      "prefill": {
        "name": name,
        // "email": "gaurav.kumar@example.com",
      },
      "notes": {
        "applicationId": applicationId
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleClickNextStep = () => {
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }

    if (
      status === EnquiryStatus.WAITING_FOR_PAYMENT &&
      currentStep === EnquiryStatus.SIGNATURE
    ) {
      let url = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/signature/parent-fee-payment/${applicationId}`;
      callApi({
        method: 'patch',
        url,
        // params: { campusId: campus?.id },
      })
        .then((results) => {
          if (results?.data?.success && results.data.success) {
            handleRefreshAndNext()
          } else {
            setToastMessage({
              status: 'error',
              title: 'Update Failed',
              message: results.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Update Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Update Error', error);
        });
    } else {
      handleRefreshAndNext()
    }
  };

  return (
    <div className="flex flex-col pl-8 mt-20 mb-16 left-5 1200px:left-0 relative payment">
      <div
        className="bg-main-blue w-80 h-40 absolute text-white font-semibold pt-5 pl-5 -left-32 -top-14 1200px:-left-36 1200px:-top-16"
        style={{ zIndex: -1 }}
      >
        <p className="mb-2">Application Fee</p>
        <p>{payment ? formatMoney(payment.amount, currency) : formatMoney(0, currency)}</p>
      </div>
      <div
        className="flex flex-col border border-green-200 bg-white"
        style={{ width: '31.25rem' }}
      >
        <div className="bg-green-200 h-8 font-semibold flex items-center pl-10">
          Pay Now
        </div>
        <div className="flex justify-between pl-10 pr-10 mt-10">
          <TextWrapper title="Application Name" body={name} />
          <TextWrapper title="Fee Type" body="Application Fee" />
        </div>
        <div className="flex justify-between pl-10 pr-10 mt-3">
          <TextWrapper title="Academic Year" body={academic} />
          <TextWrapper title="Grade of Entry" body={grade} />
        </div>
        <div className="flex justify-between pl-10 pr-10 mt-3">
          <TextWrapper
            title="Payment"
            body={
              payment &&
              `${formatMoney(payment.amount_paid, payment.currency)}/${formatMoney(
                payment.amount,
                payment.currency,
              )}`
            }
          />
        </div>
        <div className="flex flex-col pl-10 pr-10 mt-20">
          {/* <h2 className="font-bold text-sm mb-1">Cardholder Name</h2>
          <input
            className="border rounded pl-2"
            style={{ height: '2.5rem', borderColor: '#bdbdbd' }}
            placeholder="Type your name"
            value={value}
            onChange={(e) => setValue(e.target.value.toUpperCase())}
          />
          <h2 className="font-bold text-sm mt-5 mb-1">
            Card Number{' '}
            {meta?.cardType?.displayName && `=> ${meta?.cardType?.displayName}`}
          </h2>
          <PaymentInputsWrapper>
            <svg {...getCardImageProps({ images })} />
            <input {...getCardNumberProps()} />
          </PaymentInputsWrapper>
          <div className="flex justify-between mt-5">
            <div>
              <h2 className="font-bold text-sm mb-1">Card Date Number</h2>
              <PaymentInputsWrapper>
                <input {...getExpiryDateProps()} />
              </PaymentInputsWrapper>
            </div>
            <div className="mb-20">
              <h2 className="font-bold text-sm mb-1">
                {meta?.cardType?.code.name ? meta?.cardType?.code.name : 'CVC'}
              </h2>
              <PaymentInputsWrapper>
                <input {...getCVCProps()} />
              </PaymentInputsWrapper>
            </div>
          </div> */}
          {payment && payment.amount_paid !== payment.amount && (
            <Button
              text="Pay Now"
              onClick={() =>
                // location.pathname.search('bloomingdale') !== -1
                //   ? onClick(true)
                //   : history.push('/application/complete')
                handleClickPayNow()
              }
              customStyle={{
                paddingLeft: '10.938rem',
                fontWeight: 600,
                marginBottom: '2rem',
              }}
            />
          )}
          {payment && payment.amount_paid >= payment.amount && (
            <Button
              text="Next Step"
              onClick={handleClickNextStep}
              customStyle={{
                paddingLeft: '10.938rem',
                fontWeight: 600,
                marginBottom: '2rem',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const TextWrapper = ({ title, body }) => {
  return (
    <div className="font-bold text-sm">
      <p className="mb-2">{title}</p>
      <p className="text-second-gray">{body}</p>
    </div>
  );
};

Payment.propTypes = {
  /**  @param Type An object.
   * @param Object Keys for object - applicationName: string, academicYear: string, feeType: string, grade: string, data: object
   * @param data Keys for object - cardholder: string, cardNumber: string, cardDate: string, cvc: string
   * @param Example {
   * applicationName: 'Mira Ozawana',
   * academicYear: '2021-22',
   * feeType: 'Aplication Fee',
   * grade: 'MY2',
   * data: {
   * cardholder: '',
   * cardNumber: '',
   * cardDate:'',
   * cvc: ''
   * }
   * }
   **/
  data: PropTypes.object,
};
