import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import useDebounce from '@hooks/useDebounce';
import { cancel, mainavatar, search, team_manager, team_member } from '@static/image';
import { Button } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import './board-or-team-members.scss';
import taskApi from 'api/task';
import authApi from 'api/auth';
import useStorage from 'store/storage';

const styles = {
  closeBtn: {
    backgroundColor: '#fff',
    color: '#202223',
    border: '1px solid #E1E3E5',
    marginRight: '0.5rem',
  },
  createBtn: { backgroundColor: '#404eed' },
};

export default function BoardTeamMemberModal(props) {
  const { selectedTeam, setVisibleTeamMemberModal, setIsAddTeamSuccess, setNewTeamId } =
    props;
  const [listMembers, setListMembers] = useState([]);
  const [listManagers, setListManagers] = useState([]);
  const [listMembersChange, setListMembersChange] = useState([]);
  const [listManagersChange, setListManagersChange] = useState([]);
  const [listUsers, setListUsers] = useState([]);
  const [listUsersForSearch, setListUsersForSearch] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [listSearchResults, setListSearchResults] = useState([]);
  const [listSelectedUsers, setListSelectedUsers] = useState([]);
  const [isSearch, setIsSearch] = useState(false);
  const campus = useStorage((state) => state.currentCampus);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const debouncedSearch = useDebounce(searchValue, 500);

  useEffect(() => {
    if (!!selectedTeam) {
      setListMembers(selectedTeam.members);
      setListManagers(selectedTeam.managers);
    }
  }, [selectedTeam]);

  useEffect(() => {
    authApi.getUsersOfCompanyOrCampus({ excludeRoleNames: 'Student;Parent', campusId: campus?.isCentral ? undefined : campus?.id })
      .then((results) => {
        if (results?.data?.success) {
          if (results?.data?.data && Array.isArray(results.data.data.objects)) {
            setListUsers(results.data.data.objects);
            setListUsersForSearch(results.data.data.objects);
          }
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get User List Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get User List  Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get User List Error', error);
      });
  }, []);

  useEffect(() => {
    const map = new Map();
    listMembers.forEach((member) => {
      map.set(member.user.id, member);
    });
    listManagers.forEach((member) => {
      map.set(member.user.id, member);
    });
    const listUsersFilter = [];
    listUsers.forEach((user) => {
      if (!map.has(user.id)) {
        listUsersFilter.push(user);
      }
    });
    setListUsersForSearch(listUsersFilter);
  }, [listMembers, listManagers, listUsers]);

  useEffect(() => {
    if (!debouncedSearch.trim()) {
      setListSearchResults(listUsersForSearch);
      return;
    }
    const result = listUsersForSearch.filter((data) => {
      return (
        data.name.toLowerCase().includes(debouncedSearch.toLowerCase()) ||
        data.email.toLowerCase().includes(debouncedSearch.toLowerCase())
      );
    });
    setListSearchResults(result);
  }, [debouncedSearch]);

  const handleEditTeam = () => {
    if (listManagersChange.length === 0 && listMembersChange.length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'There is no change',
        message: '',
      });
      setIsShowToastMessage(true);
      return;
    }
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/team/${selectedTeam.id}`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: { managers: listManagersChange, members: listMembersChange },
    // })
    taskApi.updateTeamById(
      { campusId: campus?.isCentral ? undefined : campus?.id },
      { managers: listManagersChange, members: listMembersChange },
      selectedTeam.id)
      .then((results) => {
        if (results?.data?.success) {
          setToastMessage({
            status: 'success',
            title: 'Edit Team Successfully',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
          setVisibleTeamMemberModal(false);
          setIsAddTeamSuccess((prev) => !prev);
          setNewTeamId(selectedTeam?.id);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Edit Team Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Edit Team Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Edit Team Error', error);
      });
  };

  const checkUserInList = (user, listUsers) => {
    const result = listUsers.find((data) => {
      return data.user.id === user.id;
    });
    return !!result;
  };

  const countMembers = listManagers.length + listMembers.length;

  return (
    <div className={'board-team-member board-team-member-active relative'}>
      <div
        className="board-team-member_bg"
        onClick={() => {
          setVisibleTeamMemberModal(false);
        }}
      />
      <div className="board-team-member-card w-[50vw] 1920px:w-[40vw]">
        <div className="board-team-member-card-header w-[100%]">
          <h2 className="text-2xl ml-6">Board/Team members</h2>
          <button
            className="mr-4"
            onClick={() => {
              setVisibleTeamMemberModal((prevState) => !prevState);
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="h-[0.5px] mb-2 mx-6 border relative">
          {isSearch && (
            <div className="absolute top-12 w-[104%] h-[14rem] max-h-[14.25rem] bg-[#fff] overflow-x-hidden overflow-y-scroll">
              <div className="m-3">
                {listSearchResults.length > 0 && (
                  <div>
                    {listSearchResults.map((user) => {
                      return (
                        <div key={user.id} className="flex flex-col">
                          <div
                            onClick={() => {
                              setListSelectedUsers((prevState) => {
                                let data = [];
                                if (!checkUserInList(user, prevState)) {
                                  data = [...prevState, { user }];
                                } else {
                                  data = prevState.filter((state) => {
                                    return state.user.id !== user.id;
                                  });
                                }
                                return [...data];
                              });
                            }}
                            className={clsx(
                              'flex flex-1 items-center ml-1 my-2 p-2 border rounded-lg cursor-pointer',
                              checkUserInList(user, listSelectedUsers)
                                ? 'border-indigo-500/100'
                                : '',
                            )}
                          >
                            <img
                              src={user?.photoURL || mainavatar}
                              alt="avatar"
                              className="w-8 h-8 rounded-full"
                            />
                            <p className="ml-4">{user?.name || 'User Name'}</p>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                )}
                {listSearchResults.length === 0 && <div>User not found</div>}
              </div>
            </div>
          )}
        </div>
        <div className="flex flex-col mb-[1.5rem]">
          <div className="w-[96%] flex px-4 py-2">
            <img
              onClick={() => { }}
              src={search}
              className="mx-2 cursor-pointer text-[#000000]"
              alt=""
            />
            <input
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
                setIsSearch(true);
              }}
              onBlur={() => {
                // setSearchValue('');
                // setIsSearch(false);
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                }
              }}
              placeholder="Search by Name or Email"
              className="focus:outline-none"
            />
          </div>
          {countMembers !== 0 && (
            <ul className="w-[98%] m-2 h-[24vh] 2xl:h-[20vh] 1920px:h-[16vh] overflow-x-hidden overflow-y-scroll">
              {listManagers.map((data, idx) => {
                return (
                  <li
                    key={idx}
                    className="flex justify-start items-center ml-5 rounded text-sm my-2 cursor-pointer"
                  >
                    <div className="flex flex-1 items-center">
                      <img
                        src={data?.user?.photoURL || mainavatar}
                        alt="avatar"
                        className="w-8 h-8 rounded-full"
                      />
                      <p className="ml-4">
                        <span className='font-bold'>{data?.user?.name || 'User Name'}</span> |{' '}
                        {data?.user?.roles?.[0]?.name || 'Position'}
                      </p>
                    </div>
                    <div className="flex items-center min-w-[15%]">
                      <div
                        className="mx-1 p-1"
                        onClick={() => {
                          setListManagers((prevState) => {
                            const dataList = prevState.filter((state) => {
                              return state.user.id !== data.user.id;
                            });
                            return [...dataList];
                          });
                          setListManagersChange((previousState) => {
                            if (previousState.length === 0) {
                              return [
                                ...previousState,
                                {
                                  id: data.id,
                                  isDbDelete: true,
                                  userId: data.user.id,
                                },
                              ];
                            }
                            const dataList = previousState.filter((state) => {
                              return state.userId !== data.user.id;
                            });
                            if (dataList.length === previousState.length) {
                              return [
                                ...dataList,
                                { id: data.id, isDbDelete: true, userId: data.user.id },
                              ];
                            } else {
                              return [...dataList];
                            }
                          });
                          setListMembers((prevStateManager) => {
                            return [...prevStateManager, data];
                          });
                          setListMembersChange((previousStateManager) => {
                            if (previousStateManager.length === 0) {
                              return [
                                ...previousStateManager,
                                {
                                  userId: data.user.id,
                                },
                              ];
                            }
                            const dataList = previousStateManager.filter((state) => {
                              return state.userId !== data.user.id;
                            });
                            if (dataList.length === previousStateManager.length) {
                              return [
                                ...dataList,
                                {
                                  userId: data.user.id,
                                },
                              ];
                            } else {
                              return [...dataList];
                            }
                          });
                        }}
                      >
                        <img src={team_manager} alt="closed modal" />
                      </div>
                      <div
                        className="mx-1 p-1"
                        onClick={() => {
                          setListManagers((prevState) => {
                            const dataList = prevState.filter((state) => {
                              return state.user.id !== data.user.id;
                            });
                            return [...dataList];
                          });
                          setListManagersChange((previousState) => {
                            return [
                              ...previousState,
                              {
                                id: data.id,
                                isDbDelete: true,
                                userId: data.user.id,
                              },
                            ];
                          });
                        }}
                      >
                        <img src={cancel} alt="closed modal" />
                      </div>
                    </div>
                  </li>
                );
              })}
              {listMembers.map((data, idx) => {
                return (
                  <li
                    key={idx}
                    className="flex justify-start items-center ml-5 rounded text-sm my-2 cursor-pointer"
                  >
                    <div className="flex flex-1 items-center">
                      <img
                        src={data?.user?.photoURL || mainavatar}
                        alt="avatar"
                        className="w-8 h-8 rounded-full"
                      />
                      <p className="ml-4">
                        <span className='font-bold'>{data?.user?.name || 'User Name'}</span> |{' '}
                        {data?.user?.roles?.[0]?.name || 'Position'}
                      </p>
                    </div>
                    <div className="flex items-center min-w-[15%]">
                      <div
                        className="mx-1 p-1"
                        onClick={() => {
                          setListMembers((prevState) => {
                            const dataList = prevState.filter((state) => {
                              return state.user.id !== data.user.id;
                            });
                            return [...dataList];
                          });
                          setListMembersChange((previousState) => {
                            if (previousState.length === 0) {
                              return [
                                ...previousState,
                                { id: data.id, isDbDelete: true, userId: data.user.id },
                              ];
                            }
                            const dataList = previousState.filter((state) => {
                              return state.userId !== data.user.id;
                            });
                            if (dataList.length === previousState.length) {
                              return [
                                ...dataList,
                                { id: data.id, isDbDelete: true, userId: data.user.id },
                              ];
                            } else {
                              return [...dataList];
                            }
                          });
                          setListManagers((prevStateManager) => {
                            return [...prevStateManager, data];
                          });
                          setListManagersChange((previousStateManager) => {
                            if (previousStateManager.length === 0) {
                              return [
                                ...previousStateManager,
                                {
                                  userId: data.user.id,
                                },
                              ];
                            }
                            const dataList = previousStateManager.filter((state) => {
                              return state.userId !== data.user.id;
                            });
                            if (dataList.length === previousStateManager.length) {
                              return [
                                ...dataList,
                                {
                                  userId: data.user.id,
                                },
                              ];
                            } else {
                              return [...dataList];
                            }
                          });
                        }}
                      >
                        <img src={team_member} alt="closed modal" />
                      </div>
                      <div
                        className="mx-1 p-1"
                        onClick={() => {
                          setListMembers((prevState) => {
                            const dataList = prevState.filter((state) => {
                              return state.user.id !== data.user.id;
                            });
                            return [...dataList];
                          });
                          setListMembersChange((previousState) => {
                            return [
                              ...previousState,
                              { id: data.id, isDbDelete: true, userId: data.user.id },
                            ];
                          });
                        }}
                      >
                        <img src={cancel} alt="closed modal" />
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {countMembers === 0 && (
            <div className="w-[98%] m-4 h-[24vh] ml-10">Have no member</div>
          )}
        </div>
        <div className="h-[0.5px] my-2 mx-6 mt-4 border"></div>
        {!isSearch && (
          <div className="flex justify-end pr-4 absolute right-6 bottom-[1.5rem]">
            <Button
              text="Cancel"
              customStyle={styles.closeBtn}
              onClick={() => {
                setVisibleTeamMemberModal((prevState) => !prevState);
              }}
            />
            <Button text="Save" customStyle={styles.createBtn} onClick={handleEditTeam} />
          </div>
        )}
        {isSearch && (
          <div className="flex justify-end pr-4 absolute right-6 bottom-[1.5rem]">
            <Button
              text="Cancel"
              customStyle={styles.closeBtn}
              onClick={() => {
                setSearchValue('');
                setListSelectedUsers([]);
                setIsSearch(false);
              }}
            />
            <Button
              text="Add Member"
              customStyle={styles.createBtn}
              onClick={() => {
                if (listSelectedUsers.length > 0) {
                  setListMembers((prevState) => [...prevState, ...listSelectedUsers]);
                  setIsSearch(false);
                  setSearchValue('');
                  setListSelectedUsers([]);
                  setListMembersChange((prevState) => {
                    const data = listSelectedUsers.map((user) => {
                      return { userId: user.user.id };
                    });
                    return [...prevState, ...data];
                  });
                } else {
                  setToastMessage({
                    status: 'warning',
                    title: 'Validation Rule',
                    message: 'Please select at least one user',
                  });
                  setIsShowToastMessage(true);
                }
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
}
