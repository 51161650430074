// const companyReducer = (state = {}, action) => {
//     return {company: action.payload};
// }

import { COMPANY } from '../action-creators/types';

const initState = {};

const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case COMPANY.edit:
      return { ...state, company: action.payload };
    case COMPANY.saveInfo:
      return { ...state, info: action.payload };
    case COMPANY.listCampus:
      return { ...state, listCampus: action.payload };
    case COMPANY.current:
      return { ...state, current: action.payload };
    case COMPANY.saveRoutes:
      return { ...state, routes: action.payload };
    case COMPANY.saveCentral:
      return { ...state, central: action.payload };
    case COMPANY.saveAcademicYear:
      return { ...state, central: action.payload };
    default:
      return state;
  }
};

export default companyReducer;
