import moment from 'moment';
import { useContext, useState } from 'react';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { callApi } from '@helper/call-api';
import { request } from '@pages/tasks/image';
import { cancel } from '@static/image';
import { Button, Datepicker, Input } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import './menu-task.scss';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const styles = {
  closeBtn: {
    backgroundColor: '#fff',
    color: '#202223',
    border: '1px solid #E1E3E5',
    marginRight: '0.5rem',
  },
  createBtn: { backgroundColor: '#404eed' },
};

export default function RequestExtension(props) {
  const { selectedTask, selectedTeam, setVisibleTeamModal, setIsUpdate = () => { }, setRefresh = () => { }, setPage = () => { }, index = 0 } = props;
  const [isSelectDate, setIsSelectDate] = useState(false);
  const [selectDate, setSelectDate] = useState(new Date());
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  console.log('selectedTask', selectedTask);


  const handleDelegateUser = () => {
    // if (selectedTeam.isDeadlineExtensionRequiresApproval) {
    //   callApi({
    //     method: 'post',
    //     url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/request`,
    //     data: {
    //       name: 'Request Deadline extension Task: ' + selectedTask?.name,
    //       actionId: 5,
    //       changeContent: {
    //         actionName: 'Request Deadline Extension',
    //         apiURL: `${process.env.REACT_APP_URL_API_TASK}`,
    //         endpoint: `/api/v1/task/request-extension/:taskId`,
    //         taskId: selectedTask.id,
    //         apiMethod: 'PATCH',
    //         content: {
    //           newDeadLine: selectDate,
    //         },
    //         params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //       },
    //       keyFields: [],
    //     },
    //     params: { campusId: campus?.isCentral ? undefined : campus?.id, processId: selectedTeam.processId },
    //   })
    //     .then((results) => {
    //       if (results?.data?.success) {
    //         setToastMessage({
    //           status: 'success',
    //           title: 'Change Due Day Of Task Successfully',
    //           message: results.data.message,
    //         });
    //         setIsShowToastMessage(true);
    //         setVisibleTeamModal(false);
    //         setIsAddTeamSuccess((prev) => !prev);
    //       } else {
    //         setToastMessage({
    //           status: 'error',
    //           title: 'Change Due Day Of Task Failed',
    //           message: results.data.message,
    //         });
    //         setIsShowToastMessage(true);
    //       }
    //     })
    //     .catch((error) => {
    //       setToastMessage({
    //         status: 'error',
    //         title: 'AChange Due Day Of Task Failed',
    //         message: error.response?.data?.message || error,
    //       });
    //       setIsShowToastMessage(true);
    //       console.log('Change due day of Task Error', error);
    //     });
    // } else {
    //   taskApi.requestExtensionById({ campusId: campus?.isCentral ? undefined : campus?.id }, { newDeadLine: selectDate })
    //     .then((results) => {
    //       if (results?.data?.success) {
    //         setToastMessage({
    //           status: 'success',
    //           title: 'Change Due Day Of Task Successfully',
    //           message: results.data.message,
    //         });
    //         setIsShowToastMessage(true);
    //         setVisibleTeamModal(false);
    //         setIsAddTeamSuccess((prev) => !prev);
    //       } else {
    //         setToastMessage({
    //           status: 'error',
    //           title: 'Change Due Day Of Task Failed',
    //           message: results.data.message,
    //         });
    //         setIsShowToastMessage(true);
    //       }
    //     })
    //     .catch((error) => {
    //       setToastMessage({
    //         status: 'error',
    //         title: 'AChange Due Day Of Task Failed',
    //         message: error.response?.data?.message || error,
    //       });
    //       setIsShowToastMessage(true);
    //       console.log('Change due day of Task Error', error);
    //     });
    // }

    taskApi.requestExtensionById({ campusId: campus?.isCentral ? undefined : campus?.id }, { newDeadLine: selectDate }, selectedTask.id)
      .then((results) => {
        if (results?.data?.success) {
          setToastMessage({
            status: 'success',
            title: 'Change Due Day Of Task Successfully',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
          setVisibleTeamModal(false);
          setVisibleTeamModal(false);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index,
          }));
          setIsUpdate((prev) => !prev);
          setPage(0);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Change Due Day Of Task Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Change Due Day Of Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Change due day of Task Error', error);
      });
  };

  return (
    <div className={'menu-task menu-task-active relative'}>
      <div
        className="menu-task_bg"
        onClick={() => {
          setVisibleTeamModal(false);
        }}
      />
      <div className="menu-task-card w-[50vw] ">
        <div className="menu-task-card-header w-[100%]">
          <div className="flex items-center text-2xl ml-6">
            <img src={request} alt="delegate" className="w-6 h-6"></img>
            <h2 className="ml-2">Request Extension</h2>
          </div>
          <button
            className="mr-4"
            onClick={() => {
              setVisibleTeamModal((prevState) => !prevState);
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="h-[0.5px] my-2 mx-6 mt-4 border"></div>
        <p className="my-6 ml-6">
          The due Date will be changed from{' '}
          <strong>{moment(selectedTask.endDate).format('DD MMM, YYYY')}</strong> to{' '}
        </p>

        <div className="w-[100%] relative px-4">
          {/* <Input
            onFocus={() => {
              setIsSelectDate(true);
            }}
            onChange={(e) => {
              // setEnteredTaskName(e.target.value.trim());
            }}
            label="New Deadline"
            name="new-deadline"
            type="text"
            value={moment(selectDate).format('DD MMM, YYYY')}
            customStyle={{
              width: '100%',
              height: '3rem',
              fontSize: '0.875rem',
              borderRadius: '0.25rem',
            }}
          // error={error.statusFirstName}
          />
          {isSelectDate && (
            <div className="absolute top-[2rem] left-[1rem]">
              <Calendar
                date={selectDate}
                onChange={(date) => {
                  setSelectDate(date);
                  setIsSelectDate(false);
                }}
              />
            </div>
          )} */}
          <Datepicker
            label="New Deadline"
            disabled={false}
            pValue={selectDate ?? new Date().toUTCString()}
            psetValue={(value) => {
              setSelectDate(value);
            }}
          />
        </div>
        {/* <div className="h-[0.5px] my-2 mx-6 mt-10 border"></div> */}
        <div className="flex justify-end pr-4 absolute right-6 bottom-[1.5rem]">
          <Button
            text="Cancel"
            customStyle={styles.closeBtn}
            onClick={() => {
              setVisibleTeamModal((prevState) => !prevState);
            }}
          />
          <Button
            text="Save"
            customStyle={styles.createBtn}
            onClick={handleDelegateUser}
          />
        </div>
      </div>
    </div>
  );
}
