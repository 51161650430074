import { useHistory } from 'react-router-dom';
import { useEffect, useState } from 'react';

import payment_icon from './payment_icon.svg';
import { urls } from 'entities/urls';

function NewAdmissionPayment(props) {
  const { enquiry } = props;
  const [application, setApplication] = useState({});
  const history = useHistory();

  useEffect(() => {
    if (enquiry && enquiry.admissionResult?.info) {
      const applicationParse = JSON.parse(enquiry.admissionResult.info);
      setApplication(applicationParse);
    }
  }, [enquiry]);

  const handleClick = () => {
    let name = '';
    if (enquiry.info?.middleName) {
      name = `${enquiry.info?.firstName} ${enquiry.info?.middleName} ${enquiry.info?.lastName}`;
    } else {
      name = `${enquiry.info?.firstName} ${enquiry.info?.lastName}`;
    }
    history.push(urls.campus.admission.cashierPayments, {
      // academicYearName: enquiry?.academicYear.name,
      // programGradeId: enquiry.programGradeId,
      avatar: enquiry.info?.avatar,
      name: name,
      applicationId: application.application.id,
      // programId: enquiry.programGrade?.program?.id
    });
  };

  return (
    <div>
      Fee Payment
      <div className="flex flex-col items-center justify-center my-12">
        <img src={payment_icon} alt="Payment" />
        <p className="mt-6 text-[#202223] font-semibold text-base">Waiting for Payment</p>
        <button
          onClick={handleClick}
          className="mt-14 font-medium text-white bg-[#2E72D2] py-[4px] px-[10px] rounded text-sm"
        >
          Go to Payments Module
        </button>
      </div>
    </div>
  );
}

export default NewAdmissionPayment;
