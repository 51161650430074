import clsx from 'clsx';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Fade from 'react-reveal/Fade';
import { useHistory, useLocation } from 'react-router-dom';
import FooterFinance from '@components/finance-module/footer';
import { NavLinks } from '@pages/Header/components';
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { listResidentialFeeKey, listTutionFeeKey } from '@utils/constant';
import { ToastMessageContext } from 'context/toast-context';
import { FeeBookStatus, feebookFieldRow } from 'entities/data';
import { dataLinks } from 'entities/routes';
import CustomFee from './CustomFee';
import FoodTransportFee from './FoodAndTransportFee';
import ResidentialFee from './ResidentialFee';
import ReviewFee from './ReviewFee';
import TutionFee from './TutionFee';
import { urls } from 'entities/urls';
import './feebook-edit.scss';
import financeApi from 'api/finance';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const initOptions = [{ label: 'Admission Fee', value: 'Admission Fee' }];

const FeebookEdit = memo((props) => {
  const { burger } = props;
  const [feeCategory, setFeeCategory] = useState(feebookFieldRow[0].text);
  const [feeBookId, setFeeBookId] = useState();
  const [feeBookInfo, setFeeBookInfo] = useState({});
  const [listClasses, setListClasses] = useState([]);
  const [listGrades, setListGrades] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [dataFeeCallApi, setDataFeeCallApi] = useState({});
  const [refreshFooter, setRefreshFooter] = useState(false);
  /* NEW */
  const [admissionFee, setAdmissionFee] = useState({});
  const [applicationFee, setApplicationFee] = useState({});
  const [followingFees, setFollowingFees] = useState({});
  const [residentialFeeForClasses, setResidentialFeeForClasses] = useState({});
  const [residentialFromClassId, setResidentialFromClassId] = useState('');
  const [tutionFees, setTutionFees] = useState([]);
  const [residentialFee, setResidentialFee] = useState([]);
  const [transportFee, setTransportFee] = useState({});
  const [foodFee, setFoodFee] = useState({});
  const [customFees, setCustomFees] = useState({});
  const [options, setOptions] = useState(initOptions);
  const [termsLength, setTermsLength] = useState(0);
  const [numberOfMonthsInAcademicyear, setNumberOfMonthsInAcademicyear] = useState(1);
  const [tutionFeesSave, setTutionFeesSave] = useState([]);
  const [residentialFeeSave, setResidentialFeeSave] = useState([]);
  const [transportFeeSave, setTransportFeeSave] = useState({});
  const [foodFeeSave, setFoodFeeSave] = useState({});
  const [razorpayBankAccount, setRazorpayBankAccount] = useState({});
  const [listBankAccount, setListBankAccount] = useState([]);
  const [listAcademicYearTerm, setListAcademicYearTerm] = useState([]);
  /* ----------------------- */
  const campus = useStorage((state) => state.currentCampus);
  const location = useLocation();
  const history = useHistory();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const redirectUrl = location.pathname === urls.central.finance.feebookEdit ?
    urls.central.finance.feebooks
    : urls.campus.finance.feebooks

  useEffect(() => {
    if (location.state?.feeBookId) {
      setFeeBookId(location.state.feeBookId);
    }
  }, [location.state]);
  const handleAddData = useCallback(
    (key, value) => {
      setDataFeeCallApi((prev) => {
        const objData = { ...prev, [key]: value };
        return objData;
      });
    },
    [setDataFeeCallApi],
  );

  useEffect(() => {
    dataFeeCallApi['customFees'] && setCustomFees(dataFeeCallApi['customFees']);
  }, [dataFeeCallApi]);

  function calculateDays(startDateStr, endDateStr) {
    // Parse the date strings into Date objects
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    // Calculate the time difference in milliseconds
    const timeDiff = endDate.getTime() - startDate.getTime();

    // Convert milliseconds to days
    const daysDiff = Math.ceil(timeDiff / (1000 * 3600 * 24));

    return daysDiff;
  }
  const handleSetRazorpayBankAccount = (key, value) => {
    setRazorpayBankAccount(prev => ({ ...prev, [key]: value }));
  }

  useEffect(() => {
    feeBookInfo.academicYearId &&
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/academic/list-term`,
      //   params: { campusId: campus?.isCentral ? undefined : campus?.id, academicYearId: feeBookInfo.academicYearId },
      // })
      // financeApi.getListTerm({ campusId: campus?.isCentral ? undefined : campus?.id, academicYearId: feeBookInfo.academicYearId })
      financeApi.getListTerm({ campusId: campus?.id, academicYearId: feeBookInfo.academicYearId })
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get List Term',
            )
          ) {
            if (res.data?.data?.objects && res.data.data.objects.length > 0) {
              setTermsLength(res.data.data.objects.length);
              setListAcademicYearTerm(res.data.data.objects);
              setOptions((prev) => {
                const data = [...initOptions];
                res.data.data.objects.forEach((obj) => {
                  data.push({
                    label: obj.name,
                    value: obj.id,
                    startDate: obj.startDate,
                  });
                });
                return data;
              });
              let sum = 0;
              res.data.data.objects.forEach((obj) => {
                sum += calculateDays(obj.startDate, obj.endDate);
              });
              setNumberOfMonthsInAcademicyear(Math.round(sum / 30));
            }
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get List Term Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get List Term Error', error);
        });
  }, [feeBookInfo.academicYearId]);

  const mapFee = useMemo(
    () => ({
      [feebookFieldRow[0].text]: (
        <TutionFee
          tutionFeesSave={tutionFeesSave}
          termsLength={termsLength}
          options={options}
          followingFees={followingFees}
          admissionFee={admissionFee}
          applicationFee={applicationFee}
          tutionFees={tutionFees}
          setFollowingFees={setFollowingFees}
          setAdmissionFee={setAdmissionFee}
          setApplicationFee={setApplicationFee}
          setTutionFees={setTutionFees}
          refresh={refresh}
          listClasses={listClasses}
          burger={burger}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          setRazorpayBankAccount={handleSetRazorpayBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      ),
      [feebookFieldRow[1].text]: (
        <ResidentialFee
          residentialFeeSave={residentialFeeSave}
          numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
          options={options}
          termsLength={termsLength}
          residentialFeeForClasses={residentialFeeForClasses}
          setResidentialFeeForClasses={setResidentialFeeForClasses}
          residentialFromClassId={residentialFromClassId}
          setResidentialFromClassId={setResidentialFromClassId}
          residentialFee={residentialFee}
          setResidentialFee={setResidentialFee}
          listClasses={listClasses}
          burger={burger}
          refresh={refresh}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      ),
      [feebookFieldRow[2].text]: (
        <FoodTransportFee
          numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
          termsLength={termsLength}
          options={options}
          foodFee={foodFee}
          transportFee={transportFee}
          foodFeeSave={foodFeeSave}
          transportFeeSave={transportFeeSave}
          setFoodFee={setFoodFee}
          setTransportFee={setTransportFee}
          refresh={refresh}
          burger={burger}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      ),
      [feebookFieldRow[3].text]: (
        <CustomFee
          termsLength={termsLength}
          setDataFeeCallApi={setDataFeeCallApi}
          dataFeeCallApi={dataFeeCallApi}
          handleAddData={handleAddData}
          refresh={refresh}
          listClasses={listClasses}
          customFees={customFees}
          title={feebookFieldRow[3].text}
          burger={burger}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      ),
      [feebookFieldRow[4].text]: <ReviewFee refresh={refresh} burger={burger} />,
    }),
    [
      // foodFee,
      // transportFee,
      listClasses,
      feeBookInfo,
      tutionFees,
      admissionFee,
      applicationFee,
      residentialFee,
      options,
      termsLength,
      numberOfMonthsInAcademicyear,
      tutionFeesSave,
      residentialFeeSave,
      transportFeeSave,
      foodFeeSave,
      razorpayBankAccount
    ],
  );

  useEffect(() => {
    feeBookId &&
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/${feeBookId}`,
      // })
      financeApi.getFeebookById(feeBookId)
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Feebook Info',
            )
          ) {
            setFeeBookInfo(res.data.data);
            // Get every fee from data return by call API
            // console.log('---res.data.data', res.data.data);
            const feeInfo = res.data?.data;
            if (feeInfo) {
              // API return JSON
              feeInfo.admissionFee &&
                typeof feeInfo.admissionFee === 'string' &&
                setAdmissionFee(JSON.parse(feeInfo.admissionFee));
              feeInfo.applicationFee &&
                typeof feeInfo.applicationFee === 'string' &&
                setApplicationFee(JSON.parse(feeInfo.applicationFee));
              feeInfo.followingFees &&
                typeof feeInfo.followingFees === 'string' &&
                setFollowingFees(JSON.parse(feeInfo.followingFees));
              feeInfo.residentialFeeForClasses &&
                typeof feeInfo.residentialFeeForClasses === 'string' &&
                setResidentialFeeForClasses(JSON.parse(feeInfo.residentialFeeForClasses));
              feeInfo.razorpayBankAccount &&
                typeof feeInfo.razorpayBankAccount === 'string' &&
                setRazorpayBankAccount(JSON.parse(feeInfo.razorpayBankAccount));
              // API return string
              feeInfo.residentialFromClassId &&
                typeof feeInfo.residentialFromClassId === 'string' &&
                setResidentialFromClassId(feeInfo.residentialFromClassId);

              // API return Array
              feeInfo.tutionFees &&
                Array.isArray(feeInfo.tutionFees) &&
                setTutionFees(feeInfo.tutionFees);
              feeInfo.residentialFee &&
                Array.isArray(feeInfo.residentialFee) &&
                setResidentialFee(feeInfo.residentialFee);

              feeInfo.tutionFees &&
                Array.isArray(feeInfo.tutionFees) &&
                setTutionFeesSave(feeInfo.tutionFees);
              feeInfo.residentialFee &&
                Array.isArray(feeInfo.residentialFee) &&
                setResidentialFeeSave(_.cloneDeep(feeInfo.residentialFee));

              // API return Object
              feeInfo.transportFee && setTransportFee(feeInfo.transportFee);
              feeInfo.foodFee && setFoodFee(feeInfo.foodFee);
              feeInfo.customFees && setCustomFees(feeInfo.customFees);

              feeInfo.transportFee && setTransportFeeSave(_.cloneDeep(feeInfo.transportFee));
              feeInfo.foodFee && setFoodFeeSave(_.cloneDeep(feeInfo.foodFee));
            }
            /* -------------- */
            const listClassesFromDatabase = res.data.data.program.programGrade.filter(
              (data) => {
                return !!data.class;
              },
            );
            const listGradesFromDatabase = res.data.data.program.programGrade.filter(
              (data) => {
                return !!data.grade;
              },
            );
            if (listClassesFromDatabase.length > 0) {
              const list = listClassesFromDatabase.map((classDatabase) => {
                return classDatabase.class;
              });
              setListClasses(list);
            }
            if (listGradesFromDatabase.length > 0) {
              const list = listGradesFromDatabase.map((gradeDatabase) => {
                return gradeDatabase.grade;
              });
              setListGrades(list);
            }
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Feebook Info Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get Feebook Info Error', error);
        });
  }, [feeBookId, refresh]);

  useEffect(() => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/central-admin/razorpay-bank-account/list-by-finance-head`,
    // })
    centralAdminApi.getListBankAccountFinanceHead()
      .then(res => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get List bank Account',
          )
        ) {
          if (res.data?.data && res.data.data?.length > 0) {
            const listData = res.data.data?.map(dt => ({
              value: dt.id,
              label: dt.accountNickName,
            }))
            listData && setListBankAccount(listData)
          }
        }
      }).catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get List bank Account Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get List bank Account Error', error);
      });
  }, [])

  const handleChangeFeeCategory = useCallback((text) => {
    // if(text === 'Review') {
    //   return
    // }
    setFeeCategory(text);
  }, []);

  const handleClickCancel = () => {
    setRefresh((prev) => !prev);
  };
  const handleData = (isEdit) => {
    let ttFee = _.cloneDeep(tutionFees);
    ttFee.forEach((_, idx) => {
      listTutionFeeKey.forEach((key, index) => {
        const value = ttFee[idx].info[key].value;
        ttFee[idx].info[key] = value;
      });
    });
    //Residential Fee
    let resFee = _.cloneDeep(residentialFee);
    resFee.forEach((_, idx) => {
      listResidentialFeeKey.forEach((key) => {
        const value = resFee[idx].info[key].value;
        resFee[idx].info[key] = value;
      });
    });
    if (!isEdit) {
      ttFee = ttFee.map(fee => {
        const { id, ...rest } = fee;
        return rest;
      });
      resFee = resFee.map(fee => {
        const { id, ...rest } = fee;
        return rest;
      });
    }
    //Food Fee
    const foFee = _.cloneDeep(foodFee);
    Object.keys(foFee).forEach((key) => {
      if (typeof foFee[key] === 'object' && key !== 'terms' && foFee[key] != null) {
        foFee[key] = foFee[key].value;
      }
    });
    //Transport Fee
    const transFee = _.cloneDeep(transportFee);
    Object.keys(transFee).forEach((key) => {
      if (typeof transFee[key] === 'object' && key !== 'terms' && transFee[key] != null) {
        transFee[key] = transFee[key].value;
      }
    });
    //Custom Fee
    const cusFee = _.cloneDeep(dataFeeCallApi['customFees']);
    if (cusFee) {
    }
    // Data call API
    const data = {
      followingFees,
      applicationFee,
      admissionFee,
      tutionFees: ttFee,
      residentialFee: resFee,
      foodFee: foFee,
      transportFee: transFee,
      customFees,
      residentialFeeForClasses,
      residentialFromClassId,
      razorpayBankAccount: JSON.stringify(razorpayBankAccount)
    };
    return data
  }

  const handelSendForApproval = (isHaveApprovalProcess) => {
    // if (Object.keys(dataFeeCallApi).length === 0) {
    // setToastMessage({
    //   status: 'warning',
    //   title: 'Validate Rule',
    //   message: 'Please change setting fee',
    // });
    // setIsShowToastMessage(true);
    // return;
    // }

    let data = handleData();
    if (feeBookInfo?.status === FeeBookStatus.DRAFT) {
      data = handleData(true);
    }
    if (Object.keys(data).length === 0) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Please change setting fee',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (feeBookInfo?.status === FeeBookStatus.DRAFT) {
      // callApi({
      //   method: 'patch',
      //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/${feeBookId}`,
      //   data,
      // })
      financeApi.updateFeebookById(data, feeBookId)
        .then((res) => {
          if (
            checkErrorSingleApi(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Update Feebook Info Successfully',
            )
          ) {
            setDataFeeCallApi({});
            /**
             * Under here we have to check isHaveApprovalProcess
             * but to bypass case dont have approval process due to
             * approval service is not done, so we will comment IF ELSE
             * LATER PLEASE RECOVER THE IF ELSE
             */
            if (isHaveApprovalProcess) {
              // callApi({
              //   method: 'post',
              //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/send-for-approval/${feeBookId}`,
              // })
              financeApi.sendForApprovalFeebook(feeBookId)
                .then((res) => {
                  if (
                    checkErrorSingleApi(
                      res,
                      setToastMessage,
                      setIsShowToastMessage,
                      'Send for Approval',
                    )
                  ) {
                    history.push(redirectUrl);
                  }
                });
            }
            else {
              console.log('#dont have approve process ',)
              setToastMessage({
                status: 'warning',
                title: 'Process to approve not found',
                message: 'Please create a process to approve',
              });
              setIsShowToastMessage(true);
            }
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Update Feebook Info Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Update Feebook Info Error', error);
        });
    }
    else {
      // callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks`,
      //   params: { usePreviousYear: false, campusId: campus?.isCentral ? undefined : campus?.id },
      //   data: {
      //     programId: feeBookInfo.programId,
      //     academicYearId: feeBookInfo.academicYearId,
      //     ...data
      //   },
      // })
      financeApi.createFeebook({ usePreviousYear: false, campusId: campus?.isCentral ? undefined : campus?.id }, {
        programId: feeBookInfo.programId,
        academicYearId: feeBookInfo.academicYearId,
        ...data
      })
        .then((res) => {
          if (res.data.success) {
            if (isHaveApprovalProcess) {
              // callApi({
              //   method: 'post',
              //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks/send-for-approval/${res.data.data.id}`,
              // })
              financeApi.sendForApprovalFeebook(res.data.data.id)
                .then((res) => {
                  if (
                    checkErrorSingleApi(
                      res,
                      setToastMessage,
                      setIsShowToastMessage,
                      'Send for Approval',
                    )
                  ) {
                    history.push(redirectUrl);
                  }
                });
            } else {
              setToastMessage({
                status: 'warning',
                title: 'Process to approve not found',
                message: 'Create A Draft Successfully, but Process to approve not found. Please create a process to approve and try again',
              });
              setIsShowToastMessage(true);
            }
          } else {
            setToastMessage({
              status: 'error',
              title: 'Create New FeeBook Failed',
              message: res.data?.message || '',
            });
            setIsShowToastMessage(true);
            console.log('Create New FeeBook', res.data?.message);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Create New FeeBook Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Create New FeeBook', error);
        });
    }
  };

  const handleSaveAsDraft = () => {
    const data = handleData();
    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/feebooks`,
    //   params: { usePreviousYear: false, campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: {
    //     programId: feeBookInfo.programId,
    //     academicYearId: feeBookInfo.academicYearId,
    //     ...data
    //   },
    // })
    financeApi.createFeebook({ usePreviousYear: false, campusId: campus?.isCentral ? undefined : campus?.id }, {
      programId: feeBookInfo.programId,
      academicYearId: feeBookInfo.academicYearId,
      ...data
    })
      .then((res) => {
        if (res.data.success) {
          setRefreshFooter(prev => !prev);
          setToastMessage({
            status: 'success',
            title: 'Save As Draft Successfully',
            message: 'success',
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Save As Draft Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Save As Draft', error);
      });
  }

  const handleNext = () => {
    feebookFieldRow.forEach((field, idx) => {
      if (feeCategory === field.text) {
        setFeeCategory(feebookFieldRow[idx + 1].text);
      }
    });
  };

  return (
    <div
      className={clsx(
        'feebook-edit transition-all-300 px-16',
        burger ? '1400px:pl-72' : '1400px:pl-32',
      )}
    >
      <Fade clear duration={300}>
        <NavLinks urls={dataLinks.feebooksLinksEdit} />
        <h1 className="namepage">
          Fee Book {feeBookInfo.program?.name ? feeBookInfo.program.name : ''}{' '}
          {feeBookInfo.academicYear?.name ? feeBookInfo.academicYear.name : ''}
        </h1>

        <div className="flex justify-between mb-3">
          <div className="block w-[75%] 1920px:w-[62%]">
            <div className="pb-16">{mapFee[feeCategory]}</div>
            <FooterFinance
              customClass={`left-1/2 -translate-x-1/2`}
              feeCategory={feeCategory}
              handleNext={handleNext}
              handleClickCancel={handleClickCancel}
              handleClickSaveAsDraft={handleSaveAsDraft}
              handelSendForApproval={handelSendForApproval}
              academicYearName={feeBookInfo.academicYear?.name}
              refreshFooter={refreshFooter}
              feeBookInfo={feeBookInfo}
            />
          </div>
          <div
            className='feebook-edit-field'
          >
            {feebookFieldRow.map((item, key) => {
              const { style, text } = item;
              return (
                <div key={key} className="flex justify-between mb-10">
                  <div
                    className={clsx(
                      `feebook-edit-field_dot feebook-edit-field_${style} cursor-pointer`,
                    )}
                    onClick={() => handleChangeFeeCategory(text)}
                  />
                  <p
                    className={clsx('ml-4 cursor-pointer')}
                    onClick={() => handleChangeFeeCategory(text)}
                  >
                    {text}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </Fade>
    </div>
  );
});
FeebookEdit.displayName = 'FeebookEdit';
export default FeebookEdit;

FeebookEdit.propTypes = {
  burger: PropTypes.bool,
};
