import { Fragment, useContext, useEffect, useRef, useState } from "react";
import { Zoom } from "react-reveal";
import { logos_whatsapp_icon, whatsapp_fill } from "../image";
import SingleWhatsapp from "./single-whatsapp";
import clsx from "clsx";
import WhatsAppLine from "./whatsapp-line";
import { Chip, SelectGroup } from "@stories/index";
import { whatsappChatFrom, whatsappChatTo, whatsappChatVia } from "entities/select-style";
import Picker from "@emoji-mart/react";
import dataPicker from '@emoji-mart/data';
import admissionWhatsappApi from "api/admission-whatsapp";
import { checkErrorMultipleApiFetch, checkErrorSingleApi } from "@utils/check-error/api-error";
import { ToastMessageContext } from "context/toast-context";
import { MESSAGE_STATUS } from "@constant/index";
import convertDateToFormat from "@helper/convertDateToFormat";
import Loading from "@components/loading";

const reEngagementMessageCode = 131047
const messageTypeTemplate = 'utility'
const messageText = 'text'

const WhatsappPick = (props) => {
  const { visible, setVisible, parents, enquiry } = props
  const [refresh, setRefresh] = useState(false)
  const [action, setAction] = useState('')
  const [info, setInfo] = useState({})
  const [isSending, setIsSending] = useState(false)
  const [listMessageByDate, setListMessageByDate] = useState([])
  const refMessageId = useRef()
  const refEnclose = useRef()
  const refMessage = useRef()
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    setIsSending(false)
  }, [])

  const objParent = (() => {
    const tmp = {}
    if (parents.length === 0) return tmp
    for (let parent of parents) {
      const newPhone = parent?.phoneNumber?.slice(1)
      tmp[newPhone] = parent
    }
    return tmp
  })()

  const handleEventStreaming = (payload) => {
    if (!payload?.data) return
    const data = JSON.parse(payload.data)
    if (data.messageId === refMessageId.current) {
      setIsSending(false)
      if (data.status === MESSAGE_STATUS.sent) {
        setToastMessage({
          status: 'success',
          title: 'Send Message Successfully',
        });
        setIsShowToastMessage(true);
        refMessage.current.value = '';
        setRefresh((prev) => !prev);
        return
      }
      if (data?.error?.code === reEngagementMessageCode) {
        setToastMessage({
          status: 'warning',
          title: 'Send Message Failed',
          message: "Wait for parents' response messages"
        });
        setIsShowToastMessage(true);
        return
      }
      if (data.status === MESSAGE_STATUS.failed) {
        setToastMessage({
          status: 'error',
          title: 'Send Message Failed',
          message: data?.error?.error_data?.details || ''
        });
        setIsShowToastMessage(true);
        return
      }
    }
    if (data.status === MESSAGE_STATUS.sent &&
      (Object.keys(objParent).includes(data.from) || Object.keys(objParent).includes(data.to))) {
      setRefresh((prev) => !prev);
    }
  }

  useEffect(() => {
    if (!visible.whatsapp) return;
    const source = admissionWhatsappApi.notificationStreaming();
    source.onmessage = (payload) => handleEventStreaming(payload, source);
    source.onerror = (e) => { source.close(); };
    return () => {
      if (refMessage) refMessage.current.value = ''
      if (source) {
        source.close();
      }
    };
  }, [visible?.whatsapp]);

  const formatPhoneNumber = (phone) => {
    if (!phone) return ''
    if (phone.startsWith('+')) return phone.slice(1)
    return phone
  }

  const fetchData = async ({ signal, enquiry, parents }) => {
    const responses = await Promise.all(parents?.map(item => admissionWhatsappApi.list({
      campusId: enquiry?.campusId,
      enquiryId: enquiry?.id,
      phoneNumber: formatPhoneNumber(item.phoneNumber),
      signal
    }
    )))
    if (checkErrorMultipleApiFetch(responses, setToastMessage, setIsShowToastMessage)) {
      const data = responses.reduce((accumulator, current) => accumulator.concat(current?.data?.data || []), [])
      data.sort((a, b) => a.time - b.time);
      const listByDate = {}
      for (let message of data) {
        if (message?.time) {
          const dateStr = convertDateToFormat(new Date(message.time * 1000), 'll').replace(',', '')
          if (dateStr in listByDate) {
            listByDate[dateStr].push(message)
          } else listByDate[dateStr] = [message];
        }
      }
      setListMessageByDate(listByDate)
    } else {
      setToastMessage({
        status: 'error',
        title: 'Fetch Message Failed',
      });
      setIsShowToastMessage(true);
    }
  }


  useEffect(() => {
    let controller
    if (enquiry?.id && enquiry?.campusId && parents?.length !== 0) {
      controller = new AbortController();
      fetchData({ signal: controller.signal, enquiry, parents });
    } else
      setListMessageByDate({})

    return () => {
      setInfo({})
      if (controller)
        controller.abort()
    }
  }, [parents, refresh])

  const onChangeEnclose = (e) => {
    console.log('onChangeEnclose', e)
  }

  const handleChangeAction = (e) => {
    setAction(prev => {
      if (prev === e) return ''
      return e
    })
  }

  const onSelect = (name, e) => {
    setInfo(prev => ({ ...prev, [name]: e }))
  }

  const handleSubmit = async () => {
    try {
      if (!refMessage.current.value) {
        setToastMessage({
          status: 'warning',
          title: 'Missing message',
        });
        setIsShowToastMessage(true);
        return
      }
      if (!info?.to?.value) {
        setToastMessage({
          status: 'warning',
          title: 'Missing receiver',
        });
        setIsShowToastMessage(true);
        return
      }
      if (!info?.from?.value) {
        setToastMessage({
          status: 'warning',
          title: 'Missing sender',
        });
        setIsShowToastMessage(true);
        return
      }
      setIsSending(true)
      const dataApi = {
        type: 'new', // new or reply
        // to: info?.to?.value || '84931458643', 84935708902, 0964 413 504, 84766778858, 84973576859
        // from: '84935708902',
        // phoneNumberId: 214040111784418
        from: formatPhoneNumber(info?.from?.value),
        to: formatPhoneNumber(info?.to?.value),
        phoneNumberId: info?.from?.phoneNumberId,
        enquiryId: enquiry?.id,
        data: { message: refMessage.current.value },
      };
      const res = await admissionWhatsappApi.send({ data: dataApi, params: { campusId: enquiry?.campusId } })
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, '', false)) {
        refMessageId.current = res?.data?.data?.messages?.[0]?.id;
      } else {
        setToastMessage({
          status: 'error',
          title: 'Send Message Failed',
          message: res?.data?.data?.message || res,
        });
        setIsShowToastMessage(true);
      }
      setIsSending(false)
    } catch (error) {
      console.log('error', error)
      setToastMessage({
        status: 'error',
        title: 'Send Message Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      setIsSending(false)
    }
  }

  const handleEmojiSelect = (e) => {
    let val = refMessage.current.value;
    val += e.native;
    refMessage.current.value = val;
  };

  const optionVia = [
    {
      value: 'whatsapp',
      label: 'WhatsApp',
      symbol: logos_whatsapp_icon
    }
  ]
  const optionTo = parents?.map((item, index) => ({
    info: { ...item },
    value: item?.phoneNumber,
    label: `${item?.fullName}(${item?.relationship})`,
  }))

  const optionFrom = [
    { value: 'Bloomingdale Support', label: 'Bloomingdale Support', value: '84935708902', phoneNumberId: '214040111784418' },
  ]

  const formatOptionLabelVia = ({ value, label, symbol }) => {
    return (
      <div className="block">
        <Chip key={value}
          label={label || ''}
          image={symbol}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customLabelClass='text-xs font-medium text-[#212B36]'
        />
      </div>
    )
  }

  const render = () => {
    if (Object.keys(listMessageByDate).length === 0) return null

    return Object.keys(listMessageByDate).map((date) => {
      return (
        <Fragment key={date}>
          <WhatsAppLine key={date} data={date} />
          {listMessageByDate[date].map((message, index) => {
            if (message?.data) {
              if (JSON.parse(message.data)?.type === messageText)
                return (
                  <SingleWhatsapp key={`${date}${index}`} data={message} objParent={objParent} />
                )
            }
            return null
          })}
        </Fragment>
      )
    })
  }


  return (
    <Zoom bottom duration={700} when={visible.whatsapp}>
      <div
        className={clsx(
          'windows-field h-full w-[40rem]',
          visible.whatsapp ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="p-4 h-full">
          <div className="flex item">
            <div
              className="w-10 h-10 mr-3 flex items-center justify-center rounded-lg mb-7"
              style={{ background: '#AEE9D1' }}
            >
              <img src={whatsapp_fill} alt="" />
            </div>
            <p className="font-semibold mt-2.5">Messages</p>
          </div>

          <div className="overflow-auto mb-3" style={{ height: 'calc(100vh - 20rem)' }}>
            {render()}
          </div>

          <input
            onChange={onChangeEnclose}
            type="file"
            multiple={true}
            ref={refEnclose}
            hidden={true}
          />

          <div className="absolute h-[9.375rem] bottom-[4rem] rounded-lg px-3 py-2 border-2 border-color-[#919EAB52] ">
            <div className="flex text-[#8C9094] items-center text-xs">
              <p className="mr-2">Via</p>
              <div>
                <SelectGroup
                  styles={whatsappChatVia}
                  options={optionVia}
                  formatOptionLabel={formatOptionLabelVia}
                  onChange={(e) => onSelect('via', e)}
                  value={optionVia[0]}
                />
              </div>
              <p className="mx-2">To</p>
              <div>
                <SelectGroup
                  styles={whatsappChatTo}
                  options={optionTo}
                  value={info?.to || null}
                  onChange={(e) => onSelect('to', e)}
                />
              </div>
              <p className="mx-2">From</p>
              <div>
                <SelectGroup
                  styles={whatsappChatFrom}
                  options={optionFrom}
                  value={info?.from || null}
                  onChange={(e) => onSelect('from', e)}
                />
              </div>
            </div>

            <textarea
              placeholder="Type your message here"
              ref={refMessage}
              className="resize-none mt-2 w-full h-[3.5rem] text-xs text-[#808284] focus:outline-none"
            />

            <div className="w-full flex justify-end h-[2rem] rounded-lg">
              <div className="flex items-center justify-around max-w-[55%]">
                {/* emoji */}
                <svg
                  className="block object-fill cursor-pointer mr-3"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleChangeAction('emoji');
                  }}
                >
                  <path
                    d="M18.364 5.636a9 9 0 0 1 0 12.728 9 9 0 1 1 0-12.728M9 9.5H8m7.5-.5v1"
                    stroke={action === 'emoji' ? '#27C59A' : '#8C9094'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 14.688S14.187 16 12 16c-2.188 0-3.5-1.312-3.5-1.312"
                    stroke={action === 'emoji' ? '#27C59A' : '#8C9094'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                {/* enclose */}
                {/* <svg
                  className="cursor-pointer mr-3"
                  width="26"
                  height="26"
                  viewBox="0 0 26 26"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => {
                    handleChangeAction('enclose');
                    if (refEnclose) refEnclose.current.click();
                  }}
                >
                  <path
                    d="m17.478 14.962 2.192-2.192a4.554 4.554 0 0 0-6.44-6.44l-2.192 2.192m4.63 1.81-5.336 5.336m-1.81-4.63L6.33 13.23a4.554 4.554 0 0 0 6.44 6.44l2.192-2.192"
                    stroke={action === 'enclose' ? '#60a5fa' : '#8C9094'}
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg> */}

                {/* audio */}
                {/* <svg
                  className="cursor-pointer mr-3"
                  width="14"
                  height="20"
                  viewBox="0 0 14 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  onClick={() => handleChangeAction('audio')}
                >
                  <path
                    d="M1.375 8.125A.625.625 0 0 1 2 8.75V10a5 5 0 1 0 10 0V8.75a.625.625 0 1 1 1.25 0V10a6.25 6.25 0 0 1-5.625 6.219v2.531h3.75a.624.624 0 1 1 0 1.25h-8.75a.625.625 0 1 1 0-1.25h3.75v-2.531A6.25 6.25 0 0 1 .75 10V8.75a.625.625 0 0 1 .625-.625Z"
                    fill={action === 'audio' ? '#B72136' : '#8C9094'}
                  />
                  <path
                    d="M9.5 10a2.5 2.5 0 0 1-5 0V3.75a2.5 2.5 0 1 1 5 0V10ZM7 0a3.75 3.75 0 0 0-3.75 3.75V10a3.75 3.75 0 0 0 7.5 0V3.75A3.75 3.75 0 0 0 7 0Z"
                    fill={action === 'audio' ? '#B72136' : '#8C9094'}
                  />
                </svg> */}

                {/* <img className="block object-fill cursor-pointer" src={tag} alt='tag' /> */}

                <button
                  type="button"
                  className="text-xs text-white bg-black rounded h-[1.875rem] w-20 ml-1 font-medium"
                  onClick={handleSubmit}
                  disabled={isSending}
                >
                  {isSending ? <Loading size='20px' style={{ justifyContent: 'center' }} /> : 'Send'}
                </button>
              </div>
            </div>

            {action === 'emoji' && (
              <div className="absolute w-fit h-fit shadow overflow-hidden bottom-[10rem] h[18rem] rounded-lg">
                <Picker
                  data={dataPicker}
                  icons="outline"
                  previewPosition="top"
                  perLine={15}
                  maxFrequentRows={5}
                  onEmojiSelect={(e) => {
                    handleEmojiSelect(e);
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </Zoom>
  );
}

export default WhatsappPick;