import clsx from 'clsx';
import React, { useContext } from 'react';
import { ToastMessageContext } from '../../../../context/toast-context';
import { delegate, reopen, request } from '../../../../pages/tasks/image';
import { add } from '../../../../static/image';
import taskApi from 'api/task';
import useStorage from 'store/storage';

function MenuTask(props) {
  const {
    checkManager,
    setIsAddTask,
    isAddTask,
    setIsShowMenuTask,
    setTaskInfo,
    task,
    setIsEditTaskInfo,
    setMenuShow,
    setSelectedTask,
    setRefresh,
    index,
  } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const user = useStorage((state) => state.auth);
  const campus = useStorage((state) => state.currentCampus);

  const findUserInTask = (currentUser, assignees) => {
    const result = assignees.find((user) => {
      return user?.member?.user?.id === currentUser?.uid;
    });
    return !!result;
  };

  const handleReopenTask = (index) => {
    taskApi.reopenTAsk(task.id, { campusId: campus?.id, })
      .then((res) => {
        if (res?.data?.success) {
          setToastMessage({
            status: 'success',
            title: 'Reopen Task Successfully!',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setRefresh((prev) => ({
            ...prev,
            count: (prev.count || 0) + 1,
            index: index,
          }));
          setIsShowMenuTask(false);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Reopen Task Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Reopen Task Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Reopen task error: ', error);
      });
  };

  return (
    <div
      style={{ boxShadow: '2px 2px 15px 4px #E5E5E5' }}
      className="absolute top-[-0.5rem] left-[-11.75rem] flex flex-col w-[12rem] h-fit bg-[#fff] rounded-lg border p-3 pl-4 text-[14px]"
    >
      <p className="text-[12px] my-2">Menu</p>
      {checkManager && (
        <>
          <div className="flex items-center justify-start my-1">
            <img
              className="border border-solid border-[#80808085] rounded-full p-[3px]"
              src={add}
              alt=""
            ></img>
            <p
              onClick={() => {
                setIsAddTask(!isAddTask);
                setIsShowMenuTask(false);
              }}
              className="ml-3 cursor-pointer"
            >
              Add Subtask
            </p>
          </div>
          {/* <div className="flex items-center justify-start my-1">
            <svg
              className="border border-solid border-[#80808085] rounded-full"
              width="23"
              height="23"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill="#212B36"
                d="M13.5 12a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 3 0z"
              />
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M19.23 9.37h1.09c.93.006 1.68.76 1.68 1.69V13a1.68 1.68 0 0 1-1.62 1.67h-1.09a.34.34 0 0 0-.29.22.34.34 0 0 0 0 .37l.73.73a1.67 1.67 0 0 1 0 2.37l-1.34 1.34c-.31.319-.735.5-1.18.5a1.72 1.72 0 0 1-1.19-.5l-.76-.77a.34.34 0 0 0-.37 0c-.15.06-.26.16-.26.3v1.09A1.69 1.69 0 0 1 12.94 22h-1.89a1.68 1.68 0 0 1-1.68-1.62v-1.09a.34.34 0 0 0-.22-.29.38.38 0 0 0-.41 0l-.77.74a1.67 1.67 0 0 1-2.37 0l-1.34-1.36a1.66 1.66 0 0 1-.5-1.19 1.72 1.72 0 0 1 .5-1.19l.81-.74a.34.34 0 0 0 0-.37c-.06-.15-.16-.26-.3-.26H3.68A1.69 1.69 0 0 1 2 12.94v-1.89c0-.928.752-1.68 1.68-1.68h1.03A.34.34 0 0 0 5 9.15a.38.38 0 0 0 0-.41L4.26 8a1.67 1.67 0 0 1 0-2.4l1.37-1.34c.31-.319.735-.5 1.18-.5a1.72 1.72 0 0 1 1.19.5l.74.81a.34.34 0 0 0 .37 0c.15-.06.26-.16.26-.3V3.68A1.69 1.69 0 0 1 11.06 2H13a1.68 1.68 0 0 1 1.63 1.68v1.03a.34.34 0 0 0 .22.29.38.38 0 0 0 .41 0l.77-.74a1.67 1.67 0 0 1 2.37 0l1.34 1.37c.32.314.5.742.5 1.19a1.63 1.63 0 0 1-.5 1.18l-.81.74a.34.34 0 0 0 0 .37c.06.15.16.26.3.26ZM8.766 13.34a3.5 3.5 0 1 0 6.468-2.68 3.5 3.5 0 0 0-6.468 2.68Z"
                fill="#212B36"
              />
            </svg>
            <p
              onClick={() => {
                setTaskInfo(task);
                setIsEditTaskInfo(true);
                setIsShowMenuTask(false);
              }}
              className="ml-3 cursor-pointer"
            >
              Edit Task
            </p>
          </div> */}
        </>
      )}
      <div className="flex items-center justify-start my-1">
        <img src={delegate} alt=""></img>
        <p
          onClick={() => {
            if (task?.status === 'Done' || !checkManager) {
              return;
            }
            setMenuShow({
              delegate: true,
              request: false,
              reopen: false,
            });
            setSelectedTask(() => {
              return task
            });
            setIsShowMenuTask(false);
          }}
          className={clsx(
            'ml-3',
            task?.status === 'Done' || !checkManager
              ? 'cursor-not-allowed text-gray-400'
              : 'cursor-pointer',
          )}
        >
          Delegate Task
        </p>
      </div>
      <div className="flex items-center justify-start my-1">
        <img src={request} alt="request"></img>
        <p
          onClick={() => {
            if (
              task?.status === 'Done' ||
              task?.assignees.length === 0 ||
              !findUserInTask(user, task?.assignees)
            ) {
              return;
            }
            setMenuShow({
              delegate: false,
              request: true,
              reopen: false,
            });
            setSelectedTask(task);
            setIsShowMenuTask(false);
          }}
          className={clsx(
            'ml-3',
            task?.status === 'Done' ||
              task?.assignees.length === 0 ||
              !findUserInTask(user, task?.assignees)
              ? 'cursor-not-allowed text-gray-400'
              : 'cursor-pointer',
          )}
        >
          Request Extension
        </p>
      </div>
      <div className="flex items-center justify-start my-1">
        <img src={reopen} alt="reopen"></img>
        <p
          onClick={() => {
            if (task?.status !== 'Done' && task?.status !== 'Cancel') {
              return;
            }
            handleReopenTask(index);
          }}
          className={clsx(
            'ml-3',
            task?.status !== 'Done' && task?.status !== 'Cancel'
              ? 'cursor-not-allowed text-gray-400'
              : 'cursor-pointer',
          )}
        >
          Reopen Task
        </p>
      </div>
    </div>
  );
}

export default MenuTask;
