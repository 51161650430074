import { useMemo } from 'react';
import BillInformation from './BillInformation';
import './bill-section.scss';

function BillSection(props) {
  const {
    fee = [],
    listScholarships = [],
    handleClickPay = () => {},
  } = props;

  // const totalFeeIsPaid = useMemo(() => {
  //   const total = fee.reduce((sum, item) => {
  //     if (item.isPaid) {
  //       return sum + +item.price;
  //     }
  //     return sum;
  //   }, 0);
  //   return total;
  // }, [fee]);

  return (
    <div className="bill-section flex flex-col items-center pt-20 ml-5 w-full">
      <button onClick={handleClickPay} className="bill-section-btn">
        Pay
      </button>
      <div className="bg-white rounded-lg py-3 px-7 w-full">
        <BillInformation
          fee={fee}
          listScholarships={listScholarships}
        />
        <div className="mt-9">
          <p className="bill-section-activity">Payment Activity</p>
          <div className="mt-2">
            {fee.map((f) => {
              const dueDate = `${new Date(f.date).toGMTString().slice(4, 11)} #${f.id}`;
              return (
                f.isPaid && (
                  <div className="flex items-center justify-between">
                    <div key={`${f.id}-${f.name}`} className="mt-2">
                      <p className="bill-section-p">{f.name}</p>
                      <p className="bill-section-p">{dueDate}</p>
                    </div>
                    <div className="bill-section-price">-₹{f.price}</div>
                  </div>
                )
              );
            })}
          </div>
          <div className="mt-4">
              {listScholarships.map((scholarship) => {
                let content = ' ';
                if (scholarship.approverName.length > 0) {
                  content = `Approved by ${scholarship.approverName.join(
                    ', ',
                  )} on ${new Date(scholarship.approvedDate).toGMTString().slice(4, 11)}`;
                }
                return (
                  scholarship.isApproved && scholarship.isUsed && (
                    <div
                      key={`${scholarship.id}-${scholarship.title}`}
                      className="flex items-center justify-between"
                    >
                      <div className="mt-2">
                        <p className="bill-section-p">{scholarship.title}</p>
                        <p className="bill-section-p">{content}</p>
                      </div>
                      <div className="bill-section-scholarship">+₹{scholarship.price}</div>
                    </div>
                  )
                );
              })}
            </div>
        </div>
      </div>
    </div>
  );
}

export default BillSection;
