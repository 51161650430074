import { memo } from 'react';
import SingleCategoryFee from '../TutionFee/SingleCategoryFee';
import SingleFoodAndTransportFee from './single-food-and-transport-fee';

const FoodTransportFee = memo((props) => {
  const {
    numberOfMonthsInAcademicyear = 1,
    termsLength = 1,
    options = [],
    foodFee = {},
    transportFee = {},
    foodFeeSave = {},
    transportFeeSave = {},
    setFoodFee = () => { },
    setTransportFee = () => { },
    refresh,
    burger,
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = []
  } = props;
  return (
    <div className="w-full">
      {['Food Fee', 'Transport Fee'].map((title) => {
        return (
          <SingleFoodAndTransportFee
            key={title}
            numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
            termsLength={termsLength}
            options={options}
            foodFee={foodFee}
            foodFeeSave={foodFeeSave}
            transportFee={transportFee}
            transportFeeSave={transportFeeSave}
            setFoodFee={setFoodFee}
            setTransportFee={setTransportFee}
            refresh={refresh}
            burger={burger}
            title={title}
            razorpayBankAccount={razorpayBankAccount}
            listBankAccount={listBankAccount}
            listAcademicYearTerm={listAcademicYearTerm}
          />
        );
      })}
    </div>
  );
});
FoodTransportFee.displayName = 'FoodTransportFee';
export default FoodTransportFee;
