import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  ExpandIcon,
  FilterIcon,
  SearchIcon,
  SortIcon,
} from '@components/admission-module/application/application-window';
import BankAccountList from './components/bank-account-list.jsx';
import Loading from '@components/loading';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error.js';
import { ToastMessageContext } from 'context/toast-context.jsx';
import './bank-account.scss';
import { urls } from 'entities/urls.js';
import authApi from 'api/auth.js';
import centralAdminApi from 'api/central-admin.js';
import useStorage from 'store/storage.js';

const types = ['Active Accounts', 'Inactive Accounts'];
const bankAccountStatus = {
  Active: 'Active',
  Inactive: 'Inactive',
  Draft: 'Draft',
};

function BankAccount(props) {
  const { burger } = props
  const [type, setType] = useState(types && types[0]);
  const history = useHistory();
  const [listCampus, setListCampus] = useState([]);
  const [listBankAccountsActive, setListBankAccountsActive] = useState([]);
  const [listBankAccountsInactive, setListBankAccountsInactive] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  // const central = useStorage((state) => state.currentCampus);
  const companyId = useStorage((state) => state.auth.companyId);

  useEffect(() => {
    setIsLoading(true);
    const getListCampus = authApi.getListCampus({ companyId, isGetAll: true })
    const getListBankAccount = centralAdminApi.getListBankAccount({ companyId });
    Promise.all([getListCampus, getListBankAccount])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          res[0]?.data?.data?.objects &&
            res[0]?.data?.data?.objects?.length > 0 &&
            setListCampus(res[0]?.data?.data?.objects);
          if (res[1]?.data?.data && res[1]?.data?.data?.length > 0) {
            const listActive = [];
            const listInactive = [];
            res[1].data.data.forEach((dt) => {
              if (dt.status === bankAccountStatus.Active) {
                listActive.push(dt);
              } else {
                listInactive.push(dt);
              }
            });
            listActive.length > 0 && setListBankAccountsActive(listActive);
            listInactive.length > 0 && setListBankAccountsInactive(listInactive);
          }
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Get Data Error', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <div className={clsx('bank-account pr-24 pl-24 mt-[5rem] transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <div className="w-full flex mb-6 items-center justify-between pr-5">
        <h1 className="namepage">Bank Accounts</h1>
        <button
          onClick={() => {
            history.push(urls.central.finance.newBankAccount);
          }}
          className="bg-main-blue text-white font-medium text-sm py-2.5 px-5 rounded"
        >
          New Account
        </button>
      </div>
      <div className="flex items-center justify-between mb-5 pr-5">
        <div>
          {types.map((item, key) => (
            <button
              className={clsx(
                'mr-7 h-12 border-b-2 transition-all none-after text-sm leading-[22px] font-medium',
                item === type ? 'border-main-blue' : 'border-transparent',
              )}
              onClick={() => {
                setType(item);
              }}
              key={key}
            >
              {item}
            </button>
          ))}
        </div>
        <div className="flex items-center pl-2 relative">
          <SearchIcon />
          <input
            placeholder="Search"
            className="mx-2 w-40 text-base focus:outline-none"
          />
          <div onClick={() => { }} className="cursor-pointer">
            <SortIcon />
          </div>
          <p onClick={() => { }} className="w-20 ml-3 cursor-pointer">
            Sort
          </p>
          <div className="flex items-center py-1 pl-2 bank-account-filter">
            <FilterIcon />
            <p className="w-20 ml-3 text-base leading-[30px]">Filter / 1</p>
            <ExpandIcon width="20" height="20" classes="mr-1" />
          </div>
        </div>
      </div>
      {isLoading ? (
        <div className="mt-20 flex items-center justify-center">
          <Loading />
        </div>
      ) : (
        <BankAccountList
          listBankAccounts={
            type === types[0] ? listBankAccountsActive : listBankAccountsInactive
          }
          listCampus={listCampus}
        />
      )}
    </div>
  );
}

export default BankAccount;
