import React, { useCallback, useEffect, useState, useContext } from 'react';
import clsx from 'clsx';
import { mainavatar, phstudent } from '../../../static/image';
import ModuleSection from '../../campus-information/components/roles-permissions/ModuleSection';
import PermissionSection from '../../campus-information/components/roles-permissions/PermissionSection';
import {
  Chip,
  Input,
  SelectGroup,
  ToggleButton,
} from '../../../stories';
import { numberOfSections, maxStudentPerSections, minAge } from '../../../entities/data';
import {
  selectGroupCreateGrade,
  selectStylesWithNoTitle,
  selectStylesWithTitle,
} from '../../../entities/select-style';
import './approval-information-panel.scss';
import { colorOptionForUserSelect } from '../../../entities/colorOption';
import { ToastMessageContext } from '../../../context/toast-context';
import { formatMoney } from '../../../helper/format-money';
import { currencies } from '../../../utils/utils';
import authApi from 'api/auth';
import useStorage from 'store/storage';

const styles = {
  study: { color: '#404EED' },
  playgroup: { textTransform: 'uppercase' },
  section: { color: '#919eab' },
  yrs: { top: 1 },
};

export default function BodyInformation({ bodyData, infoData, actionId }) {
  const tabRenderComponent = {
    1: {
      //create role
      component: (key) => (
        <BodyInfoRole
          key={key}
          bodyData={bodyData}
          infoData={infoData}
          titleCase={titleCase}
        />
      ),
    },
    2: {
      //update role
      component: (key) => (
        <BodyInfoRole
          key={key}
          bodyData={bodyData}
          infoData={infoData}
          titleCase={titleCase}
        />
      ),
    },
    3: {
      //Admissions Team Settings
      component: (key) => (
        <BodyInfoAdmissionTeamSettings
          key={key}
          bodyData={bodyData}
          titleCase={titleCase}
          infoData={infoData}
        />
      ),
    },
    4: {
      //Edit class
      component: (key) => (
        <BodyInfoClass
          key={key}
          bodyData={bodyData}
          titleCase={titleCase}
          infoData={infoData}
        />
      ),
    },
    5: {
      //Task Deadline Extension
    },
    6: {
      //Task Delegation
    },
    7: {
      //Grade Fee Payment
      component: (key) => (
        <BodyInfoGradeFee
          key={key}
          bodyData={bodyData}
          titleCase={titleCase}
          infoData={infoData}
        />
      ),
    },
    9: {
      //Feebooks
      component: (key) => (
        <BodyInfoFeebooks
          key={key}
          bodyData={bodyData}
          titleCase={titleCase}
          infoData={infoData}
        />
      ),
    },
  };

  function titleCase(str) {
    return str.replace(str.charAt(0), str.charAt(0).toUpperCase());
  }

  return <div>{tabRenderComponent[actionId]?.component(actionId)}</div>;
}

const BodyInfoFeebooks = ({ bodyData, titleCase, infoData }) => {
  //TODO: Feebook approval
  // const feeBookInfo = infoData.content.extraInfo;

  // const confirmPaidOfAdmission = feeBookInfo.confirmPaidOfAdmission.map((e) => {
  //   return { value: e, label: e };
  // });

  // const listClasses = feeBookInfo.program.programGrade.map((data) => {
  //   return data.class;
  // });
  // const types = [
  //   'Monthly',
  //   'Quaterly',
  //   'Trimester',
  //   'Half Yearly',
  //   'Term Dates',
  //   'Custom',
  // ];

  // return (
  //   <div className="feebook-edit px-8 w-full">
  //     <Fade clear duration={300}>
  //       <h1 className="namepage">
  //         Fee Book {feeBookInfo.program?.name ? feeBookInfo.program.name : ''}{' '}
  //         {feeBookInfo.academicYear?.name ? feeBookInfo.academicYear.name : ''}
  //       </h1>

  //       <div className="mt-8 w-full">
  //         <p className="text-lg font-bold">Application Fee and Admission criteria</p>
  //         <div className="ml-2">
  //           <div className="flex w-full items-center">
  //             <Checkbox
  //               value={feeBookInfo?.isActiveConfirmPaidOfAdmission}
  //               disabled={true}
  //             />
  //             <span className="text-sm ml-6 flex w-full items-center">
  //               Following fees to be paid to confirm admission
  //               <SelectGroup
  //                 value={confirmPaidOfAdmission}
  //                 isMulti={true}
  //                 classNameGroup="w-[15rem] ml-3"
  //                 isDisabled={true}
  //               />
  //             </span>
  //           </div>
  //           <div className="flex w-full items-center mt-2">
  //             <Checkbox value={feeBookInfo?.isActiveAdmissionFee} disabled={true} />
  //             <span className="text-sm ml-6 flex w-full items-center">
  //               The admission fee is{' '}
  //               <SelectGroup
  //                 value={{
  //                   value: feeBookInfo?.admissionFeeVariationAcrossGrades,
  //                   label: feeBookInfo?.admissionFeeVariationAcrossGrades,
  //                 }}
  //                 isDisabled={true}
  //                 classNameGroup="w-[10rem] mx-3"
  //               />{' '}
  //               for all the grades{' '}
  //               {feeBookInfo?.admissionFeeVariationAcrossGrades === 'Same' && (
  //                 <Input
  //                   classGroup="w-[10rem] ml-3 h-8"
  //                   isHideLabel={true}
  //                   type={'number'}
  //                   value={feeBookInfo?.admissionFeeAmountForAllGrades}
  //                 />
  //               )}
  //             </span>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="flex justify-between mb-3">
  //         <div className="block w-full">
  //           {/* <div className="pb-16">{mapFee[feeCategory]}</div> */}
  //           <SingleCategoryFeeForApprove
  //             // dataFeeCallApi={dataFeeCallApi}
  //             // burger={burger}
  //             listClasses={listClasses}
  //             feeBookInfo={feeBookInfo}
  //             title="Tution Fee"
  //           />
  //         </div>
  //       </div>
  //       <div className="w-full">
  //         <div className={clsx('w-full')}>
  //           <p className="text-lg font-bold mt-8 mb-4">{'Food Fee'}</p>
  //           <div className={clsx('w-full')}>
  //             <CardForApprove
  //               //   data={feeBookInfo}
  //               title={'Food Fee'}
  //               types={types}
  //               feeBookInfo={feeBookInfo}
  //             />
  //           </div>
  //         </div>
  //         <div className={clsx('w-full')}>
  //           <p className="text-lg font-bold mt-8 mb-4">{'Transport Fee'}</p>
  //           <div className={clsx('w-full')}>
  //             <CardForApprove
  //               //   data={feeBookInfo}
  //               title={'Transport Fee'}
  //               types={types}
  //               feeBookInfo={feeBookInfo}
  //             />
  //           </div>
  //         </div>
  //       </div>
  //       <div className={clsx('w-full')}>
  //         <p className="text-lg font-bold mt-8 mb-4">{'Custom and Other Fee'}</p>
  //         <div className={clsx('w-full')}>
  //           <CardForApprove
  //             //   data={feeBookInfo}
  //             title={'Custom and Other Fee'}
  //             types={types}
  //             feeBookInfo={feeBookInfo}
  //           />
  //         </div>
  //       </div>
  //     </Fade>
  //   </div>
  // );
};

/* Admission team settings */
const BodyInfoAdmissionTeamSettings = ({ bodyData, titleCase, infoData }) => {
  const { name, divided, userWorkType, programGrade } = infoData.content.extraInfo;

  const formatOptionLabelUser = ({ value, label, info }) => {
    return (
      <div className="block">
        <Chip
          key={info?.id}
          label={label}
          image={info?.photoURL || mainavatar}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  };

  return (
    <div className="flex flex-wrap max-h-[50vh] overflow-y-auto">
      <div>Program: {name}</div>
      <div className="settings__content">
        <div className="content__wrapper flex ">
          <div className="content__bottom">
            <span className="mr-3">The admissions duties will be divided between </span>
            <div className="w-60 text-sm">
              <SelectGroup
                value={{ value: divided, label: divided }}
                styles={selectStylesWithNoTitle}
                isSearchable={true}
                isDisabled={true}
              />
            </div>
            <span className="mr-3 ml-3">and users can work on applications </span>
            <div className="area w-60 grow text-sm">
              <SelectGroup
                value={{ value: userWorkType, label: userWorkType }}
                styles={selectStylesWithNoTitle}
                isSearchable={true}
                isDisabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      {programGrade?.map((item, key) => {
        const selectedCounselors = item.admissionTeamSetting.counselors?.map(
          (element, index) => {
            const { name, photoURL } = element.counselor;
            const obj = {
              value: name,
              label: name,
              color: colorOptionForUserSelect[index % colorOptionForUserSelect.length],
              info: {
                photoURL: photoURL,
              },
            };
            return obj;
          },
        );
        return (
          <div className="grade" key={key}>
            <div className="grade__left">
              <p className="mr-3">{item?.grade?.name}</p>
              <span className="mr-3">{name}, Galileo campus</span>
            </div>
            <div className="w-80 text-sm">
              <SelectGroup
                title="Team"
                value={selectedCounselors}
                styles={selectStylesWithTitle}
                formatOptionLabel={formatOptionLabelUser}
                isMulti={true}
                isSearchable={false}
                isDisabled={true}
              />
            </div>
            <span className="mr-3 ml-3">These applications are </span>
            <div className="area w-32 grow text-sm">
              <SelectGroup
                value={{
                  value: item.admissionTeamSetting.isVisible ? 'Visible' : 'Invisible',
                  label: item.admissionTeamSetting.isVisible ? 'Visible' : 'Invisible',
                }}
                styles={selectStylesWithNoTitle}
                isSearchable={true}
                isDisabled={true}
              />
            </div>
            <span className="mr-3 ml-3">to non team members</span>
          </div>
        );
      })}
    </div>
  );
};

/* Class: create, Update, Delete */
const BodyInfoClass = ({ bodyData, titleCase, infoData }) => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [classInfo, setClassInfo] = useState([]);

  const valueSelectGroupHandle = useCallback((value, listData) => {
    const dataSelect = listData.find((data) => {
      return data.value === value;
    });
    return dataSelect;
  }, []);

  return (
    <div>
      {!isShowDetails && (
        <div>
          <p className="my-4">List Classes Update or Create</p>
          <div className="flex flex-wrap max-h-[45vh] overflow-y-auto">
            {infoData.content.data.map((item, idx) => {
              return (
                <div
                  onClick={() => {
                    setClassInfo(item);
                    setIsShowDetails(true);
                  }}
                  key={idx}
                  className="w-full mb-4 h-28 pt-3.5 flex justify-around ml-5 rounded-2xl shadow-grade cursor-pointer"
                >
                  <div className="grades-card-section_text">
                    <h5 style={styles.playgroup}>{item.name}</h5>
                    <p className="cursor-pointer" style={styles.study}>
                      <img className="inline" src={phstudent} alt="study" />
                      {item.maxStudentsPerSection} per Section
                    </p>
                  </div>
                  <div className="grades-card-section_text">
                    <p className="relative" style={styles.section}>
                      {item.noSections} Section
                    </p>
                    <p className="relative" style={styles.yrs}>
                      {item.minAge} - {item.maxAge} yrs
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isShowDetails && (
        <div
          className={clsx(
            'create-grade-panel',
            isShowDetails ? 'pointer-events-auto' : 'pointer-events-none hidden',
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center flex-auto w-32">
              <div>
                <h2 className="heading-grade-name text-2xl">
                  {classInfo?.extraInfo?.gradeName || 'Grade Name'}
                </h2>
                <p className="heading-program-name text-sm">
                  {classInfo?.extraInfo?.programName || 'Program Name'}
                </p>
              </div>
            </div>
            <button onClick={() => setIsShowDetails(false)}>
              <Close />
            </button>
          </div>
          <hr className="mt-2" />
          <div className="h-[28rem] w-full">
            <div className="flex-row overflow-y-auto overflow-x-hidden h-full w-full">
              <div>
                <h3 className="body-heading">1. Class info</h3>
                {/* <p className="body-content">Please fill the information below to set the class information</p> */}
                <Input
                  // disabled
                  onChange={(e) => { }}
                  label="Class Name"
                  name="class-name"
                  value={classInfo?.name || ''}
                  customStyle={{
                    width: '26.1875rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.5rem',
                    paddingLeft: '1.25rem',
                    marginTop: '1rem',
                  }}
                />
                <div className="flex flex-wrap">
                  <SelectGroup
                    isDisabled
                    placeholder="Number of Sections"
                    title="Number of Sections"
                    defaultValue={numberOfSections[0]}
                    value={
                      valueSelectGroupHandle(classInfo?.noSections, numberOfSections) ||
                      numberOfSections[0]
                    }
                    options={numberOfSections}
                    isMulti={false}
                    isSearchable={true}
                    styles={selectGroupCreateGrade}
                    onChange={(e) => { }}
                    customStyle={{
                      marginTop: '1.25rem',
                      width: '12.5rem',
                      borderRadius: '0.375rem',
                      fontSize: 14,
                      fontSize: '0.875rem',
                    }}
                  />
                  <SelectGroup
                    isDisabled
                    placeholder="Max Students per Section"
                    title="Max Students per Section"
                    defaultValue={maxStudentPerSections[0]}
                    value={
                      valueSelectGroupHandle(
                        classInfo?.maxStudentsPerSection,
                        maxStudentPerSections,
                      ) || maxStudentPerSections[0]
                    }
                    options={maxStudentPerSections}
                    isMulti={false}
                    isSearchable={true}
                    styles={selectGroupCreateGrade}
                    onChange={(e) => { }}
                    customStyle={{
                      marginTop: '1.25rem',
                      borderRadius: '0.375rem',
                      fontSize: 14,
                      width: '12.5rem',
                      marginLeft: '1rem',
                      fontSize: '0.875rem',
                    }}
                  />
                  <SelectGroup
                    isDisabled
                    placeholder="Minimum Age"
                    title="Minimum Age"
                    defaultValue={minAge[0]}
                    value={valueSelectGroupHandle(classInfo?.minAge, minAge) || minAge[0]}
                    options={minAge}
                    isMulti={false}
                    isSearchable={true}
                    onChange={(e) => { }}
                    customStyle={{
                      marginTop: '1.25rem',
                      width: '12.5rem',
                      borderRadius: '0.375rem',
                      fontSize: 14,
                      fontSize: '0.875rem',
                    }}
                    // classNameSelect="pl-5"
                    styles={selectGroupCreateGrade}
                  />
                </div>
              </div>
              <div>
                <h3 className="body-heading">2. Grade settings(optional)</h3>
                {/* <p className="body-content">
                  These are optional settings that help with the school process
                </p> */}
                <GradeSetting
                  checkToggle={classInfo?.isEnforceMinAge}
                  setCheckToggle={(e) => { }}
                  title="Enforce Min Age"
                  content="This will not let anyone younger to complete application"
                />
                <GradeSetting
                  checkToggle={classInfo?.isEnforceMaxStrength}
                  setCheckToggle={(e) => { }}
                  title="Enforce Max Strength"
                  content="Admissions cannot be converted maximum limit reaches"
                />
                <GradeSetting
                  checkToggle={classInfo?.isWaitingList}
                  setCheckToggle={(e) => { }}
                  title="Waiting List"
                  content="This will let candidates apply after hitting maximum strength"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

/* GradeFee: create, Update, Delete */
const BodyInfoGradeFee = ({ bodyData, titleCase, infoData }) => {
  const [isShowDetails, setIsShowDetails] = useState(false);
  const [feeInfo, setFeeInfo] = useState([]);

  return (
    <div>
      {!isShowDetails && (
        <div>
          <p className="my-4">List Fees Update or Create</p>
          <div className="flex flex-wrap max-h-[45vh] overflow-y-auto">
            {infoData.content.data.map((item, idx) => {
              return (
                <div
                  onClick={() => {
                    setFeeInfo(item);
                    // setIsShowDetails(true);
                  }}
                  key={idx}
                  className="w-full mb-4 h-28 pt-3.5 flex justify-around ml-5 rounded-2xl shadow-grade cursor-pointer"
                >
                  <div className="grades-card-section_text">
                    <h5 style={styles.playgroup}>{item?.extraInfo?.gradeName}</h5>
                    <p className="cursor-pointer" style={styles.study}>
                      {/* <img className="inline" src={phstudent} alt="study" /> */}
                      Application fee: {formatMoney(item.applicationFee, item.currency)}
                      <br></br>
                      Fee payment: {formatMoney(item.feePayment, item.currency)}
                    </p>
                  </div>
                  <div className="grades-card-section_text">
                    <p className="relative" style={styles.section}>
                      {item?.extraInfo?.programName}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
      {isShowDetails && (
        <div
          className={clsx(
            'create-grade-panel',
            isShowDetails ? 'pointer-events-auto' : 'pointer-events-none hidden',
          )}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center flex-auto w-32">
              <div>
                <h2 className="heading-grade-name text-2xl">
                  {feeInfo?.extraInfo?.gradeName || 'Grade Name'}
                </h2>
                <p className="heading-program-name text-sm">
                  {feeInfo?.extraInfo?.programName || 'Program Name'}
                </p>
              </div>
            </div>
            <button onClick={() => setIsShowDetails(false)}>
              <Close />
            </button>
          </div>
          <hr className="mt-2" />
          <div className="h-[28rem] w-full">
            <div className="flex-row overflow-y-auto overflow-x-hidden h-full w-full">
              <div>
                <h3 className="body-heading">1. Application Fee</h3>
                <Input
                  onChange={(e) => { }}
                  type="number"
                  label="Application Fee"
                  value={feeInfo?.applicationFee}
                  customStyle={{
                    width: '30rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                    marginTop: '1rem',
                    paddingLeft: '1.25rem',
                  }}
                />
              </div>

              <div>
                <h3 className="body-heading">2. Fee Payment</h3>
                <Input
                  onChange={(e) => { }}
                  type="number"
                  label="Fee Payment"
                  value={feeInfo?.feePayment}
                  customStyle={{
                    width: '30rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                    marginTop: '1rem',
                    paddingLeft: '1.25rem',
                  }}
                />
              </div>

              <div>
                <h3 className="body-heading mb-3">3. Currency</h3>
                <div className="w-[30rem]">
                  <SelectGroup
                    title="Curency"
                    value={feeInfo?.currency}
                    styles={selectStylesWithNoTitle}
                    options={currencies}
                    isMulti={false}
                    isSearchable={true}
                    onChange={(e) => { }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const GradeSetting = ({ checkToggle, setCheckToggle, title, content }) => {
  return (
    <div className="flex justify-end border rounded-md w-[30rem] h-16 items-center mt-4">
      <div className="flex-1 ml-4">
        <p className="text-sm text-[#00160A] font-semibold">{title}</p>
        <p className="text-xs text-[#637381] font-normal">{content}</p>
      </div>
      <ToggleButton
        name={title}
        value={checkToggle}
        customStyle={{ marginRight: '2rem' }}
        onChange={setCheckToggle}
        inputSize="middle"
        bg="#A2BCB0"
      />
    </div>
  );
};

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};

/* Campus: create, Update, Delete */
const BodyInfoCampus = ({ bodyData, titleCase }) => {
  return (
    <div className="flex-col mt-6">
      <div className="flex flex-wrap">
        {bodyData.map((info, idx) => {
          return (
            <div key={idx} className="w-1/3 pl-4 mb-4">
              {info.key !== 'logo' ? (
                <div>
                  <p className="text-sm" style={{ color: '#6D7175' }}>
                    {titleCase(info.key)}
                  </p>
                  {/* <p className='text-sm mt-2' style={{color: '#202223'}}>{info.value}</p> */}
                </div>
              ) : (
                <div>
                  <p className="text-sm" style={{ color: '#6D7175' }}>
                    {titleCase(info.key)}
                  </p>
                  {/* <img src={info.value} className='mt-2 w-6 h-6' /> */}
                </div>
              )}
            </div>
          );
        })}
      </div>
      {bodyData?.prevSchool && (
        <div className="flex flex-wrap pl-4 justify-start">
          <div className="mr-16">
            <p className="text-sm mb-2" style={{ color: '#6D7175' }}>
              Previous School
            </p>
            <div className="flex py-1 px-2 rounded-lg shadow-[0_3px_10px_3px_rgba(0,0,0,0.2)]">
              <img
                src={bodyData.prevSchool.school.logo}
                alt="avatar"
                className="w-6 h-6 mr-2 pb-0.5 rounded-full"
              />
              <div>
                <p style={{ color: '#6D7175', fontSize: '0.5rem' }}>
                  {bodyData.prevSchool.school.schoolName}
                </p>
                <p style={{ color: '#BDC1CC', fontSize: '0.5rem' }}>
                  {bodyData.prevSchool.school.address}
                </p>
              </div>
            </div>
          </div>
          <div>
            <p className="text-sm" style={{ color: '#6D7175' }}>
              Silblings
            </p>
            <div
              className="flex mt-2 py-1.5 px-2 rounded-full"
              style={{ backgroundColor: '#2E72D2' }}
            >
              <img
                src={bodyData.prevSchool.silblings.avatar}
                alt="avatar"
                className="w-5 h-5 mr-2 pb-0.5 rounded-full"
              />
              <p
                className="pt-0.5 pr-1"
                style={{ color: '#FFFFFF', fontSize: '0.625rem' }}
              >
                {bodyData.prevSchool.silblings.name}
              </p>
            </div>
          </div>
        </div>
      )}
      {bodyData?.feedback && (
        <div className="relative pl-4 mt-6">
          <p className="text-sm" style={{ color: '#6D7175' }}>
            Key Feedback:
          </p>
          <div className="flex mt-2">
            <div>
              <img
                src={bodyData.feedback.avatar}
                alt="avatar"
                className="w-6 h-6 mr-2 pb-0.5 rounded-full"
              />
            </div>
            <div>
              <p className="" style={{ color: '#000000', fontSize: '0.75rem' }}>
                {bodyData.feedback.name}
              </p>
              <p className="" style={{ color: '#637381', fontSize: '0.5rem' }}>
                {bodyData.feedback.position}
              </p>
            </div>
            <div
              className="ml-4 mt-0.5"
              style={{ color: '#000000', fontSize: '0.625rem' }}
            >
              {bodyData.feedback.time}
            </div>
          </div>
          <div className="triangle-bottom-right"></div>
          <p
            className="inline-block border mt-2 py-3 px-3 rounded-lg"
            style={{ fontSize: '0.625rem', color: '#8C9094', backgroundColor: '#F1F2F3' }}
          >
            {bodyData.feedback.content}
          </p>
        </div>
      )}
    </div>
  );
};

/* Role: create, Update, Delete */
const BodyInfoRole = ({ bodyData, titleCase, infoData }) => {
  const [listModule, setListModule] = useState([]);
  const [listPermission, setListPermission] = useState([]);
  const [openPage, setOpenPage] = useState({});
  const [openPermission, setOpenPermission] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campusId = useStorage((state) => state.currentCampus)?.id;

  const handleClickArrow = useCallback((module) => {
    setOpenPage((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
    setOpenPermission((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
  }, []);

  const transformPagePermission = useCallback((data) => {
    const result = {};
    data.forEach((item) => {
      const { pageId, permissionId, active } = item;
      if (!result[pageId]) result[pageId] = {};
      if (permissionId === 1) result[pageId].create = { active };
      else if (permissionId === 2) result[pageId].edit = { active };
      else result[pageId].read = { active };
    });
    return result;
  }, []);

  useEffect(() => {
    authApi.getAllPage({ campusId }).then((res) => {
      if (res.data.success) {
        const dataInput = infoData.content.rolePagePermissions || infoData.content.data;
        const groupPermission = transformPagePermission(dataInput);
        const dataRender = [];
        const pageModule = [];
        const modules = {};
        res.data.data.forEach((item) => {
          const { module, pages } = item;
          modules[module] = true;
          const listPage = [];
          pages.forEach((page) => {
            const { id, name } = page;
            listPage.push(name);
            if (groupPermission[id]) {
              dataRender.push(groupPermission[id]);
            } else dataRender.push({});
          });
          pageModule.push({ module, pageModule: listPage });
        });

        setListModule(pageModule);
        setListPermission(dataRender);
        setOpenPage(modules);
        setOpenPermission(modules);
      } else {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: res.data.message,
        });
        setIsShowToastMessage(true);
      }
    })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Approval Body Information Error', error);
      });
  }, []);

  return (
    <div className="flex-col text-sm mt-4">
      <div className="overflow-x-hidden overflow-y-auto flex">
        <div className="block" style={{ width: '19rem' }}>
          <ModuleSection
            listModule={listModule}
            handleClickArrow={handleClickArrow}
            openPage={openPage}
          />
        </div>
        <div className="block" style={{ marginTop: '0rem' }}>
          <PermissionSection
            listRole={['a']}
            listPermission={listPermission}
            listModule={listModule}
            openPermission={openPermission}
            isApproval={true}
            handleClickUpdate={() => { }}
          />
        </div>
      </div>
    </div>
  );
};
