import { Modal } from '@mui/material';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import Pagination from '@stories/pagination/pagination';
import { rowsPerPageCount } from '@utils/storage';
import { DEFAULT_PAGE_START } from '@utils/utils';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { NavLinks } from '../Header/components/index';
import AddDistributionModal from './components/add-distribution-modal';
import DistributionItem from './components/index';
import './distribution.scss';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const Distribution = ({ burger }) => {
  const [open, openModal] = useState(false);
  const [listDistribution, setListDistribution] = useState([]);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalDistributions, setTotalDistributions] = useState(0);
  const campus = useStorage((state) => state.currentCampus);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const isOpen = () => {
    openModal(!open);
  };

  const getDataFormAPi = (page, pageSize) => {
    centralAdminApi.getListDistribution({ page: page, pageSize: pageSize, campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setListDistribution(data.data.objects);
          setTotalDistributions(data.data.total);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get Distributions Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Distributions Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Distribution Error', error);
      });
  };
  useEffect(() => {
    getDataFormAPi(currentPage, selectedRowsPerPage);
  }, [currentPage, selectedRowsPerPage, campus]);

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Modal open={open} onClose={isOpen}>
        <div>
          <AddDistributionModal
            open={open}
            isOpen={isOpen}
            setListDistribution={setListDistribution}
          />
        </div>
      </Modal>

      <div className={clsx('distribution pr-20 pl-0 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
        <NavLinks urls={dataLinks.admissionsSettingLinks} />
        <div className="w-full flex mb-10 items-center justify-between">
          <h1 className="namepage">Distribution</h1>
          <button
            className="bg-main-blue text-white p-2 pr-3.5 pl-3.5 rounded"
            onClick={isOpen}
          >
            Add Distribution Rule
          </button>
        </div>
        <div className="w-full">
          {listDistribution.map((item, index) => (
            <DistributionItem
              key={index}
              indexOfDistribution={index}
              distribution={item}
              setListDistribution={setListDistribution}
              getDataFormAPi={getDataFormAPi}
              currentPage={currentPage}
              selectedRowsPerPage={selectedRowsPerPage}
            />
          ))}
        </div>
      </div>
      <Pagination
        customClass="fixed bottom-0 right-0 z-[3] pr-4"
        customStyle={{ height: '3rem' }}
        page={currentPage}
        rowsPerPage={selectedRowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleChangePage={handleChangePage}
        rowsPerPageCount={rowsPerPageCount}
        totalRows={totalDistributions}
      />
    </>
  );
};

export default Distribution;
