import PropTypes from 'prop-types';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { usePaymentInputs } from 'react-payment-inputs';

import { ToastMessageContext } from '../../../../context/toast-context';
import { callApi } from '../../../../helper/call-api';
import { formatMoney } from '../../../../helper/format-money';
import { Button } from '../../../../stories';
import { checkErrorApiFetch } from '../../../../utils/check-error/api-error';

export default function FeePayment(props) {
  const { enquiry, handleNextStage, isCompletedOrClosed, onClick } = props
  const [payment, setPayment] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const { admissionResult, application } = useMemo(() => {
    const admissionResult = JSON.parse(enquiry.admissionResult.info);
    const application = admissionResult.application

    return { admissionResult, application }
  }, [])

  const cardNumberValidator = ({ cardNumber, cardType, errorMessages }) => {
    if (cardType.displayName === 'Visa' || cardType.displayName === 'Mastercard') {
      return;
    }
    return 'Card must be Visa or Mastercard';
  };
  const {
    wrapperProps,
    getCardImageProps,
    getCardNumberProps,
    meta,
    getExpiryDateProps,
    getCVCProps,
  } = usePaymentInputs({
    cardNumberValidator,
  });

  useEffect(() => {
    application?.id &&
      callApi({
        method: 'get',
        url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/fee-payment/payment-order/${application?.id}`,
      })
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Payment Info',
            )
          ) {
            const paymentInfo = JSON.parse(res.data.data.info);
            setPayment(paymentInfo);
            // const { short_url } = paymentInfo;
            // window.open(short_url, '_blank');
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Payment Info Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get Payment Info Error', error);
        });
  }, [application]);

  const handleClickPayNow = () => {
    const { short_url } = payment;
    window.open(short_url, '_blank');
  };
  const handleClickNextStep = () => {
    if (isCompletedOrClosed) {
      handleNextStage()
      return
    }
    if (!admissionResult?.feePayment || admissionResult?.feePayment?.isPending) {
      setToastMessage({
        status: 'warning',
        title: 'Waiting for admission process',
        message: `The current status is ${enquiry.status} at step ${enquiry.currentStep}`,
      });
      setIsShowToastMessage(true);
      return;
    }
    handleNextStage();
  };
  return (
    <div className="flex flex-col mt-20 mb-16 left-5 1200px:left-0 relative payment">
      <div
        className="bg-main-blue w-80 h-40 absolute text-white font-semibold pt-5 pl-5 -left-32 -top-14 1200px:-left-36 1200px:-top-16"
        style={{ zIndex: -1 }}
      >
        <p className="mb-2">Fee Payment</p>
        <p>
          {formatMoney(
            enquiry?.programGrade?.programGradeFee?.feePayment,
            enquiry?.programGrade?.programGradeFee?.currency,
          )}
        </p>
      </div>
      <div
        className="flex flex-col border border-green-200 bg-white"
        style={{ width: '31.25rem' }}
      >
        <div className="bg-green-200 h-8 font-semibold flex items-center pl-10">
          Pay Now
        </div>
        <div className="flex justify-between pl-10 pr-10 mt-10">
          <TextWrapper
            title="Application Name"
            body={`${application?.info?.firstName} ${application?.info?.lastName}`}
          />
          <TextWrapper title="Fee Type" body="Fee Payment" />
        </div>
        <div className="flex justify-between pl-10 pr-10 mt-3">
          <TextWrapper title="Academic Year" body={enquiry?.academicYear?.name} />
          <TextWrapper
            title="Grade of Entry"
            body={enquiry?.programGrade?.grade?.code}
          />
        </div>
        <div className="flex justify-between pl-10 pr-10 mt-3">
          <TextWrapper
            title="Payment"
            body={
              payment &&
              `${formatMoney(payment.amount_paid, payment.currency)}/${formatMoney(
                payment.amount,
                payment.currency,
              )}`
            }
          />
        </div>
        <div className="flex flex-col pl-10 pr-10 mt-20">
          {payment && payment.amount_paid !== payment.amount && (
            <Button
              text="Pay Now"
              onClick={() =>
                handleClickPayNow()
              }
              customStyle={{
                paddingLeft: '10.938rem',
                fontWeight: 600,
                marginBottom: '2rem',
              }}
            />
          )}
          {payment && payment.amount_paid >= payment.amount && (
            <Button
              text="Next Step"
              onClick={() =>
                // location.pathname.search('bloomingdale') !== -1
                //   ? onClick(true)
                //   : history.push('/application/complete')
                handleClickNextStep()
              }
              customStyle={{
                paddingLeft: '10.938rem',
                fontWeight: 600,
                marginBottom: '2rem',
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
}

const TextWrapper = ({ title, body }) => {
  return (
    <div className="font-bold text-sm">
      <p className="mb-2">{title}</p>
      <p className="text-second-gray">{body}</p>
    </div>
  );
};

FeePayment.propTypes = {
  /**  @param Type An object.
   * @param Object Keys for object - applicationName: string, academicYear: string, feeType: string, grade: string, data: object
   * @param data Keys for object - cardholder: string, cardNumber: string, cardDate: string, cvc: string
   * @param Example {
   * applicationName: 'Mira Ozawana',
   * academicYear: '2021-22',
   * feeType: 'Aplication Fee',
   * grade: 'MY2',
   * data: {
   * cardholder: '',
   * cardNumber: '',
   * cardDate:'',
   * cvc: ''
   * }
   * }
   **/
  data: PropTypes.object,
};
