import { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { ToastMessageContext } from '../../context/toast-context';
import AutocompletePlace from '@components/autocomplete-place';
import { PlaceTypes } from '@constant/index';
import useMap from '@hooks/google-map';
import { dataLinks } from '../../entities/routes';
import { staticsize } from '../../static/image';
import { Input, Upload } from '../../stories';
import { NavLinks } from '../Header/components/index';
import './campus-detail.scss';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import mediaApi from 'api/media';
import centralAdminApi from 'api/central-admin';
import authApi from 'api/auth';
import { LoadingButton } from '@mui/lab';
import useStorage from 'store/storage';
const styles = {
  btnWhite: {
    width: '6.563rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.563rem',
    marginTop: '1rem',
    marginBottom: '1rem',
    '&:hover': {
      backgroundColor: 'rgba(64, 78, 237, 0.9)',
    }
  },
};

export default function CampusDetail({ burger }) {
  const { getPlaces } = useMap();
  const [campus, setCampus] = useState({});
  const [avatar, setAvatar] = useState('');
  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const { id } = useParams();
  const location = useLocation();
  const history = useHistory();
  const currentCampus = useStorage((state) => state.currentCampus);

  const onChangePlace = async (place) => {
    if (place) {
      let latitude = place.geometry.location.lat();
      let longitude = place.geometry.location.lng();
      const { data, error } = await getPlaces({ latitude, longitude });
      if (error) {
        setToastMessage({
          status: 'error',
          title: 'Google Map',
          message: 'Something went wrong. Please try again',
        });
        setIsShowToastMessage(true);
        return;
      }
      if (data.data.results.length !== 0) {
        let country = '';
        let state = '';
        let city = '';
        let zipCode = '';
        for (let address of data.data.results[0].address_components) {
          const { long_name, types } = address;
          if (types.includes(PlaceTypes.locality)) city = long_name;
          if (types.includes(PlaceTypes.administrative_area_level_1)) {
            if (!city) city = long_name;
            state = long_name;
          }
          if (types.includes(PlaceTypes.country)) country = long_name;
          if (types.includes(PlaceTypes.postal_code)) zipCode = long_name;
        }
        setCampus((prev) => ({
          ...prev,
          country,
          state,
          city,
          zipCode,
          address: [place.formatted_address],
        }));
      }
    } else {
      setToastMessage({
        status: 'error',
        title: 'Google Map',
        message: 'Something went wrong. Please try again',
      });
      setIsShowToastMessage(true);
    }
  };

  useEffect(() => {
    const fetchCampus = async () => {
      if (location?.state) {
        setCampus(location.state?.data ? location.state.data : {});
        setAvatar(location.state?.data?.logo ? location.state.data.logo : staticsize);
      } else {
        try {
          const res = await centralAdminApi.getCampus({ campusId: currentCampus?.isCentral ? undefined : currentCampus?.id });
          if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Get Campus', false)) {
            setCampus(res?.data.data);
            setAvatar(res?.data?.data?.logo ? res.data.data.logo : staticsize);
          }
        } catch (error) {
          setToastMessage({
            status: 'error',
            title: 'Get Campus Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Campus Detail Error', error);
        }
      }
    }
    fetchCampus();
  }, []);

  const handleShowWarning = (field) => {
    setToastMessage({
      status: 'warning',
      title: 'Validation Rule',
      message: 'Missing field ' + field,
    });
    setIsShowToastMessage(true);
  };

  const createCampus = async (campus) => {
    if (!campus.name) {
      handleShowWarning('name');
      return;
    }
    if (!campus.code) {
      handleShowWarning('code');
      return;
    }
    if (!campus.address) {
      handleShowWarning('address');
      return;
    }
    if (!campus.country) {
      handleShowWarning('country');
      return;
    }
    if (!campus.city) {
      handleShowWarning('city');
      return;
    }
    if (!campus.state) {
      handleShowWarning('state');
      return;
    }
    if (!campus.zipCode) {
      handleShowWarning('zipCode');
      return;
    }
    if (!campus.phoneNumber) {
      handleShowWarning('phoneNumber');
      return;
    }
    try {
      let api;
      if (id === 'company-edit' || id === 'campus-edit') {
        api = authApi.updateCampus(
          { campusId: currentCampus?.isCentral ? undefined : currentCampus?.id },
          { ...(({ dateUpdated, dateCreated, ...o }) => o)(campus) }
        )
      } else {
        api = authApi.createCampus(
          {
            ...(({ dateUpdated, dateCreated, id, ...o }) => ({
              ...o,
              companyId: location?.state?.companyId,
            }))(campus),
          }
        )
      }
      const res = await api;
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage,
        id === 'company-edit' || id === 'campus-edit'
          ? 'Update Campus Successfully'
          : 'Create Campus Successfully',)) {
        history.goBack();
        // if (id === 'campus-edit') {
        //   history.push('/campus/campus-information');
        // } else if (id === 'company') {
        //   history.push('/giiki/settings');
        // } else if (id === 'company-edit') {
        //   history.push('/institutions');
        // } else {
        //   history.push('/central-admin/campuses');
        // }
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title:
          id === 'company-edit' || id === 'campus-edit'
            ? 'Update campus fail'
            : 'Create campus fail',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Campus Detail Error', error);
    }
  };

  const handleUpdateCampus = async (e, campus) => {
    try {
      setIsLoading(true);
      e.preventDefault();
      if (file) {
        const formData = new FormData();
        formData.set('file', file);
        formData.set('mediaId', file.name);
        const res = await mediaApi.uploadMedia(formData);
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Upload Logo', false)) {
          campus.logo = res.data.data;
          await createCampus(campus);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Logo Failed',
            message: res?.data?.message,
          });
          setIsShowToastMessage(true);
        }
      } else await createCampus(campus);
      setIsLoading(false);
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: '',
        message: 'Something went wrong. Please try again',
      });
      setIsShowToastMessage(true);
      console.log('Campus Detail Error', error);
      setIsLoading(false);
    }
  };

  return (
    <div className="campus-detail px-8 w-full">
      <div>
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.campusDetailLinks} />
          <h1 className="namepage">
            {id === 'company-edit' || id === 'campus-edit'
              ? 'Edit Campus Details'
              : 'Create a New Campus'}
          </h1>
          <div className="campus-detail-form">
            <div className="campus-detail-form_section campus-detail-section">
              <div className="campus-detail-section_header">
                <h2 className="campus-detail-section_title">1. Campus Image</h2>
                <p className="campus-detail-section_text">
                  Upload a campus image to make it easily recognizable.
                </p>
              </div>
              <div className="campus-detail-upload">
                <img
                  className="campus-detail-upload_image"
                  src={avatar ? `${avatar}` : staticsize}
                  alt="uploaded logo"
                />
                <Upload
                  text="Select Image"
                  id="image-upload"
                  accept="image/*"
                  onChange={(e) => {
                    let selected = e.target.files;
                    if (selected && selected?.[0]) {
                      const tmp = selected[0];
                      setFile(tmp);
                      const reader = new FileReader();
                      reader.readAsDataURL(tmp);
                      reader.onload = () => {
                        setAvatar(reader.result);
                      };
                      reader.onerror = function (error) {
                        console.log('Error: ', error);
                      };
                    }
                  }}
                />
              </div>
            </div>
            <div className="campus-detail-form_section campus-detail-section">
              <div className="campus-detail-section_header">
                <h2 className="campus-detail-section_title">2. Campus Details</h2>
                <p className="campus-detail-section_text">
                  Add/ Edit campus details including name, address and other essentials.
                </p>
              </div>
              <div className="campus-detail-section_content gap-x-0">
                <div className="flex">
                  <Input
                    onChange={(e) => {
                      id === 'company-edit'
                        ? setCampus({ ...campus, name: e.target.value })
                        : setCampus({ ...campus, name: e.target.value });
                    }}
                    label="Campus Name"
                    name="campus-name"
                    value={campus?.name ? campus.name : ''}
                    customStyle={{ width: '14.25rem' }}
                  />
                  <Input
                    onChange={(e) => {
                      setCampus({ ...campus, code: e.target.value });
                    }}
                    label="Campus Code"
                    name="campus-code"
                    value={campus?.code ? campus.code : ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      marginLeft: '1.75rem',
                    }}
                  />
                </div>
                <AutocompletePlace
                  onChange={onChangePlace}
                  types={['route']}
                  renderInput={() => (
                    <Input
                      onChange={(e) => {
                        setCampus({ ...campus, address: [e.target.value] });
                      }}
                      label="Address"
                      name="address"
                      value={campus?.address ? campus.address[0] : ''}
                      customStyle={{ width: '30.25rem' }}
                    />
                  )}
                />
                <div className="flex">
                  <Input
                    onChange={(e) => {
                      setCampus({ ...campus, country: e.target.value });
                    }}
                    label="Country"
                    name="country"
                    value={campus?.country ? campus.country : ''}
                    customStyle={{ width: '14.25rem' }}
                  />
                  <Input
                    onChange={(e) => {
                      setCampus({ ...campus, city: e.target.value });
                    }}
                    label="City"
                    name="city"
                    value={campus?.city ? campus.city : ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      marginLeft: '1.75rem',
                    }}
                  />
                </div>
                <div className="flex">
                  <Input
                    onChange={(e) => {
                      setCampus({ ...campus, state: e.target.value });
                    }}
                    label="State/Province"
                    name="state"
                    value={campus?.state ? campus.state : ''}
                    customStyle={{ width: '14.25rem', fontSize: 18 }}
                  />
                  <Input
                    onChange={(e) => {
                      setCampus({ ...campus, zipCode: e.target.value });
                    }}
                    label="Zip Code"
                    name="zip-code"
                    value={campus?.['zipCode'] ? campus['zipCode'] : ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      marginLeft: '1.75rem',
                    }}
                  />
                </div>
                <Input
                  onChange={(e) => {
                    setCampus({ ...campus, phoneNumber: [e.target.value] });
                  }}
                  label="Phone Number"
                  name="phone"
                  value={campus?.['phoneNumber'] ? campus['phoneNumber'][0] : ''}
                  customStyle={{ width: '30.25rem' }}
                />
              </div>
              <div className="campus-detail-section_footer">
                <LoadingButton loading={isLoading}
                  sx={styles.btnWhite}
                  loadingPosition="start"
                  startIcon={<></>}
                  variant="contained"
                  onClick={(e) => {
                    handleUpdateCampus(e, campus);
                  }}
                >
                  Save
                </LoadingButton>

              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
