import clsx from 'clsx';
import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { boxArrow, campus_default } from '../../../../static/image';
import Checkbox from '../../../../stories/checkbox/checkbox';
import Pagination from '../../../../stories/pagination/pagination';
import {
  objFieldGiikiInfo,
  rowsPerPageCount,
  updateGiikiInfo,
} from '../../../../utils/storage';
import { headerName, navbarName } from '../../../../utils/utils';
import ActionDropdownMenu from './actiondropdownmenu';
import './table.scss';

const Table = (props) => {
  const {
    headerData,
    campuses,
    setHeader,
    setNavbar,
    currentPage,
    setCurrentPage,
    selectedRowsPerPage,
    setSelectedRowsPerPage,
    totalObjectsFromApi,
  } = props;
  const history = useHistory();

  const handleChangeRowsPerPage = (event) => {
    setSelectedRowsPerPage(+event.target.value);
    setCurrentPage(0);
  };

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleOnClickEditGoToCampusUser = (campus, key) => {
    const index = key + currentPage * selectedRowsPerPage;
    const selectCampus = campuses[index];
    history.push(`/central-admin/campus/users`, { campus, selectCampus });
  };

  const handleChangeCampus = (info) => {
    updateGiikiInfo(objFieldGiikiInfo.currentCampus, info);
    updateGiikiInfo(objFieldGiikiInfo.navbarName, navbarName.campus);
    updateGiikiInfo(objFieldGiikiInfo.headerName, headerName.campus);
    setNavbar(navbarName.campus);
    setHeader(headerName.campus);
    history.push('/dashboard');
  };

  const getWidth = useCallback((index) => {
    switch (index) {
      case 0:
        return '5%';
      case 1:
        return '45%';
      case 2:
        return '25%';
      case 3:
        return '15%';
      case 4:
        return '10%';
      default:
        return '';
    }
  }, []);

  return (
    <>
      <table className="table-auto">
        <thead className="datatable-header">
          <tr>
            {headerData.map((item, key) => {
              return (
                <th
                  key={key}
                  align={key === 4 ? 'rigth' : ''}
                  style={{ width: getWidth(key) }}
                >
                  {item}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody className="datatable-body">
          {campuses.map((data, key) => {
            return (
              <React.Fragment key={key}>
                <tr className="datatable-item">
                  <td className="datatable-checkbox">
                    {data.status && data.status.trim() === 'Draft' ? (
                      <Checkbox onChange={(e) => { }} disabled={true} value={true} />
                    ) : (
                      <Checkbox onChange={(e) => { }} />
                    )}
                  </td>

                  <td className="datatable-info h-full pt-2">
                    <img className="datatable-avatar" src={data?.logo || campus_default} alt="" />
                    <div
                      className="cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        history.push(`/central-admin/campuses/general`, {
                          selectCampus: data,
                        });
                      }}
                    >
                      <p
                        className="datatable-name font-medium"
                        style={{ color: '#212B36' }}
                      >
                        {data.name}
                      </p>
                      <p className="datatable-location">{data.address[0]}</p>
                    </div>
                  </td>

                  <td className="datatable-code h-full pt-2">{data.code}</td>

                  <td className="pt-2">
                    {data.status?.trim() === 'Active' ? (
                      <DataTableStatus text="Active" status="Active"></DataTableStatus>
                    ) : data.status?.trim() === 'Inactive' ? (
                      <DataTableStatus
                        text="Inactive"
                        status="Inactive"
                      ></DataTableStatus>
                    ) : data.status?.trim() === 'Draft' ? (
                      <DataTableStatus text="Draft" status="Draft"></DataTableStatus>
                    ) : (
                      ''
                    )}
                  </td>

                  <td className="datatable-action table-cell h-full pt-2" align="right">
                    <div className="flex justify-between">
                      <img
                        className="cursor-pointer mr-4"
                        src={boxArrow}
                        alt="button"
                        onClick={() => {
                          handleChangeCampus(data);
                        }}
                      />
                      <ActionDropdownMenu
                        data={data}
                        index={key}
                        handleClickUsers={handleOnClickEditGoToCampusUser}
                      />
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <div className="datatable-footer">
        <Pagination
          customClass="fixed bottom-6 right-12 z-[3] pr-4"
          customStyle={{ height: '3rem' }}
          page={currentPage}
          rowsPerPage={selectedRowsPerPage}
          rowsPerPageCount={rowsPerPageCount}
          totalRows={totalObjectsFromApi}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleChangePage={handleChangePage}
        />
      </div>
    </>
  );
};

const DataTableStatus = ({ text, status }) => {
  return (
    <div className={clsx('datatable-status', `datatable-status--${status}`)}>{text}</div>
  );
};

export default Table;
