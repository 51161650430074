import { useEffect, useMemo, useState } from 'react';
import { colorOptionForUserSelect } from '../../../../entities/colorOption';
import {
  selectStylesWithNoTitle,
  selectStylesWithNoTitle2,
  selectStylesWithTitle,
  selectStylesWithTitle3,
} from '../../../../entities/select-style';
import { mainavatar } from '../../../../static/image';
import { Chip, SelectGroup } from '../../../../stories';

export const GradeSetting = ({
  index,
  settingsOfGrade,
  counselorsOption,
  programsTabCurrent,
  setSettingsOfProgram,
}) => {
  const visibleOptions = [
    { value: true, label: 'Visible' },
    { value: false, label: 'Invisible' },
  ];

  const formatOptionLabelUser = ({ value, label, info }) => {
    return (
      <div className="block">
        <Chip
          key={info?.id}
          label={label}
          image={info?.photoURL || mainavatar}
          chipAvatarStyle={{ width: 18, height: 18, marginLeft: '-2px', marginTop: '0px' }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
          customLabelStyle={{ marginTop: '-6px' }}
          customLabelClass={'pr-0.5'}
        />
      </div>
    );
  };
  const [selectedCounselors, setSelectedCounselors] = useState([]);
  const [selectedVisible, setSelectedVisible] = useState();

  useEffect(() => {
    const counselors = settingsOfGrade?.admissionTeamSetting?.counselors?.map(
      (item, key) => {
        const { counselor, id } = item;
        return {
          value: counselor.name,
          label: counselor.name,
          color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
          info: {
            photoURL: counselor.photoURL,
            id: id,
            counselor: { ...counselor },
          },
        };
      },
    );
    setSelectedCounselors(counselors ?? []);

    const valueOfVisible = settingsOfGrade?.admissionTeamSetting?.isVisible;
    const visible = {
      value: valueOfVisible,
      label: valueOfVisible ? 'Visible' : 'Invisible',
    };

    setSelectedVisible(visible);
  }, [settingsOfGrade]);

  const newCounselorsOption = useMemo(() => {
    const counselors = settingsOfGrade?.admissionTeamSetting?.counselors?.map(
      (item, key) => {
        const { counselor, id } = item;
        return {
          value: counselor.name,
          label: counselor.name,
          color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
          info: {
            photoURL: counselor.photoURL,
            id: id,
            counselor: { ...counselor },
          },
        };
      },
    );
    const addIdForCounselors = [];
    counselorsOption.forEach((originItem) => {
      const result = (counselors || []).find(
        (newItem) => originItem.info.counselor.id === newItem.info.counselor.id,
      );
      result ? addIdForCounselors.push(result) : addIdForCounselors.push(originItem);
    });
    return addIdForCounselors;
  }, []);

  return (
    <div className="grade p-5 mt-0 mb-[22px]">
      <div className="grade__left gap-0 mr-10 w-48">
        <p className="">{settingsOfGrade?.grade?.name}</p>
        <span className="">{programsTabCurrent}, Galileo campus</span>
      </div>
      <div className="w-96 h-11 grow">
        <SelectGroup
          title="Team"
          value={selectedCounselors[0]?.value === undefined ? null : selectedCounselors}
          styles={selectStylesWithTitle3}
          // options={counselorsOption}
          options={newCounselorsOption}
          formatOptionLabel={formatOptionLabelUser}
          isMulti={true}
          isSearchable={false}
          onChange={(e) => {
            setSelectedCounselors(e);
            setSettingsOfProgram((prev) => {
              return {
                ...prev,
                programGrade: (() => {
                  return prev.programGrade.map((item, key) => {
                    if (key === index) {
                      return {
                        ...item,
                        admissionTeamSetting: {
                          ...item.admissionTeamSetting,
                          counselors: e.map((element) => {
                            const result = element.info.counselor;
                            return {
                              id: element.info.id,
                              counselor: {
                                ...result,
                              },
                            };
                          }),
                        },
                      };
                    }
                    return item;
                  });
                })(),
              };
            });
          }}
        />
      </div>
      <span className="mr-[9px] ml-5">These applications are </span>
      <div className="area w-34">
        <SelectGroup
          defaultValue={selectedVisible ?? null}
          value={selectedVisible ?? null}
          styles={selectStylesWithNoTitle2}
          options={visibleOptions}
          isSearchable={true}
          onChange={(e) => {
            setSelectedVisible(e);
            setSettingsOfProgram((prev) => {
              return {
                ...prev,
                programGrade: (() => {
                  return prev.programGrade.map((item, key) => {
                    if (key === index) {
                      return {
                        ...item,
                        admissionTeamSetting: {
                          ...item.admissionTeamSetting,
                          isVisible: e.value ?? selectedVisible.values,
                        },
                      };
                    }
                    return item;
                  });
                })(),
              };
            });
          }}
        />
      </div>
      <span className="mx-[9px]">to non team members</span>
    </div>
  );
};
