import clsx from 'clsx';
import { memo } from 'react';
import Select from 'react-select';
import { selectStyle } from '../../../entities/select-style';
import SingleSelect from './component/single-select';
import SingleInput from './component/single-input';

const AcademicCard = memo((props) => {
  const { data, optionType, optionsDiscountType, processApproval, aboveLimitOption, handleChange } = props
  const { discountType, maximumValue, aboveLimit, needApproval, finalProcess } = data

  return (
    <div className="principial-card">

      {/* Discount Type */}
      <div className="principial-card-row mb-2">
        <span className="principial-card-row_fix">Discount Type</span>
        <div>
          <Select
            defaultValue={optionsDiscountType.find(item => item.value === optionType.Percentage)}
            styles={selectStyle}
            options={optionsDiscountType}
            onChange={(e) => {
              handleChange({
                scholarship: 'academicScholarship',
                index: 0,
                name: 'discountType',
                value: e.value
              });
            }}
            customStyle={{ maxWidth: '50%' }}
          />
        </div>
      </div>

      {/* Maximum Value */}
      <div className="principial-card-row mb-3">
        <span>Maximum Value</span>
        <div
          className={clsx(
            'flex relative',
            discountType !== optionType.Percentage ? 'mr-5' : 'mr-14_fix',
          )}
        >
          <label className="absolute mr-6">{discountType !== optionType.Percentage && '₹'}</label>
          <input
            className={clsx(
              'flex',
              discountType !== optionType.Percentage
                ? 'w-20  text-left pl-3.5'
                : 'w-12 text-right pr-5',
            )}
            value={maximumValue ? maximumValue.toString() : '0'}
            onChange={(e) => {
              handleChange({
                scholarship: 'academicScholarship',
                index: 0,
                name: 'maximumValue',
                value: +e.target.value
              });
            }}
            type="number"
          />
          <label className="absolute ml-8">{discountType === optionType.Percentage && '%'}</label>
        </div>
      </div>

      {/* Approval */}
      <div className='mb-2'>
        <SingleInput label='Approval'
          value={processApproval}
          type='text'
          disabled={true}
          onChange={() => { }}
        />
      </div>

      {/* Above limit option */}
      <SingleSelect label='Above limit option'
        value={aboveLimitOption.find(item => item.value === aboveLimit) || null}
        options={aboveLimitOption}
        onChange={(e) => {
          handleChange({
            scholarship: 'academicScholarship',
            index: 0,
            name: 'aboveLimit',
            value: e.value
          })
        }}
      />

      {/* Final Approval */}
      {aboveLimit === 'Need Approval' && (
        <div className='mb-3'>
          <SingleInput label='Final Approval'
            value={finalProcess}
            type='text'
            disabled={true}
            onChange={() => { }}
          />
        </div>
      )}

    </div>
  )
})
AcademicCard.displayName = 'AcademicCard'

export default AcademicCard
