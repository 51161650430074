import './index.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import App from './App';
import reportWebVitals from './reportWebVitals';
import store, { persistor } from './store/store';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';
import { ToastMessageProvider } from './context/toast-context';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://b578ed1e7e44593b168be962a4c02926@o4506621881679872.ingest.us.sentry.io/4507068425764864',
  integrations: [new Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('../firebase-messaging-sw.js')
    .then(function (registration) {
      console.log('Registration successful, scope is:', registration.scope);
    })
    .catch(function (err) {
      console.log('Service worker registration failed, error:', err);
    });
}

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));

const THEME = createTheme({
  typography: {
    fontFamily: `"Inter", "Roboto", "Helvetica", "Arial", sans-serif`,
  },
});

root.render(
  <BrowserRouter>
    <ThemeProvider theme={THEME}>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ToastMessageProvider>
            <QueryClientProvider client={queryClient}>
              <App />
            </QueryClientProvider>
          </ToastMessageProvider>
        </PersistGate>
      </Provider>
    </ThemeProvider>
  </BrowserRouter>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
