import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { NavLinks } from '../Header/components/index';
import Table from './components/campus-list/table';
import { ToastMessageContext } from 'context/toast-context';
import { Button } from '@stories/index';
import { dataLinks } from 'entities/routes';
import { DEFAULT_PAGE_SIZE, DEFAULT_PAGE_START } from '@utils/utils';
import './campus-list.scss';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function CampusList(props) {
  const { burger, isOpen, setHeader, setNavbar } = props;
  const history = useHistory();
  const theadData = [false, 'Campus Name', 'Campus Code', 'Status', false];
  const [loading, setLoading] = useState(false);
  const [campuses, setCampuses] = useState([]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(DEFAULT_PAGE_SIZE);
  const [currentPage, setCurrentPage] = useState(DEFAULT_PAGE_START);
  const [totalObjectsFromApi, setTotalObjectsFromApi] = useState(0);
  const [companyId, setCompanyId] = useState();
  const userLogin = useStorage((state) => state.auth)

  useEffect(() => {
    userLogin?.companyId && setCompanyId(userLogin?.companyId);
  }, [userLogin]);

  useEffect(() => {
    setLoading(true);

    const apiListCampus = async (companyId, currentPage, selectedRowsPerPage) => {
      try {
        if (!companyId) return
        const res = await authApi.getListCampus({ page: currentPage, pageSize: selectedRowsPerPage, companyId });
        if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, false)) {
          setCampuses(res.data?.data?.objects);
          setTotalObjectsFromApi(res.data?.data?.total);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get User Failed',
            message: res?.data?.message,
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Get User Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Campus List Error', error);
      }
    }
    setLoading(true);
    apiListCampus(companyId, currentPage, selectedRowsPerPage);
    setLoading(false);
  }, [currentPage, selectedRowsPerPage, companyId]);

  return (
    <>
      <div className={clsx('campus-list pr-24 transition-all-300', burger ? 'pl-80' : 'pl-40')}>
        <NavLinks urls={dataLinks.campusListLinks} />
        <div className="w-full flex items-center justify-between">
          <h1 className="namepage">Campuses</h1>
          <div className="flex items-center relative bottom-2.5">
            <Button
              text="Create a Campus"
              customStyle={{ marginLeft: '1rem' }}
              onClick={() =>
                history.push('/detail/company', {
                  companyId: campuses[0]?.companyId ? campuses[0].companyId : '',
                })
              }
            />
          </div>
        </div>
        {campuses?.length ? (
          <Table
            headerData={theadData}
            campuses={campuses}
            setHeader={setHeader}
            setNavbar={setNavbar}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            selectedRowsPerPage={selectedRowsPerPage}
            setSelectedRowsPerPage={setSelectedRowsPerPage}
            totalObjectsFromApi={totalObjectsFromApi}
          />
        ) : (
          <div>Have No Campus</div>
        )}
      </div>
    </>
  );
}
