function LoginSucess() {
    window.addEventListener('message', (event) => {
        // if (event.origin === 'https://your-website.com') {
        //   // Xử lý dữ liệu từ Google sau khi đăng nhập thành công
        //   const userData = event.data;
          
        //   // Đóng cửa sổ popup
        //   window.opener.postMessage({ userData, action: 'closePopup' }, 'https://your-website.com');
        // }
        const userData = event.data;
          console.log('userData', userData);
          // Đóng cửa sổ popup
          window.opener.postMessage({ userData, action: 'closePopup' }, window.location.origin);
      });
    return ( <div>
        Login success
    </div> );
}

export default LoginSucess;