import {mainavatar} from "@static/image";
import React from "react";
import {task} from '../image';

const SingleTask = (props) => {
  const {className} = props

  return (
    <div className={`maincard w-full h-[4.5rem] p-4 flex-row items-center justify-between ${className}`}>
      <div className={'flex items-center'}>
        <img src={task}/>
        <div className={'ml-3'}>
          <p className={'text-sm text-[#637381]'}>Call to parents</p>
          <p className={'text-xs text-[#63738199]'}>Assigned by <span className={'text-[#404EED]'}>System</span></p>
        </div>
      </div>

      <div className={'text-xs text-[#63738199]'}>
        <p className={'text-xs'}>Due on <span className={'text-[#27C59A]'}>20th Oct</span></p>
        <div className={'flex justify-end'}>
          <p>to</p><img className='w-5 h-5 ml-2' src={mainavatar}/>
        </div>
      </div>

    </div>
  )
}

export default SingleTask;