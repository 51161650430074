import clsx from 'clsx';
import * as _ from 'lodash';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import BloomCrumbs from '../../../components/bloomingdale/crumbs/crumbs';
import BloomHeader from '../../../components/bloomingdale/header/header';
import { ToastMessageContext } from '../../../context/toast-context';
import { EnquiryStatus } from '../../../entities/data';
import { bloomDetailsSelectMini } from '../../../entities/select-style';
import { callApi } from '../../../helper/call-api';
import { Checkbox, SelectGroup } from '../../../stories/';
import {
  checkErrorApiFetch,
  checkErrorMultipleApiFetch,
  checkErrorSingleApi,
} from '../../../utils/check-error/api-error';
import { idType, languages } from '../../../utils/utils';
import { backarrow, buttoncheck } from '../image';
import admissionApi from 'api/admission';

const dataLinks = [
  { name: 'Applicant Details', active: 'Active' },
  { name: 'Application Fee', active: 'Unactive' },
  { name: 'Child Assessment', active: 'Unactive' },
  { name: 'Principial Approval', active: 'Unactive' },
  { name: 'Documents', active: 'Unactive' },
  { name: 'Finance Aproval', active: 'Unactive' },
];

export default function BloomFormMore() {
  const location = useLocation();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [isUploadDoc, setIsUploadDoc] = useState(false);

  const [enquiry, setEnquiry] = useState({});
  const enquiryId = location?.state?.enquiryId;
  const initDataUrls = location?.state?.initDataUrls;
  const [formsData, setFormsData] = useState({});
  const [admissionResult, setAdmissionResult] = useState({});

  const [admissionResultPrevious, setAdmissionResultPrevious] = useState({});
  const [admissionResultApplication, setAdmissionResultApplication] = useState();
  const history = useHistory();
  const [listGrade, setListGrade] = useState([]);
  const [indexUploadDocument, setIndexUploadDocument] = useState([]);

  const appStyle = document.getElementsByClassName('app');

  useEffect(() => {
    if (location.state?.enquiry) {
      setEnquiry(location.state.enquiry);
      fetchGrade(location.state.enquiry?.campusId);
    }
  }, [location.state]);

  const fetchGrade = useCallback(async (campusId) => {
    try {
      const apiGrade = await callApi({
        method: 'get',
        url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/list`,
        // params: { campusId },
      });
      if (checkErrorApiFetch(apiGrade, setToastMessage, setIsShowToastMessage)) {
        const tmp = apiGrade.data.data.objects.map(({ id, name }) => ({
          value: id,
          label: name,
        }));
        setListGrade(tmp);
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Fetch Data Failed',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Enquiry Error', error);
    }
  }, []);

  const fetchData = useCallback(() => {
    if (enquiry?.currentStep === EnquiryStatus.COMPLETE) {
      setEnquiry(enquiry);
      const admissionResult = JSON.parse(enquiry.admissionResult.info);
      setAdmissionResult(admissionResult);
      return;
    }

    // let enquiryInfo = {
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry/parent/${enquiryId}`,
    // };

    let api = admissionApi.getEnquiryParent(enquiryId);

    if (enquiry.id) {
      // enquiryInfo = {
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry/parent/${enquiry.id}`,
      // };
      api = admissionApi.getEnquiryParent(enquiry.id)
    }
    api.then((res) => {
      if (
        checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get Enquiry')
      ) {
        const enquiry = res.data.data;

        const admissionResult =
          enquiry.admissionResult && JSON.parse(enquiry.admissionResult.info);
        setAdmissionResult(admissionResult);
        setAdmissionResultPrevious(_.cloneDeep(admissionResult));

        if (admissionResult?.application) {
          setAdmissionResultApplication({ ...admissionResult.application });
        } else {
          setAdmissionResultApplication({
            info: enquiry.info,
            enquiryId: enquiry.id,
            applicationParent: enquiry.info.familyInfo,
          });
        }
      }
    })
      .catch((error) => {
        console.log('error', error);
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [enquiryId]);

  useEffect(() => {
    fetchData();
  }, []);

  const handleNext = () => {
    if (!_.isEqual(admissionResultPrevious?.application, admissionResultApplication)) {
      setToastMessage({
        status: 'warning',
        title: 'Your change do not save',
        message: 'Please save before next step',
      });
      setIsShowToastMessage(true);
    } else {
      history.push(initDataUrls[2].linkId, {
        enquiryId: enquiryId,
        enquiry: enquiry,
        initDataUrls: initDataUrls,
        index: 3,
        formId: initDataUrls[2].formId,
        stageName: initDataUrls[2].stageName,
      });
    }
  };

  const handleSubmit = async () => {
    if (isUploadDoc) {
      const listCallApiUpload = [];

      Object.values(formsData).forEach((formData) => {
        listCallApiUpload.push(
          admissionApi.uploadMedia(formData)
          // callApi({
          //   method: 'post',
          //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/media`,
          //   data: formData,
          //   contentType: 'multipart/form-data',
          // }),
        );
      });

      await Promise.all(listCallApiUpload)
        .then(async (res) => {
          if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
            for (let i = 0; i < res.length; i++) {
              admissionResultApplication.additionalInfo.identityDetails.idProof[
                indexUploadDocument[i]
              ]['uploadDocument'] = res[i].data.data;
            }
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Upload Image Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Create Application Error', error);
        });
    }

    if (!_.isEqual(admissionResultPrevious?.application, admissionResultApplication)) {
      // callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent`,
      //   data: {
      //     applicationInfo: {
      //       id: admissionResultApplication.id,
      //       enquiryId: admissionResultApplication.enquiryId,
      //       info: admissionResultApplication.info,
      //       academicYearId: admissionResultApplication.academicYearId,
      //       academicYearTermId: admissionResultApplication.academicYearTermId,
      //       gradeId:
      //         admissionResultApplication?.gradeId ||
      //         admissionResultApplication?.programGrade?.gradeId,
      //     },
      //     familyInfo: admissionResultApplication?.familyInfo,
      //     additionalInfo: admissionResultApplication?.additionalInfo,
      //   },
      //   // params: { campusId: enquiryInfo?.campus?.value },
      // })
      admissionApi.updateApplicationParent(undefined, {
        applicationInfo: {
          id: admissionResultApplication.id,
          enquiryId: admissionResultApplication.enquiryId,
          info: admissionResultApplication.info,
          academicYearId: admissionResultApplication.academicYearId,
          academicYearTermId: admissionResultApplication.academicYearTermId,
          gradeId:
            admissionResultApplication?.gradeId ||
            admissionResultApplication?.programGrade?.gradeId,
        },
        familyInfo: admissionResultApplication?.familyInfo,
        additionalInfo: admissionResultApplication?.additionalInfo,
      })
        .then((res) => {
          if (
            checkErrorSingleApi(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Submit Data',
            )
          ) {
            history.push(initDataUrls[2].linkId, {
              enquiryId: res.data.data?.id,
              enquiry: enquiry,
              initDataUrls: initDataUrls,
              index: 3,
              formId: initDataUrls[2].formId,
              stageName: initDataUrls[2].stageName,
            });
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Submit Data Failed',
            message: error?.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Application Error', error);
        });
    }
  };

  return (
    <div>
      <BloomHeader />
      <BloomCrumbs data={dataLinks} />
      <div className="w-screen flex items-start mt-12 pl-20 pr-20">
        <button
          className="w-6 h-8 none-after cursor-pointer mr-20 mt-5"
          onClick={() =>
            history.push('/bloomingdale-details', {
              admissionResultApplication: admissionResultApplication,
            })
          }
        >
          <img src={backarrow} className="w-4 h-4" />
        </button>
        <div className="flex w-full justify-between">
          <div className="mt-5">
            <IdentityDetails
              application={admissionResultApplication}
              setApplication={setAdmissionResultApplication}
              formsData={formsData}
              setFormsData={setFormsData}
              setIsUploadDoc={setIsUploadDoc}
              setIndexUploadDocument={setIndexUploadDocument}
            />
            <SchoolHistory
              listGrade={listGrade}
              application={admissionResultApplication}
              setApplication={setAdmissionResultApplication}
            />
            <Language
              application={admissionResultApplication}
              setApplication={setAdmissionResultApplication}
            />
            <AdditionalSupport
              application={admissionResultApplication}
              setApplication={setAdmissionResultApplication}
            />
          </div>
        </div>
      </div>
      <div className="bloom-footer">
        <button
          className="pl-3.5 pr-3.5 pt-1.5 pb-1.5 text-sm text-white font-semibold mr-3.5"
          style={{ background: '#00a86b' }}
          onClick={handleSubmit}
        >
          Save
        </button>
        {/* <NavLink
          to={{
            pathname: '/bloomingdale-review',
            state: { enquiryId: enquiry.id, enquiry: enquiry },
          }}
        >
          <button className="flex items-center bg-main-blue pl-3.5 pr-1.5 pt-1 pb-1 text-sm text-white font-semibold mr-3.5">
            Next
            <Arrow />
          </button>
        </NavLink> */}
        <button
          className="flex items-center bg-main-blue pl-3.5 pr-1.5 pt-1 pb-1 text-sm text-white font-semibold mr-3.5"
          onClick={handleNext}
        >
          Next
          <Arrow />
        </button>
      </div>
    </div>
  );
}

const AdditionalSupport = (props) => {
  const { setApplication, application } = props;

  const [buttons, setButtons] = useState({ expirience: false, recommended: false });
  const [additionalSupport, setAdditionalSupport] = useState({
    isChildExpAcademicOrEmotionalOrBehavioral: false,
    isChildReceiveOrRecommendedForExtraSupport: false,
    isPsychological: false,
    isPhysical: false,
    isOccupation: false,
    isSpeech: false,
  });

  useEffect(() => {
    if (application?.additionalInfo?.additionalSupport)
      setAdditionalSupport(application?.additionalInfo?.additionalSupport);
  }, [application]);

  return (
    <div>
      <div
        className="flex items-center pl-5 font-semibold mb-4"
        style={{ width: '26.25rem', height: '3rem', background: '#f5fcf9' }}
      >
        Additional Support
      </div>
      <div className="pl-3 mb-5 flex flex-col">
        <div className="mb-5">
          <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
            Did your child expirience any academic/ emotional/behavioural difficulties in
            school?
          </p>
          <div className="flex">
            <button
              className={clsx(
                'group-button none-after',
                additionalSupport.isChildExpAcademicOrEmotionalOrBehavioral === true &&
                'group-button--active',
              )}
              onClick={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isChildExpAcademicOrEmotionalOrBehavioral: true,
                    },
                  },
                }))
              }
            >
              Yes
              {additionalSupport.isChildExpAcademicOrEmotionalOrBehavioral === true && (
                <img src={buttoncheck} className="absolute w-3.5 h-3.5 top-1 right-1" />
              )}
            </button>
            <button
              className={clsx(
                'group-button none-after',
                additionalSupport.isChildExpAcademicOrEmotionalOrBehavioral === false &&
                'group-button--active',
              )}
              onClick={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isChildExpAcademicOrEmotionalOrBehavioral: false,
                    },
                  },
                }))
              }
            >
              No
              {additionalSupport.isChildExpAcademicOrEmotionalOrBehavioral === false && (
                <img src={buttoncheck} className="absolute w-3.5 h-3.5 top-1 right-1" />
              )}
            </button>
          </div>
        </div>
        <div className="mb-5">
          <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
            Did your child receive or been recommended for extra support in or outside
            school?
          </p>
          <div className="flex">
            <button
              className={clsx(
                'group-button none-after',
                additionalSupport.isChildReceiveOrRecommendedForExtraSupport === true &&
                'group-button--active',
              )}
              onClick={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isChildReceiveOrRecommendedForExtraSupport: true,
                    },
                  },
                }))
              }
            >
              Yes
              {additionalSupport.isChildReceiveOrRecommendedForExtraSupport === true && (
                <img src={buttoncheck} className="absolute w-3.5 h-3.5 top-1 right-1" />
              )}
            </button>
            <button
              className={clsx(
                'group-button none-after',
                additionalSupport.isChildReceiveOrRecommendedForExtraSupport === false &&
                'group-button--active',
              )}
              onClick={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isChildReceiveOrRecommendedForExtraSupport: false,
                    },
                  },
                }))
              }
            >
              No
              {additionalSupport.isChildReceiveOrRecommendedForExtraSupport === false && (
                <img src={buttoncheck} className="absolute w-3.5 h-3.5 top-1 right-1" />
              )}
            </button>
          </div>
        </div>
        <div>
          <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
            Did you child ever receive a
          </p>
          <div className="flex items-center">
            <Checkbox
              color="green"
              value={additionalSupport?.isPsychological}
              onChange={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isPsychological:
                        !prev.additionalInfo.additionalSupport.isPsychological,
                    },
                  },
                }))
              }
            />
            <p
              className="ml-1.5 font-semibold"
              style={
                additionalSupport?.isPsychological
                  ? { color: '#00a86b' }
                  : { color: '#ababcd' }
              }
            >
              Psychological assessment
            </p>
          </div>
          <div className="flex items-center mt-3 mb-3">
            <Checkbox
              color="green"
              value={additionalSupport?.isPhysical}
              onChange={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isPhysical: !prev.additionalInfo.additionalSupport.isPhysical,
                    },
                  },
                }))
              }
            />
            <p
              className="ml-1.5 font-semibold"
              style={
                additionalSupport?.isPhysical
                  ? { color: '#00a86b' }
                  : { color: '#ababcd' }
              }
            >
              Physical Therapy
            </p>
          </div>
          <div className="flex items-center mt-3 mb-3">
            <Checkbox
              color="green"
              value={additionalSupport.isOccupation}
              onChange={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isOccupation: !prev.additionalInfo.additionalSupport.isOccupation,
                    },
                  },
                }))
              }
            />
            <p
              className="ml-1.5 font-semibold"
              style={
                additionalSupport?.isOccupation
                  ? { color: '#00a86b' }
                  : { color: '#ababcd' }
              }
            >
              Occupation Therapy
            </p>
          </div>
          <div className="flex items-center">
            <Checkbox
              color="green"
              value={additionalSupport?.isSpeech}
              onChange={() =>
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    additionalSupport: {
                      ...prev.additionalInfo.additionalSupport,
                      isSpeech: !prev.additionalInfo.additionalSupport.isSpeech,
                    },
                  },
                }))
              }
            />
            <p
              className="ml-1.5 font-semibold"
              style={
                additionalSupport?.isSpeech ? { color: '#00a86b' } : { color: '#ababcd' }
              }
            >
              Speech and Language Therapy
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

const Language = (props) => {
  const { application, setApplication, formsData, setFormsData } = props;

  const [cardRender, addCardRender] = useState([0]);
  const [languagesInfo, setLanguagesInfo] = useState({
    motherTongue: {
      name: '',
      language: { label: 'Indian', value: 'Indian' },
    },
    additionalLanguage: [
      // {
      //   language: { label: 'Indian', value: 'Indian' },
      //   isRead: false,
      //   isSpeak: false,
      //   isWrite: false,
      // },
    ],
  });

  useEffect(() => {
    if (application?.additionalInfo?.languages)
      setLanguagesInfo(application?.additionalInfo?.languages);
  }, [application]);

  return (
    <div>
      <div
        className="flex items-center pl-5 font-semibold mb-4"
        style={{ width: '26.25rem', height: '3rem', background: '#f5fcf9' }}
      >
        Language
      </div>
      <div className="pl-3 mb-5 flex items-center">
        <div>
          <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
            Mother Tongue
          </p>
          <input
            type="text"
            className="group-input"
            style={{ width: '16.5rem' }}
            value={languagesInfo?.motherTongue?.name}
            onChange={(e) => {
              setApplication((prev) => ({
                ...prev,
                additionalInfo: {
                  ...prev?.additionalInfo,
                  languages: {
                    ...prev?.additionalInfo?.languages,
                    motherTongue: {
                      ...prev?.additionalInfo?.languages?.motherTongue,
                      name: e.target.value,
                    },
                  },
                },
              }));
            }}
          />
        </div>
        <div className="ml-7 mr-7">
          <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
            Language choice in previous school
          </p>
          <SelectGroup
            styles={bloomDetailsSelectMini}
            options={languages}
            value={languagesInfo?.motherTongue?.language || null}
            onChange={(e) => {
              setApplication((prev) => ({
                ...prev,
                additionalInfo: {
                  ...prev?.additionalInfo,
                  languages: {
                    ...prev?.additionalInfo?.languages,
                    motherTongue: {
                      ...prev?.additionalInfo?.languages?.motherTongue,
                      language: e,
                    },
                  },
                },
              }));
            }}
          // error={error}
          />
        </div>
      </div>
      {languagesInfo?.additionalLanguage?.map((item, index) => {
        return (
          <LanguageCard
            key={index}
            index={index}
            item={item}
            application={application}
            setApplication={setApplication}
          />
        );
      })}
      <button
        onClick={() =>
          setApplication((prev) => ({
            ...prev,
            additionalInfo: {
              ...prev.additionalInfo,
              languages: {
                ...prev.additionalInfo.languages,
                additionalLanguage: ((listAdditionalLanguage) => {
                  if (listAdditionalLanguage) {
                    listAdditionalLanguage.push({
                      language: { label: 'Indian', value: 'Indian' },
                      isRead: false,
                      isSpeak: false,
                      isWrite: false,
                    });
                    return listAdditionalLanguage;
                  } else {
                    return [
                      {
                        language: { label: 'Indian', value: 'Indian' },
                        isRead: false,
                        isSpeak: false,
                        isWrite: false,
                      },
                    ];
                  }
                })(prev?.additionalInfo?.languages?.additionalLanguage),
              },
            },
          }))
        }
        className="mb-10 none-after flex items-center font-semibold text-sm"
        style={{ color: '#ababcd' }}
      >
        <Close />
        Add additional language
      </button>
    </div>
  );
};

const LanguageCard = (props) => {
  const { index, item, setApplication, application } = props;

  const [buttons, setButtons] = useState({ read: true, speak: false, write: false });

  return (
    <div className="mb-5 flex items-center">
      <div className="ml-3 mr-7">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Additional Language
        </p>
        <SelectGroup
          styles={bloomDetailsSelectMini}
          options={languages}
          value={item.language || null}
          onChange={(e) => {
            setApplication((prev) => ({
              ...prev,
              additionalInfo: {
                ...prev.additionalInfo,
                languages: {
                  ...prev.additionalInfo.languages,
                  additionalLanguage: ((listAdditionalLanguage) => {
                    listAdditionalLanguage[index]['language'] = e;
                    return listAdditionalLanguage;
                  })(prev?.additionalInfo?.languages?.additionalLanguage),
                },
              },
            }));
          }}
        // error={error}
        />
      </div>
      <div className="flex mt-8">
        <button
          className={clsx(
            'group-button none-after',
            item.isRead === true && 'group-button--active',
          )}
          onClick={() => {
            setApplication((prev) => ({
              ...prev,
              additionalInfo: {
                ...prev.additionalInfo,
                languages: {
                  ...prev.additionalInfo.languages,
                  additionalLanguage: ((listAdditionalLanguage) => {
                    listAdditionalLanguage[index]['isRead'] = !item.isRead;
                    return listAdditionalLanguage;
                  })(prev?.additionalInfo?.languages?.additionalLanguage),
                },
              },
            }));
          }}
        >
          Read
          {item.isRead === true && (
            <img
              src={buttoncheck}
              className="absolute w-3.5 h-3.5 top-1 right-1"
              alt="read"
            />
          )}
        </button>
        <button
          className={clsx(
            'group-button none-after',
            item.isSpeak === true && 'group-button--active',
          )}
          onClick={() => {
            setApplication((prev) => ({
              ...prev,
              additionalInfo: {
                ...prev.additionalInfo,
                languages: {
                  ...prev.additionalInfo.languages,
                  additionalLanguage: ((listAdditionalLanguage) => {
                    listAdditionalLanguage[index]['isSpeak'] = !item.isSpeak;
                    return listAdditionalLanguage;
                  })(prev?.additionalInfo?.languages?.additionalLanguage),
                },
              },
            }));
          }}
        >
          Speak
          {item.isSpeak === true && (
            <img
              src={buttoncheck}
              className="absolute w-3.5 h-3.5 top-1 right-1"
              alt="speak"
            />
          )}
        </button>
        <button
          className={clsx(
            'group-button none-after',
            item.isWrite === true && 'group-button--active',
          )}
          onClick={() => {
            setApplication((prev) => ({
              ...prev,
              additionalInfo: {
                ...prev.additionalInfo,
                languages: {
                  ...prev.additionalInfo.languages,
                  additionalLanguage: ((listAdditionalLanguage) => {
                    listAdditionalLanguage[index]['isWrite'] = !item.isWrite;
                    return listAdditionalLanguage;
                  })(prev?.additionalInfo?.languages?.additionalLanguage),
                },
              },
            }));
          }}
        >
          Write
          {item.isWrite === true && (
            <img
              src={buttoncheck}
              className="absolute w-3.5 h-3.5 top-1 right-1"
              alt="write"
            />
          )}
        </button>
      </div>
    </div>
  );
};

const SchoolHistory = (props) => {
  const { listGrade, setApplication, application } = props;
  const [schoolsHistory, setSchoolsHistory] = useState([
    {
      name: '',
      grade: { value: listGrade[0] ?? '', label: listGrade[0] ?? '' },
    },
  ]);

  useEffect(() => {
    if (application?.additionalInfo?.schoolsHistory)
      setSchoolsHistory(application?.additionalInfo?.schoolsHistory);
  }, [application]);

  return (
    <div>
      <div
        className="flex items-center pl-5 font-semibold mb-4"
        style={{ width: '26.25rem', height: '3rem', background: '#f5fcf9' }}
      >
        School history
      </div>
      {schoolsHistory.map((item, index) => (
        <div key={index} className="pl-3 mb-5 flex items-center">
          <div>
            <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
              School Name
            </p>
            <input
              key={index}
              type="text"
              className="group-input"
              style={{ width: '16.5rem' }}
              value={item?.name}
              onChange={(e) => {
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    schoolsHistory: ((listSchoolsHistory) => {
                      if (listSchoolsHistory) {
                        listSchoolsHistory[index]['name'] = e.target.value;
                        return listSchoolsHistory;
                      } else {
                        return [
                          {
                            name: e.target.value,
                          },
                        ];
                      }
                    })(prev?.additionalInfo?.schoolsHistory),
                  },
                }));
              }}
            />
          </div>
          <div className="ml-7 mr-7">
            <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
              Grades Attended
            </p>
            <SelectGroup
              styles={bloomDetailsSelectMini}
              options={listGrade}
              value={item?.grade || null}
              onChange={(e) => {
                setApplication((prev) => ({
                  ...prev,
                  additionalInfo: {
                    ...prev.additionalInfo,
                    schoolsHistory: ((listSchoolsHistory) => {
                      if (listSchoolsHistory) {
                        listSchoolsHistory[index]['grade'] = e;
                        return listSchoolsHistory;
                      } else {
                        return [
                          {
                            name: e,
                          },
                        ];
                      }
                    })(prev?.additionalInfo?.schoolsHistory),
                  },
                }));
              }}
            // error={error}
            />
          </div>
        </div>
      ))}
      <button
        onClick={() =>
          setApplication((prev) => ({
            ...prev,
            additionalInfo: {
              ...prev.additionalInfo,
              schoolsHistory: ((listSchoolsHistory) => {
                if (listSchoolsHistory) {
                  listSchoolsHistory.push({
                    name: '',
                  });
                  return listSchoolsHistory;
                } else {
                  return [
                    {
                      name: '',
                    },
                  ];
                }
              })(prev?.additionalInfo?.schoolsHistory),
            },
          }))
        }
        className="mb-10 none-after flex items-center font-semibold text-sm"
        style={{ color: '#ababcd' }}
      >
        <Close />
        Add school
      </button>
    </div>
  );
};

const IdentityDetails = (props) => {
  const {
    application,
    setApplication,
    formsData,
    setFormsData,
    setIsUploadDoc,
    setIndexUploadDocument,
  } = props;
  const [listImgPreview, setListImgPreview] = useState({});
  const [cardRender, addCardRender] = useState([0]);
  const [showDocument, setShowDocument] = useState({});
  const [identityDetails, setIdentityDetails] = useState({
    nationality: '',
    idProof: [
      // {
      //   idType: '',
      //   idNumber: '',
      //   uploadDocument: '',
      // },
    ],
  });

  const changeImageHandler = (e, index) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      setIsUploadDoc(true);
      setIndexUploadDocument((prev) => {
        prev.push(index);
        return prev;
      });
      showDocument['index'] = true;

      formsData[index] = new FormData();
      formsData[index].set('file', file);
      formsData[index].set('mediaId', file.name);
      setFormsData(formsData);
      let reader = new FileReader();

      reader.onload = (e) => {
        setListImgPreview((prev) => ({
          ...prev,
          [index]: e.target.result,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  useEffect(() => {
    if (application?.additionalInfo?.identityDetails)
      setIdentityDetails(application?.additionalInfo?.identityDetails);
  }, [application]);

  return (
    <div>
      <div
        className="flex items-center pl-5 font-semibold mb-4"
        style={{ width: '26.25rem', height: '3rem', background: '#f5fcf9' }}
      >
        Identity Details
      </div>
      <div className="pl-3 mb-5">
        <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
          Nationality
        </p>
        <input
          type="text"
          className="group-input"
          value={identityDetails?.nationality}
          onChange={(e) => {
            setApplication((prev) => ({
              ...prev,
              additionalInfo: {
                ...prev.additionalInfo,
                identityDetails: {
                  ...prev.additionalInfo?.identityDetails,
                  nationality: e.target.value,
                },
              },
            }));
          }}
        />
      </div>
      {identityDetails?.idProof &&
        identityDetails.idProof.map((item, index) => {
          const showDocumentBySrc = !!item?.uploadDocument;
          // const a = listImgPreview[index] || item?.uploadDocument;
          return (
            <React.Fragment key={index}>
              <div className="pl-3 mb-5 flex items-center">
                <div>
                  <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
                    ID Type
                  </p>
                  <SelectGroup
                    styles={bloomDetailsSelectMini}
                    options={idType}
                    value={identityDetails?.idProof[index]['idType'] || null}
                    onChange={(e) => {
                      setApplication((prev) => ({
                        ...prev,
                        additionalInfo: {
                          ...prev.additionalInfo,
                          identityDetails: {
                            ...prev.additionalInfo.identityDetails,
                            idProof: ((listIdProof) => {
                              listIdProof[index]['idType'] = e;
                              return listIdProof;
                            })(prev.additionalInfo.identityDetails.idProof),
                          },
                        },
                      }));
                    }}
                  // error={error}
                  />
                </div>
                <div className="ml-7 mr-7">
                  <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
                    ID Number
                  </p>
                  <input
                    key={index}
                    type="text"
                    className="group-input"
                    style={{ width: '16.5rem' }}
                    value={item.idNumber}
                    onChange={(e) => {
                      setApplication((prev) => ({
                        ...prev,
                        additionalInfo: {
                          ...prev.additionalInfo,
                          identityDetails: {
                            ...prev.additionalInfo.identityDetails,
                            idProof: ((listIdProof) => {
                              listIdProof[index]['idNumber'] = e.target.value;
                              return listIdProof;
                            })(prev.additionalInfo.identityDetails.idProof),
                          },
                        },
                      }));
                    }}
                  />
                </div>
                <div>
                  <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
                    Upload documents
                  </p>
                  <div style={{ width: '16.5rem', height: '2.8rem' }}>
                    <label htmlFor={'image-upload' + index} className="cursor-pointer">
                      {(showDocumentBySrc || showDocument['index']) && (
                        <img
                          key={index}
                          className="h-16 border border-main-blue border-dashed"
                          style={{ width: '16.5rem', height: '2.8rem' }}
                          src={listImgPreview[index] || item?.uploadDocument}
                          alt="uploaded"
                        />
                      )}
                      {!showDocumentBySrc && !showDocument['index'] && (
                        <div
                          style={{ width: '16.5rem', height: '2.8rem' }}
                          className="flex items-center justify-center h-16 border border-main-blue border-dashed text-main-blue text-xl"
                        >
                          <p className="bg-main-blue rounded-full text-white w-4 flex items-center justify-center h-4 text-sm">
                            +
                          </p>
                        </div>
                      )}
                    </label>
                    <input
                      type="file"
                      id={'image-upload' + index}
                      name={'image-upload' + index}
                      className="hidden"
                      accept="image/*"
                      onChange={(e) => changeImageHandler(e, index)}
                    />
                  </div>
                </div>
              </div>
            </React.Fragment>
          );
        })}
      <button
        onClick={() =>
          setApplication((prev) => ({
            ...prev,
            additionalInfo: {
              ...prev?.additionalInfo,
              identityDetails: {
                ...prev?.additionalInfo?.identityDetails,
                idProof: ((listIdProof) => {
                  if (listIdProof) {
                    listIdProof.push({
                      idType: '',
                      idNumber: '',
                      uploadDocument: '',
                    });
                    return listIdProof;
                  } else {
                    return [
                      {
                        idType: '',
                        idNumber: '',
                        uploadDocument: '',
                      },
                    ];
                  }
                })(prev?.additionalInfo?.identityDetails?.idProof),
              },
            },
          }))
        }
        className="mb-10 none-after flex items-center font-semibold text-sm"
        style={{ color: '#ababcd' }}
      >
        <Close />
        Add id proof
      </button>
    </div>
  );
};

const Arrow = () => {
  return (
    <svg
      width="24"
      height="24"
      className="ml-1.5"
      viewBox="0 -5 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55 2.5L7 4.05L14.95 12L7 19.95L8.55 21.5L18 12L8.55 2.5Z"
        fill="white"
      />
    </svg>
  );
};

const Close = () => {
  return (
    <svg
      width="20"
      className="mr-3 ml-3 transform rotate-45"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66602 10C1.66602 5.39763 5.39698 1.66667 9.99935 1.66667C12.2095 1.66667 14.3291 2.54464 15.8919 4.10744C17.4547 5.67025 18.3327 7.78986 18.3327 10C18.3327 14.6024 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6024 1.66602 10ZM12.5042 11.6667C12.5042 11.4445 12.4154 11.2315 12.2577 11.075L11.1743 10L12.2577 8.925C12.5844 8.59823 12.5844 8.06844 12.2577 7.74167C11.9309 7.4149 11.4011 7.4149 11.0743 7.74167L9.99935 8.825L8.92435 7.74167C8.59758 7.4149 8.06778 7.4149 7.74101 7.74167C7.41425 8.06844 7.41425 8.59823 7.74101 8.925L8.82435 10L7.74101 11.075C7.58325 11.2315 7.49452 11.4445 7.49452 11.6667C7.49452 11.8889 7.58325 12.1019 7.74101 12.2583C7.89749 12.4161 8.11048 12.5048 8.33268 12.5048C8.55488 12.5048 8.76788 12.4161 8.92435 12.2583L9.99935 11.175L11.0743 12.2583C11.2308 12.4161 11.4438 12.5048 11.666 12.5048C11.8882 12.5048 12.1012 12.4161 12.2577 12.2583C12.4154 12.1019 12.5042 11.8889 12.5042 11.6667Z"
        fill="#ababcd"
      />
    </svg>
  );
};
