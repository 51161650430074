import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { mainavatar } from '@static/image';
import { Chip, SelectGroup } from '@stories/index';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import {
  localStorageKey,
  sessionStorageKey,
} from '@utils/storage';
import { ToastMessageContext } from 'context/toast-context';
import { deleteIcon, editIcon } from './icon';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const FormParentGuardian = (props) => {
  const {
    id,
    index,
    isEdit,
    isBuild,
    isFocus,
    isRequired,
    deletable,
    label,
    value,
    error,
    textForError,
    cascadeValue,
    formStyle,
    handleFocus,
    handleDelete,
    ...rest
  } = props;
  const { width, height, ...restFormStyle } = formStyle;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [options, setOptions] = useState([]);

  const campus = useStorage((state) => state.currentCampus);
  const campusId = useMemo(() => {
    let auth = {};
    auth = sessionStorage.getItem(sessionStorageKey);
    if (!auth) auth = localStorage.getItem(localStorageKey);
    return JSON.parse(auth).campusId || campus?.id;
  }, [campus]);

  const fetchData = useCallback(() => {
    centralAdminApi.getUserOfCompanyOrCampus({ campusId, filterRoleNames: 'parent' })
      .then((res) => {
        if (
          checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Get Parent')
        ) {
          const listParent = res.data.data.objects.map(({ id, name, photoURL }) => ({
            avatar: photoURL,
            value: id,
            label: name,
          }));
          setOptions(listParent);
        }
      })
      .catch((error) => {
        console.log('Get Parent', error);
        setToastMessage({
          status: 'error',
          title: 'Get Parent Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [campusId]);

  useEffect(() => {
    fetchData();
  }, [campusId]);

  const formatOptionLabelUser = useCallback((props) => {
    const { label, avatar, isRole } = props;
    return (
      <div className="block">
        <Chip
          label={label}
          image={!isRole ? avatar || mainavatar : ''}
          symbol={isRole ? label[0] : ''}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  }, []);

  const attributes = {
    label,
    value,
    options,
    styles: { width: '100%', height },
    formatOptionLabel: formatOptionLabelUser,
    ...rest,
  };

  return (
    <div
      className="component"
      style={{
        width,
        ...restFormStyle,
        outline: error ? '1px #ff4842 solid' : isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isBuild && (
          <>
            <img
              alt=""
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            <img
              alt=""
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(index);
              }}
            />
          </>
        )}
        {isEdit && (
          <>
            <img
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
              alt=""
            />
            {deletable && (
              <img
                className="ml-2 cursor-pointer"
                title="delete"
                src={deleteIcon}
                onClick={() => {
                  handleDelete(index);
                }}
                alt=""
              />
            )}
          </>
        )}
      </div>
      <SelectGroup {...attributes} />
      {error && textForError && (
        <p className="text-sm" style={{ color: '#ff4842', marginLeft: 10 }}>
          {textForError}
        </p>
      )}
    </div>
  );
};
export default FormParentGuardian;
