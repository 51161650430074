export const whatsappChatVia = {
  control: (styles, state) => ({
    ...styles,
    width: '8rem',
    minHeight: '30px',
    maxHeight: '30px',
    backgroundColor: '#D9E6E9',
    borderRadius: '1rem'
  }),
  container: (styles) => ({
    ...styles,
    width: '100%'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    paddingRight: '5px'
  }),
  indicatorSeparator: () => null,
  valueContainer: (styles) => ({
    ...styles,
    paddingRight: 0
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    fontWeight: 500,
    color: '#212B36'
  }),
  menu: (styles) => ({
    ...styles,
    width: '100%',
    paddingLeft: '0.1rem',
    paddingRight: '0.1rem',
  }),
  option: (styles, state) => ({
    ...styles,
    padding: '0.1rem',
    borderRadius: '0.1rem',
    backgroundColor: state.isSelected ? '#3b82f6' : 'white',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#bfdbfe' },
  }),
};

export const whatsappChatTo = {
  control: (styles, state) => ({
    ...styles,
    width: '10rem',
    minHeight: '30px',
    maxHeight: '30px',
    backgroundColor: '#D9E6E9',
    borderRadius: '1rem'
  }),
  container: (styles) => ({
    ...styles,
    width: '100%'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    padding: 0,
    paddingRight: '5px'
  }),
  indicatorSeparator: () => null,
  valueContainer: (styles) => ({
    ...styles,
    paddingRight: 0
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    fontWeight: 500,
    color: '#212B36'
  }),
  menu: (styles) => ({
    ...styles,
    width: '100%',
    paddingLeft: '0.1rem',
    paddingRight: '0.1rem',
  }),
  option: (styles, state) => ({
    ...styles,
    padding: '0.1rem',
    borderRadius: '0.1rem',
    backgroundColor: state.isSelected ? '#3b82f6' : 'white',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#bfdbfe' },
  }),
};


export const whatsappChatFrom = {
  control: (styles, state) => ({
    ...styles,
    width: '12.2rem',
    minHeight: '30px',
    maxHeight: '30px',
    backgroundColor: '#D9E6E9',
    borderRadius: '1rem'
  }),
  container: (styles) => ({
    ...styles,
    width: '100%'
  }),
  dropdownIndicator: (styles) => ({
    ...styles,
    paddingTop: 0,
    paddingBottom: 0,
  }),
  indicatorSeparator: () => null,
  singleValue: (styles) => ({
    ...styles,
    padding: '0',
    margin: '0',
    fontWeight: 500,
    color: '#212B36'
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
};

export const toggleButtonStyles = {
  btnBlue: {
    width: '10.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    backgroundColor: '#404eed',
    height: '2.4375rem',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
  btnWhite: {
    width: '10.5rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#404eed',
    backgroundColor: 'white',
    height: '2.4375rem',
    marginTop: '1rem',
    marginRight: '1rem',
    marginBottom: '1rem',
    border: '1px solid #404eed',
  },
};

export const selectAdmission = {
  control: (styles) => ({
    ...styles,
    height: '2.9rem',
  }),
};

export const selectStylesWithTitle = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
    paddingTop: '0.5rem',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
};
export const selectStylesWithTitle3 = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
    paddingTop: '0.5rem',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
      height: '24px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '1' }),
  valueContainer: (styles) => ({
    ...styles,
    width: '100%',
    zIndex: '1',
    gap: '8px',
    overflowY: 'none',
    overflowxX: 'auto',
    height: '36px',
    alignItems: 'flex-start',
  }),
  indicatorSeparator: (styles) => ({ ...styles, width: '0%' }),
};

export const selectStylesApprovers = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
    paddingTop: '0.5rem',
    minWidth: '10rem',
    maxHeight: "5rem",
    overflowY: 'auto',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
};

export const selectStylesWithNoTitle = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
};

export const selectStylesWithNoTitle2 = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  indicatorSeparator: (styles) => ({ ...styles, width: '0' }),
  valueContainer: (styles) => ({ ...styles, padding: '10px 20px' }),
  input: (styles) => ({
    ...styles,
    fontSize: '14px',
    lineHeight: '22px',
    padding: '0',
    margin: '0',
  }),
  singleValue: (styles) => ({ ...styles, padding: '0', margin: '0', lineHeight: '22px' }),
  indicatorsContainer: (styles) => ({
    ...styles,
    marginLeft: '-8px',
    paddingRight: '12px',
  }),
};

export const selectStylesWithNoTitle3 = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
    height: '40px',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  indicatorSeparator: (styles) => ({ ...styles, width: '0' }),
  indicatorsContainer: (styles) => ({ ...styles, paddingRight: '4px' }),
  valueContainer: (styles) => ({ ...styles, paddingLeft: '14px', paddingRight: '14px' }),
  input: (styles) => ({
    ...styles,
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '24px',
  }),
};

export const selectStylesWithNoTitle2ArrowBlue = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
  }),
  dropdownIndicator: base => ({
    ...base,
    color: "#404EED"
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: data.color || '#404EED',
      color: 'white',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'white',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#FFFFFF',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  indicatorSeparator: (styles) => ({ ...styles, width: '0' }),
  valueContainer: (styles) => ({ ...styles, padding: '10px 20px' }),
  input: (styles) => ({
    ...styles,
    fontSize: '14px',
    lineHeight: '22px',
    padding: '0',
    margin: '0',
  }),
  singleValue: (styles) => ({ ...styles, padding: '0', margin: '0', lineHeight: '22px' }),
  indicatorsContainer: (styles) => ({
    ...styles,
    marginLeft: '-8px',
    paddingRight: '12px',
  }),
};


export const selectStylesWithNoTitleNoBackground = {
  control: (styles, state) => ({
    ...styles,
    backgroundColor: state.isDisabled ? '#f2f2f2' : 'white',
  }),
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'white',
      border: '1px solid rgba(145, 158, 171, 0.32)',
      borderRadius: '25px',
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: 'black',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    margin: 'auto',
    marginRight: '0.375rem',
    width: '1rem',
    height: '1rem',
    backgroundColor: '#f2f2f2',
    borderRadius: '100%',
    color: '#404EED',
    ':hover': {
      backgroundColor: data.color,
      color: 'red',
    },
  }),
};

export const selectStyle = {
  control: (styles) => ({
    ...styles,
    width: '9.063rem',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    // height: '2.188rem',
    height: 'fit-content',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#404eed' : 'white',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#e2e2e2', color: 'black' },
  }),

  menu: (styles) => ({ ...styles, width: '8.313rem', marginLeft: '0.313rem' }),
};

export const selectStyleWindow = {
  control: (styles) => ({
    ...styles,
    width: '7.813rem',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#1890ff' : 'white',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#1890ff', color: 'white' }
      : { backgroundColor: '#e2e2e2', color: 'black' },
  }),
  menu: (styles) => ({ ...styles, width: '7.375rem', marginLeft: '0.313rem' }),
};

export const selectStyleBig = {
  control: (styles) => ({
    ...styles,
    width: '10.938rem',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#404eed' : 'white',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#e2e2e2', color: 'black' },
  }),
  menu: (styles) => ({ ...styles, width: '17.188rem', marginLeft: '0.313rem' }),
};

export const selectStyleDashboardHeader = {
  control: (styles) => ({
    ...styles,
    width: '15.063rem',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#0C53B7' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#404eed' : 'white',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#404eed', color: 'white' }
      : { backgroundColor: '#e2e2e2', color: 'black' },
  }),
  menu: (styles) => ({ ...styles, width: '15.313rem', marginLeft: '0.375rem' }),
  singleValue: (styles) => ({ ...styles, color: '#0C53B7' }),
};

export const selectStyleDashboardCharts = {
  control: (styles) => ({
    ...styles,
    width: '14.375rem',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#637381' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#404eed' : 'white',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#404eed', color: 'white' }
      : { backgroundColor: '#e2e2e2', color: 'black' },
  }),
  menu: (styles) => ({ ...styles, width: '13.313rem', marginLeft: '0.375rem' }),
  singleValue: (styles) => ({ ...styles, color: '#637381' }),
};

export const selectStyleCardApplication = {
  control: (styles) => ({
    ...styles,
    width: '14rem',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    fontSize: '1.375rem',
    marginLeft: '0.875rem',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: 'black' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#404eed' : 'white',
    cursor: 'pointer',
    '&:hover': { backgroundColor: '#e2e2e2', color: 'black' },
  }),
  menu: (styles) => ({ ...styles, width: '13.313rem', marginLeft: '1.188rem' }),
};

export const selectGroup = {
  container: (styles) => ({ ...styles, width: '100%' }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    // border: '1px solid  rgba(145, 158, 171, 0.32)',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    padding: '0.25rem 0',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '100%',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const selectGroupDistributionRule = {
  container: (styles) => ({ ...styles, width: '100%', height: '56px' }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    height: '100%',
    // border: '1px solid  rgba(145, 158, 171, 0.32)',
    border: '1px solid #e2e2e2',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    padding: '0.25rem 0',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({
    ...styles,
    width: '6.25rem',
    color: '#212B36',
    fontSize: '16px',
    fontWeight: '400',
    lineHeight: '24px',
  }),
  indicatorsContainer: (styles) => ({ ...styles, paddingRight: '14px' }),
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '100%',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const selectGroupEditUser = {
  container: (styles) => ({ ...styles, width: '100%' }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    border: '1px solid  rgba(145, 158, 171, 0.32)',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    padding: '0.1rem 0',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '100%',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const selectEventAndHost = {
  container: (styles) => ({ ...styles, width: '100%' }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    border: '1px solid  rgba(145, 158, 171, 0.32)',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '100%',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const selectAddEventGuests = {
  container: (styles) => ({
    ...styles,
    width: '100%',
  }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    border: '1px solid  rgba(145, 158, 171, 0.32)',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    paddingTop: '0.15rem',
    paddingBottom: '0.15rem',
    '& .css-0': {
      display: 'flex',
      alignItems: 'center'
    }
  }),
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    display: 'flex',
    height: '2.188rem',
    width: '100%',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    alignItems: 'center',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
  valueContainer: (styles) => ({ ...styles, maxHeight: '3rem', overflowY: 'auto' }),
  multiValue: (styles, { data }) => ({
    ...styles,
    backgroundColor: data.color,
    borderRadius: '25px',
    maxHeight: '48px',
    overflowY: 'auto',
    '&:hover': {
      backgroundColor: '#64748b'
    },
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    color: '#FFF',
    fontWeight: 500,
    fontSize: '12px'
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    color: '#FFF',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  })
};

export const selectGroupNew = {
  container: (styles) => ({ ...styles, width: '52%' }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.5rem',
    padding: '0 0.25rem 0 0',
    marginLeft: '0',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '90%',
    padding: '0.438rem',
    marginRight: '0.5rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const selectGroupCreateGrade = {
  container: (styles) => ({ ...styles, width: '12.5rem' }),
  control: (styles) => ({
    ...styles,
    width: '100%',
    border: '1px solid  rgba(145, 158, 171, 0.32)',
    boxShadow: 'none',
    cursor: 'pointer',
    borderRadius: '0.25rem',
    padding: '0 0 0 0',
    marginLeft: '0',
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '90%',
    padding: '0.438rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    borderRadius: '0.25rem',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '100%', zIndex: '2' }),
  singleValue: (styles) => ({
    ...styles,
    color: '#212B36',
    fontSize: '13px',
    paddingLeft: '0.25rem',
  }),
};

export const selectGroupInstitutions = {
  container: (styles) => ({ ...styles, width: '25.25rem', height: '2.5rem' }),
  control: (styles, state) => ({
    ...styles,
    width: '25.25rem',
    border: '1px solid  rgba(145, 158, 171, 0.32)',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '2.5rem',
    transition: '0.2s',
    borderRadius: '0.25rem',
    padding: '0rem 0',
    '&:hover': { borderColor: '#404eed', color: 'white' },
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '24.25rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '25.25rem', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const modalSelect = {
  container: (styles) => ({ ...styles, width: '17.438rem', height: '2.25rem' }),
  control: (styles, state) => ({
    ...styles,
    width: '100%',
    border: 'none',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '2.25rem',
    transition: '0.2s',
    borderRadius: '0.25rem',
    backgroundColor: '#F6F6F7',
    padding: '0rem 0',
    '&:hover': { borderColor: '#404eed', color: 'white' },
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '17.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '100%',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '14.438rem', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const bloomSelect = {
  container: (styles) => ({ ...styles, width: '100%', height: '2.8rem' }),
  control: (styles, state) => ({
    ...styles,
    width: '100%',
    border: '1px solid #e5e7eb',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '2.8rem',
    transition: 'all 0.1s',
    borderRadius: '0rem',
    backgroundColor: 'none',
    padding: '0rem 0',
    '&:hover': { borderColor: '#404eed', color: 'white' },
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '26.25rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '26.25rem', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const bloomDetailsSelect = {
  container: (styles) => ({ ...styles, width: '25.5rem', height: '2.8rem' }),
  control: (styles, state) => ({
    ...styles,
    width: '25.5rem',
    border: '1px solid #e5e7eb',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '2.8rem',
    transition: 'all 0.1s',
    borderRadius: '0rem',
    backgroundColor: 'none',
    padding: '0rem 0',
    '&:hover': { borderColor: '#404eed', color: 'white' },
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '25.5rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '25.5rem', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};

export const bloomDetailsSelectMini = {
  container: (styles) => ({ ...styles, width: '16.5rem', height: '2.8rem' }),
  control: (styles, state) => ({
    ...styles,
    width: '16.5rem',
    border: '1px solid #e5e7eb',
    boxShadow: 'none',
    cursor: 'pointer',
    height: '2.8rem',
    transition: 'all 0.1s',
    borderRadius: '0rem',
    backgroundColor: 'none',
    padding: '0rem 0',
    '&:hover': { borderColor: '#404eed', color: 'white' },
  }),
  indicatorSeparator: () => null,
  input: (styles) => ({ ...styles, width: '6.25rem', color: '#212B36' }),
  indicatorsContainer: () => null,
  option: (styles, state) => ({
    ...styles,
    height: '2.188rem',
    width: '16.5rem',
    padding: '0.438rem',
    backgroundColor: state.isSelected ? '#e2e2e2' : 'white',
    color: '#212B36',
    cursor: 'pointer',
    '&:hover': state.isSelected
      ? { backgroundColor: '#e2e2e2' }
      : { backgroundColor: '#404eed', color: 'white' },
  }),
  menu: (styles) => ({ ...styles, width: '16.5rem', zIndex: '2' }),
  singleValue: (styles) => ({ ...styles, color: '#212B36' }),
};
