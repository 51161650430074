import React, { memo, useCallback, useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { FeeTermsCard, TypesOfFees } from '@stories';
import { anal, analarrow, arrow, anal_down, analarrow_down } from '@static/image';
import { formatMoney } from '@helper/format-money';
import { ToastMessageContext } from 'context/toast-context';
import { calcPercent } from '@utils/utils';
import { SelectGroup } from '@stories/index';

const types = ['Monthly', 'Term Dates', 'Per Meal', 'Custom'];
const styles = {
  p: { color: '#81878c', fontSize: '0.875rem', marginBottom: '0.313rem' },
  img: { marginBottom: '0.125rem', marginLeft: '0.313rem' },
  span: { fontSize: '0.75rem', marginBottom: '0.063rem' },
};

let isStart = true;

const listFee = ['Food fee'];

const FoodFeeCard = memo((props) => {
  const {
    numberOfMonthsInAcademicyear = 1,
    options = [],
    termsLength = 1,
    foodFee = {},
    foodFeeSave = {},
    setFoodFee = () => { },
    refresh,
    burger,
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = []
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [feeType, setFeeType] = useState(types && types[0]);
  const [feeTypeFee, setFeeTypeFee] = useState(0);
  const [monthlyFee, setMonthlyFee] = useState(0);
  const [approxPerTermFee, setApproxPerTermFee] = useState(0);
  const [estAnnualFee, setEstAnnualFee] = useState(0);
  const [perMealFee, setPerMealFee] = useState(0);
  const [mealsPerMonth, setMealsPerMonth] = useState(1);
  const [numberTerms, setNumberTerms] = useState(termsLength);
  const [termsArray, setTermsArray] = useState([]);
  const [listBankAccountsSelected, setListBankAccountsSelected] = useState({
    'Food fee': null,
  });

  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    foodFee.feeType && setFeeType(foodFee.feeType);
    foodFee.feeTypeFee?.value && setFeeTypeFee(foodFee.feeTypeFee.value);
    foodFee.monthlyFee?.value && setMonthlyFee(foodFee.monthlyFee.value);
    foodFee.perMealFee?.value && setPerMealFee(foodFee.perMealFee.value);
    foodFee.mealsPerMonth?.value && setMealsPerMonth(foodFee.mealsPerMonth.value);
    foodFee.approxPerTermFee?.value &&
      setApproxPerTermFee(foodFee.approxPerTermFee.value);
    foodFee.estAnnualFee?.value && setEstAnnualFee(foodFee.estAnnualFee.value);

    const dataTerm = _.cloneDeep(foodFee.terms || []);
    if (foodFee.feeType === 'Custom') {
      const numberOfTerms = foodFee.numberOfTerms || 0;
      numberOfTerms && setNumberTerms(numberOfTerms);
      setTermsArray(dataTerm);
    } else if (foodFee.feeType === 'Term Dates') {
      setNumberTerms(termsLength);
      setTermsArray(dataTerm);
    } else {
      const newDataTerm = listAcademicYearTerm?.map(academicYear => {
        return {
          fee: 0,
          name: `${academicYear.name} Fee`,
          dueFee: academicYear.startDate
        }
      }) || [];
      setTermsArray(newDataTerm);
      setNumberTerms(termsLength);
    }
    // foodFee.numberOfTerms && setNumberTerms(foodFee.numberOfTerms);
    // foodFee.terms && setTermsArray(foodFee.terms);

    const selectedBankAccount = listBankAccount.find(
      (acc) => acc.value === foodFee.razorpayBankAccountId,
    );
    selectedBankAccount &&
      setListBankAccountsSelected((prev) => {
        return {
          ...prev,
          'Food fee': selectedBankAccount,
        };
      });
  }, [foodFee, refresh, listAcademicYearTerm]);

  useEffect(() => {
    // if (!isStart){
    if (numberTerms) {
      if (numberTerms === 0) {
        setTermsArray([]);
      } else if (numberTerms > termsArray.length) {
        setTermsArray((prev) => {
          const arr = [...prev];
          for (let i = termsArray.length; i < numberTerms; i++) {
            arr.push({
              name: `Term ${i + 1} Fee`,
              fee: 0,
              dueFee: new Date(),
            });
          }
          return arr;
        });
      } else if (numberTerms < termsArray.length) {
        setTermsArray((prev) => {
          return prev.slice(0, numberTerms);
        });
      }
    }
    // } else {
    //   isStart = false;
    // }
  }, [numberTerms]);

  useEffect(() => {
    if (!isStart) {
      const foodFee = {
        feeType,
        feeTypeFee: { value: feeTypeFee },
        monthlyFee: { value: monthlyFee },
        perMealFee: { value: perMealFee },
        mealsPerMonth: { value: mealsPerMonth },
        approxPerTermFee: { value: approxPerTermFee },
        estAnnualFee: { value: estAnnualFee },
        numberOfTerms: numberTerms,
        terms: termsArray,
      };
      setFoodFee(foodFee);
    } else {
      isStart = false;
    }
  }, [
    feeType,
    feeTypeFee,
    monthlyFee,
    approxPerTermFee,
    estAnnualFee,
    perMealFee,
    mealsPerMonth,
    numberTerms,
    termsArray,
  ]);
  const stringValidation = (val) => {
    val = '' + val;
    return val > 999
      ? val < 9999
        ? val.substr(0, 1) + ',' + val.substr(1, val.length)
        : val < 99999
          ? val.substr(0, 2) + ',' + val.substr(2, val.length)
          : val.substr(0, 3) + ',' + val.substr(3, val.length)
      : val;
  };

  const calcCustomFee = (termsArray = []) => {
    let sum = 0;
    termsArray.forEach((term) => {
      sum += +term.fee;
    });
    return sum;
  };

  const calcApproxPerTermFee = (type, monthlyFee, termsArray) => {
    if (type === 'Monthly' || type === 'Per Meal') {
      return (monthlyFee * numberOfMonthsInAcademicyear) / termsLength;
    }
    if (termsArray.length !== termsLength) return NaN;
    return calcCustomFee(termsArray) / termsLength;
  };

  const calcEstManualFee = (type, monthlyFee, approxPerTermFee, termsArray = []) => {
    let sum = 0;
    if (type !== 'Custom' && type !== 'Term Dates') {
      sum += approxPerTermFee * termsLength;
    } else {
      sum += calcCustomFee(termsArray);
    }
    return sum;
  };
  const handleBlur = (value) => {
    if (!value) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Rule',
        message: 'Value must be a number and must be larger than zero',
      });
      setIsShowToastMessage(true);
    }
  };
  const handleChangeTermsArray = useCallback((length, arr = []) => {
    if (length === 0) return [];
    if (length === arr.length) return arr;
    if (length > arr.length) {
      for (let i = arr.length; i < length; i++) {
        arr.push({
          name: `Term ${i + 1} Fee`,
          fee: 0,
          dueFee: options[i]?.startDate || new Date(),
        });
      }
      return arr;
    }
    if (length < arr.length) {
      return arr.slice(0, length);
    }
  }, []);

  const handleSetFoodFee = (property, terms) => {
    const approxPerTermF = calcApproxPerTermFee(feeType, monthlyFee, terms);
    const estFee = calcEstManualFee(feeType, monthlyFee, approxPerTermF, terms);
    const perMealF = calcCustomFee(terms) / numberOfMonthsInAcademicyear / mealsPerMonth;
    setPerMealFee(perMealF);
    setFeeTypeFee(perMealF);
    setTermsArray(terms);
    setEstAnnualFee(estFee);
    setApproxPerTermFee(approxPerTermF);
  };

  const Percent = ({ fee }) => {
    return (
      <>
        {(!!foodFeeSave?.feeTypeFee?.percentageChange ||
          foodFeeSave?.feeTypeFee?.percentageChange == 0) &&
          !isNaN(
            calcPercent(
              foodFeeSave.feeTypeFee.percentageChange,
              foodFeeSave.feeTypeFee.value,
              fee,
            ),
          ) && (
            <img
              src={
                calcPercent(
                  foodFeeSave.feeTypeFee.percentageChange,
                  foodFeeSave.feeTypeFee.value,
                  fee,
                ) < 0
                  ? analarrow_down
                  : analarrow
              }
              style={styles.img}
              alt=""
              className={clsx(
                (!!foodFeeSave?.feeTypeFee?.percentageChange ||
                  foodFeeSave?.feeTypeFee?.percentageChange == 0) &&
                  calcPercent(
                    foodFeeSave.feeTypeFee.percentageChange,
                    foodFeeSave.feeTypeFee.value,
                    fee,
                  ) < 0
                  ? 'rotate-180'
                  : '',
              )}
            />
          )}
        <span
          style={styles.span}
          className={clsx(
            (!!foodFeeSave?.feeTypeFee?.percentageChange ||
              foodFeeSave?.feeTypeFee?.percentageChange == 0) &&
              calcPercent(
                foodFeeSave.feeTypeFee.percentageChange,
                foodFeeSave.feeTypeFee.value,
                fee,
              ) < 0
              ? 'text-[#ff4343]'
              : 'text-[#2ED775]',
          )}
        >
          {(!!foodFeeSave?.feeTypeFee?.percentageChange ||
            foodFeeSave?.feeTypeFee?.percentageChange == 0) &&
            !isNaN(
              calcPercent(
                foodFeeSave.feeTypeFee.percentageChange,
                foodFeeSave.feeTypeFee.value,
                fee,
              ),
            )
            ? '' +
            calcPercent(
              foodFeeSave.feeTypeFee.percentageChange,
              foodFeeSave.feeTypeFee.value,
              fee,
            ) +
            '%'
            : '--'}
        </span>
      </>
    );
  };

  return (
    <React.Fragment>
      <div
        className={clsx(
          'feebook-edit-card py-2',
          isOpen ? 'feebook-edit-card_open' : 'feebook-edit-card_closed',
        )}
      >
        <div className="feebook-edit-card_row w-full" onClick={() => setIsOpen(!isOpen)}>
          <p
            className={clsx(
              'font-normal text-base ml-8',
              burger ? 'min-w-[8rem]' : 'min-w-[9rem]',
            )}
          >
            Food Fee
          </p>
          <TypesOfFees title="Per Meal Fee" sum={`${formatMoney(perMealFee)}`} />
          <TypesOfFees title="Monthly" sum={`${formatMoney(monthlyFee)}`} />
          <TypesOfFees
            title="Approx per Term Fee"
            sum={isNaN(approxPerTermFee) ? `--` : `${formatMoney(approxPerTermFee)}`}
          />
          <TypesOfFees title="Est. Annual Fee" sum={`${formatMoney(estAnnualFee)}`} />
          <div className="flex items-center">
            {(!!foodFeeSave?.estAnnualFee?.percentageChange ||
              foodFeeSave?.estAnnualFee?.percentageChange == 0) && (
                <img
                  src={
                    calcPercent(
                      foodFeeSave.estAnnualFee.percentageChange,
                      foodFeeSave.estAnnualFee.value,
                      estAnnualFee,
                    ) < 0
                      ? anal_down
                      : anal
                  }
                  alt=""
                  className={clsx(
                    calcPercent(
                      foodFeeSave.estAnnualFee.percentageChange,
                      foodFeeSave.estAnnualFee.value,
                      estAnnualFee,
                    ) < 0
                      ? 'rotate-90'
                      : '',
                  )}
                />
              )}
            <span
              className={clsx(
                'ml-1',
                (!!foodFeeSave?.estAnnualFee?.percentageChange ||
                  foodFeeSave?.estAnnualFee?.percentageChange == 0) &&
                  calcPercent(
                    foodFeeSave.estAnnualFee.percentageChange,
                    foodFeeSave.estAnnualFee.value,
                    estAnnualFee,
                  ) < 0
                  ? 'text-[#ff4343]'
                  : '',
              )}
            >
              {!!foodFeeSave?.estAnnualFee?.percentageChange ||
                foodFeeSave?.estAnnualFee?.percentageChange == 0
                ? '' +
                calcPercent(
                  foodFeeSave.estAnnualFee.percentageChange,
                  foodFeeSave.estAnnualFee.value,
                  estAnnualFee,
                ) +
                '%'
                : '--'}
            </span>
          </div>
          <button
            className={clsx('feebook-edit-card_row_button', burger ? 'mr-4' : 'mr-8')}
          >
            <img src={arrow} alt="" />
          </button>
        </div>
        {isOpen && (
          <React.Fragment>
            <div className="feebook-edit-card_content w-[95%]">
              <h3 className=" font-normal text-sm">Fee Type</h3>
              <div className="feebook-edit-card_content_buttons flex justify-stretch">
                {types.map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setFeeType(type);
                      if (type === 'Monthly') {
                        setFeeTypeFee(monthlyFee / mealsPerMonth);
                        setPerMealFee(monthlyFee / mealsPerMonth);
                        const approxPerTermF = calcApproxPerTermFee(
                          type,
                          monthlyFee,
                          termsArray,
                        );
                        const estFee = calcEstManualFee(
                          type,
                          monthlyFee,
                          approxPerTermF,
                          termsArray,
                        );
                        setEstAnnualFee(estFee);
                        setApproxPerTermFee(approxPerTermF);
                      } else if (type === 'Per Meal') {
                        setFeeTypeFee(monthlyFee / mealsPerMonth);
                        setPerMealFee(monthlyFee / mealsPerMonth);
                        const approxPerTermF = calcApproxPerTermFee(
                          type,
                          monthlyFee,
                          termsArray,
                        );
                        setApproxPerTermFee(approxPerTermF);
                        const estFee = calcEstManualFee(
                          type,
                          monthlyFee,
                          approxPerTermF,
                          termsArray,
                        );
                        setEstAnnualFee(estFee);
                      } else if (type === 'Term Dates') {
                        const terms = handleChangeTermsArray(termsLength, termsArray);
                        const approxPerTermF = calcApproxPerTermFee(
                          type,
                          monthlyFee,
                          terms,
                        );
                        const estFee = calcEstManualFee(
                          type,
                          monthlyFee,
                          approxPerTermF,
                          terms,
                        );
                        setEstAnnualFee(estFee);
                        setApproxPerTermFee(approxPerTermF);
                        setFeeTypeFee(calcCustomFee(terms));
                        setTermsArray(terms);
                        setNumberTerms(termsLength);
                      } else {
                        setFeeTypeFee(calcCustomFee(termsArray));
                        const approxPerTermF = calcApproxPerTermFee(
                          type,
                          monthlyFee,
                          termsArray,
                        );
                        const estFee = calcEstManualFee(
                          type,
                          monthlyFee,
                          approxPerTermF,
                          termsArray,
                        );
                        setEstAnnualFee(estFee);
                        setApproxPerTermFee(approxPerTermF);
                      }
                    }}
                    className={clsx(
                      'tab-button',
                      type === feeType && 'tab-button_active',
                    )}
                  >
                    {type}
                  </button>
                ))}
              </div>
              <h3 className=" font-normal text-sm">Fee Distribution</h3>
              <div className="flex ml-1.5 mb-3">
                <div className="flex flex-col w-40">
                  <p style={styles.p}>
                    {feeType === 'Custom' || feeType === 'Term Dates'
                      ? 'Number of Terms'
                      : `${feeType} Fee`}
                  </p>
                  <p className="flex items-center">
                    {feeType === 'Custom' || feeType === 'Term Dates' ? (
                      <input
                        disabled={feeType === 'Term Dates'}
                        value={numberTerms || undefined}
                        type="number"
                        className="w-14 border-none outline-none"
                        onChange={(e) => {
                          setNumberTerms(+e.target.value);
                          if (e.target.value) {
                            const terms = handleChangeTermsArray(
                              +e.target.value,
                              termsArray,
                            );
                            const approxPerTermF = calcApproxPerTermFee(
                              feeType,
                              monthlyFee,
                              terms,
                            );
                            const estFee = calcEstManualFee(
                              feeType,
                              monthlyFee,
                              approxPerTermF,
                              terms,
                            );
                            setFeeTypeFee(calcCustomFee(terms) / mealsPerMonth);
                            setTermsArray(terms);
                            setApproxPerTermFee(approxPerTermF);
                            setEstAnnualFee(estFee);
                          }
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        ₹{' '}
                        {feeType === 'Monthly' && (
                          <>
                            <input
                              value={monthlyFee || undefined}
                              type="number"
                              className="ml-1 border-none outline-none"
                              style={{ maxWidth: '3.5rem' }}
                              onChange={(e) => {
                                setMonthlyFee(+e.target.value);
                                setFeeTypeFee(+e.target.value / mealsPerMonth);
                                setPerMealFee(+e.target.value / mealsPerMonth);

                                const approxPerTermF = calcApproxPerTermFee(
                                  feeType,
                                  +e.target.value,
                                  termsArray,
                                );
                                const estFee = calcEstManualFee(
                                  feeType,
                                  +e.target.value,
                                  approxPerTermF,
                                  termsArray,
                                );
                                setEstAnnualFee(estFee);
                                setApproxPerTermFee(approxPerTermF);
                              }}
                            />{' '}
                            <Percent fee={monthlyFee} />
                          </>
                        )}
                        {feeType === 'Per Meal' && (
                          <>
                            <input
                              value={perMealFee || undefined}
                              type="number"
                              className="ml-1 border-none outline-none"
                              style={{ maxWidth: '3.5rem' }}
                              onChange={(e) => {
                                setPerMealFee(+e.target.value);
                                setFeeTypeFee(+e.target.value);
                                setMonthlyFee(+e.target.value * mealsPerMonth);
                                const approxPerTermF = calcApproxPerTermFee(
                                  feeType,
                                  +e.target.value * mealsPerMonth,
                                  termsArray,
                                );
                                const estFee = calcEstManualFee(
                                  feeType,
                                  +e.target.value * mealsPerMonth,
                                  approxPerTermF,
                                  termsArray,
                                );
                                setEstAnnualFee(estFee);
                                setApproxPerTermFee(approxPerTermF);
                              }}
                            />{' '}
                            <Percent fee={perMealFee} />
                          </>
                        )}
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className="flex flex-col w-40 mr-6">
                  <div className="flex items-center">
                    <p className="flex items-center" style={styles.p}>
                      Meals per month
                    </p>
                  </div>
                  <p
                    className={clsx(
                      'flex items-center card-row_active',
                      mealsPerMonth ? '' : 'text-red-600',
                    )}
                  >
                    ₹{' '}
                    <input
                      onBlur={(e) => {
                        handleBlur(+e.target.value);
                      }}
                      value={mealsPerMonth || undefined}
                      type="number"
                      min={1}
                      step={1}
                      className={clsx(
                        'ml-1 outline-none',
                        mealsPerMonth ? 'border-none' : 'border-b-2 border-rose-500',
                      )}
                      style={{ maxWidth: '3.5rem' }}
                      onChange={(e) => {
                        setMealsPerMonth(+e.target.value);
                        if (feeType === 'Monthly') {
                          setFeeTypeFee(monthlyFee / e.target.value);
                          setPerMealFee(monthlyFee / e.target.value);
                          const approxPerTermF = calcApproxPerTermFee(
                            feeType,
                            monthlyFee,
                            termsArray,
                          );
                          const estFee = calcEstManualFee(
                            feeType,
                            monthlyFee,
                            approxPerTermF,
                            termsArray,
                          );
                          setEstAnnualFee(estFee);
                          setApproxPerTermFee(approxPerTermF);
                        } else if (feeType === 'Per Meal') {
                          const monthFee = +e.target.value * perMealFee;
                          const approxPerTermF = calcApproxPerTermFee(
                            feeType,
                            monthlyFee,
                            termsArray,
                          );
                          const estFee = calcEstManualFee(
                            feeType,
                            monthlyFee,
                            approxPerTermF,
                            termsArray,
                          );
                          setEstAnnualFee(estFee);
                          setApproxPerTermFee(approxPerTermF);
                          setMonthlyFee(monthFee);
                        } else {
                          const monthFee = +e.target.value * perMealFee;
                          setMonthlyFee(monthFee);
                        }
                      }}
                    />{' '}
                    {(!!foodFeeSave?.mealsPerMonth?.percentageChange ||
                      foodFeeSave?.mealsPerMonth?.percentageChange == 0) &&
                      !isNaN(
                        calcPercent(
                          foodFeeSave.mealsPerMonth.percentageChange,
                          foodFeeSave.mealsPerMonth.value,
                          mealsPerMonth,
                        ),
                      ) && (
                        <img
                          src={
                            calcPercent(
                              foodFeeSave.mealsPerMonth.percentageChange,
                              foodFeeSave.mealsPerMonth.value,
                              mealsPerMonth,
                            ) < 0
                              ? analarrow_down
                              : analarrow
                          }
                          style={styles.img}
                          alt=""
                          className={clsx(
                            (!!foodFeeSave?.mealsPerMonth?.percentageChange ||
                              foodFeeSave?.mealsPerMonth?.percentageChange == 0) &&
                              calcPercent(
                                foodFeeSave.mealsPerMonth.percentageChange,
                                foodFeeSave.mealsPerMonth.value,
                                mealsPerMonth,
                              ) < 0
                              ? 'rotate-180'
                              : '',
                          )}
                        />
                      )}
                    <span
                      style={styles.span}
                      className={clsx(
                        (!!foodFeeSave?.mealsPerMonth?.percentageChange ||
                          foodFeeSave?.mealsPerMonth?.percentageChange == 0) &&
                          calcPercent(
                            foodFeeSave.mealsPerMonth.percentageChange,
                            foodFeeSave.mealsPerMonth.value,
                            mealsPerMonth,
                          ) < 0
                          ? 'text-[#ff4343]'
                          : 'text-[#2ED775]',
                      )}
                    >
                      {(!!foodFeeSave?.mealsPerMonth?.percentageChange ||
                        foodFeeSave?.mealsPerMonth?.percentageChange == 0) &&
                        !isNaN(
                          calcPercent(
                            foodFeeSave.mealsPerMonth.percentageChange,
                            foodFeeSave.mealsPerMonth.value,
                            mealsPerMonth,
                          ),
                        )
                        ? '' +
                        calcPercent(
                          foodFeeSave.mealsPerMonth.percentageChange,
                          foodFeeSave.mealsPerMonth.value,
                          mealsPerMonth,
                        ) +
                        '%'
                        : '--'}
                    </span>
                  </p>
                </div>
              </div>
              {(feeType === 'Custom' || feeType === 'Term Dates') &&
                termsArray.map((item, key) => (
                  <FeeTermsCard
                    isSetTime={feeType === 'Term Dates' ? false : true}
                    handleSetFee={handleSetFoodFee}
                    setTermsArray={setTermsArray}
                    termsArray={termsArray}
                    item={item}
                    key={key}
                    index={key}
                    maxValue={termsArray.length}
                    oldFee={
                      key >= foodFeeSave.terms?.length ? {} : foodFeeSave.terms?.[key]
                    }
                  />
                ))}
            </div>
            {razorpayBankAccount.type === 'Different' && (
              <div className="px-8 flex flex-col gap-2.5 my-5">
                <p className="text-[#81878C] text-sm font-semibold">Bank Account</p>
                {listFee.map((fee) => (
                  <div key={fee} className="flex gap-5 items-center justify-start">
                    <p className="min-w-[120px] text-[#919EAB] font-normal text-sm">
                      {fee}
                    </p>
                    <SelectGroup
                      value={listBankAccountsSelected[fee]}
                      options={listBankAccount}
                      onChange={(e) => {
                        setListBankAccountsSelected(prev => ({ ...prev, [fee]: e }));
                        setFoodFee(prev => {
                          return {
                            ...prev,
                            razorpayBankAccountId: e.value
                          }
                        })
                      }}
                      classNameGroup="w-[20rem] mx-3"
                    />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});
FoodFeeCard.displayName = 'FoodFeeCard';

export default FoodFeeCard;
