import { callApi } from '@helper/call-api';

class AuthApi {
  // constructor() {
  //   this.baseUrl = `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user`;
  // }

  getListUserOfCampus = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/get-users-of-campus`,
      params,
      signal,
    });
  };

  getListUserOfCompany = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/get-users-of-company`,
      params,
    });
  };

  userCategories = async (signal) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/user-categories`,
      signal,
    });
  };

  importUser = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/import`,
      data,
      params,
    });
  };

  createUser = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/create`,
      data,
      params,
    });
  };

  getRoleOfCompanyOrCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/get-role-of-company-or-campus`,
      params,
    });
  };

  getRole = async (accessToken) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/get-role`,
      accessToken,
    });
  };

  getListRoleDraft = async (params = undefined) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/draft/list`,
      params,
    });
  };

  createRoleDraft = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/draft`,
      params,
      data,
    });
  };

  updateRole = async (data) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role`,
      data,
    });
  };

  getPageWithRolePermission = async (params = undefined) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/get-page-with-role-permission`,
      params,
    });
  };

  getDraftRolePagePermission = async (params, draftId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/draft/${draftId}`,
      params,
    });
  };

  getListCampusUser = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus/user`,
      params,
    });
  };

  getListCompanyUser = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/get-by-user`,
      params,
    });
  };

  getListCompany = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/list`,
      params,
    });
  };

  createCustomRole = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role/add-custom-role`,
      params,
      data,
    });
  };

  createCampus = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus`,
      data,
    });
  };

  getListCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus/company`,
      params,
    });
  };

  updateCampus = async (params, data) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus`,
      params,
      data,
    });
  };

  getCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/campus`,
      params,
    });
  };

  checkHomeMenu = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/check-home-menu`,
    });
  };

  updateParent = async (data, parentUserId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/user/parent/${parentUserId}`,
      data,
    });
  };

  createParent = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/parent`,
      data,
    });
  };

  getAllPage = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/role/get-all-page`,
      params,
    });
  };

  findUser = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/find-one`,
      params,
    });
  };

  findUsers = async (params, signal) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/find-many`,
      params,
      signal,
    });
  };

  getUsersOfCompanyOrCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/get-users-of-company-or-campus`,
      params,
    });
  };

  deleteDeviceToken = async (data, accessToken) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/delete-device-token`,
      data,
      accessToken,
    });
  };

  syncDeviceToken = async (data, accessToken) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/sync-device-token`,
      data,
      accessToken,
    });
  };

  syncPhoto = async (data, accessToken) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/auth/sync-photo`,
      data,
      accessToken,
    });
  };

  getMe = async (accessToken) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/me`,
      accessToken,
    });
  };

  resetPassword = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/reset-password`,
      data,
    });
  };

  signInWithPhoneNumber = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/auth/sign-in-with-phone-number`,
      data,
    });
  };

  sendVerifyCode = async (email, tenantId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/send-verify-code?email=${email}&tenantId=${tenantId}`,
    });
  };

  checkVerifyCode = async (email, tenantId, codeVerify) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/auth/check-verify-code?email=${email}&tenantId=${tenantId}&codeVerify=${codeVerify}`,
    });
  };

  getListBilling = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/billing/list`,
    });
  };

  getListPlan = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/plan/list`,
    });
  };

  getListIntegration = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/integration/list`,
    });
  };

  checkDomain = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company/check-domain`,
      params,
    });
  };

  createCompany = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company`,
      data,
    });
  };

  updateCompany = async (data) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company`,
      data,
    });
  };

  getRoleOfUser = async (params, userId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/${userId}`,
      params,
    });
  };

  updateUserById = async (data, userId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/${userId}`,
      data,
    });
  };

  updateActiveRole = async (params, data) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/role/update-active`,
      params,
      data,
    });
  };

  getUserOfCompanyOrCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/get-users-of-company-or-campus`,
      params,
    });
  };

  getUserById = async (id) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/user/${id}`,
    });
  };
}

const authApi = new AuthApi();
export default authApi;
