import clsx from 'clsx';

import './cashider-sidebar.scss';

const tabs = ['Unpaid', 'Scholarships', 'Paid'];

function CashierSideBar(props) {
  const { title, tabActive, setTabActive = () => {} } = props;
  // const [tabActive, setTabActive] = useState(tabs[0]);
  return (
    <div className="pt-16 px-8 w-96 min-w-[24rem] cashider-sidebar relative">
      <div>
        <h3 className="text-2xl font-bold mb-4">{title}</h3>
        <div className="flex items-center justify-start">
          {tabs.map((tab) => {
            return (
              <button
                className={clsx(
                  'mr-4 pb-1 font-medium',
                  tabActive === tab
                    ? 'text-[#212B36] border-b-2 border-[#1F5199]'
                    : 'border-b-2 border-transparent text-[#637381]',
                )}
                key={tab}
                onClick={() => {
                  setTabActive(tab);
                }}
              >
                {tab}
              </button>
            );
          })}
        </div>
      </div>
      <div>{props.children}</div>
    </div>
  );
}

export default CashierSideBar;
