import clsx from 'clsx';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CompanyTabs } from '../../stories';
import './company-settings.scss';
import {
  Admins,
  Campuses,
  Data,
  General,
  Integrations,
  PlanBilling,
  ReportIssues,
} from './components';
import google_drive from './components/integrations/google-drive.svg';
import one_drive from './components/integrations/onedrive.svg';
import google_meet from './components/integrations/google-meet.svg';
import whatsapp from './components/integrations/whatsapp.svg';
import useStorage from 'store/storage';

const dataTypes = [
  'General',
  'Campuses',
  'Users',
  'Plan & Billing',
  'Reports / Issues',
  'Data',
  'Integrations',
];
let dataIntegrations = new Map();
let planBill = [];

const componentRender = [
  {
    type: 'General',
    component: (key, state, burger) => (
      <General key={key} state={state} burger={burger} />
    ),
  },
  {
    type: 'Campuses',
    component: (key, state, burger, setNavbar, setHeader) => (
      <Campuses
        key={key}
        state={state}
        burger={burger}
        setNavbar={setNavbar}
        setHeader={setHeader}
      />
    ),
  },
  {
    type: 'Users',
    component: (key, state, burger) => <Admins key={key} state={state} burger={burger} />,
  },
  {
    type: 'Plan & Billing',
    component: (key, state, burger) => (
      <PlanBilling key={key} state={state} planBill={planBill} burger={burger} />
    ),
  },
  {
    type: 'Reports / Issues',
    component: (key, state) => <ReportIssues key={key} state={state} />,
  },
  { type: 'Data', component: (key, state) => <Data key={key} state={state} /> },
  {
    type: 'Integrations',
    component: (key, state) => (
      <Integrations key={key} state={state} integrations={dataIntegrations} />
    ),
  },
];

export default function CompanySettings(props) {
  const { burger, info, setNavbar, setHeader } = props;
  addDefaultIntegrationsData();
  const company = useStorage((state) => {
    return state.company
  });
  const [companyId, setCompanyId] = useState(company.id);
  planBill = {
    date: company.dateUpdated,
    companyPlan: [...company.companyPlan],
    companyBilling: [...company.companyBilling],
  };
  company.companyIntegrations.map((data) => {
    dataIntegrations.get(data.integration.id).active = 'activate';
  });
  const [typeState, setTypeState] = useState(dataTypes && dataTypes[0]);
  const history = useHistory();

  function addDefaultIntegrationsData() {
    dataIntegrations.set('google-drive', {
      title: 'Google Drive',
      img: google_drive,
      body: 'Google Upload integration allows students to directly upload files from their Google Drive folders into their Portfolios and Projects.',
      active: 'deactivate',
    });
    dataIntegrations.set('one-drive', {
      title: 'One Drive',
      img: one_drive,
      body: 'One Drive Upload integration allows students to directly upload files from their One Drive folders into their Portfolios and Projects.',
      active: 'deactivate',
    });
    dataIntegrations.set('google-meet', {
      title: 'Google Meet',
      img: google_meet,
      body: 'One Drive Upload integration allows students to directly upload files from their One Drive folders into their Portfolios and Projects.',
      active: 'deactivate',
    });
    dataIntegrations.set('whatsapp', {
      title: 'WhatsApp',
      img: whatsapp,
      body: 'One Drive Upload integration allows students to directly upload files from their One Drive folders into their Portfolios and Projects.',
      active: 'deactivate',
    });
  }

  return (
    <div className={clsx('company-settings px-20', burger ? '1400px:pl-80' : '1400px:pl-40')}>
      <h1 className="namepage">Company Settings</h1>
      <div className="flex items-center w-full mb-12 mt-4">
        <CompanyTabs
          types={dataTypes}
          setTypeState={setTypeState}
          typeState={typeState}
        />
      </div>
      {componentRender.map(
        (item, key) =>
          item.type === typeState &&
          item.component(key, company, burger, setNavbar, setHeader),
      )}
    </div>
  );
}
