import React, { useCallback, useState, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import { NavLink, useHistory } from 'react-router-dom';
import { inbox } from '../image';
// import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import InputVerification from '../../../../components/inputverification/input-verification.jsx';
import { deleteAuth } from '../../../../store/action-creators/authActions';
import { saveConfirmCodeSms } from '../../../../store/action-creators/confirmCodeSMSAction';
import { Logo } from '../../index.jsx';
import firebase from '../UserLogin/firebase';
import { useAuth } from '../UserLogin/firebaseSignin';
import '../UserLogin/index.scss';
import { ToastMessageContext } from '../../../../context/toast-context';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function OTPLogin() {
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [codeVerify, setCodeVerify] = useState('');
  const history = useHistory();
  const { removeAuth, updateConfirmCodeSms } = useStorage();
  // const dispatch = useDispatch();
  const location = useLocation();
  const confirm = useStorage((state) => state.confirmCodeSms);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const phoneNumber3digits = urlParams.get('phoneNumber');
  const [phoneNumber, setPhoneNumber] = useState(location?.state?.phoneNumber || '');
  const [rememberMe, setRememberMe] = useState(location?.state?.remember || false);
  const { handleUser, checkRole } = useAuth();

  const onChangeCodeVerify = useCallback((e) => {
    setCodeVerify(e);
  }, []);

  const onClickConfirmCodeVerify = (e) => {
    e.preventDefault();
    if (codeVerify === '' || codeVerify === null) {
      setToastMessage({
        status: 'warning',
        title: 'Invalid Data',
        message: 'Please enter a valid code verify!',
      });
      setIsShowToastMessage(true);
    }
    if (confirm === '' || confirm === null) {
      console.log('Confirm is null');
      return;
    }
    confirm(codeVerify)
      .then((result) => {
        const user = result.user;

        const data = JSON.stringify({
          email: user.email,
          tenantId: 'giiki-test-1-a9hhl-2uj1p', // user.tenantId = null
          // tenantId: "company-1-mu4w9", // user.tenantId = null
        });

        // const config = {
        //   method: 'post',
        //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/auth/sign-in-with-phone-number`, // tenandId = giiki-test-1-a9hhl-2uj1p
        //   data: data,
        // };

        // callApi(config)
        authApi.signInWithPhoneNumber(data)
          .then(async function (response) {
            if (response.data.success) {
              const accessToken = response.data.data.stsTokenManager.accessToken;
              const checkedRole = await checkRole(accessToken);
              if (checkedRole.isValid) {
                const user = {
                  ...response.data.data,
                  roles: checkedRole.roles,
                };
                handleUser(user, rememberMe);
                history.go('/dashboard');
              } else {
                handleUser(null, rememberMe);
                // dispatch(deleteAuth());
                removeAuth();
                setToastMessage({
                  status: 'error',
                  title: 'Login Failed',
                  message: 'You do not have permission',
                });
                setIsShowToastMessage(true);
                history.push('/login/type');
              }
            } else {
              setToastMessage({
                status: 'error',
                title: 'Login Failed',
                message: response.data.message,
              });
              setIsShowToastMessage(true);
              history.push('/login/type');
            }
          })
          .catch(function (error) {
            console.log(error);
            setToastMessage({
              status: 'error',
              title: 'Login Failed',
              message: error.response?.data?.message || error,
            });
            setIsShowToastMessage(true);
            history.push('/login/type');
          });
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Login Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Verify code failed!', error);
      });
  };

  const onClickResendOTP = (e) => {
    e.preventDefault();

    const verify = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
      size: 'invisible',
    });

    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, verify)
      .then((confirmationResult) => {
        updateConfirmCodeSms({ confirmationResult })
        // dispatch(saveConfirmCodeSms({ confirmationResult }));
      })
      .catch((error) => {
        console.log('error', error);
        setToastMessage({
          status: 'error',
          title: 'Send OTP Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <div className="login account-type" style={{ background: '#F9F3F3' }}>
      <NavLink className="mb-10" to="/">
        <Logo fill={'#CDBABA'} />
      </NavLink>{' '}
      <Fade clear duration={300}>
        <div className="box">
          <h1 className="text-3xl font-black">Please check your phone!</h1>
          <p
            className="text-second-gray font-normal text-base mt-1"
            style={{ width: '28rem' }}
          >
            {`We've emailed a 6-digit confirmation code to xxxxxxx${phoneNumber3digits}, please
            enter the code in below box to verify your phone.`}
          </p>
          <div className="flex mt-5">
            <div style={{ width: '25.75rem' }}>
              <InputVerification
                value={codeVerify}
                onChange={onChangeCodeVerify}
                className="mt-12"
              />
              <button
                className="box-submit"
                onClick={(e) => {
                  onClickConfirmCodeVerify(e);
                }}
                style={{ width: '25.75rem', marginTop: '1.5rem' }}
                type="submit"
              >
                Verify
              </button>
              <div
                className="text-sm text-main-black font-normal flex justify-center"
                style={{ width: '25.75rem', marginTop: '1rem' }}
              >
                Don't have a code?
                <div id="recaptcha-container"></div>
                <button
                  className="mb-10 ml-3"
                  style={{ color: '#007B55' }}
                  // to="/login/otp"
                  onClick={(e) => {
                    onClickResendOTP(e);
                  }}
                >
                  Resend Code
                </button>
              </div>
            </div>
            <img src={inbox} alt="looking" className="dodles" />
          </div>
        </div>
      </Fade>
    </div>
  );
}
