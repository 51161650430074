import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { plus } from '@static/image';
import { Button } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import AcademicTable from '../academic-table/academic-table';
import './academic-modal.scss';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

export default function AcademicModal(props) {
  const { setOnEdit, cancelHandle, burger, selectAcademic } = props;
  const [listTerms, setListTerms] = useState([]);
  const [academicYearName, setAcademicYearName] = useState('');
  const campus = useStorage((state) => state.currentCampus);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    setListTerms(selectAcademic.academicYearTerm);
  }, [selectAcademic]);

  useEffect(() => {
    if (!listTerms || listTerms.length === 0) return;
    const previousYear = new Date(listTerms[0].startDate).getFullYear();
    const nextYear = previousYear + 1;
    setAcademicYearName(`${previousYear || ''}-${nextYear || ''}`);
  }, [listTerms]);

  const addNewTermHandle = useCallback(() => {
    setListTerms((prev) => {
      const length = prev.length
      const termName = `Term ${length + 1}`;
      const lastEndDate = length !== 0 && new Date(prev[length - 1].endDate)
      const newStartDate = length !== 0
        ? lastEndDate.setDate(lastEndDate.getDate() + 1)
        : new Date();
      const newEndDate = length !== 0
        ? new Date(newStartDate).setMonth(new Date(newStartDate).getMonth() + 3)
        : new Date().setMonth(new Date().getMonth() + 3)
      const data = [
        ...prev,
        {
          name: termName,
          startDate: new Date(newStartDate),
          endDate: new Date(newEndDate),
          lockTerm: false,
        },
      ];
      return data;
    });
  }, []);
  const termNameChangeHandle = (e, idx) => {
    e.preventDefault();
    setListTerms((prev) => {
      const data = [...prev];
      data[idx].name = e.target.value;
      return data;
    });
  };

  const lockTermChangeHandle = (e, idx) => {
    e.preventDefault();
    setListTerms((prev) => {
      // const data = [...prev];
      // data[idx].lockTerm = !data[idx].lockTerm;
      // return data;

      prev[idx].lockTerm = !prev[idx].lockTerm
      return [...prev]
    });
  };

  const changeDateHandle = (time, value, idx) => {
    if (time === 'start') {
      setListTerms((prev) => {
        const data = [...prev];
        data[idx].startDate = value;
        return data;
      });
    } else if (time === 'end') {
      setListTerms((prev) => {
        const data = [...prev];
        data[idx].endDate = value;
        return data;
      });
    }
  };

  const onSaveListTerms = () => {
    const campusId = campus?.isCentral ? undefined : campus?.id
    const data = listTerms.map((term) => {
      return {
        academicYearId: selectAcademic.id,
        ...(({ dateCreated, dateUpdated, ...o }) => o)(term),
      };
    });
    for (const term of data) {
      if (term.name.trim() === '') {
        setToastMessage({
          status: 'warning',
          title: 'Missing Field',
          message: 'Please enter term name',
        });
        setIsShowToastMessage(true);
        return;
      }
      if (new Date(term.startDate) >= new Date(term.endDate)) {
        setToastMessage({
          status: 'warning',
          title: 'Data Validation',
          message: 'Start date must be less than end date',
        });
        setIsShowToastMessage(true);
        return;
      }
    }

    let api = centralAdminApi.updateAcademicYear({ campusId }, {
      academicYearTerms: data,
      name: academicYearName,
      programId: selectAcademic.programId,
    })
    if (selectAcademic?.isNew) {
      api = centralAdminApi.createAcademicYear({ campusId }, {
        programId: selectAcademic.programId,
        academicYearTerms: data,
        name: academicYearName,
      })
    }
    api.then((response) => {
      if (
        checkErrorSingleApi(
          response,
          setToastMessage,
          setIsShowToastMessage,
          'Create/Update Academic Terms',
        )
      ) {
        setOnEdit(true);
      }
    })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Create/Update Academic Terms Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Academic Modal Error', error);
      });
  };

  const styles = {
    termButtonFull: { width: '13rem' },
    termButtonMin: { position: 'absolute', right: '0px', width: '10rem' },
    saveButton: { marginRight: '1.2rem', paddingLeft: '1.2rem', paddingRight: '1.2rem' },
    cancelButton: { color: 'red', marginLeft: 5 },
  };

  return (
    <div
      className='academ-terms-modal'
    >
      <Fade clear duration={300}>
        <div className="flex justify-between">
          <input
            disabled
            onChange={(e) => {
              e.preventDefault();
              setAcademicYearName(e.target.value);
            }}
            value={academicYearName}
            className="academ-terms-modal_name pl-0 border-none outline-none pr-32"
          />
          <Button
            text="Add New Term"
            customStyle={styles.termButtonFull}
            onClick={addNewTermHandle}
            icon={plus}
          />
        </div>
        <AcademicTable
          changeDateHandle={changeDateHandle}
          termNameChangeHandle={termNameChangeHandle}
          lockTermChangeHandle={lockTermChangeHandle}
          listTerms={listTerms}
          setListTerms={setListTerms}
          burger={burger}
        />
        <div className="academ-terms-modal-buttons">
          <Button
            buttonSize="medium"
            customStyle={styles.saveButton}
            onClick={onSaveListTerms}
            text="Save"
          />{' '}
          or
          <Button
            buttonSize="medium"
            buttonStyle="text"
            onClick={() => {
              cancelHandle();
            }}
            customStyle={styles.cancelButton}
            text="Cancel"
          />
        </div>
      </Fade>
    </div>
  );
}

AcademicModal.propTypes = {
  cancelHandle: PropTypes.func,
  setOnEdit: PropTypes.func,
  selectAcademic: PropTypes.object,
  burger: PropTypes.bool,
};
