export const AUTH = {
  saveAuth: 'saveAuth',
  deleteAuth: 'deleteAuth',
};

export const LOGO = {
  saveLogo: 'saveLogo',
};

export const CONFIRM_CODE_SMS = {
  saveConfirmCodeSms: 'saveConfirmCodeSms',
};

export const COMPANY = {
  edit: 'edit',
  saveInfo: 'saveInfo',
  listCampus: 'listCampus',
  current: 'current', // current company or campus
  saveRoutes: 'saveRoutes', // save routes for small navbar
  saveCentral: 'saveCentral', // save central info
  saveAcademicYear: 'saveAcademicYear', // save academic year for campus (feebook)
};

export const NAVBAR = {
  setRoutes: 'setRoutes',
  setCurrentCampusOrCompany: 'setCurrentCampusOrCompany',
  setCampusRecent: 'setCampusRecent',
  setTitle: 'setTitle',
  setIsGiikiAdmin: 'setIsGiikiAdmin',
  setCompany: 'setCompany',
  setIsCentral: 'setIsCentral',
  setIsFinance: 'setIsFinance',
  setCentralInfo: 'setCentralInfo',
  setCurrentAcademicYearName: 'setCurrentAcademicYearName',
  setPreviousAcademicYearName: 'setPreviousAcademicYearName',
  setListAcademicYearName: 'setListAcademicYearName',
  setAcademicYearName: 'setAcademicYearName'
}
