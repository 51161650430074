import { memo, useEffect, useState } from 'react';
import _ from 'lodash';

import { selectStylesWithNoTitle } from 'entities/select-style';
import { Checkbox, Input, SelectGroup } from '@stories/index';
import { calcEstManualFee } from '@helper/finance';
import { optionFee } from '@utils/constant';

const inputStyle = {
  width: '100%',
  height: '100%',
  fontSize: '0.875rem',
  borderRadius: '0.25rem',
  border: 'none',
  outline: 'none',
  paddingLeft: '4px',
};

const optionsBank = [
  { label: 'Same', value: 'Same' },
  { label: 'Different', value: 'Different' },
];

const AdmissionCriteria = memo((props) => {
  const {
    followingFees,
    admissionFee,
    applicationFee,
    razorpayBankAccount,
    setFollowingFees = () => {},
    setAdmissionFee = () => {},
    setApplicationFee = () => {},
    setTutionFees = () => {},
    setRazorpayBankAccount = () => {},
    options = [],
    listBankAccount = [],
  } = props;

  const [listConfirmAdmission, setListConfirmAdmission] = useState();
  const [admFeeType, setAdmFeeType] = useState();
  const [appFeeType, setAppFeeType] = useState();
  const [razorpayBankAccountType, setRazorpayBankAccountType] = useState();

  const [followingFeesAC, setFollowingFeesAC] = useState({});
  const [admissionFeeAC, setAdmissionFeeAC] = useState({});
  const [applicationFeeAC, setApplicationFeeAC] = useState({});
  const [razorpayBankAccountAC, setRazorpayBankAccountAC] = useState({});

  useEffect(() => {
    setRazorpayBankAccountType((prev) => {
      const type = razorpayBankAccount.type || '';
      const result = optionsBank.filter((e) => e.value === type);
      return result.length > 0 ? result[0] : null;
    });
    setRazorpayBankAccountAC((prev) => {
      const razorpayBankAccountId = razorpayBankAccount.razorpayBankAccountId || '';
      const result = listBankAccount.filter((e) => e.value === razorpayBankAccountId);
      return result.length > 0 ? result[0] : null;
    })
  }, [razorpayBankAccount]);

  useEffect(() => {
    setFollowingFeesAC(followingFees);
    setListConfirmAdmission(
      options.filter((e) => {
        const listConfirm = followingFees?.listConfirmAdmission || [];
        return listConfirm.some((i) => i === e.value);
      }),
    );
  }, [followingFees]);

  useEffect(() => {
    setAdmissionFeeAC(admissionFee);
    setAdmFeeType((prev) => {
      const type = admissionFee.type || '';
      const result = optionFee.filter((e) => e.value === type);
      return result.length > 0 ? result[0] : null;
    });
  }, [admissionFee]);

  useEffect(() => {
    setApplicationFeeAC(applicationFee);
    setAppFeeType((prev) => {
      const type = applicationFee.type || '';
      const result = optionFee.filter((e) => e.value === type);
      return result.length > 0 ? result[0] : null;
    });
  }, [applicationFee]);

  useEffect(() => {
    setListConfirmAdmission(
      options.filter((e) => {
        const listConfirm = followingFees
          ? followingFees?.listConfirmAdmission || []
          : [];
        return listConfirm.some((i) => i === e.value);
      }),
    );
  }, [options]);

  const handleSetTutionFee = (key, isCalcEstFee) => {
    setTutionFees((prev) => {
      if (!prev || prev.length === 0) return [];
      const listTTFee = _.cloneDeep(prev);
      listTTFee.forEach((_, idx) => {
        if (listTTFee[idx]?.info.feeDistribution?.[key]) {
          listTTFee[idx].info.feeDistribution[key] = false;
          if (isCalcEstFee) {
            const fee = listTTFee[idx].info;
            const feeTypeFee = fee.feeTypeFee?.value || 0;
            const bookKitFee = fee.bookKitFee?.value || 0;
            const admissionFee = fee.admissionFee?.value || 0;
            const feeType = fee.feeType?.value || '';
            const isBookFeeActive = fee.feeDistribution?.isBookFeeActive || false;
            const isAdmissionFeeActive =
              fee.feeDistribution?.isAdmissionFeeActive || false;
            const termsArray = fee.terms || [];

            const estFee = calcEstManualFee(
              feeTypeFee,
              bookKitFee,
              admissionFee,
              feeType,
              isBookFeeActive,
              isAdmissionFeeActive,
              termsArray,
            );
            listTTFee[idx].info.estAnnualFee = { value: estFee };
          }
        }
      });
      return listTTFee;
    });
  };

  return (
    <div className="mt-8 w-full">
      <p className="text-lg font-bold">Application Fee and Admission criteria</p>
      <div className="ml-2">
        <div className="flex w-full items-center">
          <Checkbox
            value={followingFeesAC.isActive}
            onChange={(e) => {
              setFollowingFees((prev) => {
                return {
                  ...prev,
                  isActive: e.target.checked,
                };
              });
              setFollowingFeesAC((prev) => {
                return {
                  ...prev,
                  isActive: e.target.checked,
                };
              });
            }}
          />
          <span className="text-sm ml-6 flex w-full items-center">
            Following fees to be paid to confirm admission
            <SelectGroup
              isDisabled={!followingFeesAC.isActive}
              value={listConfirmAdmission}
              isMulti={true}
              onChange={(e) => {
                const arr = ((list) => list.map((o) => o.value))(e);
                setListConfirmAdmission(e);
                setFollowingFees((prev) => {
                  return {
                    ...prev,
                    listConfirmAdmission: arr,
                  };
                });
                setFollowingFeesAC((prev) => {
                  return {
                    ...prev,
                    listConfirmAdmission: arr,
                  };
                });
              }}
              options={options}
              styles={selectStylesWithNoTitle}
              classNameGroup="w-[20rem] ml-3"
            />
          </span>
        </div>
        <div className="flex w-full items-center mt-2">
          <Checkbox
            value={admissionFeeAC.isActive}
            onChange={(e) => {
              setAdmissionFee((prev) => {
                return {
                  ...prev,
                  isActive: e.target.checked,
                };
              });
              setAdmissionFeeAC((prev) => {
                return {
                  ...prev,
                  isActive: e.target.checked,
                };
              });
              if (
                e.target.checked &&
                (admFeeType?.value === optionFee[0]?.value ||
                  admFeeType?.value === optionFee[1]?.value)
              ) {
                handleSetTutionFee('isAdmissionFeeActive', true);
              }
            }}
          />
          <span className="text-sm ml-6 flex w-full items-center">
            The admission fee is{' '}
            <SelectGroup
              isDisabled={!admissionFeeAC.isActive}
              value={admFeeType}
              options={optionFee}
              onChange={(e) => {
                setAdmFeeType(e);
                setAdmissionFee((prev) => {
                  return {
                    ...prev,
                    type: e.value,
                  };
                });
                setAdmissionFeeAC((prev) => {
                  return {
                    ...prev,
                    type: e.value,
                  };
                });
                if (e.value === optionFee[0].value || e.value === optionFee[1].value) {
                  handleSetTutionFee('isAdmissionFeeActive', true);
                }
              }}
              classNameGroup="w-[10rem] mx-3"
            />{' '}
            for all the grades{' '}
            {admFeeType && admFeeType.value === 'Same' && (
              <div className="flex items-center w-28 h-[38px] border rounded ml-3 hover:border-blue-500">
                <span className="ml-3">₹</span>
                <Input
                  disabled={!admissionFeeAC.isActive}
                  classGroup="w-full h-8"
                  isHideLabel={true}
                  type={'number'}
                  value={admissionFeeAC.price || undefined}
                  onChange={(e) => {
                    setAdmissionFee((prev) => {
                      return {
                        ...prev,
                        price: +e.target.value,
                      };
                    });
                    setAdmissionFeeAC((prev) => {
                      return {
                        ...prev,
                        price: +e.target.value,
                      };
                    });
                  }}
                  customStyle={inputStyle}
                />
              </div>
            )}
          </span>
        </div>
        <div className="flex w-full items-center mt-2">
          <Checkbox
            value={applicationFeeAC.isActive}
            onChange={(e) => {
              setApplicationFee((prev) => {
                return {
                  ...prev,
                  isActive: e.target.checked,
                };
              });
              setApplicationFeeAC((prev) => {
                return {
                  ...prev,
                  isActive: e.target.checked,
                };
              });
              if (
                e.target.checked &&
                (appFeeType?.value === optionFee[0]?.value ||
                  appFeeType?.value === optionFee[1]?.value)
              ) {
                handleSetTutionFee('isApplicationFeeActive');
              }
            }}
          />
          <span className="text-sm ml-6 flex w-full items-center">
            The application fee is{' '}
            <SelectGroup
              isDisabled={!applicationFeeAC.isActive}
              value={appFeeType}
              options={optionFee}
              onChange={(e) => {
                setAppFeeType(e);
                setApplicationFee((prev) => {
                  return {
                    ...prev,
                    type: e.value,
                  };
                });
                setApplicationFeeAC((prev) => {
                  return {
                    ...prev,
                    type: e.value,
                  };
                });
                if (e.value === optionFee[0]?.value || e.value === optionFee[1]?.value) {
                  handleSetTutionFee('isApplicationFeeActive');
                }
              }}
              classNameGroup="w-[10rem] mx-3"
            />{' '}
            for all the grades{' '}
            {appFeeType && appFeeType.value === 'Same' && (
              <div className="flex items-center w-28 h-[38px] border rounded ml-3 hover:border-blue-500">
                <span className="ml-3">₹</span>
                <Input
                  disabled={!applicationFeeAC.isActive}
                  classGroup="w-full h-8"
                  isHideLabel={true}
                  type={'number'}
                  value={applicationFeeAC.price || undefined}
                  onChange={(e) => {
                    setApplicationFee((prev) => {
                      return {
                        ...prev,
                        price: +e.target.value,
                      };
                    });
                    setApplicationFeeAC((prev) => {
                      return {
                        ...prev,
                        price: +e.target.value,
                      };
                    });
                  }}
                  customStyle={inputStyle}
                />
              </div>
            )}
          </span>
        </div>
        <div className="flex w-full items-center mt-2">
          <Checkbox value={true} />
          <span className="text-sm ml-6 flex w-full items-center">
            All the fees are linked to{' '}
            <SelectGroup
              value={razorpayBankAccountType}
              options={optionsBank}
              onChange={(e) => {
                setRazorpayBankAccountType(e);
                setRazorpayBankAccount('type', e.value);
              }}
              classNameGroup="w-[10rem] mx-3"
            />{' '}
            account{' '}
            {razorpayBankAccountType && razorpayBankAccountType.value === 'Same' && (
              <SelectGroup
                value={razorpayBankAccountAC}
                options={listBankAccount}
                onChange={(e) => {
                  setRazorpayBankAccountAC(e);
                  setRazorpayBankAccount('razorpayBankAccountId', e.value);
                }}
                classNameGroup="w-[20rem] mx-3"
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
});
AdmissionCriteria.displayName = 'AdmissionCriteria';
export default AdmissionCriteria;
