import React, { memo } from 'react';
import clsx from 'clsx';
import Card from './CardFee';
import TutionCardFee from './TutionCardFee';
import _ from 'lodash';

const SingleCategoryFee = memo((props) => {
  const {
    tutionFeesSave = [],
    admissionFee,
    applicationFee,
    options = [],
    tutionFees,
    setTutionFees,
    termsLength,
    title,
    listClasses = [],
    burger,
    refresh,
    listBankAccount = [],
    razorpayBankAccount,
    listAcademicYearTerm = []
  } = props;
  const types = [
    'Monthly',
    'Quaterly',
    'Trimester',
    'Half Yearly',
    'Term Dates',
    'Custom',
  ];
  const getClassFeeBookInfo = (classId, tutionFees) => {
    if (!classId || !tutionFees) {
      return {
        feeInfo: '',
        razorpayBankAccountId: ''
      };
    }
    const result = tutionFees.find((fee) => {
      return fee.classId === classId;
    });
    if (!result) {
      return {
        feeInfo: '',
        razorpayBankAccountId: ''
      };
    }
    // return JSON.parse(result.info);
    return {
      feeInfo: result.info,
      razorpayBankAccountId: result.razorpayBankAccountId
    };
  };
  return (
    <div className={clsx('w-full h-[70vh]')}>
      <p className="text-lg font-bold mt-8 mb-4">{title}</p>
      <div className={clsx('w-full max-h-[60vh] overflow-y-auto')}>
        {listClasses.map((data) => {
          const classFeeInfo = getClassFeeBookInfo(data.id, tutionFees).feeInfo;
          const classOldFeeInfo = getClassFeeBookInfo(data.id, tutionFeesSave).feeInfo;
          const oldTerms = _.cloneDeep(classOldFeeInfo.terms || []);
          const razorpayBankAccountId = getClassFeeBookInfo(data.id, tutionFees).razorpayBankAccountId;
          return (
            <div key={data.id} className="mb-4">
              <TutionCardFee
                tutionFeesSave={tutionFeesSave}
                oldTerms={oldTerms}
                admissionFee={admissionFee}
                applicationFee={applicationFee}
                options={options}
                classId={data.id}
                setTutionFees={setTutionFees}
                termsLength={termsLength}
                classFeeInfo={classFeeInfo}
                classOldFeeInfo={classOldFeeInfo}
                title={title}
                refresh={refresh}
                burger={burger}
                data={data}
                types={types}
                listBankAccount={listBankAccount}
                razorpayBankAccount={razorpayBankAccount}
                razorpayBankAccountId={razorpayBankAccountId}
                listAcademicYearTerm={listAcademicYearTerm}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
});
SingleCategoryFee.displayName = 'SingleCategoryFee';
export default SingleCategoryFee;
