import { selectStylesWithNoTitle } from 'entities/select-style';
import { memo, useEffect, useState } from 'react';

import { Checkbox, Input, SelectGroup } from '@stories/index';

const inputStyle = {
  width: '100%',
  height: '100%',
  fontSize: '0.875rem',
  borderRadius: '0.25rem',
  border: 'none',
  outline: 'none',
  'paddingLeft': '4px'
}

const ResidentialCriteria = memo((props) => {
  const {
    residentialFeeForClasses,
    setResidentialFeeForClasses,
    residentialFromClassId,
    setResidentialFromClassId,
    title,
    listClasses = [],
  } = props;

  const optionFee = [
    { label: 'Zero', value: 'Zero' },
    { label: 'Same', value: 'Same' },
    { label: 'Different', value: 'Different' },
  ];

  const [listGradeClasses, setListGradeClasses] = useState([]);
  const [residentialFromClassIdRF, setResidentialFromClassIdRF] = useState();
  const [residentialFeeForClassesRF, setResidentialFeeForClassesRF] = useState({});
  const [isActive, setIsActive] = useState(false)
  const [selectedClassId, setSelectedClassId] = useState({});
  const [selectedClassFeeType, setSelectedClassFeeType] = useState({});

  useEffect(() => {
    setResidentialFromClassIdRF(residentialFromClassId);
    setIsActive(!!residentialFromClassId);
  }, [residentialFromClassId]);
  useEffect(() => {
    setResidentialFeeForClassesRF(residentialFeeForClasses);
    setSelectedClassFeeType((prev) => {
      return optionFee.find((e) => e.value === residentialFeeForClasses.type);
    });
  }, [residentialFeeForClasses]);

  useEffect(() => {
    const list = listClasses.map((cls) => {
      return {
        label: cls.name,
        value: cls.id,
      };
    });
    const classConfirm = list.find((e) => {
      return e.value === residentialFromClassId;
    });
    classConfirm && setSelectedClassId(classConfirm);
    setListGradeClasses(list);
  }, [listClasses]);

  const handleResFeeForClass = (property, value) => {
    const initialValue = {
      isActive: isActive,
      type: selectedClassFeeType?.value || '',
      price: 0
    }
    setResidentialFeeForClassesRF(prev => {
      if(Object.keys(prev).length === 0) {
        return {
          ...initialValue,
          [property]: value
        }
      };
      return {
        ...prev,
        [property]: value
      }
    });
    setResidentialFeeForClasses(prev => {
      if(Object.keys(prev).length === 0) {
        return {
          ...initialValue,
          [property]: value
        }
      };
      return {
        ...prev,
        [property]: value
      }
    });
  }

  return (
    <div className="mt-8 w-full">
      <p className="text-lg font-bold">{title}</p>
      <div className="ml-2">
        <div className="flex w-full items-center">
          <Checkbox
            value={isActive}
            onChange={(e) => {
              setIsActive(e.target.checked);
              if(!e.target.checked) {
                setSelectedClassId({})
              }
              setResidentialFromClassIdRF((prev) => {
                if(!e.target.checked) {
                  return null;
                };
                return prev;
              });
              setResidentialFromClassId((prev) => {
                if(!e.target.checked) {
                  return null;
                };
                return prev;
              });
            }}
          />
          <span className="text-sm ml-6 flex w-full items-center">
            The school offers Residential from grade
            <SelectGroup
              isDisabled={!isActive}
              value={Object.keys(selectedClassId).length > 0 ? selectedClassId : null}
              isMulti={false}
              onChange={(e) => {
                setSelectedClassId(e);
                setResidentialFromClassIdRF(e.value);
                setResidentialFromClassId(e.value);
              }}
              options={listGradeClasses}
              styles={selectStylesWithNoTitle}
              classNameGroup="w-[15rem] ml-3"
            />
          </span>
        </div>
        <div className="flex w-full items-center mt-2">
          <Checkbox
            value={residentialFeeForClassesRF?.isActive}
            onChange={(e) => {
              handleResFeeForClass('isActive', e.target.checked);
            }}
          />
          <span className="text-sm ml-6 flex w-full items-center">
            The residential fee is{' '}
            <SelectGroup
              isDisabled={!residentialFeeForClassesRF?.isActive}
              value={selectedClassFeeType}
              options={optionFee}
              onChange={(e) => {
                setSelectedClassFeeType(e);
                handleResFeeForClass('type', e.value);
              }}
              classNameGroup="w-[10rem] mx-3"
            />{' '}
            for all the grades{' '}
            {selectedClassFeeType && selectedClassFeeType?.value === 'Same' && (
              <div className='flex items-center w-28 h-[38px] border rounded ml-3 hover:border-blue-500'>
                <span className='ml-3'>₹</span>
                <Input
                  disabled={!residentialFeeForClassesRF?.isActive}
                  classGroup="w-full h-8"
                  isHideLabel={true}
                  type={'number'}
                  value={residentialFeeForClassesRF?.price || undefined}
                  onChange={(e) => {
                    handleResFeeForClass('price', +e.target.value)
                  }}
                  customStyle={inputStyle}
                />
              </div>
            )}
          </span>
        </div>
      </div>
    </div>
  );
});
ResidentialCriteria.displayName = 'ResidentialCriteria';
export default ResidentialCriteria;
