import {AUTH} from '../action-creators/types';

const initState = {};

const authSlice = (state = initState, action) =>{
  switch (action.type) {
  case AUTH.saveAuth:
    return {...state, ...action.payload};
  case AUTH.deleteAuth:
    return {};
  default:
    return state;
  };
};

export default authSlice;