import React, { useMemo } from 'react';

// components
import Eyes from '../general/Eyes';
import Draw from '../general/Draw';
import Add from '../general/Add';
import clsx from 'clsx';

// PermissionSection is the whole Permission on the right, not the Module names and Page names on the left
// each page for each role will have a object like {read:{...}, edit:{...}, create:{...}}
// for example have 2 roles: roleA and roleB, 2 module MA and MB and pages PA1 and PA2, PB1, PB2 under MA and MB
// so 4 pages and 2 roles, we will have listPermission of 8 items (2 x 4 = 8)
// ++++++++ roleA +++++++++roleB+++++++
// MA ---------------------------------
// PA1 -----item0----------item4-------
// PA2 -----item1----------item5-------
// MB ---------------------------------
// PB1 -----item2----------item6-------
// PB2 -----item3----------item7-------
//
// each item will has structure as {read:{active: true...}, edit:{active: false...}, create:{active: false...}}
// the active boolean value stands for the permission granted or not. True is granted, False is not granted
// for example item0 = {read:{active: true...}, edit:{active: false...}, create:{active: false...}} means
// roleA on page PA1 will have read permission and do not have edit and create permission
export default function PermissionSection(props) {
  const {
    openPopupRoleDetail,
    isApproval,
    listRole,
    listPermission,
    listModule,
    openPermission,
    handleClickUpdate,
    handleOnChange,
  } = props;

  const countPage = useMemo(() => {
    let result = 0;
    listModule.forEach((module) => {
      result += module.pageModule.length;
    });
    return result;
  }, [listModule]);
  const abcd = listPermission.filter(rs => rs.read.active);
  let start = 0;
  let end = countPage;
  return (
    <>
      {listRole.map((role, indexRole) => {
        if (indexRole !== 0) {
          start = end;
          end += countPage;
        }
        return (
          <SingleSection
            openPopupRoleDetail={openPopupRoleDetail}
            handleRoleDetail
            key={`PermissionSection1${indexRole}`}
            isApproval={isApproval}
            role={role}
            dataPermission={listPermission.slice(start, end)}
            listModule={listModule}
            openPermission={openPermission}
            handleClickUpdate={handleClickUpdate}
            posRole={countPage * indexRole}
            handleOnChange={handleOnChange}
          />
        );
      })}
    </>
  );
}

// SingleSection stand for a column (a role that has 3 permission Read Edit Create across pages)
function SingleSection(props) {
  const {
    openPopupRoleDetail,
    isApproval,
    role,
    dataPermission,
    listModule,
    openPermission,
    posRole,
    handleOnChange,
  } = props;
  const { name, loginTypeId, isDefault } = role;
  const opinio = loginTypeId;

  let start = 0;
  let end = 0;
  return (
    <div className="w-52" style={{ minWidth: '13rem ' }}>
      {!isApproval && (
        <div className="mb-2 h-24">
          <div className="prog-card-new-row">
            <div className="prog-card-new-row_text">
              <h5>Role</h5>
              <p>{name}</p>
            </div>
          </div>

          <div className="prog-card-new-row">
            <div className="prog-card-new-row_text">
              <h5>Access Level</h5>
              {opinio > 0 && opinio < 5 ? (
                <div className="flex">
                  <div
                    className={clsx(
                      'prog-card-new-row_opinion',
                      `prog-card-new-row_opinion_${opinio}`,
                    )}
                  />
                  <div
                    className={clsx(
                      'prog-card-new-row_opinion',
                      opinio > 1
                        ? `prog-card-new-row_opinion_${opinio}`
                        : `prog-card-new-row_opinion_none`,
                    )}
                  />
                  <div
                    className={clsx(
                      'prog-card-new-row_opinion',
                      opinio > 2
                        ? `prog-card-new-row_opinion_${opinio}`
                        : `prog-card-new-row_opinion_none`,
                    )}
                  />
                  <div
                    className={clsx(
                      'prog-card-new-row_opinion',
                      opinio > 3
                        ? `prog-card-new-row_opinion_${opinio}`
                        : `prog-card-new-row_opinion_none`,
                    )}
                  />
                </div>
              ) : null}

              <div
                className="absolute -right-14 top-2 cursor-pointer border flex items-center justify-center w-7 h-7"
                onClick={() => {
                  if (isDefault) {
                    return;
                  }
                  openPopupRoleDetail(role);
                }}
              >
                <Crumbs />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="w-full">
        {listModule.map(({ module, pageModule }, indexModule) => {
          if (indexModule === 0) {
            start = 0;
            end = pageModule.length;
          } else {
            start = end;
            end += pageModule.length;
          }
          return openPermission[module] ? (
            <div key={`SingleSection${indexModule}`} className="pt-6">
              {dataPermission.slice(start, end).map((item, index) => {
                return (
                  <React.Fragment key={`AreaPermission1${index}`}>
                    <AreaPermission
                      data={item}
                      // isPopup={isPopup}
                      isApproval={isApproval}
                      roleName={name}
                      handleOnChange={handleOnChange}
                      indexPer={posRole + start + index}
                    />
                    <hr className="border-transparent" />
                  </React.Fragment>
                );
              })}
            </div>
          ) : (
            <div
              key={`SingleSection${indexModule}`}
              style={{ height: 'calc(1.5rem + 1px)' }}
            />
          );
        })}
      </div>
    </div>
  );
}

// AreaPermission show the block of Read Edit and Create whose symbols are Eye, Draw and Add
function AreaPermission(props) {
  const { data, isApproval, handleOnChange, indexPer, roleName } = props;
  const { read, create, edit } = data;
  function isEmptyObject(obj) {
    if (!obj) return true;
    return Object.keys(obj).length === 0;
  }

  return (
    <div
      className="h-10 flex identity-icon w-full"
      style={{
        // height: '1.5rem',
        alignItems: 'center',
        marginRight: '1.5rem',
        marginLeft: '1.875rem',
      }}
    >
      {!isEmptyObject(read) && (
        <Eyes
          disable={read.active}
          onlyView={isApproval}
          handleChange={handleOnChange}
          indexPer={indexPer}
          roleName={roleName}
        />
      )}
      {!isEmptyObject(edit) && (
        <Draw
          disable={edit.active}
          onlyView={isApproval}
          handleChange={handleOnChange}
          indexPer={indexPer}
          roleName={roleName}
        />
      )}
      {!isEmptyObject(create) && (
        <Add
          disable={create.active}
          onlyView={isApproval}
          handleChange={handleOnChange}
          indexPer={indexPer}
          roleName={roleName}
        />
      )}
    </div>
  );
}

const Crumbs = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.3335 3.33333C9.3335 4.06971 8.73654 4.66667 8.00016 4.66667C7.26378 4.66667 6.66683 4.06971 6.66683 3.33333C6.66683 2.59695 7.26378 2 8.00016 2C8.73654 2 9.3335 2.59695 9.3335 3.33333ZM9.3335 8C9.3335 8.73638 8.73654 9.33333 8.00016 9.33333C7.26378 9.33333 6.66683 8.73638 6.66683 8C6.66683 7.26362 7.26378 6.66667 8.00016 6.66667C8.73654 6.66667 9.3335 7.26362 9.3335 8ZM8.00016 14C8.73654 14 9.3335 13.403 9.3335 12.6667C9.3335 11.9303 8.73654 11.3333 8.00016 11.3333C7.26378 11.3333 6.66683 11.9303 6.66683 12.6667C6.66683 13.403 7.26378 14 8.00016 14Z"
        fill="#637381"
      />
    </svg>
  );
};
