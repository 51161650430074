const WhatsAppLine = (props) => {
  const { data } = props

  return (
    <div className="flex items-center justify-between w-full my-2">
      <hr className="h-[1px] border border-color-[#B0B6BE] bg-[#B0B6BE] w-[32%]" />
      <p className="text-[#919EAB3D] text-xs font-medium">{data}</p>
      <hr className="h-[1px] border border-color-[#B0B6BE] bg-[#B0B6BE] w-[32%]" />
    </div>
  )
}

export default WhatsAppLine;