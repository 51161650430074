import { Menu, MenuItem, MenuButton } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { ellipsis } from '../../../../static/image';
import { useHistory } from 'react-router-dom';
import { urls } from 'entities/urls';

const ActionDropdownMenu = (props) => {
  const { data, index, handleClickUsers } = props;
  const history = useHistory();
  return (
    <Menu
      menuButton={
        <MenuButton>
          <img src={ellipsis} alt="button" />
        </MenuButton>
      }
      transition
    >
      {/* <MenuItem
        onClick={() => {
          // handleClickUsers(data, index);
          handleClickUsers(data);
        }}
      >
        Users
      </MenuItem>
      <MenuItem>Like</MenuItem>
      <MenuItem>Disable</MenuItem> */}
      <MenuItem
        data={data}
        onClick={() => {
          history.push('/detail/company-edit', { data: data });
        }}
      >
        Edit Campus
      </MenuItem>
      <MenuItem
        data={data}
        onClick={() => {
          history.push(urls.importUser, { data: data });
        }}
      >
        Import Users
      </MenuItem>
    </Menu>
  );
};
export default ActionDropdownMenu;
