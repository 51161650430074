import React, { memo, useEffect, useState } from 'react';
import ContentGroup from '../content-group/content-group';
import InputSearchUser from '../input-suggest-user/input-suggest-user';
import { useSuggestUser, useUser } from '@utils/custom-hook';
import { Input } from '@stories/index';

const OtherQuestions = memo((props) => {
  const { info, handleChange } = props;

  const [showOption, setShowOption] = useState(false)
  const [input, setInput] = useState()
  const { data, error, loading } = useUser({ id: info?.whereDidYouHearAboutUs?.userId });

  useEffect(() => {
    if (data)
      setInput(data?.name)
  }, [data])

  const textSearch = () => {
    if (input) {
      if (isNaN(input[0])) return input
      return encodeURIComponent(info.phoneNumber)
    }

    return ''
  }

  const { data: dataSuggest } = useSuggestUser({ textSearch: textSearch() });

  const handleChooseOption = (e) => {
    handleChange({
      name: 'otherQuestions',
      key: 'whereDidYouHearAboutUs',
      value: { userId: e.id }
    });

    setInput(e.name)
    setShowOption(false);
  }

  const handleChangeText = (value) => {
    setInput(value)
    setShowOption(true);
  }


  return (
    <>
      <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
        Where did you hear about us?
      </p>
      <InputSearchUser
        label=""
        value={input || ""}
        options={dataSuggest}
        exceptId={{}}
        showOption={showOption}
        customStyle={{ height: 'unset' }}
        classGroup="mb-5"
        handleChangeText={handleChangeText}
        handleChooseOption={handleChooseOption}
      />

      <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
        Why did you choose to educate your child in an international School?
      </p>
      <Input
        customStyle={{ height: 'unset' }}
        type="text"
        label=''
        value={info?.whyDidYouChooseToEducateYourChildInAnInternationalSchool || ''}
        onChange={(e) => {
          const val = e.target.value;
          handleChange({
            name: 'otherQuestions',
            key: 'whyDidYouChooseToEducateYourChildInAnInternationalSchool',
            value: val
          });
        }}
      />
    </>
  );
});
OtherQuestions.displayName = 'OtherQuestions';
export default OtherQuestions;
