import { css } from '@emotion/react';
import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import BounceLoader from 'react-spinners/BounceLoader';
import { Viewer, Worker } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import NewPdf from './PDF/NewPdf';
import doc from './doc.png';
import done from './done.svg';
import print from './print.svg';
import './signature.scss';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import { checkErrorApiFetch } from '@utils/check-error/api-error';
import { transformDataFormBuilder } from '@helper/transform-data-formbuilder';
import { formatMoney } from '@helper/format-money';
import Loading from '@components/loading';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const override = css`
  display: block;
  margin: 0 auto;
  border-color: red;
`;

export default function Signature(props) {
  const {
    isParent,
    enquiry,
    handleNextStage,

    // setRefresh = () => {},
    // setIsCallAPI = () => {},
    // setRefreshEnquiries = () => {},
  } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [isLoading, setIsLoading] = useState(false);
  const [application, setApplication] = useState();
  const [showPrint, setShowPrint] = useState(false);
  const [urlSignatureFile, setUrlSignatureFile] = useState();
  const [paymentInfo, setPaymentInfo] = useState();
  const [signatureInfo, setSignatureInfo] = useState({});
  const [state, setState] = useState({
    start: true,
    waitingSignature: false,
    waitingPayment: false,
    done: false,
  });
  const campus = useStorage(state => state.currentCampus)

  useEffect(() => {
    if (application?.signatureInfo) {
      setSignatureInfo(JSON.parse(application.signatureInfo));
    }
    if (application?.status !== EnquiryStatus.WAITING_FOR_SIGNATURE) {
      setState({
        waitingSignature: false,
        waitingPayment: false,
        done: false,
        start: false,
      });
    }
    if (application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE) {
      setState({
        waitingSignature: true,
        waitingPayment: false,
        done: false,
        start: true,
      });
    }
    if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
      setState({
        start: false,
        waitingSignature: false,
        done: false,
        waitingPayment: true,
      });
      // setShowPrint(true);
    }
    if (application?.status === EnquiryStatus.APPLICATION_COMPLETE) {
      setState({
        start: false,
        waitingSignature: false,
        waitingPayment: false,
        done: true,
      });
      setShowPrint(true);
    }

    if (
      application &&
      enquiry.status === EnquiryStatus.WAITING_FOR_PAYMENT &&
      enquiry.currentStep === EnquiryStatus.SIGNATURE
    ) {
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/application-payment/${application.id}`,
      // })
      admissionApi.getApplicationPayment(application.id)
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Payment Info',
            )
          ) {
            const paymentInfo = JSON.parse(res.data.data.info);
            setPaymentInfo(paymentInfo);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Payment Info Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Get Payment Info Error', error);
        });
    }
  }, [application]);

  useEffect(() => {
    if (enquiry?.admissionResult?.info) {
      const admissionResultInfo = JSON.parse(enquiry.admissionResult.info);
      const applicationInfo = transformDataFormBuilder(
        admissionResultInfo.application.info,
      );
      const application = admissionResultInfo.application;
      application.info = applicationInfo;
      setApplication(application);

      // setApplication(admissionResultInfo.application);

      if (
        admissionResultInfo?.application &&
        admissionResultInfo.application.signatureFileURL
      ) {
        setUrlSignatureFile(admissionResultInfo.application.signatureFileURL);
      }
    }
    setState({
      start: true,
      waitingSignature: false,
      waitingPayment: false,
      done: false,
    });
    setShowPrint(false);
  }, [enquiry]);

  const updateStatus = () => {
    let api = admissionApi.signature({ campusId: campus?.isCentral ? undefined : campus?.id }, application.id);
    if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
      api = admissionApi.signatureFeePayment({ campusId: campus?.isCentral ? undefined : campus?.id }, application.id);
    }
    api.then((results) => {
      if (results?.data?.success && results.data.success) {
        handleNextStage();
      } else {
        if (application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE) {
          setState({
            ...state,
            waitingSignature: true,
            start: false,
          });
        } else if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
          setState({
            ...state,
            waitingSignature: false,
            waitingPayment: true,
            start: false,
          });
        }
        setToastMessage({
          status: 'error',
          title: 'Update Failed',
          message: results.data.message,
        });
        setIsShowToastMessage(true);
      }
    })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update Error', error);
      });
  };

  const handleUpdate = () => {
    if (application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE) {
      setState({
        ...state,
        waitingSignature: true,
        start: false,
      });
    }
    if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
      setState({
        ...state,
        waitingSignature: false,
        waitingPayment: true,
        start: false,
      });
    }
    updateStatus();
  };

  const styles = {
    main: { background: `url(${doc})`, backgroundPosition: 'center' },
    box: { width: '44.375rem', height: '55.438rem' },
  };

  const handleDownloadPdfFile = () => {
    // const accesstoken = getAccessToken();
    const accesstoken = useStorage.getAccessToken();

    let apiUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/signature/pdf/${application?.id}`;
    if (isParent) {
      apiUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/application/parent-signature/pdf/${application?.id}`;
    }
    // callApi({
    //   method: 'get',
    //   url: apiUrl,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    fetch(apiUrl, {
      headers: {
        accesstoken,
        'Content-Type': 'application/pdf',
      },
    })
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        // Create a URL for the blob object
        const url = window.URL.createObjectURL(blob);

        // Create a link element and trigger download
        const link = document.createElement('a');
        link.href = url;
        link.download = `application_${application?.id}.pdf`;
        link.click();
      });
    // .then((results) => {
    //   console.log('results', results)
    //   if (results?.data?.success && results.data.success) {
    //     //   console.log(results.data.data);
    //     // setIsCallAPI((prev) => !prev);
    //     // handleRefreshAndNext();
    //     setToastMessage({
    //       status: 'success',
    //       title: 'Download File Successfully',
    //       message: results.data.message,
    //     });
    //     setIsShowToastMessage(true);
    //   } else {
    //     setToastMessage({
    //       status: 'error',
    //       title: 'Download File Failed',
    //       message: results?.data?.message || '',
    //     });
    //     setIsShowToastMessage(true);
    //   }
    // })
    // .catch((error) => {
    //   setToastMessage({
    //     status: 'error',
    //     title: 'Download File Failed',
    //     message: error.response?.data?.message || error,
    //   });
    //   setIsShowToastMessage(true);
    //   console.log('Download File Error', error);
    // });
  };

  const handleConfirmSignature = () => {
    admissionApi.confirmSignature({ campusId: campus?.isCentral ? undefined : campus?.id }, application?.id)
      .then((results) => {
        if (results?.data?.success) {
          if (results.data.success) {
            // setIsCallAPI((prev) => !prev);
            handleNextStage();
            setState({
              ...state,
              waitingSignature: false,
              waitingPayment: true,
              start: false,
            });
            setToastMessage({
              status: 'success',
              title: 'Confirm Signature Successfully',
              message: results.data.success || 'success',
            });
            setIsShowToastMessage(true);
          }
          // else {
          //   setToastMessage({
          //     status: 'warning',
          //     title: 'Confirm Signature Failed',
          //     message: 'Please signature document before confirm',
          //   });
          //   setIsShowToastMessage(true);
          // }
        } else {
          if (application?.status === EnquiryStatus.WAITING_FOR_SIGNATURE) {
            setState({
              ...state,
              waitingSignature: true,
              start: false,
            });
          } else if (application?.status === EnquiryStatus.WAITING_FOR_PAYMENT) {
            setState({
              ...state,
              waitingSignature: false,
              waitingPayment: true,
              start: false,
            });
          }
          setToastMessage({
            status: 'error',
            title: 'Confirm Signature Failed',
            message: results.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Confirm Signature Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Confirm Signature Error', error);
      });
  };
  return (
    <>
      {showPrint && (
        <div className="flex items-center justify-center">
          <img className="w-4 h-4 mr-2" src={print} alt="print" />
          <button
            className="text-[#2F80ED] text-[1.2rem]"
            onClick={() => {
              handleDownloadPdfFile();
            }}
          >
            Print Application
          </button>
        </div>
      )}
      {!isParent && state.waitingPayment && (
        <div className="flex items-center justify-center mt-4">
          <button
            onClick={() => {
              handleUpdate();
            }}
            className="mb-4 py-2 px-4 border border-[#2E72D2] rounded bg-[#2E72D2] text-[#fff]"
          >
            Collect Application Fee
          </button>
        </div>
      )}
      <div className="flex items-center justify-center">
        <div
          className={clsx(
            'mb-10 flex items-end justify-center relative',
            state.waitingPayment ? 'mt-2' : 'mt-10',
          )}
          style={styles.box}
        >
          {/* <div className="absolute" style={Object.assign(styles.main, styles.box)} /> */}
          {/* <div
            className="absolute"
            style={{ width: '44.375rem', height: '55.438rem' }}
          ></div> */}
          {application && (
            <div
              className="flex justify-center w-full"
              style={{ width: '44.375rem', height: '55rem' }}
            >
              <NewPdf
                setRefresh={() => { }}
                applicationId={application.id}
                isShowESign={false}
                studentInfo={application.info}
                isShowButtonSign={(state.waitingPayment && !isParent) || showPrint ? false : true}
                applicationParent={application?.applicationParent || []}
                setIsLoading={setIsLoading}
              />
            </div>
          )}

          <div
            className={clsx(
              'absolute flex flex-col items-center justify-center',
              state.done && 'signature-done z-10',
              (state.waitingSignature || state.waitingPayment) &&
              'signature-waiting z-10',
              (state.waitingPayment && !isParent) || showPrint ? 'top-0' : 'top-[243px]',
            )}
            style={{ width: '44.475rem', height: '57.438rem' }}
          >
            {(state.waitingSignature || state.waitingPayment) && (
              <BounceLoader color="#FFEBD3" loading={true} css={override} size={60} />
            )}
            {state.done && (
              <img
                src={done}
                style={{ width: '3.75rem', height: '3.75rem' }}
                alt="done"
              />
            )}
            {state.waitingSignature && (
              <p className="text-sm text-main-black font-semibold mt-3">
                Waiting for Signature
              </p>
            )}
            {state.waitingPayment && (
              <p className="text-sm text-main-black font-semibold mt-3">
                Waiting for Payment{' '}
                {`${formatMoney(
                  paymentInfo?.amount_paid,
                  paymentInfo?.currency ||
                  enquiry?.programGrade?.programGradeFee?.currency,
                )}/${formatMoney(
                  paymentInfo?.amount ||
                  enquiry?.programGrade?.programGradeFee?.applicationFee,
                  enquiry?.programGrade?.programGradeFee?.currency,
                )}`}
              </p>
            )}
            {state.done && (
              <p className="text-sm text-main-black font-semibold mt-3">
                Application Complete
              </p>
            )}
          </div>
        </div>
      </div>
      {isLoading && (
        <div className="fixed inset-0 bg-[#33333380] flex items-center justify-center z-[999]">
          <Loading />
        </div>
      )}
    </>
  );
}

const PDFView = ({ base64 }) => {
  const [urlNew, setUrlNew] = useState();

  useEffect(() => {
    if (base64) {
      // console.log('base64', base64);
      const pdfContentType = 'application/pdf';

      const base64toBlob = (data) => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr(`data:${pdfContentType};base64,`.length);
        // const base64WithoutPrefix = data;
        // console.log('base64', base64WithoutPrefix.length);
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        let out = new Uint8Array(length);

        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], { type: pdfContentType });
      };

      const url = window.URL.createObjectURL(base64toBlob(base64));
      setUrlNew(url);
    }
  }, [base64]);
  /* NO DELETE */
  //   var base64 = ("yourBase64StringVariable")
  // const blob = base64ToBlob( base64, 'application/pdf' );
  // const url = URL.createObjectURL( blob );
  // const pdfWindow = window.open("");
  // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + url + "'></iframe>");

  // function base64ToBlob( base64, type = "application/octet-stream" ) {
  //   const binStr = atob( base64 );
  //   const len = binStr.length;
  //   const arr = new Uint8Array(len);
  //   for (let i = 0; i < len; i++) {
  //     arr[ i ] = binStr.charCodeAt( i );
  //   }
  //   return new Blob( [ arr ], { type: type } );
  // }
  const defaultLayoutPluginInstance = defaultLayoutPlugin();
  return (
    <>
      {urlNew && (
        <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.12.313/build/pdf.worker.js">
          <div
            style={{
              width: '44.375rem',
              height: '55.438rem',
              margin: '1rem auto',
            }}
          >
            <Viewer
              defaultScale={1}
              // scrollMode={ScrollMode.Page}
              // spreadsMode={SpreadsMode.EvenSpreads}
              // fileUrl={`files/abc.pdf`}
              fileUrl={urlNew}
              plugins={[defaultLayoutPluginInstance]}
            />
          </div>
        </Worker>
      )}
    </>
  );
};

function downloadPDF(pdf) {
  if (!pdf) {
    return;
  }
  const linkSource = `data:application/pdf;base64,${pdf}`;
  // const linkSource = pdf;
  const downloadLink = document.createElement('a');
  const fileName = 'abc.pdf';
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
}
