import * as React from 'react';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

export default function NewSelectGroup(props) {
  const { id, label = 'Grouping', value, onChange = () => { }, listOptions = [], isError } = props;
  return (
    <div>
      <FormControl fullWidth sx={{ m: 1, minWidth: 120, width: '100%' }} error={isError}
        className='m-0'>
        <InputLabel
          sx={{
            bgcolor: 'white',
          }}
          htmlFor={`grouped-native-select-${id}`}
        >
          {label}
        </InputLabel>
        <Select
          onChange={onChange}
          native
          defaultValue=""
          // id={`grouped-native-select-${id}`}
          label="Grouping"
          sx={{
            borderRadius: "8px"
          }}
          value={value?.value}
        >
          <option aria-label="None" value="" />
          {listOptions &&
            listOptions.length &&
            listOptions.map((option) => {
              return (
                <optgroup label={option?.label} key={option?.value}>
                  {option?.childrens &&
                    option.childrens?.length &&
                    option.childrens?.map((childData) => {
                      return (
                        <option key={childData.value} value={childData.value}>
                          {childData.label}
                        </option>
                      );
                    })}
                </optgroup>
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
