import clsx from 'clsx';
import { cloneDeep } from 'lodash';
import { useContext, useEffect, useMemo, useState } from 'react';
import EditCreateRole from '@components/edit-panel/edit-create-role';
import Footer from '@components/footer/footer';
import { NavLinks } from '@pages/Header/components';
import { icsettings, icusergroup } from '@pages/Login/components/image';
import { icuser } from '@static/image';
import { Button } from '@stories/index';
import {
  giikiAdminId,
} from '@utils/storage';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { RolesPermissions } from '..';
import PopupRole from './popup-role';
import './role-permission.scss';
import authApi from 'api/auth';
import useStorage from 'store/storage';

export default function RoleAndPermissions({ burger }) {
  const [visiblePanel, setVisiblePanel] = useState(false);
  const [showPopupCreate, setShowPopupCreate] = useState(false);
  const [loginTypes, setLoginTypes] = useState([]);
  const [edited, setEdited] = useState(false);
  const [permissionOrigin, setPermissionOrigin] = useState('');
  const [listRole, setListRole] = useState([]);
  const [listModule, setListModule] = useState([]);
  const [listPermission, setListPermission] = useState([]);
  const [listRoleUpdate, setListRoleUpdate] = useState([]);
  const [openPage, setOpenPage] = useState([]);
  const [openPermission, setOpenPermission] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [isUpdateRole, setIsUpdateRole] = useState(false);
  const [isCreateRole, setIsCreateRole] = useState(false);
  const [isHaveProcessCreateRole, setIsHaveProcessCreateRole] = useState(false);
  const [isHaveProcessUpdateRole, setIsHaveProcessUpdateRole] = useState(false);
  const [changes, setChanges] = useState({});
  const [numberOfEdits, setNumberOfEdits] = useState(0);
  const [draftsList, setDraftsList] = useState([]);
  const [isRenderDraft, setIsRenderDraft] = useState(false);
  const [draftId, setDraftId] = useState();
  const [dataChange, setDataChange] = useState({});
  // role's detail when you click on its crumbs:
  const [roleDetail, setRoleDetail] = useState(null);
  const campus = useStorage((state) => state.currentCampus);
  const userLogin = useStorage((state) => state.auth);
  const isAdmin = useMemo(() => userLogin?.roles.some((role) => role.id === giikiAdminId), []);

  const permissionInit = {
    create: 'create',
    edit: 'edit',
    read: 'read',
  };

  const listLoginType = useMemo(
    () => [
      {
        id: 1,
        title: 'Access Level',
        subtitle: 'Low',
        image: icuser,
        opinio: 1,
      },
      {
        id: 2,
        title: 'Access Level',
        subtitle: 'Medium',
        image: icusergroup,
        opinio: 2,
      },
      {
        id: 3,
        title: 'Access Level',
        subtitle: 'Medium +',
        image: icsettings,
        opinio: 3,
      },
      {
        id: 4,
        title: 'Access Level',
        subtitle: 'High',
        image: icsettings,
        opinio: 4,
      },
    ],
    [],
  );

  const countPage = useMemo(() => {
    let result = 0;
    listModule.forEach((module) => {
      result += module.pageModule.length;
    });
    return result;
  }, [listModule]);

  const isShowTextApproval = useMemo(() => {
    if (isCreateRole) {
      return isCreateRole && isHaveProcessCreateRole;
    }
    if (isUpdateRole) {
      return isUpdateRole && isHaveProcessUpdateRole;
    }
  }, [isCreateRole, isUpdateRole, isHaveProcessCreateRole, isHaveProcessUpdateRole]);

  const getRoleNameAndModule = (dataApi) => {
    const listModule = [];
    const listRoleName = [];
    dataApi.forEach((data) => {
      const { module, pages } = data;
      const pageModule = [];

      pages.forEach((page) => {
        const { name, roles } = page;
        if (!pageModule.includes(name)) pageModule.push(name);

        roles.forEach((role) => {
          const { name, loginTypeId, id } = role;
          const tmp = { name, loginTypeId, id };
          if (!listRoleName.some((item) => item.name === tmp.name))
            listRoleName.push(tmp);
        });
      });
      listModule.push({ module, pageModule });
    });
    return { modules: listModule, roleNames: listRoleName };
  };

  //#region origin getDataPermission
  const getDataPermission = (dataApi, listRole, dataDraft) => {
    const objResult = {};
    listRole.forEach(({ name }) => (objResult[name] = []));

    dataApi.forEach((data) => {
      const { pages } = data;
      pages.forEach(({ roles, id: pageId }) => {
        const roleHasPer = [];
        roles.forEach((role) => {
          const { name: roleName, permissions } = role;
          if (objResult[roleName]) {
            roleHasPer.push(roleName);
            const tmp = {};
            permissions.forEach((permission) => {
              const { name, id, rolePagePermission } = permission;
              const { active, id: uidPermission } = rolePagePermission;
              if (id === permissionInit.create)
                tmp.create = { pageId, name, permissionId: id, active, uidPermission };
              if (id === permissionInit.edit)
                tmp.edit = { pageId, name, permissionId: id, active, uidPermission };
              if (id === permissionInit.read)
                tmp.read = { pageId, name, permissionId: id, active, uidPermission };
            });
            objResult[roleName].push(tmp);
          }
        });
        if (roleHasPer.length < listRole.length) {
          listRole.forEach(({ name }) => {
            if (!roleHasPer.includes(name)) objResult[name].push({});
          });
        }
      });
    });

    const result = [];
    listRole.forEach(({ name }) => result.push(...objResult[name]));
    return result;
  };
  //#end region

  const newGetDataPermission = (dataApi, listRole, dataDraft) => {
    const objResult = {};
    listRole.forEach(({ name }) => (objResult[name] = []));
    if (!dataDraft?.length) {
      dataApi.forEach((data) => {
        const { pages, module } = data;
        pages.forEach(({ roles, id: pageId, name: pageName }) => {
          const roleHasPer = [];
          roles.forEach((role) => {
            const { name: roleName, permissions, id: roleId } = role;
            if (objResult[roleName]) {
              roleHasPer.push(roleName);
              const tmp = {};
              ['read', 'edit', 'create'].forEach((permission) => {
                const data = {
                  module,
                  page: {
                    pageId,
                    pageName,
                  },
                  role: {
                    roleId,
                    roleName,
                  },
                  permission: {
                    permissionId: permission,
                    permissionName: permission,
                  },
                  rolePagePermission: {
                    uidPermission: permission,
                    oldActive: permissions.includes(permission),
                  },
                };
                ['read', 'edit', 'create'].forEach((per) => {
                  tmp[per] = {
                    pageId,
                    name: per,
                    permissionId: per,
                    active: permissions.includes(permissionInit[per]),
                    uidPermission: per,
                    data,
                  };
                });
              });
              objResult[roleName].push(tmp);
            }
          });
          if (roleHasPer.length < listRole.length) {
            listRole.forEach(({ name }) => {
              if (!roleHasPer.includes(name)) objResult[name].push({});
            });
          }
        });
      });
    } else {
      dataApi.forEach((data) => {
        const { pages, module } = data;
        pages.forEach(({ roles, id: pageId, name: pageName }) => {
          const roleHasPer = [];
          roles.forEach((role) => {
            const { name: roleName, permissions, id: roleId } = role;
            if (objResult[roleName]) {
              roleHasPer.push(roleName);
              const tmp = {};
              permissions.forEach((permission) => {
                const { name, id, rolePagePermission } = permission;
                const { active, id: uidPermission } = rolePagePermission;
                const data = {
                  module,
                  page: {
                    pageId,
                    pageName,
                  },
                  role: {
                    roleId,
                    roleName,
                  },
                  permission: {
                    permissionId: id,
                    permissionName: name,
                  },
                  rolePagePermission: {
                    uidPermission,
                    oldActive: active,
                  },
                };

                let resultActive = active;
                for (const obj of dataDraft) {
                  const { uidPermission: uidPermissionDraft, active: activeDraft } = obj;
                  if (uidPermission === uidPermissionDraft) {
                    resultActive = activeDraft;
                    break;
                  }
                }

                if (id === permissionInit.create)
                  tmp.create = {
                    pageId,
                    name,
                    permissionId: id,
                    active: resultActive,
                    uidPermission,
                    data,
                  };
                if (id === permissionInit.edit)
                  tmp.edit = {
                    pageId,
                    name,
                    permissionId: id,
                    active: resultActive,
                    uidPermission,
                    data,
                  };
                if (id === permissionInit.read)
                  tmp.read = {
                    pageId,
                    name,
                    permissionId: id,
                    active: resultActive,
                    uidPermission,
                    data,
                  };
              });
              objResult[roleName].push(tmp);
            }
          });
          if (roleHasPer.length < listRole.length) {
            listRole.forEach(({ name }) => {
              if (!roleHasPer.includes(name)) objResult[name].push({});
            });
          }
        });
      });

      const roleChanges = dataDraft.map((draft) => draft.data.role.roleName);
      setListRoleUpdate([...new Set(roleChanges)]);
    }
    const result = [];
    listRole.forEach(({ name }) => result.push(...objResult[name]));
    return result;
  };

  const getDraftsList = async () => {
    await authApi.getListRoleDraft({ campusId: campus?.id || undefined })
      .then((res) => {
        if (res.data.success) {
          const drafts = res.data.data.objects;
          setDraftsList(drafts);
          setNumberOfEdits(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log('Get list draft fail', error);
      });
  };

  const sendDraft = async (draft) => {
    await authApi.createRoleDraft({ campusId: campus?.id || undefined, companyId: userLogin?.companyId }, draft)
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Save as Draft Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Save as Draft Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      });
  };

  const handleOnChange = (roleName, index, key, isActive) => {
    setDataChange((prev) => {
      const data = { ...prev };
      data[index] = roleName;
      return data;
    });
    const keyword = `${roleName}-${index}-${key}`;
    const value = {
      roleName,
      index,
      key,
    };
    const dictChanges = cloneDeep(changes);
    if (dictChanges[keyword]) {
      delete dictChanges[keyword];
    } else {
      dictChanges[keyword] = value;
    }
    setChanges(dictChanges);

    setListPermission((prev) => {
      const tmp = [...prev];
      const active = tmp[index][key].active;
      tmp[index][key].active = !active;
      return tmp;
    });

    setListRoleUpdate((prev) => {
      if (!prev.includes(roleName)) prev.push(roleName);
      return prev;
    });

    setIsUpdateRole(true);
  };

  const handleClickArrow = (module) => {
    setOpenPage((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
    setOpenPermission((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
  };

  const handleClickCreate = () => {
    setShowPopupCreate((prev) => !prev);
  };

  const getDataPermissionUpdate = (dataRole, draft = false) => {
    const result = [];
    dataRole.forEach((data) => {
      for (const value of Object.values(data)) {
        if (Object.keys(value).length !== 0) {
          const { uidPermission, active, pageId, permissionId } = value;
          if (draft)
            result.push({
              pagePermissionId: uidPermission,
              active,
              pageId,
              permissionId,
            });
          result.push({ id: uidPermission, active, pageId, permissionId });
        }
      }
    });
    return result;
  };

  const handleSaveAsDraft = async () => {
    return;
    if (!Object.keys(changes).length) return;

    const infoDraftChange = [];
    const dataDraftChange = [];

    const now = new Date();
    const timeString = now.toLocaleTimeString('en-US', {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });

    Object.keys(changes).forEach((keyWord) => {
      const { index, key } = changes[keyWord];

      const objChange = {
        [key]: { ...listPermission[index][key] },
        [keyWord]: { ...changes[keyWord] },
      };

      const { role, page, module } = objChange[key].data;
      const content = objChange[key].active ? 'Enable' : 'Disable';
      const info = {
        title: `Edit role: ${role.roleName}`,
        subtitle: `${content} ${key} permission of page: ${page.pageName} (Module: ${module})`,
        time: timeString,
        type: 'Edit',
      };

      dataDraftChange.push(objChange[key]);
      infoDraftChange.push(info);
    });

    const info = {
      changes: [...infoDraftChange],
      name: 'Change permission',
    };
    info['campusId'] = campus?.id;

    await sendDraft({ info, data: dataDraftChange });
    await getDraftsList();
  };

  const handleClickCancel = () => {
    setListPermission(JSON.parse(permissionOrigin));
    setChanges({});
    setListRoleUpdate([]);
  };

  const handelSendForApproval = () => {
    const listApi = [];
    if (isAdmin) {
      // listRoleUpdate.forEach((roleName) => {
      //   const index = listRole.findIndex(({ name }) => name === roleName);
      //   if (index !== -1) {
      //     const dataPer = listPermission.slice(
      //       index * countPage,
      //       (index + 1) * countPage,
      //     );
      //     let dataApi = [];
      //     if (dataPer.length !== 0) dataApi = getDataPermissionUpdate(dataPer);
      //     listApi.push(
      //       callApi({
      //         method: 'patch',
      //         url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/role/update-active`,
      //         data: { data: dataApi },
      //         // params: { campusId: isCentral ? undefined : campus?.id },
      //       }),
      //     );
      //   }
      // });
      const dataCallApi = [];
      Object.keys(dataChange).forEach((key) => {
        const data = listPermission[key];
        if (
          data?.create?.active !== data?.create?.data?.rolePagePermission?.oldActive ||
          data?.read?.active !== data?.read?.data?.rolePagePermission?.oldActive ||
          data?.edit?.active !== data?.edit?.data?.rolePagePermission?.oldActive
        ) {
          const permission = [];
          if (data?.create?.active) permission.push('create');
          if (data?.read?.active) permission.push('read');
          if (data?.edit?.active) permission.push('edit');
          dataCallApi.push({
            pageId: data?.create?.pageId || data?.read?.pageId || data?.edit?.pageId,
            roleId: data?.create?.data?.role?.roleId || data?.read?.data?.role?.roleId || data?.edit?.data?.role?.roleId,
            permission: permission.join(';')
          });
        }
      });
      if (!dataCallApi.length) {
        return;
      }
      // callApi({
      //   method: 'patch',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role`,
      //   // params: {
      //   //   // campusId: campus?.id || undefined,
      //   // },
      //   data: { data: dataCallApi },
      // })
      authApi.updateRole({ data: dataCallApi })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: isAdmin ? 'Update Successfully' : 'Send for Approvals Successfully',
              message: 'Update role Successfully',
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Submit Failed',
              // message: error.response?.data?.message || error,
            });
            setIsShowToastMessage(true);
          }
        }).catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Submit Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Campus Information Role Permissions Error', error);
        });
    } else {
      // listRoleUpdate.forEach((roleName) => {
      //   const index = listRole.findIndex(({ name }) => name === roleName);
      //   if (index !== -1) {
      //     const dataPer = listPermission.slice(
      //       index * countPage,
      //       (index + 1) * countPage,
      //     );
      //     let dataApi = [];
      //     if (dataPer.length !== 0) dataApi = getDataPermissionUpdate(dataPer);
      //     listApi.push(
      //       callApi({
      //         method: 'post',
      //         url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v1/approval/request`,
      //         // params: { campusId: isCentral ? undefined : campus?.id },
      //         data: {
      //           name: `Update role ${roleName}`,
      //           actionId: 2, // action id update
      //           changeContent: {
      //             actionName: 'Update role',
      //             apiURL: process.env.REACT_APP_URL_API_AUTH,
      //             endpoint: '/api/v1/role/update-active',
      //             apiMethod: 'PATCH',
      //             content: {
      //               data: dataApi,
      //             },
      //             // params: { campusId: isCentral ? undefined : campus?.id },
      //           },
      //           keyFields: [],
      //         },
      //       }),
      //     );
      //   }
      // });
      const dataCallApi = [];
      Object.keys(dataChange).forEach((key) => {
        const data = listPermission[key];
        if (
          data?.create?.active !== data?.create?.data?.rolePagePermission?.oldActive ||
          data?.read?.active !== data?.read?.data?.rolePagePermission?.oldActive ||
          data?.edit?.active !== data?.edit?.data?.rolePagePermission?.oldActive
        ) {
          const permission = [];
          if (data?.create?.active) permission.push('create');
          if (data?.read?.active) permission.push('read');
          if (data?.edit?.active) permission.push('edit');
          dataCallApi.push({
            pageId: data?.create?.pageId || data?.read?.pageId || data?.edit?.pageId,
            roleId: data?.create?.data?.role?.roleId || data?.read?.data?.role?.roleId || data?.edit?.data?.role?.roleId,
            permission: permission.join(';')
          });
        }
      });
      if (!dataCallApi.length) {
        return;
      }
      // callApi({
      //   method: 'patch',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/role`,
      //   // params: {
      //   //   // campusId: campus?.id || undefined,
      //   // },
      //   data: { data: dataCallApi },
      // })
      authApi.updateRole({ data: dataCallApi })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: isAdmin ? 'Update Successfully' : 'Send for Approvals Successfully',
              message: 'Update role Successfully',
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Submit Failed',
              // message: error.response?.data?.message || error,
            });
            setIsShowToastMessage(true);
          }
        }).catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Submit Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Campus Information Role Permissions Error', error);
        });
    }
  };

  // useEffect(() => {
  //   const getProcessCreateRole = callApi({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/search`,
  //     params: { actionId: 1, campusId: campus?.id },
  //   });
  //   const getProcessUpdateRole = callApi({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/search`,
  //     params: { actionId: 2, campusId: campus?.id },
  //   });

  //   const getDrafts = callApi({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/role/draft/list`,
  //     params: {
  //       campusId: campus?.id,
  //       companyId: userLogin?.companyId,
  //     },
  //   });

  //   Promise.all([getProcessCreateRole, getProcessUpdateRole, getDrafts])
  //     .then((res) => {
  //       if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
  //         setIsCreateRole(!!Object.keys(res[0].data.data || {}).length);
  //         setIsUpdateRole(!!Object.keys(res[1].data.data || {}).length);

  //         const drafts = res[2].data.data.objects;
  //         setDraftsList(drafts);
  //         setNumberOfEdits(res[2].data.data.total);
  //       }
  //     })
  //     .catch((error) => {
  //       console.log('Get Process Failed', error);
  //     });
  // }, [campus]);

  useEffect(() => {
    const campusId = campus.isCentral ? undefined : campus?.id
    const getRolePagePermission = authApi.getPageWithRolePermission({ campusId });
    const listAPI = [getRolePagePermission];
    if (isRenderDraft) {
      const getDraftRolePagePermission = authApi.getDraftRolePagePermission({
        campusId,
        companyId: userLogin?.companyId,
      }, draftId)
      listAPI.push(getDraftRolePagePermission);
    }

    Promise.all(listAPI)
      .then((res) => {
        const { data } = res[0];
        if (!data.success) {
          setToastMessage({
            status: 'error',
            title: 'Get Pages Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        } else {
          let dataDraft = [];

          if (isRenderDraft) {
            dataDraft = JSON.parse(res[1].data.data.data);
          }

          const dataApi = data.data;
          const { modules, roleNames } = getRoleNameAndModule(dataApi);
          // const dataPermission = getDataPermission(dataApi, roleNames, dataDraft);
          const dataPermission = newGetDataPermission(dataApi, roleNames, dataDraft);
          const openModule = {};
          modules.forEach(({ module }) => {
            openModule[module] = true;
          });
          setListModule(modules);
          setListRole(roleNames);
          setListPermission(dataPermission);
          setPermissionOrigin(JSON.stringify(dataPermission));
          setOpenPage(openModule);
          setOpenPermission(openModule);
          setLoginTypes(listLoginType);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Pages Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Campus Information Role Permissions Error', error);
      });
  }, [refresh, isRenderDraft, campus]);

  useEffect(() => {
    if (listPermission.length !== 0) {
      setEdited(permissionOrigin !== JSON.stringify(listPermission));
      setIsCreateRole(true);
    }
  }, [listPermission]);

  // handle when you click a role's crumbs:
  const openPopupRoleDetail = (role) => {
    setShowPopupCreate((pre) => !pre);
    setRoleDetail(role);
  };
  return (
    <>
      <div
        className={clsx(
          'campus-information pr-4 pl-10 transition-all-300 pb-24',
          burger ? '1400px:pl-[17.5rem]' : '1400px:pl-[7.5rem]',
        )}
      >
        <NavLinks urls={dataLinks.rolePermissionLinks} />
        <div className="flex items-center justify-between">
          <h1 className="namepage">Roles and Permissions</h1>
          <Button
            text="Create a Role"
            onClick={() => {
              setShowPopupCreate((prev) => !prev);
              setRoleDetail(null);
            }}
            className="relative bottom-2 text-sm"
          />
        </div>
        <div
          className={clsx(
            visiblePanel ? 'pointer-events-none' : 'pointer-events-auto w-full',
          )}
        >
          <EditCreateRole
            visible={visiblePanel}
            setVisible={setVisiblePanel}
            campus={{}}
          />
        </div>

        <div className="flex items-center w-full mb-12"></div>
        <RolesPermissions
          openPopupRoleDetail={openPopupRoleDetail}
          burger={burger}
          isApproval={false}
          listModule={listModule}
          listRole={listRole}
          listPermission={listPermission}
          openPage={openPage}
          openPermission={openPermission}
          handleOnChange={handleOnChange}
          handleClickArrow={handleClickArrow}
        />
      </div>
      {(numberOfEdits > 0 || edited) && (
        <Footer
          isStorybook={true}
          burger={burger}
          isApproval={!isAdmin && isShowTextApproval}
          numberOfEdits={numberOfEdits}
          draftsList={draftsList}
          handleSaveAsDraft={handleSaveAsDraft}
          handleClickCancel={handleClickCancel}
          handelSendForApproval={handelSendForApproval}
          setDraftId={setDraftId}
          setIsRenderDraft={setIsRenderDraft}
        />
      )}
      {showPopupCreate && (
        <PopupRole
          roleDetail={roleDetail}
          showPopupCreate={showPopupCreate}
          isCreate
          isAdmin={isAdmin}
          handleClickClose={handleClickCreate}
          loginTypes={loginTypes}
          setRefresh={setRefresh}
        />
      )}
    </>
  );
}
