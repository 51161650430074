const calcCustomFee = (termsArray = []) => {
    let sum = 0;
    termsArray.forEach((term) => {
      sum += +term.fee;
    });
    return sum;
  };

  const calcEstManualFee = (
    feeTypeFeeValue,
    bookKitFeeValue,
    admissionFeeValue,
    feeType,
    isBookFeeActive,
    admission,
    termsArray = [],
  ) => {
    let sum = 0;
    if (feeType !== 'Custom' && feeType !== 'Term Dates') {
      sum += +feeTypeFeeValue;
    } else {
      sum += calcCustomFee(termsArray);
    }
    sum += isBookFeeActive ? +bookKitFeeValue : 0;
    sum += admission ? +admissionFeeValue : 0;
    return sum;
  };

  export { calcCustomFee, calcEstManualFee };