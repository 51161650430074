import clsx from 'clsx';
import { useContext, useEffect, useState } from 'react';
import { CircularProgressbarWithChildren, buildStyles } from 'react-circular-progressbar';
import { useHistory } from 'react-router';

import ScholarshipItem from '@components/admission-module/application/documents/scholarship-item';
import { callApi, handleCallApiFail } from '@helper/call-api';
import {
  academic,
  board_fund,
  partner_company,
  partner_school,
  principal_fund,
  sport,
} from '@static/image';
import { Button } from '@stories/index';
import {
  checkErrorApiFetch,
  checkErrorMultipleApiFetch,
} from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { EnquiryStatus } from 'entities/data';
import admissionApi from 'api/admission';
import financeApi from 'api/finance';

const styles = {
  circle: buildStyles({
    textSize: '1.5rem',
    pathTransitionDuration: 5,
    pathColor: `#008060`,
    textColor: 'black',
    trailColor: 'rgba(226,226,226,0.2)',
    backgroundColor: '#3e98c7',
  }),
};

const listDataRender = [
  { id: 1, icon: sport, title: 'Sports' },
  { id: 2, icon: academic, title: 'Academic' },
  { id: 3, icon: partner_school, title: 'Partner School' },
  { id: 4, icon: partner_company, title: 'Partner Company' },
  { id: 5, icon: principal_fund, title: 'Principal Fund' },
  { id: 6, icon: board_fund, title: 'Board Fund' },
  { id: 7, icon: board_fund, title: 'Sibbling Scholarship' },
];

function ChildScholarship({
  enquiry,
  applicationId = '43633e95-b72b-4b35-b040-02eabd0a820b',
  percent,
  setStep = () => { },
}) {
  const [admissionResult, setAdmissionResult] = useState({});
  const [listScholarShip, setListScholarShip] = useState([
    {
      info: '',
      documents: '',
      scholarshipId: null,
    },
  ]);
  const [listScholarShipMemo, setListScholarShipMemo] = useState([]);
  const [scholarshipFinance, setScholarshipFinance] = useState({});
  const [listScholarShipDelete, setListScholarShipDelete] = useState([]);
  const [listScholarShipIds, setListScholarShipIds] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const history = useHistory();

  const handleCallApiSuccess = (response) => {
    if (response.data.success) {
      setToastMessage({
        status: 'success',
        title: 'Update Scholarship Successfully',
        message: response.data.message,
      });
      setIsShowToastMessage(true);
      history.push('/bloomingdale-child-finance', {
        enquiry: enquiry,
        applicationId: applicationId,
      });
    } else {
      setToastMessage({
        status: 'error',
        title: 'Update Scholarship Failed',
        message: response.data.message,
      });
      setIsShowToastMessage(true);
    }
  };

  const handleNextStep = () => {
    if (
      !admissionResult.documentVerification ||
      admissionResult.documentVerification?.isPending
    ) {
      setToastMessage({
        status: 'warning',
        title: 'Waiting for admission process',
        message: `The current status is ${enquiry.status} at step ${enquiry.currentStep}`,
      });
      setIsShowToastMessage(true);
      return;
    }
    if (
      listScholarShipDelete.length === 0 &&
      JSON.stringify(listScholarShip) === JSON.stringify(listScholarShipMemo)
    ) {
      // setToastMessage({
      //   status: 'info',
      //   title: 'Validation Rule',
      //   message: 'Please select scholarship!',
      // });
      // setIsShowToastMessage(true);
      // return;
      history.push('/bloomingdale-child-finance', {
        enquiry: enquiry,
        applicationId: applicationId,
      });
      return;
    }
    const idx = listScholarShip.findIndex((scholarship) => !scholarship.scholarshipId);
    // if (idx !== -1) {
    //   setToastMessage({
    //     status: 'info',
    //     title: 'Validation Rule',
    //     message: 'Please select scholarship!',
    //   });
    //   setIsShowToastMessage(true);
    //   return;
    // }
    const listScholarShipUpdate = [];
    if (
      JSON.stringify([
        {
          info: '',
          documents: '',
          scholarshipId: null,
        },
      ]) !== JSON.stringify(listScholarShip)
    ) {
      listScholarShip.forEach((scholarship) => {
        if (!scholarship.id) {
          listScholarShipUpdate.push(scholarship);
        } else {
          const idx = listScholarShipMemo.findIndex((sch) => {
            return sch.id === scholarship.id;
          });
          if (
            idx !== -1 &&
            JSON.stringify(scholarship) !== JSON.stringify(listScholarShipMemo[idx])
          ) {
            listScholarShipUpdate.push({
              documents: scholarship.documents,
              info: scholarship.info,
              scholarshipId: scholarship.scholarshipId,
            });
          }
        }
      });
    }
    if (listScholarShipUpdate.length > 0 && listScholarShipDelete.length > 0) {
      // const deleteReq = callApi({
      //   method: 'delete',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/scholarship/parent/${applicationId}`,
      //   data: {
      //     applicationScholarshipIds: listScholarShipDelete,
      //   },
      //   params: { campusId: enquiry?.campusId },
      // });
      // const updateReq = callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/scholarship/parent`,
      //   data: { applicationId: applicationId, scholarships: listScholarShipUpdate },
      //   params: { campusId: enquiry?.campusId },
      // });
      const deleteReq = admissionApi.deleteApplicationScholarshipParent1({ campusId: enquiry?.campusId }, { applicationScholarshipIds: listScholarShipDelete }, applicationId)
      const updateReq = admissionApi.updateApplicationScholarshipParent({ campusId: enquiry?.campusId }, { applicationId: applicationId, scholarships: listScholarShipUpdate })
      Promise.all([deleteReq, updateReq])
        .then((res) => {
          if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
            setRefresh((prev) => !prev);
            setToastMessage({
              status: 'success',
              title: 'Update Scholarship Successfully',
              message: 'success',
            });
            setIsShowToastMessage(true);
            // setIsCallAPI((prev) => !prev);
            history.push('/bloomingdale-child-finance', {
              enquiry: enquiry,
              applicationId: applicationId,
            });
          }
        })
        .catch((error) => {
          handleCallApiFail(
            error,
            'Update Scholarship Failed',
            setToastMessage,
            setIsShowToastMessage,
          );
        });
    } else if (listScholarShipUpdate.length > 0) {
      // callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/scholarship/parent`,
      //   data: { applicationId: applicationId, scholarships: listScholarShipUpdate },
      //   params: { campusId: enquiry?.campusId },
      // })
      admissionApi.updateApplicationScholarshipParent({ campusId: enquiry?.campusId }, { applicationId: applicationId, scholarships: listScholarShipUpdate })
        .then(handleCallApiSuccess)
        .catch((error) => {
          handleCallApiFail(
            error,
            'Update Scholarship Failed',
            setToastMessage,
            setIsShowToastMessage,
          );
        });
    } else if (listScholarShipDelete.length > 0) {
      // callApi({
      //   method: 'delete',
      //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/application/scholarship/parent/${applicationId}`,
      //   data: {
      //     applicationScholarshipIds: listScholarShipDelete,
      //   },
      //   params: { campusId: enquiry?.campusId },
      // })
      admissionApi.deleteApplicationScholarshipParent1({ campusId: enquiry?.campusId }, { applicationScholarshipIds: listScholarShipDelete }, applicationId)
        .then(handleCallApiSuccess)
        .catch((error) => {
          handleCallApiFail(
            error,
            'Update Scholarship Failed',
            setToastMessage,
            setIsShowToastMessage,
          );
        });
    }
  };

  useEffect(() => {
    if (!!enquiry?.campusId) {
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/scholarship`,
      //   params: { campusId: enquiry.campusId },
      // })
      financeApi.getListScholarship({ campusId: enquiry.campusId })
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Scholarship Finance ',
            )
          ) {
            setScholarshipFinance(res.data.data);
          }
        })
        .catch((error) => {
          handleCallApiFail(
            error,
            'Get Data Failed',
            setToastMessage,
            setIsShowToastMessage,
          );
        });
    }
  }, []);

  useEffect(() => {
    if (enquiry?.currentStep === EnquiryStatus.COMPLETE) {
      const admissionResult = JSON.parse(enquiry.admissionResult.info);
      setAdmissionResult(admissionResult);
      const scholarships = admissionResult.documentVerification?.scholarship || [];
      if (scholarships.length > 0) {
        setListScholarShip(scholarships);
      }
      setListScholarShipMemo(scholarships);
      return;
    }
    enquiry.id &&
      admissionApi.getEnquiryParent(enquiry.id)
        .then((res) => {
          const { data } = res;
          if (data.success) {
            const admissionResult = JSON.parse(data.data.admissionResult.info);
            setAdmissionResult(admissionResult);
            const scholarships = admissionResult.documentVerification?.scholarship
              ? admissionResult.documentVerification.scholarship
              : [];
            if (scholarships.length > 0) {
              setListScholarShip(scholarships);
            }
            setListScholarShipMemo(scholarships);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get Enquiry Failed',
              message: data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          console.log('Get Application Scholarship Error', error);
          setToastMessage({
            status: 'error',
            title: 'Get Application Scholarship Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
        });
  }, [enquiry, refresh]);

  const checkIdInArr = (arr = [], id) => {
    if (arr.length === 0) {
      return -1;
    }
    const findId = arr.findIndex((data) => {
      return data === id;
    });
    return findId;
  };

  return (
    <div>
      <div className="flex items-center justify-between w-[92%]">
        <div className="mb-10 ml-[5%]">
          <h3 className="font-medium text-2xl mb-3">Scholarship</h3>
          <p className="text-xs" style={{ width: '17.375rem' }}>
            If the candidate qualifies for a scholarship please update relevant documents
            to check elgibility
          </p>
        </div>
        <div className="w-20 h-20">
          <CircularProgressbarWithChildren value={percent} styles={styles.circle}>
            <div
              className="flex flex-col items-center text-xs"
              style={{ marginTop: '-0.313rem' }}
            >
              <strong>{percent}%</strong>
              <p>Complete</p>
            </div>
          </CircularProgressbarWithChildren>
        </div>
      </div>
      <div className="flex flex-wrap items-center justify-center">
        {listScholarShip.length > 0 &&
          listScholarShip.map((data, idx) => {
            return (
              <div className="w-[70%] mx-auto" key={idx}>
                <ScholarshipItem
                  scholarshipFinance={scholarshipFinance}
                  listScholarShip={listScholarShip}
                  listScholarShipMemo={listScholarShipMemo}
                  idxScholarship={idx}
                  scholarship={data}
                  setListScholarShip={setListScholarShip}
                  setListScholarShipDelete={setListScholarShipDelete}
                />
              </div>
            );
          })}
        {listScholarShip.length === 0 && (
          <div className="w-[70%] mx-auto">
            <ScholarshipItem
              idxScholarship={0}
              setListScholarShip={setListScholarShip}
              listScholarShip={[
                {
                  info: '',
                  documents: '',
                  scholarshipId: null,
                },
              ]}
            />
          </div>
        )}
      </div>
      <div className="flex justify-center my-10">
        <Button
          onClick={() => {
            setListScholarShip((prev) => {
              const data = [...prev];
              data.push({
                info: '',
                documents: '',
                scholarshipId: null,
              });
              return data;
            });
          }}
          text="Add an another Scholarship"
          buttonStyle="outlined"
          customStyle={{
            width: '17rem',
            paddingLeft: '1.5rem',
            paddingRight: '0.5rem',
          }}
        />
      </div>
      <div className={clsx('flex items-center justify-center mt-3.5 mb-6 justify-end')}>
        <Button
          onClick={() => {
            setStep(false);
          }}
          text="Previous"
          buttonStyle="outlined"
          customStyle={{ width: '15rem', paddingLeft: '5rem', paddingRight: '2.5rem' }}
        />
        <Button
          text="Next Step"
          // buttonDisabled={!isEdit}
          customStyle={{
            paddingLeft: '3.438rem',
            paddingRight: '3.438rem',
            marginLeft: '1.25rem',
          }}
          onClick={handleNextStep}
        />
      </div>
    </div>
  );
}

export default ChildScholarship;
