import { navbarTitle } from "entities/data";
import { campusAdmissionRoutes, campusFinanceRoutes, campusSettingRoutes, centralAdmissionRoutes, centralApprovalRoutes, centralFinanceRoutes, centralIntegrationRoutes, centralSettingRoutes, centralSetupRoutes, giikiAdminRoutes } from "entities/routes";
import { 
    BASE_CAMPUS_ADMISSION_URL,
    BASE_CAMPUS_FINANCE_URL,
    BASE_CAMPUS_SETTING_URL,
    BASE_CENTRAL_ADMISSION_URL,
    BASE_CENTRAL_APPROVAL_URL,
    BASE_CENTRAL_COMMUNICATION_URL,
    BASE_CENTRAL_FINANCE_URL,
    BASE_CENTRAL_INTEGRATION_URL,
    BASE_CENTRAL_SETUP_URL,
    BASE_GIIKI_URL
} from "entities/urls";

export const getPanelTitleAndRoutes = (path) => {
    if (path.includes(BASE_GIIKI_URL + '/')) return {
        title: navbarTitle.ADMIN,
        routes: giikiAdminRoutes
    };
    if (path.includes(BASE_CENTRAL_ADMISSION_URL + '/')) return {
        title: navbarTitle.CENTRAL_ADMISSIONS,
        routes: centralAdmissionRoutes
    }
    if (path.includes(BASE_CENTRAL_SETUP_URL + '/')) return {
        title: navbarTitle.CENTRAL_BASIC_SETUP,
        routes: centralSetupRoutes
    };
    if (path.includes(BASE_CENTRAL_FINANCE_URL + '/')) return {
        title: navbarTitle.CENTRAL_FINANCE,
        routes: centralFinanceRoutes
    };
    if (path.includes(BASE_CENTRAL_APPROVAL_URL + '/')) return {
        title: navbarTitle.CENTRAL_APPROVALS,
        routes: centralApprovalRoutes
    };
    if (path.includes(BASE_CENTRAL_COMMUNICATION_URL + '/')) return {
        title: navbarTitle.CENTRAL_COMMUNICATION,
        routes: centralSettingRoutes
    };
    if (path.includes(BASE_CENTRAL_INTEGRATION_URL + '/')) return {
        title: navbarTitle.CENTRAL_INTEGRATIONS,
        routes: centralIntegrationRoutes
    };
    if (path.includes(BASE_CAMPUS_ADMISSION_URL + '/')) return {
        title: navbarTitle.CAMPUS_ADMISSIONS,
        routes: campusAdmissionRoutes
    };
    if (path.includes(BASE_CAMPUS_FINANCE_URL + '/')) return {
        title: navbarTitle.CAMPUS_FINANCE,
        routes: campusFinanceRoutes
    };
    if (path.includes(BASE_CAMPUS_SETTING_URL + '/')) return {
        title: navbarTitle.CAMPUS_SETTINGS,
        routes: campusSettingRoutes
    };
    return {
        title: 'Campus',
        routes: campusSettingRoutes
    };
}