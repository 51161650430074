import './partner-card.scss';

export default function AddPartnerCard(props) {
  const { setScholarship, optionType } = props
  return (
    <div
      className="partner-card partner-card-add min-h-[14.5rem]"
      onClick={() => {
        setScholarship((prev) => {
          return {
            ...prev,
            partnerDiscount: {
              ...prev.partnerDiscount,
              items: [...prev.partnerDiscount.items, {
                partnerName: 'Partner Name',
                partnerType: 'Fixed Percentage',
                value: 0,
                discountType: optionType['Fixed Percentage']
              }]
            }
          };
        });
      }}
    >
      <span className="text-3xl">+</span>
      <span className="text-base">Add Partner Card</span>
    </div>
  )
}