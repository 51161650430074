import React, { useState } from 'react';
import Fade from 'react-reveal/Fade';
import clsx from 'clsx';

import EditPanel from '../edit-panel/edit-panel.jsx';
import { Button } from '../../stories';
import './footer.scss';

export default function Footer(props) {
  const {
    isStorybook = false,
    isSaveDraft = true,
    handleSaveAsDraft,
    isCreateRole,
    dataRoleLogs,
    handleClickCancel,
    handleClickEdit,
    isDisableButton,
    handelSendForApproval,
    // isAdmin,
    isApproval,
    draftsList,
    numberOfEdits,
    setIsRenderDraft,
    setDraftId,
    burger,
    ...other
  } = props;
  const [visiblePanel, setVisiblePanel] = useState(false);

  const styles = {
    span: { fontSize: '0.875rem' },
    button: { color: '#FF4842' },
    buttonDisabled: { color: 'gray', cursor: 'not-allowed' },
  };

  const editHandle = () => setVisiblePanel(!visiblePanel);

  if (isStorybook) {
    return (
      <div className={clsx(`footer-storybook w-full transition-all-300`, burger && 'pl-[15rem]')}>
        <div className={clsx(visiblePanel ? 'pointer-events-none' : 'pointer-events-auto')}>
          <EditPanel
            visible={visiblePanel}
            setVisible={setVisiblePanel}
            draftsList={draftsList}
            setDraftId={setDraftId}
            setIsRenderDraft={setIsRenderDraft}
          />
        </div>
        <Fade clear duration={300}>
          <div className='footer-storybook-row'>
            <button className='footer-storybook-row-button' onClick={() => setVisiblePanel(!visiblePanel)}>
              <span>{numberOfEdits}</span>
              <span style={styles.span}>Edits</span>
            </button>
            <Button
              buttonStyle='text'
              buttonSize='large'
              text='Cancel'
              customStyle={styles.button}
              onClick={handleClickCancel}
            />
            <Button
              buttonStyle='text'
              buttonSize='large'
              text='Save as Draft'
              onClick={handleSaveAsDraft}
            />
            <Button
              text={isApproval ? 'Send for Approval' : 'Save'}
              buttonSize='large'
              onClick={handelSendForApproval}
            />
          </div>
        </Fade>
      </div>
    )
  }

  if (!isStorybook) {
    return (
      <div className="footer" {...other}>
        <div className={clsx(visiblePanel ? 'pointer-events-none' : 'pointer-events-auto')}>
          <EditPanel
            visible={visiblePanel}
            setVisible={setVisiblePanel}
            data={dataRoleLogs}
          />
        </div>
        <Fade clear duration={300}>
          <div className="footer-row">
            {!isCreateRole && (
              <button className="footer-row-button" onClick={handleClickEdit || editHandle}>
                <span style={styles.span}>Edits</span>
              </button>
            )}
            <Button
              buttonDisabled={isDisableButton || false}
              buttonStyle="text"
              buttonSize="large"
              text="Cancel"
              customStyle={isDisableButton ? styles.buttonDisabled : styles.button}
              onClick={handleClickCancel}
            />
            {isSaveDraft && (
              <Button
                buttonDisabled={isDisableButton || false}
                buttonStyle="text"
                buttonSize="large"
                text="Save as Draft"
                customStyle={
                  isDisableButton ? styles.buttonDisabled : { cursor: 'pointer' }
                }
                onClick={handleSaveAsDraft}
              />
            )}
            {!isSaveDraft && <span>or</span>}
            <Button
              buttonDisabled={isDisableButton || false}
              // text={isAdmin ? 'Update role' : 'Send for Approval'}
              text={isApproval ? 'Send for Approval' : 'Save'}
              buttonSize="large"
              customStyle={isDisableButton ? styles.buttonDisabled : { cursor: 'pointer' }}
              onClick={handelSendForApproval}
            />
          </div>
        </Fade>
      </div>
    );
  }

}
