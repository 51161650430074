import { callApi } from '@helper/call-api';

class AdmissionWhatsappApi {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/whatsapp`;
  }

  list = ({ campusId, enquiryId, phoneNumber, signal }) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/list`,
      params: { campusId, enquiryId, phoneNumber },
      signal,
    });
  };

  send = async ({ data, params }) => {
    return await callApi({
      method: 'post',
      url: this.baseUrl,
      data,
      params,
    });
  };

  notificationStreaming = () => {
    return new EventSource(`${this.baseUrl}/notification`);
  };

  listByDate = async ({ campusId, startDate, endDate }) => {
    // startDate: mm/dd/yyyy
    // endDate: mm/dd/yyyy
    return await callApi({
      method: 'get',
      url: `${this.baseUrl}/list-by-date`,
      params: { campusId, startDate, endDate },
    });
  };
}

const admissionWhatsappApi = new AdmissionWhatsappApi();
export default admissionWhatsappApi;
