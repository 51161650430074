import { callApi } from "@helper/call-api"

class GradeApi {
  getGrade = async () => {
    try {
      const res = await callApi({
        method: 'get',
        url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/grade/list`
      })
      return { res }
    } catch (error) {
      return { error }
    }
  }
}

const gradeApi = new GradeApi()
export default gradeApi