import clsx from 'clsx';
import React, { useContext, useEffect, useState } from 'react';
import { Fade } from 'react-reveal';
import { RadioButton } from '@components/radio-button/radio-button';
import { ic_user_group, mainavatar } from '@static/image';
import { Button, Checkbox, Chip, Input, SelectGroup } from '@stories/index';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { colorOptionForUserSelect } from 'entities/colorOption';
import { selectStylesWithNoTitle3, selectStylesWithTitle } from 'entities/select-style';
import './leads-distribution.scss';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const orderingOptions = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
  { value: '5', label: '5' },
  { value: '6', label: '6' },
  { value: '7', label: '7' },
  { value: '8', label: '8' },
  { value: '9', label: '9' },
  { value: '10', label: '10' },
];

const distributionTypeOptions = [
  { value: 'round robin', label: 'Round Robin' },
  { value: 'workload', label: 'Workload' },
];

export default function LeadsDistributionPanel(props) {
  const {
    visible,
    setVisible,
    distribution,
    getDataFormAPi,
    currentPage,
    selectedRowsPerPage,
  } = props;
  const campus = useStorage((state) => state.currentCampus);
  const [isShowCriteria, setIsShowCriteria] = useState(false);
  const [membersOrRolesOptions, setMembersOrRolesOptions] = useState([]);
  const [selectedMembersOrRoles, setSelectedMembersOrRoles] = useState([]);
  const [selectedDistributionType, setSelectedDistributionType] = useState();
  const [selectedOrdering, setSelectedOrdering] = useState();
  const [isProcessOther, setIsProcessOther] = useState(false);
  const [conditions, setConditions] = useState([]);
  const [terms, setTerms] = useState();
  const [customLogic, setCustomLogic] = useState(' ');
  const [isExecuteWhenSpecifiedChanges, setIsExecuteWhenSpecifiedChanges] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  useEffect(() => {
    // const getRoles = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/role/get-role-of-company-or-campus`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // });
    const getRoles = centralAdminApi.getRoleOfCompanyOrCampus({ campusId: campus?.isCentral ? undefined : campus?.id });
    // const getUsers = callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/user/get-users-of-company-or-campus`,
    //   params: { excludeRoleNames: 'Student;Parent' },
    // });
    const getUsers = centralAdminApi.getUserOfCompanyOrCampus({ excludeRoleNames: 'Student;Parent' });

    Promise.all([getRoles, getUsers])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const roles = res[0].data.data;
          const users = res[1].data.data.objects;
          const options = [];

          roles.forEach((role, key) => {
            if (role.name != 'Parent' && role.name != 'Student') {
              options.push({
                value: role.id,
                label: role.name,
                color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
                info: { id: role.id, name: role.name, photoURL: ic_user_group },
              });
            }
          });
          users.forEach((user, key) => {
            options.push({
              value: user.name,
              label: user.name,
              color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
              info: { ...user, isUser: true },
            });
          });

          setMembersOrRolesOptions(options);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Leads Distribution Error', error);
      });

    const {
      distributionType,
      ordering,
      isProcessOther,
      conditions,
      rotateBetweenRole,
      rotateBetweenUser,
      terms,
      customLogic,
      isExecuteWhenSpecifiedChanges,
    } = distribution;

    setSelectedMembersOrRoles([
      ...rotateBetweenRole.map((role, key) => ({
        value: role.role.id,
        label: role.role.name,
        color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
        info: { ...role.role, photoURL: ic_user_group },
      })),
      ...rotateBetweenUser.map((user, key) => ({
        value: user.users.name,
        label: user.users.name,
        color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
        info: user.users,
      })),
    ]);

    setSelectedDistributionType({
      value: distributionType,
      label: distributionType,
    });

    setSelectedOrdering({
      value: ordering,
      label: ordering,
    });

    setIsProcessOther(isProcessOther);
    setConditions(conditions);
    setTerms(terms);
    setCustomLogic(customLogic);
    setIsExecuteWhenSpecifiedChanges(isExecuteWhenSpecifiedChanges);
    setIsShowCriteria(conditions.length > 0);
  }, [distribution]);

  const formatOptionLabelUser = ({ value, label, info }) => {
    return (
      <div className="block">
        <Chip
          key={info?.id}
          label={label}
          image={info?.photoURL || mainavatar}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  };

  const handleOnClickCancel = () => {
    setVisible(false);
    getDataFormAPi(currentPage, selectedRowsPerPage);
  };
  const handleOnClickSave = async () => {
    setVisible(false);

    const rotateUsers = [];
    const rotateRoles = [];

    const data = {
      name: distribution.name,
      category: distribution.category,
      distributionType: selectedDistributionType.value,
      ordering: +selectedOrdering.value,
      isProcessOther,
      conditions,
      terms,
      customLogic,
      isExecuteWhenSpecifiedChanges,
    };

    if (distribution.isNew) {
      selectedMembersOrRoles.forEach((item) => {
        if (item.info.isUser) {
          rotateUsers.push(item.info.id);
        } else {
          rotateRoles.push(item.info.id);
        }
      });
      // await callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/distribution`,
      //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
      //   data: {
      //     ...data,
      //     rotates: {
      //       users: rotateUsers,
      //       roles: rotateRoles,
      //     },
      //   },
      // })
      await centralAdminApi.createDistribution({ campusId: campus?.isCentral ? undefined : campus?.id }, {
        ...data,
        rotates: {
          users: rotateUsers,
          roles: rotateRoles,
        },
      })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Add Distribution Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Add Distribution Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Add Distribution Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Leads Distribution Error', error);
        });
    } else {
      const { rotateBetweenUser, rotateBetweenRole } = distribution;
      const LENGTH_ROLE = 3;

      rotateBetweenRole.forEach((oldRole) => {
        const result = selectedMembersOrRoles.findIndex(
          (newItem) => newItem.info.id === oldRole.role.id,
        );
        if (result === -1) {
          rotateRoles.push({
            roleId: oldRole.role.id,
            isDbDelete: true,
          });
        } else {
          rotateRoles.push({
            roleId: oldRole.role.id,
            isDbDelete: false,
          });
        }
      });

      rotateBetweenUser.forEach((oldUser) => {
        const result = selectedMembersOrRoles.findIndex(
          (newItem) => newItem.info.id === oldUser.users.id,
        );
        if (result === -1) {
          rotateUsers.push({
            userId: oldUser.users.id,
            isDbDelete: true,
          });
        } else {
          rotateUsers.push({
            userId: oldUser.users.id,
            isDbDelete: false,
          });
        }
      });

      selectedMembersOrRoles.forEach((newItem) => {
        const resultRole = rotateBetweenRole.findIndex(
          (oldRole) => oldRole.role.id === newItem.info.id,
        );
        if (resultRole === -1 && Object.keys(newItem.info).length === LENGTH_ROLE) {
          rotateRoles.push({
            roleId: newItem.info.id,
          });
        }

        const resultUser = rotateBetweenUser.findIndex(
          (oldUser) => oldUser.users.id === newItem.info.id,
        );
        if (resultUser === -1 && Object.keys(newItem.info).length !== LENGTH_ROLE) {
          rotateUsers.push({
            userId: newItem.info.id,
          });
        }
      });

      // await callApi({
      //   method: 'PATCH',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/distribution/${distribution.id}`,
      //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
      //   data: {
      //     ...data,
      //     rotates: {
      //       users: rotateUsers,
      //       roles: rotateRoles,
      //     },
      //   },
      // })
      await centralAdminApi.updateDistribution({ campusId: campus?.isCentral ? undefined : campus?.id }, {
        ...data,
        rotates: {
          users: rotateUsers,
          roles: rotateRoles,
        },
      })
        .then((res) => {
          if (res.data.success) {
            setToastMessage({
              status: 'success',
              title: 'Update Distribution Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Update Distribution Failed',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Update Distribution Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Leads Distribution Error', error);
        });
    }
    getDataFormAPi(currentPage, selectedRowsPerPage);
  };

  const DropdownIcon = () => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M4 12L0 8H8L4 12ZM4 0L8 4H0L4 0Z" fill="#5C5F62" />
    </svg>
  )

  const toggleButtonStyles = {
    btnBlue: {
      width: '340px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: '#404eed',
      marginTop: '1rem',
      marginBottom: '1rem',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '26px',
    },
    btnWhite: {
      width: '340px',
      height: '40px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#404eed',
      backgroundColor: 'white',
      marginTop: '1rem',
      marginRight: '1rem',
      marginBottom: '1rem',
      border: '1px solid #404eed',
      fontWeight: '500',
      fontSize: '14px',
      lineHeight: '26px',
    },
  };

  return (
    <Fade right duration={500}>
      <div
        style={{ left: 'unset' }}
        className={clsx(
          'leads-distribution-panel',
          visible ? 'pointer-events-auto' : 'pointer-events-none hidden',
        )}
      >
        <div className="flex items-center justify-between mt-6 mb-9">
          <div className="flex items-center flex-auto w-32">
            <div>
              <h2 className="heading-grade-name text-2xl semi-bold leading-5">Leads Distribution</h2>
            </div>
          </div>
          <button className="mr-5" onClick={() => setVisible(false)}>
            <Close />
          </button>
        </div>
        <hr className="mt-2" />
        <div className="w-full h-[calc(100%-7rem)]">
          <div className="flex-row overflow-y-auto overflow-x-hidden h-full w-full">
            <div>
              <h3 className="distribution-heading">1. General Info</h3>
              <p className="distribution-content">
                Please select the teams and type of distribution
              </p>
              <div className="flex flex-wrap justify-between">
                <div className="distribution-left">
                  <h4 className="distribution-subtitle">Assign to</h4>
                  <SelectGroup
                    title="Members or Roles"
                    value={selectedMembersOrRoles}
                    options={membersOrRolesOptions}
                    isMulti={true}
                    isSearchable={true}
                    styles={selectStylesWithTitle}
                    onChange={(e) => {
                      setSelectedMembersOrRoles(e);
                    }}
                    formatOptionLabel={formatOptionLabelUser}
                    customStyle={{
                      marginTop: '12px',
                      width: '100%',
                      borderRadius: '0.375rem',
                      fontSize: 14,
                      fontSize: '0.875rem',
                    }}
                  />
                </div>
                <div className="distribution-right">
                  <h4 className="distribution-subtitle">Distribution Type</h4>
                  <SelectGroup
                    isSearchable={true}
                    value={selectedDistributionType}
                    options={distributionTypeOptions}
                    styles={selectStylesWithNoTitle3}
                    onChange={(e) => {
                      setSelectedDistributionType(e);
                    }}
                    customStyle={{
                      marginTop: '12px',
                      width: '100%',
                      borderRadius: '0.375rem',
                      fontSize: 14,
                      fontSize: '0.875rem',
                    }}
                    dropDown={<DropdownIcon />}
                  />
                </div>
              </div>
              <div className="flex flex-wrap justify-between items-end">
                <div className="distribution-left">
                  <h4 className="distribution-subtitle">Ordering</h4>
                  <SelectGroup
                    isSearchable={true}
                    value={selectedOrdering}
                    options={orderingOptions}
                    styles={selectStylesWithNoTitle3}
                    onChange={(e) => {
                      setSelectedOrdering(e);
                    }}
                    customStyle={{
                      marginTop: '0.5rem',
                      width: '100%',
                      borderRadius: '0.375rem',
                      fontSize: 14,
                      fontSize: '0.875rem',
                    }}
                    dropDown={<DropdownIcon />}
                  />
                </div>
                <div className="distribution-right flex mb-1">
                  <Checkbox
                    value={isProcessOther}
                    onChange={(e) => {
                      setIsProcessOther(e.target.checked);
                    }}
                  />
                  <span className="distribution-sub-content">
                    Process other Distribution rules for Leads
                  </span>
                </div>
              </div>
            </div>
            <hr className="mt-6" />
            <div>
              <h3 className="distribution-heading">2. Criteria</h3>
              <p className="distribution-content">
                If you need this distribution rule to apply in a specific criteria please
                select
              </p>
              {!isShowCriteria && (
                <button
                  className="distribution-add-item"
                  onClick={() => {
                    setConditions((conditions) => {
                      return [
                        ...conditions,
                        { index: 1, field: '', operator: '', type: '', value: '' },
                      ];
                    });
                    setIsShowCriteria(true);
                  }}
                >
                  <div className="distribution_btn-plus">
                    <span>+</span>
                  </div>
                  Add Criteria
                </button>
              )}
              {isShowCriteria && (
                <Criteria
                  conditions={conditions}
                  setConditions={setConditions}
                  terms={terms}
                  setTerms={setTerms}
                  customLogic={customLogic}
                  setCustomLogic={setCustomLogic}
                  isExecuteWhenSpecifiedChanges={isExecuteWhenSpecifiedChanges}
                  setIsExecuteWhenSpecifiedChanges={setIsExecuteWhenSpecifiedChanges}
                  setIsShowCriteria={setIsShowCriteria}
                />
              )}
            </div>
          </div>
          <div className="flex justify-center absolute bottom-0 left-0 right-0 gap-8">
            <Button
              text="Cancel"
              customStyle={toggleButtonStyles.btnWhite}
              onClick={handleOnClickCancel}
            />
            <Button
              text="Save"
              customStyle={toggleButtonStyles.btnBlue}
              onClick={handleOnClickSave}
            />
          </div>
        </div>
      </div>
    </Fade>
  );
}

const Criteria = (props) => {
  const {
    conditions,
    setConditions,
    terms,
    customLogic,
    isExecuteWhenSpecifiedChanges,
    setTerms,
    setCustomLogic,
    setIsExecuteWhenSpecifiedChanges,
    setIsShowCriteria,
  } = props;

  const radioChangeHandler = (e) => {
    setTerms(e.target.value);
  };

  const styleInputCustomLogic = {
    width: '100%',
    padding: '0.25rem',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '1.5rem',
  };
  return (
    <div className="criteria">
      <div className="criteria-header">
        <h4 className="criteria-header_title">Conditions</h4>
        <button
          onClick={(e) => {
            setIsShowCriteria(false);
            setConditions([]);
          }}
        >
          <CloseSecondary />
        </button>
      </div>
      <div>
        <Condition conditions={conditions} setConditions={setConditions} />
      </div>
      <div className="criteria-body">
        <div className="criteria-body_bottom">
          <h4 className="criteria-header_title mt-5">Terms</h4>
        </div>
        <div>
          <RadioButton
            onChange={radioChangeHandler}
            id="and"
            checked={terms === 'AND'}
            label="All Conditions are met (AND)"
            value="AND"
            className={'criteria-body_option'}
          />
          <RadioButton
            onChange={radioChangeHandler}
            id="or"
            checked={terms === 'OR'}
            label="Any of the conditions are met (OR)"
            value="OR"
            className={'criteria-body_option'}
          />
          <div>
            <RadioButton
              onChange={radioChangeHandler}
              id="custom"
              checked={terms === 'CUSTOM'}
              label="Custom Logic"
              value="CUSTOM"
              className={'criteria-body_option'}
            />
            {terms === 'CUSTOM' && (
              <Input
                label={''}
                placeholder={'Enter Custom Logic'}
                value={customLogic}
                customStyle={styleInputCustomLogic}
                onChange={(e) => {
                  setCustomLogic(e.target.value);
                }}
              />
            )}
          </div>
        </div>
        <div className="criteria-body_bottom mt-5">
          <Checkbox
            value={isExecuteWhenSpecifiedChanges}
            onChange={(e) => {
              setIsExecuteWhenSpecifiedChanges(e.target.checked);
            }}
          />
          <span className="-ml-1 criteria-body_option">
            Do you want to execute the actions only when specified changes are made to the
            record?
          </span>
        </div>
      </div>
    </div>
  );
};

const Condition = ({ conditions, setConditions }) => {
  const styleInput = {
    title: {
      width: '2.5rem',
      marginRight: '0.5rem',
      height: '2.5rem',
      textAlign: 'center',
      backgroundColor: '#404EED',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      color: '#fff',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    content: {
      width: '95%',
      // marginBottom: '0.5rem',
      marginRight: '0.5rem',
      height: '2.5rem',
      fontFamily: 'Inter',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
  };

  const handleOnDelete = (indexDelete) => {
    setConditions(conditions.filter((condition) => condition.index !== indexDelete));
  };

  const handleOnAdd = () => {
    const index = conditions.length + 1;
    setConditions([
      ...conditions,
      { index, field: '', operator: '', type: '', value: '' },
    ]);
  };

  return (
    <>
      <table className="w-full criteria-conditions">
        <thead>
          <tr className="">
            <th align="left" className="criteria-conditions_title"></th>
            <th align="left" className="criteria-conditions_title">
              Field
            </th>
            <th align="left" className="criteria-conditions_title">
              Operator
            </th>
            <th align="left" className="criteria-conditions_title">
              Type
            </th>
            <th align="left" className="criteria-conditions_title">
              Value
            </th>
          </tr>
        </thead>
        <tbody>
          {conditions?.map((condition, key) => {
            const { index, field, operator, type, value } = condition;
            return (
              <React.Fragment key={key}>
                <tr className="font-medium text-sm mt-2">
                  <td>
                    <Input
                      label={''}
                      value={key + 1}
                      disabled={true}
                      onChange={(e) => { }}
                      customStyle={styleInput.title}
                    />
                  </td>
                  <td>
                    <Input
                      label={''}
                      value={field}
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        const newConditions = [...conditions];
                        newConditions[key].field = e.target.value;
                        newConditions[key].index = key + 1; // update index
                        setConditions(newConditions);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      label={''}
                      value={operator}
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        const newConditions = [...conditions];
                        newConditions[key].operator = e.target.value;
                        setConditions(newConditions);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      label={''}
                      value={type}
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        const newConditions = [...conditions];
                        newConditions[key].type = e.target.value;
                        setConditions(newConditions);
                      }}
                    />
                  </td>
                  <td>
                    <Input
                      label={''}
                      value={value}
                      customStyle={styleInput.content}
                      onChange={(e) => {
                        const newConditions = [...conditions];
                        newConditions[key].value = e.target.value;
                        setConditions(newConditions);
                      }}
                    />
                  </td>
                  <td>
                    <button
                      className="pr-2 ml-2"
                      onClick={() => {
                        handleOnDelete(index);
                      }}
                    >
                      <Close />
                    </button>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
      <button className="flex items-center" onClick={handleOnAdd}>
        <CreateIcon />
        <span className="criteria-btn-content">Add Row</span>
      </button>
    </>
  );
};

const CreateIcon = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.9981 1V13"
        stroke="#404EED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1 6.99785H13"
        stroke="#404EED"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const CloseSecondary = () => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5 7.72485H19"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 7.72485V18.7249C18 19.8299 17.105 20.7249 16 20.7249H8C6.895 20.7249 6 19.8299 6 18.7249V7.72485"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15 4.48486H9"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.8699 16.0947L10.1299 12.3547"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.1299 16.0947L13.8699 12.3547"
        stroke="#EB5757"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

const Close = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16 1.4L14.6 0L8 6.6L1.4 0L0 1.4L6.6 8L0 14.6L1.4 16L8 9.4L14.6 16L16 14.6L9.4 8L16 1.4Z"
        fill="#2F80ED"
      />
    </svg>
  );
};
