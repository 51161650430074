import React, { useState, useEffect, useContext, useMemo, useCallback } from 'react';

import RenderForm from '@components/formbuilder/render';
import { callApi } from '@helper/call-api';
import { ToastMessageContext } from 'context/toast-context';
import { Button } from '@stories/index';
import {
  validateName,
  variantName,
  promiseUploadPhoto,
} from '@components/formbuilder/utils';
import { validEmail, validPhone } from '@utils/validate';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { sortObj } from '@utils/utils';
import { EnquiryStatus } from 'entities/data';
import admissionApi from 'api/admission';

function ChildCustomStage(props) {
  const { formId, campusId, stageName, enquiry, handleNextStage, handleRefreshAndNext } = props
  const [objForm, setObjForm] = useState([]);
  const [objInfoOriginal, setObjInfoOriginal] = useState({});
  // const [latestEnquiry, setLatestEnquiry] = useState({});
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [refreshForm, setRefreshForm] = useState(0);

  const { applicationId, infoStage } = useMemo(() => {
    if (enquiry?.admissionResult?.info) {
      const admissionResultInfo = JSON.parse(enquiry.admissionResult.info);
      let applicationId = admissionResultInfo?.application?.id;
      let infoStage = admissionResultInfo?.application?.additionalInfo?.[stageName]?.data;
      return { applicationId, infoStage };
    }
    return { applicationId: null, infoStage: null };
  }, [stageName, enquiry]);


  const styles = useMemo(
    () => ({
      btnBlue: {
        width: '10.563rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: 'white',
        backgroundColor: '#404eed',
        height: '2.563rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        marginLeft: '2rem',
      },
      btnWhite: {
        width: '6.563rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        color: '#404eed',
        backgroundColor: 'white',
        height: '2.563rem',
        marginTop: '1rem',
        marginBottom: '1rem',
        border: '1px solid #404eed',
      },
      input: {
        height: '2.5rem',
      },
    }),
    [],
  );

  useEffect(() => {
    admissionApi.getFormBuilder({ campusId }, formId)
      .then((res) => {
        if (res.data.success) {
          const json = res.data.data.formContentString;
          if (json) {
            const jsonParse = JSON.parse(json);

            if (!!infoStage) {
              Object.keys(jsonParse).forEach((key) => {
                jsonParse[key].info = infoStage[key];
              });
              setObjInfoOriginal(JSON.parse(JSON.stringify(infoStage)));
            } else {
              const tmpInfo = {};
              Object.keys(jsonParse).forEach((key) => {
                tmpInfo[key] = JSON.parse(JSON.stringify(jsonParse[key].info));
              });
              setObjInfoOriginal(tmpInfo);
            }
            setObjForm(jsonParse);
            setRefreshForm((prev) => prev + 1);
          }
        }
      })
      .catch((error) => {
        console.log('error', error);
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Form Builder Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  }, [formId, stageName, infoStage]);

  const handleClickCancel = useCallback(() => {
    Object.keys(objForm).forEach((key) => {
      objForm[key].info = objInfoOriginal[key];
    });
    setRefreshForm((prev) => prev + 1);
  }, [objForm, objInfoOriginal]);

  const validateSingleFiled = useCallback(({ isRequired, validate, value }) => {
    let textError = '';
    let isError = false;

    if (isRequired && !value) {
      isError = true;
      textError = 'Field is required. Please fil out!';
      return { textError, isError };
    } else {
      isError = false;
      textError = '';
    }

    if (validate === validateName.Email) {
      if (!validEmail(value)) {
        isError = true;
        textError = 'Email is invalid';
      }
    } else if (validate === validateName['Phone Number']) {
      if (!validPhone(value)) {
        isError = true;
        textError = 'Phone Number is invalid';
      }
    } else {
      isError = false;
      textError = '';
    }

    return { textError, isError };
  }, []);

  const checkValidation = useCallback(
    (objData) => {
      let isValid = true;
      Object.keys(objData).forEach((key) => {
        const { info, validationRule } = objData[key];
        validationRule.forEach((item, index) => {
          const { isRequired, validate } = item;
          const value = info[index].value;
          const { textError, isError } = validateSingleFiled({
            isRequired,
            validate,
            value,
          });
          info[index].error = isError;
          info[index].textForError = textError;
          if (isError) {
            isValid = false;
          }
        });
      });
      return isValid;
    },
    [validateSingleFiled],
  );

  const getIndexPhoto = useCallback((objData) => {
    const indexPhoto = {};
    const promisePhoto = [];

    Object.keys(objData).forEach((key) => {
      const { info } = objData[key];
      const tmpPhoto = [];
      info.forEach((item, index) => {
        const { variationOf, value } = item;
        if (variationOf === variantName.photo) {
          if (value?.name) {
            tmpPhoto.push(index);
            promisePhoto.push(promiseUploadPhoto(value));
          }
        }
      });

      indexPhoto[key] = tmpPhoto;
    });
    return { indexPhoto, promisePhoto };
  }, []);

  const handleSave = useCallback(async () => {
    try {
      if ([EnquiryStatus.CLOSED, EnquiryStatus.COMPLETE].includes(enquiry.status)) {
        handleNextStage()
        return
      }

      const objFormSorted = sortObj(objForm);
      const isValid = checkValidation(objFormSorted);
      setRefreshForm((prev) => prev + 1);
      if (!isValid) {
        setToastMessage({
          status: 'warning',
          title: 'Validation',
          message: 'A little field is invalid. Please check!',
        });
        setIsShowToastMessage(true);
        return;
      }
      const { indexPhoto, promisePhoto } = getIndexPhoto(objFormSorted);

      const listRes = await Promise.all(promisePhoto);
      Object.keys(indexPhoto).forEach((key) => {
        const item = indexPhoto[key];
        if (item && item.length !== 0) {
          item.forEach((index) => {
            const url = listRes.shift().data.data;
            objFormSorted[key].info[index].value = url;
          });
        }
      });

      const objInfo = {};
      Object.keys(objFormSorted).forEach((key) => (objInfo[key] = objForm[key].info));
      const data = {
        data: {
          [stageName]: {
            data: objInfo,
          },
        },
      };

      const res = await admissionApi.updateCustomStageParent({ campusId }, data, applicationId);
      if (
        checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Submit Stage')
      ) {
        handleRefreshAndNext();
      }
    } catch (error) {
      console.log('Submit Stage error', error);
      setToastMessage({
        status: 'error',
        title: 'Submit Stage Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }, [
    applicationId,
    campusId,
    checkValidation,
    getIndexPhoto,
    objForm,
    setIsShowToastMessage,
    setToastMessage,
    stageName,
  ]);

  if (objForm.length === 0) return <div></div>;
  return (
    <div className="w-full px-6">
      <h3 className="font-semibold text-lg mb-10 text-main-blue">
        {stageName}
      </h3>
      <RenderForm data={objForm} refreshOutside={refreshForm} />
      <div className="flex justify-center fixed bottom-3 left-[50%]" style={{ transform: 'translateX(-50%)' }}>
        <Button
          text="Cancel"
          customStyle={styles.btnWhite}
          onClick={handleClickCancel}
        />
        <Button
          text={[EnquiryStatus.CLOSED, EnquiryStatus.COMPLETE].includes(enquiry.status) ? 'Next Step' : 'Save and next'}
          customStyle={styles.btnBlue}
          onClick={handleSave}
        />
      </div>
    </div>
  );
}

export default ChildCustomStage;
