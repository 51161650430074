export * from './list-time';

export const documentSettingStatus = {
  disabled: 'Disabled',
  optional: 'Optional',
  required: 'Required',
};

export const PlaceTypes = {
  postal_code: 'postal_code',
  country: 'country',
  administrative_area_level_1: 'administrative_area_level_1',
  locality: 'locality',
};

export const EVENT_CALL_TYPE = {
  ORIGINATE: 'ORIGINATE',
  AGENT_CALL: 'AGENT_CALL',
  AGENT_ANSWER: 'AGENT_ANSWER',
  CUSTOMER_CALL: 'CUSTOMER_CALL',
  CUSTOMER_ANSWER: 'CUSTOMER_ANSWER',
  HANGUP: 'HANGUP',
  CDR: 'CDR',
};

export const CALL_RESULT_TYPE = {
  Phone: 'Phone',
  'Agent Missed': 'Agent Missed',
  'Customer Missed': 'Customer Missed',
  Disconnect: 'Disconnect',
  Completed: 'Completed',
  Missed: 'Missed',
};

export const CALL_TYPE = {
  incoming: 0,
  outgoing: 1,
};

export const HOME_PATH = '/home';

export const COMPANY_GIIKI_ID = '17ba321d-4fa2-434d-9d1c-1915114d953c';

export const GIIKI_ADMIN_DASHBOARD_PATH = '/admin/dashboard';

export const MESSAGE_STATUS = {
  sent: 'sent',
  failed: 'failed',
  delivered: 'delivered',
};

export const listTypeOfRole = ['Admin', 'Parent', 'Student', 'Teacher/Staff'];
