import React, { useState, useEffect } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { useAuth } from '../../../pages/Login/components/UserLogin/firebaseSignin';
import { dashboard, icsettings } from '../../../static/image';
import './header.scss';
import useStorage from 'store/storage';

export default function BloomHeader() {
  const location = useLocation();
  const userLogin = useStorage((state) => state.auth);

  const [visible, setVisible] = useState({
    profile: false,
    notification: false,
    navapp: false,
    options: false,
  });

  const [crumbs, setCrumbs] = useState(false);

  useEffect(() => {
    location.pathname.search('bloomingdale-dashboard') !== -1
      ? setCrumbs(true)
      : setCrumbs(false);
  }, [location]);
  const { signOut } = useAuth();
  return (
    <div className="w-full h-12 flex items-center justify-between pl-7 fixed top-0 bg-white z-10">
      <div className="flex items-center">
        <NavLink to="/bloomingdale-welcome">
          <div className="bloom-logo" />
        </NavLink>
        {crumbs && (
          <NavLink to="/bloomingdale-welcome">
            <div className="flex items-center ml-20">
              <button className="transition-all text-sm font-semibold none-after hover:text-main-blue">
                Home
              </button>
            </div>
          </NavLink>
        )}
      </div>
      <div
        onClick={() => {
          setVisible({
            notification: false,
            navapp: false,
            options: false,
            profile: !visible.profile,
          });
        }}
        className="flex items-center cursor-pointer"
      >
        <p className="font-medium text-sm mr-2" style={{ color: '#96969a' }}>
          {userLogin?.displayName}
        </p>
        <img
          className="header-search-nav-profile_img mr-5"
          src={`${userLogin?.photoURL}`}
          alt="profile"
        />
      </div>
      <div className="absolute top-[3.5rem] right-2 w-[14rem] shadow-lg">
        {visible.profile && (
          <div className="cursor-pointer border bg-[#fff] rounded-lg">
            <div className="border-b p-4 pl-5 flex items-center justify-between">
              {/* <div>
                <img
                  className="header-search-nav-profile_img mr-5"
                  src={`${userLogin?.photoURL}`}
                  alt="profile"
                />
              </div> */}
              <div>
                <p className="text-main-black text-base font-semibold leading-4">
                  {userLogin?.displayName}
                </p>
                <p className="text-second-gray text-sm">{userLogin?.email}</p>
              </div>
            </div>
            <div className="flex flex-col items-center justify-start mt-4 ">
              <div className="flex flex-col items-start">
                {[
                  { img: dashboard, name: 'Dashboard', link: '/bloomingdale-welcome' },
                  {
                    img: icsettings,
                    name: 'Change Password',
                    link: '/settings/change-password',
                  },
                ].map((item, key) => (
                  <NavLink
                    onClick={() => {
                      setVisible({
                        notification: false,
                        navapp: false,
                        options: false,
                        profile: !visible.profile,
                      });
                    }}
                    key={key}
                    to={item.link}
                    className="profile-box-card_link my-2 py-1"
                  >
                    <img
                      src={item.img}
                      alt={item.name}
                      className="relative inline-block mr-1.5 h-6 w-6"
                      style={{ bottom: 1 }}
                    />
                    {item.name}
                  </NavLink>
                ))}
              </div>
              <button
                onClick={signOut}
                className="flex items-center justify-center font-semibold border rounded-lg w-52 h-9 my-4"
              >
                Logout
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
