import { mainavatar } from "@static/image";
import { call_success, call_fall } from "@components/application-panel/image";
import PlayAudio from "@components/application-panel/sub-components/play-audio";
import { CALL_RESULT_TYPE, CALL_TYPE } from "@constant/index";
import convertDateToFormat from "@helper/convertDateToFormat";
import DefaultAvatar from "@components/default-avatar";

const SingleCall = (props) => {
  const { data, applicationParents, className } = props

  const call_recording = data?.recordUrl || ""
  const call_duration = data?.duration || 0

  const notes = data?.data ? JSON.parse(data.data)?.notes : ""
  const agentName = data?.createdBy?.name
  const roleName = data?.createdBy?.roles?.[0]?.name || ''
  let avatar = data?.createdBy?.photoURL || ''
  let parent = Object.values(applicationParents).find(item => item.parentUserId === data?.parentId)
  let relationship = parent?.relationship

  if (data.Call_Type === CALL_TYPE.incoming) {
    parent = applicationParents?.[data?.customer_number]
    relationship = parent?.relationship
    avatar = parent?.photoURL
  }


  const StatusComponent = () => {
    if (data.Call_Type === 0) {
      // Outgoing Call
      if (data?.call_recording)
        return <Status text={'Completed'} color={'#00664D'} bgColor={'#00806029'} />

      return <Status text={`Missed`} color={'#EB5757'} bgColor={'#FED3D1'} />
    } else {
      // Incoming Call
      if (data?.status === CALL_RESULT_TYPE['Agent Missed'])
        return <Status text={`Agent Missed`} color={'#EB575Call made by7'} bgColor={'#FED3D1'} />

      if (data?.status === CALL_RESULT_TYPE['Customer Missed'])
        return <Status text={`Customer Missed`} color={'#EB5757'} bgColor={'#FED3D1'} />

      return <Status text={'Completed'} color={'#00664D'} bgColor={'#00806029'} />
    }
  }

  const getStatus = () => {
    if (data.Call_Type === 0) {
      // Outgoing Call
      if (data?.call_recording)
        return CALL_RESULT_TYPE.Completed
      return CALL_RESULT_TYPE.Missed
    } else {
      // Incoming Call
      if ([CALL_RESULT_TYPE['Agent Missed'], CALL_RESULT_TYPE['Customer Missed']].includes(data?.status))
        return CALL_RESULT_TYPE.Missed
      return CALL_RESULT_TYPE.Completed
    }
  }

  const callIcon = {
    [CALL_RESULT_TYPE.Completed]: call_success,
    [CALL_RESULT_TYPE.Missed]: call_fall,
  }

  return (
    <div className={`maincard w-full p-4 block text-[10px] ${className} h-fit`}>
      <div className={'flex items-center'}>
        <img src={callIcon[getStatus()]} />
        <div className={'ml-2'}>
          <div className={'flex items-center gap-x-2'}>
            <p className={'text-[#212B36] text-xs font-medium'}>
              {data?.Call_Type === 0 ? `Call made by ${relationship} to ` : `Call made to ${relationship}`}
            </p>
            {StatusComponent()}
          </div>

          <div className={'flex items-center'}>
            {avatar ? (
              <img className={'w-5 aspect-square mr-2'} src={avatar || mainavatar} />
            ) : (
              <DefaultAvatar title={parent?.fullName} className='w-5 aspect-square mr-1 rounded-full' />
            )}

            <p className={'text-[#637381]'}>
              {data.Call_Type !== 1 && agentName && roleName ?
                `${agentName} | ${roleName} | ${convertDateToFormat(data?.start_time || data?.dateCreated, 'DD/MM/YYYY hh:mm a')}`
                : `${convertDateToFormat(data?.start_time || data?.dateCreated, 'DD/MM/YYYY hh:mm a')}`}
            </p>
          </div>
        </div>
      </div>

      {call_recording && (
        <>
          <textarea className={'resize-none pointer-events-none text-[#8C9094] bg-[#D9E6E9] mt-3 rounded-lg w-full px-2 py-3 h-[4rem]'}
            defaultValue={notes}
          />
          <PlayAudio url={call_recording} duration={call_duration} />
        </>
      )}

    </div>)
}

export default SingleCall;

const Status = (props) => {
  const { text, color, bgColor } = props

  return (<p className={'py-0.5 px-1.5 rounded-lg text-[10px] font-bold'}
    style={{ color: `${color}`, backgroundColor: `${bgColor}` }}>
    {text}
  </p>)
}