import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Fade } from 'react-reveal';
import { useHistory, useLocation } from 'react-router-dom';
import Select from 'react-select';
import { selectGroup, selectStylesWithTitle } from '../../entities/select-style';
import { ToastMessageContext } from '../../context/toast-context';
import { dataLinks } from '../../entities/routes';
import { compareString } from '../../helper/compare-string';
import { mainavatar, staticsize } from '../../static/image';
import { Button, Chip, Input, SelectGroup, Upload } from '../../stories';
import { NavLinks } from '../Header/components/index';
import { selectStylesApprovers } from 'entities/select-style';
import { newPlus } from '@static/image';
import { companyStatus, listDataCategoryRole } from '@utils/constant';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import './create-user.scss';
import authApi from 'api/auth';
import mediaApi from 'api/media';
import centralAdminApi from 'api/central-admin';

const USER_ROLE = {
  TEACHER: 'Teacher',
  STUDENT: 'Student',
  PARENT: 'Parent',
};

const colorOption = ['#404EED', '#663174', '#F2C94C', '#00AB55', '#FFA48D'];

export default function CreateUser({ burger }) {
  const styles = {
    btnBlue: {
      width: '6.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: 'white',
      backgroundColor: '#404eed',
      height: '2.563rem',
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    btnWhite: {
      width: '6.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#404eed',
      backgroundColor: 'white',
      height: '2.563rem',
      marginTop: '1rem',
      marginBottom: '1rem',
      border: '1px solid #404eed',
    },
    btnAddLocation: {
      width: '6.563rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: '#404eed',
      backgroundColor: 'white',
      height: '2.563rem',
      border: '1px solid #404eed',
      margin: "auto",
    },
  };

  const [user, setUser] = useState({});
  const [avatar, setAvatar] = useState('');
  const [isLogo, setIsLogo] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const [formData, setFormData] = useState(new FormData());

  const [companyId, setCompanyId] = useState('');
  const [campusId, setCampusId] = useState('');

  const [roleSelected, setRoleSelected] = useState({});
  const [children, setChildren] = useState([]);
  const [childrenSelected, setChildrenSelected] = useState([]);
  const [addRoleForTeacher, setAddRoleForTeacher] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const [dataLocationAndRole, setDataLocationAndRole] = useState([]);

  const [dataCategoryRole, setDataCategoryRole] = useState(listDataCategoryRole);
  const [dataCategoryRoleSelected, setDataCategoryRoleSelected] = useState(listDataCategoryRole[0]);
  const [listLocations, setListLocations] = useState([]);
  const [listRoles, setListRoles] = useState([]);

  useEffect(() => {
    if (location.state) {
      location.state.companyId && setCompanyId(location.state.companyId);
      location.state.campusId && setCampusId(location.state.campusId);
      let getListLocations = authApi.getListCampusUser({
        companyId: location.state.companyId,
        isGetHQCampus: true
      });
      if (!location?.state?.campusId) {
        getListLocations = authApi.getListCompany({
          companyId: location.state.companyId,
          status: companyStatus[0]
        })
      }
      const getListRole = authApi.getRoleOfCompanyOrCampus({
        companyId: location.state.companyId,
        campusId: location.state.campusId,
      })
      Promise.all([getListRole, getListLocations])
        .then((res) => {
          if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage, 'Fetch List Role Or List Campus')) {
            if (res?.[0]?.data?.data?.length) {
              const listRoles = res[0].data.data.map((data, key) => ({
                label: data.name,
                value: data.id,
                color: colorOption[key % colorOption.length],
              }));
              setListRoles(listRoles);
            };
            if (res?.[1]?.data?.data?.objects?.length) {
              const listLocationsFromApi = res[1].data.data.objects.map(data => ({
                value: data.id,
                label: data.name,
                info: data,
                isCampus: !!location.state.campusId
              }));
              setListLocations(listLocationsFromApi);
            }
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Roles Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Create User Error', error);
        });
    }
  }, []);


  const [error, setError] = useState({
    statusFirstName: false,
    errorMessageFirstName: 'Please enter the first name',
    statusLastName: false,
    errorMessageLastName: 'Please enter the last name',
    statusEmail: false,
    errorMessageEmail: 'Please enter the email',
    statusPhoneNumber: false,
    errorMessagePhoneNumber: 'Please enter the phone number',
  });

  useEffect(() => {
    if (compareString(roleSelected.info?.name, USER_ROLE.PARENT)) {
      centralAdminApi.getUserOfCompanyOrCampus({
        campusId,
        filterRoleNames: USER_ROLE.STUDENT,
      })
        .then((res) => {
          const { data } = res;
          if (data.success) {
            setChildren(data.data.objects);
          } else {
            setToastMessage({
              status: 'error',
              title: 'Get Users Failed',
              message: data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Get Users Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Create User Error', error);
        });
    }
    if (!compareString(roleSelected?.info?.name, USER_ROLE.TEACHER)) {
      setAddRoleForTeacher([]);
    }

    if (!compareString(roleSelected?.info?.name, USER_ROLE.PARENT)) {
      setChildrenSelected([]);
    }
  }, [roleSelected, campusId]);

  const listStudentRender = children?.map((item, key) => {
    return {
      value: item.name,
      label: item.name,
      color: colorOption[key % colorOption.length],
      info: {
        photoURL: item.photoURL,
        ...item,
      },
    };
  });

  const formatOptionLabelUser = ({ value, label, info }) => {
    return (
      <div className="block">
        <Chip
          key={info?.id}
          label={label}
          image={info?.photoURL || info?.logo}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
          customStyle={{ padding: '0 !important', margin: '0', fontSize: '0.875rem' }}
        />
      </div>
    );
  };

  const handlerOnClickCancel = () => {
    setAvatar(staticsize);
    setUser({});
    history.goBack();
  };

  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const validPhone = new RegExp('^([+][s0-9]+)(d{3}|[(]?[0-9]+[)])?([-]?[s]?[0-9])+$');
  // const validateLocationAndRole = () => {
  //   let isErr = false;
  //   for (let i = 0; i < locationAndRole.length; i++) {
  //     if (Object.keys(dataLocationAndRole[i].location).length === 0) {
  //       locationAndRole[i].isErrorLocation = true;
  //       isErr = true;
  //     } else {
  //       locationAndRole[i].isErrorLocation = false;
  //     }
  //     if (dataLocationAndRole[i].role.length === 0) {
  //       locationAndRole[i].isErrorRole = true;
  //       isErr = true;
  //     } else {
  //       locationAndRole[i].isErrorRole = false;
  //     }
  //   }
  //   return isErr;
  // };

  const handleOnClickSave = async () => {
    let errorData = {};
    errorData = { ...errorData, statusFirstName: !user.firstName };
    errorData = { ...errorData, statusLastName: !user.lastName };
    errorData = { ...errorData, statusEmail: !validEmail.test(user.email) };
    errorData = { ...errorData, statusPhoneNumber: !validPhone.test(user.phoneNumber) };
    setError({ ...error, ...errorData });
    if (
      errorData.statusFirstName ||
      errorData.statusLastName ||
      errorData.statusEmail ||
      errorData.statusPhoneNumber
    ) {
      setToastMessage({
        status: 'error',
        title: 'Missing Field',
        message: 'Missing User Information Field',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (Object.keys(dataLocationAndRole).length === 0) {
      setToastMessage({
        status: 'error',
        title: 'Missing Field',
        message: 'Missing Location and Role',
      });
      setIsShowToastMessage(true);
      return;
    }
    const length = dataLocationAndRole.length;
    for (let i = 0; i < length; ++i) {
      if (!dataLocationAndRole[i]?.location) {
        setToastMessage({
          status: 'error',
          title: 'Missing Field',
          message: 'Missing Location',
        });
        setIsShowToastMessage(true);
        return;
      }
      if (!dataLocationAndRole[i]?.role?.length) {
        setToastMessage({
          status: 'error',
          title: 'Missing Field',
          message: 'Missing Role',
        });
        setIsShowToastMessage(true);
        return;
      }
    }

    let logo = '';

    if (isLogo) {
      try {
        const resultUploadImage = await mediaApi.uploadMedia(formData);
        if (resultUploadImage.data.success) {
          logo = resultUploadImage.data.data;
        } else {
          setToastMessage({
            status: 'error',
            title: 'Upload Image Failed',
            message: resultUploadImage.data.message,
          });
          setIsShowToastMessage(true);
        }
      } catch (error) {
        setToastMessage({
          status: 'error',
          title: 'Upload Image Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Create User Error', error);
      }
    }

    // const addRoleIdsForTeacher = addRoleForTeacher
    //   ? addRoleForTeacher.map((item) => item.info.id)
    //   : [];
    // addRoleIdsForTeacher.push(roleSelected.id ?? roleSelected.info.id);

    // const studentUserIds = childrenSelected
    //   ? childrenSelected.map((item) => item.id)
    //   : null;

    // if (validateLocationAndRole()) {
    //   setLocationAndRole([...locationAndRole]);
    //   return;
    // }

    const listDataLocationAndRoleCallApi = dataLocationAndRole.map(item => {
      const listRoleIds = item.role;
      const listRoleIdsCallApi = (listRoleIds || []).map(role => {
        return role.value;
      });
      return {
        location: {
          id: item.location.value,
          isCampus: item.location.isCampus
        },
        roles: listRoleIdsCallApi || []
      }
    });

    const data = {
      ...user,
      photoURL: logo,
      dataLocationAndRole: listDataLocationAndRoleCallApi,
    };
    authApi.createUser({ companyId, campusId }, data)
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setToastMessage({
            status: 'success',
            title: 'Add User Successfully',
            message: data.message,
          });
          setIsShowToastMessage(true);
          history.goBack();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Add User Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Add User Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Create User Error', error);
      });
  };

  return (
    // <div className={clsx('create-user pr-24', burger ? 'pl-69' : 'pl-20')}>
    <div className="create-user px-8 w-full">
      <div className="min-w-[50%]">
        <Fade clear duration={300}>
          <NavLinks urls={dataLinks.createUserLinks} />
          <h1 className="namepage">Add User</h1>
          <div className="create-user-form">
            <div className="create-user-form_section create-user-section">
              <div className="create-user-section_header">
                <h2 className="create-user-section_title">1. User Photo</h2>
                <p className="create-user-section_text mt-2">
                  Upload a user image to make it easily recognizable.
                </p>
              </div>
              <div className="create-user-upload">
                <img
                  className="create-user-upload_image"
                  src={avatar ? `${avatar}` : staticsize}
                  alt="uploaded img"
                />
                <Upload
                  text="Upload Image"
                  id="image-upload"
                  accept="image/*"
                  onChange={(e) => {
                    let selected = e.target.files;
                    if (selected && selected[0]) {
                      let file = selected[0];
                      setIsLogo(true);
                      formData.set('file', file);
                      formData.set('mediaId', file.name);
                      setFormData(formData);
                      let reader = new FileReader();
                      reader.readAsDataURL(file);
                      reader.onload = () => {
                        setAvatar(reader.result);
                      };
                      reader.onerror = function (error) {
                        console.log('Error: ', error);
                      };
                    }
                  }}
                />
              </div>
            </div>
            <div className="create-user-form_section create-user-section">
              <div className="create-user-section_header">
                <h2 className="create-user-section_title">2. User Details</h2>
                <div className="flex items-center mt-3">
                  <span className="create-user-section_text">Add/ Edit</span>
                  <Select
                    className="ml-2 mr-2 text-sm w-40"
                    placeholder="Role"
                    defaultValue={dataCategoryRoleSelected}
                    value={dataCategoryRoleSelected}
                    isDisabled={dataCategoryRole.length === 1 ? true : false}
                    styles={{ selectGroup }}
                    options={dataCategoryRole}
                    isSearchable={true}
                    onChange={(e) => {
                      setDataCategoryRoleSelected(e);
                    }}
                  />
                  <span className="create-user-section_text">
                    campus details including name, address and other essentials.
                  </span>
                </div>
              </div>
              <div className="create-user-section_content">
                <div className="flex">
                  <Input
                    onChange={(e) => {
                      setUser({ ...user, firstName: e.target.value });
                      const errorData = { statusFirstName: !e.target.value };
                      setError({ ...error, ...errorData });
                    }}
                    label="First Name"
                    name="campus-name"
                    value={user?.firstName ?? ''}
                    customStyle={{
                      width: '14.25rem',
                      height: '2.5rem',
                      fontSize: '0.875rem',
                      borderRadius: '0.25rem',
                    }}
                    error={error.statusFirstName}
                  />
                  <Input
                    onChange={(e) => {
                      setUser({ ...user, lastName: e.target.value });
                      const errorData = { statusLastName: !e.target.value };
                      setError({ ...error, ...errorData });
                    }}
                    label="Last Name"
                    name="campus-code"
                    value={user?.lastName ?? ''}
                    labelFix="left-10"
                    customStyle={{
                      width: '14.25rem',
                      height: '2.5rem',
                      marginLeft: '1.75rem',
                      fontSize: '0.875rem',
                      borderRadius: '0.25rem',
                    }}
                    error={error.statusLastName}
                  />
                </div>
                <Input
                  onChange={(e) => {
                    setUser({ ...user, email: e.target.value });
                    const errorData = { statusEmail: !validEmail.test(e.target.value) };
                    setError({ ...error, ...errorData });
                  }}
                  label="Email"
                  name="address"
                  value={user?.email ?? ''}
                  customStyle={{
                    width: '30.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                  error={error.statusEmail}
                />
                <Input
                  onChange={(e) => {
                    setUser({ ...user, phoneNumber: e.target.value });
                    const errorData = { statusPhoneNumber: !validPhone.test(e.target.value) };
                    setError({ ...error, ...errorData });
                  }}
                  label="Phone Number"
                  name="phone"
                  value={user?.phoneNumber ?? ''}
                  customStyle={{
                    width: '30.25rem',
                    height: '2.5rem',
                    fontSize: '0.875rem',
                    borderRadius: '0.25rem',
                  }}
                  error={error.statusPhoneNumber}
                />
              </div>
              {compareString(roleSelected?.info?.name, USER_ROLE.PARENT) && (
                <div className="create-user-section_header">
                  <h2 className="create-user-section_title">3. Family Details</h2>
                  <div className="mt-2">
                    <p className="create-user-section_text mb-4">
                      Add the children information below
                    </p>
                    <SelectGroup
                      customStyle={{ width: '30.25rem' }}
                      title="Children"
                      placeholder=""
                      labelFix={{
                        left: '0.3125rem',
                        fontSize: '1rem',
                      }}
                      formatOptionLabel={formatOptionLabelUser}
                      defaultValue={childrenSelected}
                      value={childrenSelected}
                      options={listStudentRender}
                      isMulti={true}
                      isSearchable={true}
                      styles={selectStylesWithTitle}
                      onChange={(e) => {
                        setChildrenSelected(e);
                      }}
                    />
                  </div>
                </div>
              )}
              {dataLocationAndRole.length > 0 &&
                dataLocationAndRole.map(
                  ({ location, role, isErrorLocation, isErrorRole }, index) => {
                    return (
                      <div key={index} className="flex gap-1 max-h-26">
                        <div>
                          <SelectGroup
                            error={isErrorLocation}
                            classNameGroup="mt-6 mb-6 text-sm z-3"
                            customStyle={{ width: '15.25rem' }}
                            title="Location"
                            placeholder=""
                            labelFix={{ left: 5, top: 3, fontSize: '120%' }}
                            // defaultValue={location}
                            value={location}
                            options={listLocations}
                            isMulti={false}
                            isSearchable={true}
                            // styles={selectStylesWithTitle}
                            formatOptionLabel={formatOptionLabelUser}
                            styles={{
                              ...selectStylesApprovers,
                              singleValue: (styles, { data }) => {
                                return {
                                  ...styles,
                                  backgroundColor: data.color || '#404EED',
                                  color: 'white',
                                  borderRadius: '25px',
                                  paddingTop: '0.25rem',
                                  paddingBottom: '0.25rem',
                                };
                              },
                            }}
                            onChange={(e) => {
                              setDataLocationAndRole((prev) => {
                                prev[index].location = e;
                                return [...prev];
                              });
                            }}
                          />
                        </div>
                        <SelectGroup
                          error={isErrorRole}
                          classNameGroup="mt-6 mb-6 text-sm z-3"
                          customStyle={{ width: '30.25rem' }}
                          title="Role"
                          placeholder=""
                          labelFix={{ left: 5, top: 3, fontSize: '120%' }}
                          // defaultValue={undefined}
                          value={role}
                          options={listRoles}
                          isMulti={true}
                          isSearchable={true}
                          // formatOptionLabel={formatOptionLabelUser}
                          styles={selectStylesWithTitle}
                          // styles={selectStylesApprovers}
                          onChange={(e) => {
                            setDataLocationAndRole((prev) => {
                              prev[index].role = e;
                              return [...prev];
                            });
                          }}
                        />
                      </div>
                    );
                  },
                )}

              <div
                className={'rounded-md shadow-standart my-0 mx-auto w-[30rem] h-12 mt-5 flex'}
              >
                <Button
                  text="Add Location"
                  customStyle={styles.btnAddLocation}
                  className={'border-none w-full text-main-black text-sm font-medium leading-[22px]'}
                  styleIcon={'w-8 h-8'}
                  icon={newPlus}
                  onClick={() => {
                    if (listLocations.length <= dataLocationAndRole.length) return;
                    setDataLocationAndRole((prev) => [
                      ...prev,
                      {
                        location: null, // save the input value at this location
                        role: [], // save the input value at this role
                      },
                    ]);
                  }}
                />
              </div>

              <div className="create-user-section_footer flex justify-center items-center mt-3">
                <Button
                  text="Cancel"
                  customStyle={styles.btnWhite}
                  className={'w-[12rem]'}
                  onClick={() => {
                    handlerOnClickCancel();
                  }}
                />
                <Button
                  text="Save"
                  customStyle={styles.btnBlue}
                  className={'w-[12rem]'}
                  onClick={() => {
                    handleOnClickSave();
                  }}
                />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
