import { memo, useEffect, useState } from 'react';
import ContentGroup from './content-group/content-group';
import { useSuggestUser, useUser } from '@utils/custom-hook';
import InputSearchUser from './input-suggest-user/input-suggest-user';
import useStorage from 'store/storage';

const OtherQuestions = memo((props) => {
  const { info, listField, handleChange } = props;
  const item = listField[1];
  const [showOption, setShowOption] = useState(false)
  const [input, setInput] = useState()
  const auth = useStorage(state => state.auth);
  const { data, error, loading } = useUser({ id: info?.whereDidYouHearAboutUs?.userId });

  useEffect(() => {
    if (data)
      setInput(data.name)
  }, [data])

  const textSearch = () => {
    if (input) {
      if (isNaN(input[0])) return input
      return encodeURIComponent(info.phoneNumber)
    }
    return ''
  }

  const { data: dataSuggest } = useSuggestUser({ textSearch: textSearch() });

  const handleChooseOption = (e) => {
    console.log('handleChooseOption', e)
    handleChange({
      name: 'otherQuestions',
      key: 'whereDidYouHearAboutUs',
      value: { value: e.id }
    });
    setInput(e.name)
    setShowOption(false);
  }
  const handleChangeText = (value) => {
    setInput(value)
    setShowOption(true);
  }

  return (
    <>
      <p className="mb-2 font-semibold" style={{ color: '#2b2c34' }}>
        Where did you hear about us?
      </p>
      <InputSearchUser
        label=""
        value={input || ""}
        options={dataSuggest.filter(item => item.id !== auth.uid)}
        exceptId={{}}
        showOption={showOption}
        customStyle={{ height: 'unset' }}
        classGroup="mb-5"
        handleChangeText={handleChangeText}
        handleChooseOption={handleChooseOption}
      />

      <ContentGroup
        data={item}
        info={info?.[item.name]}
        handleChange={(e) => {
          handleChange({ name: 'otherQuestions', key: item.name, value: e });
        }}
      />
    </>
  )
});
OtherQuestions.displayName = 'OtherQuestions';
export default OtherQuestions;
