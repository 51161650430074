import { Checkbox } from "@stories/index"
import ValueType from "./value-type"
import InputFlowType from "./input-flow-type"
import AddIcon from '@mui/icons-material/Add';


const SingleFee = (props) => {
  const { data, editFee, onChange, objValueType, onRemove } = props
  const { active, name, value, valueType } = data

  return (
    <div className="flex items-center justify-between">
      {/* active */}
      <div className="flex items-center w-[40%] min-w-[10rem]">
        <Checkbox value={active}
          onChange={(e) => { onChange({ name: 'active', value: e.target.checked }) }} />
        <p className="text-base">{name}</p>
      </div>

      {/* input */}
      <div className="w-[35%] min-w-[12rem]">
        <InputFlowType value={value}
          type={valueType}
          editFee={editFee}
          objValueType={objValueType}
          onChange={(e) => onChange({ name: 'value', value: e })}
        />
      </div>

      {/* type */}
      <ValueType value={valueType}
        objValueType={objValueType}
        editFee={editFee}
        onChange={(e) => { onChange({ name: 'valueType', value: e }) }}
      />

      {/* remove */}
      <button onClick={onRemove}>
        <AddIcon style={{ color: '#EB5757', transform: 'rotate(45deg)' }} />
      </button>

    </div>
  )
}

export default SingleFee;