import { Box, } from "@mui/material";
import AreaCommenting from "@pages/tasks/components/area-commenting";
import { useContext } from "react";
import admissionCallApi from "api/admission-call";
import { checkErrorSingleApi } from "@utils/check-error/api-error";
import { ToastMessageContext } from "context/toast-context";
import PlayAudio from "./play-audio";
import { getPrefixUserName } from "@helper/application";
import { getStatus } from "@helper/application-panel";
import DefaultAvatar from "@components/default-avatar";

const DialogCallEnd = (props) => {
  const { onClose, eventCall, personCalled, enquiry, setRefresh, className } = props
  const phoneNumber = personCalled?.phoneNumber
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const onPublish = async (data) => {
    try {
      const objData = {
        notes: data?.message || "",
      }

      const apiData = {
        enquiryId: enquiry?.id,
        parentId: personCalled?.parentUserId,
        recordUrl: eventCall?.resource_url || '',
        duration: eventCall?.call_duration || '',
        status: getStatus(eventCall?.business_call_type),
        data: JSON.stringify(objData)
      }

      const res = await admissionCallApi.create({ campusId: enquiry?.campus?.id, data: apiData })

      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Store History')) {
        onClose()
        setRefresh(prev => !prev)
      } else {
        setToastMessage({
          status: 'error',
          title: 'Store History Failed',
        });
        setIsShowToastMessage(true);
      }

    } catch (error) {
      console.log('error', error)
      setToastMessage({
        status: 'error',
        title: 'Store History Failed',
        message: error.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
    }
  }

  const parentName = `${getPrefixUserName(personCalled?.relationship)}${personCalled?.fullName || ''}(${personCalled?.relationship || ''})`


  return (
    <Box className={`${className ? className : 'w-[25rem]'}`}>
      <Box className="flex justify-between items-start">
        <Box className="flex item-center max-w[90%]">
          {personCalled?.photoURL ? <img className="rounded-full w-10 aspect-square mr-4" src={personCalled?.photoURL} />
            : <DefaultAvatar title={personCalled?.fullName} className='rounded-full w-10 aspect-square mr-4' />
          }
          <div className="place-self-center">
            <p className="text-sm font-medium">{'Mrs. Ha Ha(Mother)' || parentName}</p>
            <p className="text-xs text-gray">{'+84354374111' || phoneNumber || ''}</p>
          </div>
        </Box>
      </Box>

      <PlayAudio url={eventCall?.resource_url} duration={eventCall?.call_duration} />

      <Box className={'mt-4'}>
        <AreaCommenting customStyle={{}}
          actions={{ emoji: true, enclose: false, record: false }}
          styleGroup={{ position: 'relative', width: '100%', bottom: '0px' }}
          handlePublish={onPublish}
          clear={false}
          setClear={() => { }}
        // infoAction={{ info: { notes: 'abc' } }} 
        />
      </Box>
    </Box>
  )
}

export default DialogCallEnd;