import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';

import './stats-item.scss';

const StatsItem = ({ num, title, textColor }) => {
  return (
    <li className="stats-item">
      <h2 style={{ color: textColor }} className={clsx('stats-item_number')}>
        {num}
      </h2>
      <p className="stats-item_text">{title}</p>
    </li>
  );
};

export default StatsItem;

StatsItem.propTypes = {
  num: PropTypes.any,
  title: PropTypes.string,
  textColor: PropTypes.string,
};
