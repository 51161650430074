import { createStore, applyMiddleware } from 'redux';
import reducers from './reducer';
import thunk from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { composeWithDevTools } from 'redux-devtools-extension';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'giiki',
  storage: storage,
  blacklist: [],
  whitelist: ['auth'],
};

const pReducer = persistReducer(persistConfig, reducers);

// const store = createStore(pReducer, {}, applyMiddleware(thunk));
const middleware = [thunk];
const store = createStore(pReducer, composeWithDevTools(applyMiddleware(...middleware)));

export const persistor = persistStore(store);

export default store;
