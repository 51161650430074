import { callApi } from '@helper/call-api';

class CentralAdminApi {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2`;
  }

  getListAcademicYear = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/academic/list`,
      params,
    });
  };

  createAcademicYear = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/academic`,
      params,
      data,
    });
  };

  updateAcademicYear = async (params, data, academicId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/academic/${academicId}`,
      params,
      data,
    });
  };

  getListDocumentSetting = async (params, signal = undefined) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/document-setting/list`,
      params,
      signal,
    });
  };

  updateDocumentSetting = async (params, data, documentSettingId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/document-setting/${documentSettingId}`,
      params,
      data,
    });
  };

  createDocumentSetting = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/document-setting`,
      params,
      data,
    });
  };

  getListDistribution = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/distribution/list`,
      params,
    });
  };

  createDistribution = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/distribution`,
      params,
      data,
    });
  };

  updateDistribution = async (params, data, distributionId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/distribution/${distributionId}`,
      params,
      data,
    });
  };

  getListProgram = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/program/list`,
      params,
    });
  };

  getListTeamSettingDraft = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/program/team-setting/draft/list`,
      params,
    });
  };

  getListGrade = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/grade/list`,
      params,
    });
  };

  getListAvailableGrade = async (params, programId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/list/available/${programId}`,
      params,
    });
  };

  getListProgramGrade = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/grade/program/list`,
      params,
    });
  };

  getListTeamSetting = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/program/team-setting/list`,
      params,
    });
  };

  getDraftTeamSetting = async (params, draftId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/program/team-setting/draft/${draftId}`,
      params,
    });
  };

  getListDraftTeamSetting = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/program/team-setting/draft/list`,
      params,
    });
  };

  createDraftTeamSetting = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/program/team-setting/draft`,
      params,
      data,
    });
  };

  getListFormBuilder = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder/list`,
      params,
    });
  };

  createFormBuilder = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder`,
      params,
      data,
    });
  };

  updateFormBuilder = async (params, data, formId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder/${formId}`,
      params,
      data,
    });
  };

  getFormBuilderById = async (params, formId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/form-builder/${formId}`,
      params,
    });
  };

  deleteClass = async (classId) => {
    return callApi({
      method: 'delete',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/classes/${classId}`,
    });
  };

  getClass = async (classId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/classes/check/${classId}`,
    });
  };

  getListClassDraft = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/classes/draft/list`,
      params,
    });
  };

  createClassDraft = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/classes/draft`,
      params,
      data,
    });
  };

  getClassDraft = async (params, draftId) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/classes/draft/${draftId}`,
      params,
    });
  };

  createClass = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/classes`,
      params,
      data,
    });
  };

  getCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/campus`,
      params,
    });
  };

  getMyCampus = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/campus/user`,
    });
  };

  getMyCompany = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company`,
    });
  };

  addCustomRole = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company/add-custom-role`,
      data,
    });
  };

  getUserOfCompanyOrCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/user/get-users-of-company-or-campus`,
      params,
    });
  };

  getRoleOfCompanyOrCampus = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/role/get-role-of-company-or-campus`,
      params,
    });
  };

  uploadFile = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media/file`,
      data,
      contentType,
    });
  };

  uploadMedia = async (data, contentType = 'multipart/form-data') => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`,
      data,
      contentType,
    });
  };

  getListBankAccount = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/central-admin/razorpay-bank-account/list`,
      params,
    });
  };

  addBankAccount = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/central-admin/razorpay-bank-account/add`,
      params,
      data,
    });
  };

  getListBankAccountFinanceHead = async () => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/central-admin/razorpay-bank-account/list-by-finance-head`,
    });
  };

  submitEnquiryForm = async (data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company/submit-enquiry-form`,
      data,
    });
  };

  getListGradeOfAcademicYear = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/academic`,
      params,
    });
  };

  getListYearForEnquiry = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/academic/list-for-enquiry`,
      params,
    });
  };

  getListCampusOfCompany = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/campus/company`,
      params,
    });
  };

  getCompanyById = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company/search`,
      params,
    });
  };

  getListProgramAndClass = async (campusId, year) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/grade/list-programs-and-classes?campusId=${campusId}&academicYearName=${year}`,
    });
  };

  getListGradeProgram = async (params) => {
    return callApi({
      method: 'get',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/fee/list`,
      params,
    });
  };

  updateProgram = async (params, data, programId) => {
    return callApi({
      method: 'patch',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/program/${programId}`,
      params,
      data,
    });
  };

  createProgram = async (params, data) => {
    return callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v2/admission/program`,
      params,
      data,
    });
  };
}
const centralAdminApi = new CentralAdminApi();
export default centralAdminApi;
