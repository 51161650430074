import React, { useContext, useEffect, useState } from 'react'
import { group } from '../image';
import { Chip, SelectGroup, TextArea } from '@stories/index';
import HorizontalCalendar from '@components/horizontal-calendar';
import { selectAddEventGuests, selectEventAndHost } from 'entities/select-style';
import { ToastMessageContext } from 'context/toast-context';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { eventTypes, objEventType, objRole } from '@utils/constant';
import { getPrefixUserName } from '@helper/application';
import { colorOptionForUserSelect } from 'entities/colorOption';
import DefaultAvatar from '@components/default-avatar';
import admissionApi from 'api/admission';

const AddEvent = (props) => {
  const { closeAddEvent, enquiry, scheduleByDate, users, parents, setRefresh } = props
  const [info, setInfo] = useState({})
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const optionParent = parents?.map((item, index) => ({
    info: { ...item },
    value: item?.parentUserId,
    label: `${getPrefixUserName(item?.fullName)}${item?.fullName}`,
    color: colorOptionForUserSelect[index % colorOptionForUserSelect.length]
  }))

  const onChangeEventType = (e) => {
    if (e?.value === objEventType['Meeting With Principal']) {
      let principal = ''
      for (let user of users) {
        for (let role of user.info.roles) {
          if (role?.name === objRole['Principal']) {
            principal = user
            break
          }
        }
      }
      setInfo(prev => ({ ...prev, eventType: e, host: principal }))
    } else
      setInfo(prev => ({ ...prev, eventType: e, host: null }))
  }

  const onChangeHost = (e) => {
    setInfo(prev => ({ ...prev, host: e }))
  }

  const onChangeNote = (e) => {
    setInfo(prev => ({ ...prev, notes: e.target.value }))
  }

  const onChangeGuest = (e) => {
    setInfo(prev => ({ ...prev, guests: e }))
  }

  const validateInfo = () => {
    for (let key of ['eventType', 'host', 'date', 'time', 'guests']) {
      if (!info[key]) return false
    }
    return true
  }

  const onAddSchedule = async () => {
    if (!validateInfo()) {
      setToastMessage({
        status: 'warning',
        title: 'Validate Failed',
      });
      setIsShowToastMessage(true);
      return
    }

    const response = await admissionApi.createAdmissionCalendar({
      data: {
        campusId: enquiry?.campusId,
        notes: info?.notes || '',
        eventType: info.eventType.value,
        hostId: info.host.value,
        date: new Date(info.date),
        time: info.time,
        guestIds: JSON.stringify(info.guests.map((item => item.value))),
        enquiryId: enquiry.id
      }
    })

    if (checkErrorSingleApi(response, setToastMessage, setIsShowToastMessage, 'Create Schedule Successfully')) {
      setInfo({})
      setRefresh(prev => !prev)
    } else {
      setToastMessage({
        status: 'error',
        title: 'Create Schedule Failed',
        message: response?.data?.message || ""
      });
      setIsShowToastMessage(true);
    }
  }

  const formatOptionLabelUser1 = ({ info, value, label }) => {
    return (
      <div className="block">
        <Chip key={value}
          label={label || ''}
          avatarElement={<DefaultAvatar title={info?.name} className='rounded-full w-full aspect-square' />}
          image={info?.photoURL}
          chipAvatarStyle={{ width: 18, height: 18 }}
          styleChip={'object-cover'}
        />
      </div>
    );
  }

  const formatOptionLabelUser2 = ({ info, value, label }) => {
    if (value === 'all')
      return (
        <div className="block">
          <Chip key={value}
            label='Select All'
            avatarElement={<DefaultAvatar title='a' className='rounded-full w-full aspect-square' />}
            chipAvatarStyle={{ width: 18, height: 18 }}
            styleChip={'object-cover'}
          />
        </div>
      )
    return (<div className="block">
      <Chip key={value}
        avatarElement={<DefaultAvatar title={info.fullName} className='rounded-full w-full aspect-square' />}
        label={label}
        image={info?.photoURL}
        chipAvatarStyle={{ width: 18, height: 18 }}
        styleChip={'object-cover'}
        deleteChip
        customStyle={{ paddingRight: 0 }}
        deleteIconStyle={{ display: 'none' }}
      />
    </div>)
  };

  const DropdownIcon = () => (
    <svg width="8" height="12" viewBox="0 0 8 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4 12L0 8H8L4 12ZM4 0L8 4H0L4 0Z" fill="#5C5F62" />
    </svg>
  )


  return (
    <div className='p-3 w-full'>
      <div className='flex items-center justify-between mb-6'>
        <div className='flex items-center'>
          <div
            className='w-10 h-10 mr-3 flex items-center justify-center rounded-lg'
            style={{ background: '#FFE7D9' }}
          >
            <img src={group} alt='' />
          </div>
          <p className='font-semibold'>Calendar</p>
        </div>
        <button className='bg-black w-6 h-6 text-white text-xl flex items-center justify-center rounded-full rotate-45'
          onClick={closeAddEvent}
        >
          +
        </button>
      </div>

      <section className='mb-4 w-full'>
        <SelectGroup title="Event Type"
          dropDown={<DropdownIcon />}
          value={info?.eventType || null}
          isSearchable={true}
          styles={selectEventAndHost}
          options={eventTypes}
          onChange={onChangeEventType} />
      </section>

      <section className='mb-4 w-full'>
        <SelectGroup title="Host"
          styles={selectEventAndHost}
          isDisabled={!!(info?.eventType?.value === objEventType['Meeting With Principal'])}
          value={info?.host || null}
          dropDown={<DropdownIcon />}
          isSearchable={true}
          options={users}
          onChange={onChangeHost}
          formatOptionLabel={formatOptionLabelUser1}
        />
      </section>

      <section className='mb-4 w-full'>
        <SelectGroup title="Guest"
          styles={selectAddEventGuests}
          isMulti={true}
          value={info?.guests || null}
          labelFix={{ background: 'linear-gradient(white 70%, transparent 30%)' }}
          dropDown={<DropdownIcon />}
          isSearchable={true}
          options={optionParent}
          onChange={onChangeGuest}
          formatOptionLabel={formatOptionLabelUser2}
        />
      </section>

      <section className='mb-8 w-full'>
        <HorizontalCalendar setInfo={setInfo} info={info} scheduleByDate={scheduleByDate} />
      </section>

      <section>
        <TextArea label='Notes'
          disableLabel
          value={info?.notes || ''}
          customStyle={{ fontSize: '1rem', width: '100%', height: '5rem', resize: 'none' }}
          onChange={onChangeNote}
        />
      </section>

      <button className='bg-[#2E3338] text-white font-semibold w-full h-[2.5rem] rounded-md mt-4'
        onClick={onAddSchedule}
      >
        Schedule
      </button>
    </div>
  )
}

export default AddEvent;