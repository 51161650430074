import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import uuid from 'react-uuid';
import { cancel } from '@static/image';
import { Button, Input, SelectGroup } from '@stories/index';
import { ToastMessageContext } from 'context/toast-context';
import { selectStylesWithTitle } from 'entities/select-style';
import './action-modal.scss';
import approvalApi from 'api/approval';
import useStorage from 'store/storage';

export default function ActionModal({
  open,
  isOpen,
  listApprovalProcess,
  setListApprovalProcess,
}) {
  const [tableOpen, setTableOpen] = useState(false);
  const [optionProcess, setOptionProcess] = useState([]);
  const [selectedProcess, setSelectedProcess] = useState({});
  const [selectedFields, setSelectedFields] = useState([]);
  const [optionFields, setOptionFields] = useState([]);
  const [name, setName] = useState('');
  const [actions, setActions] = useState();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const uniqueId = useMemo(() => uuid(), []);
  const campus = useStorage((state) => state.currentCampus);

  const styles = {
    closeBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#008060' },
  };

  const handleClose = useCallback(() => {
    setName('');
    setSelectedFields([]);
    setSelectedProcess({});
  }, []);

  const allClose = () => {
    setTableOpen(false);
  };

  const handleChangeName = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/action/list?isUsed=false`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    approvalApi.getListActionNotUsed()
      .then((res) => {
        const { data } = res;
        if (data.success) {
          const tmpOptionProcess = data.data.map((item) => {
            const { id, name, tableFields } = item;
            return {
              value: name,
              label: name,
              tableFields: tableFields,
              id: id,
              info: item,
            };
          });
          setActions(data.data);
          setOptionProcess(tmpOptionProcess);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Action Modal Error', error);
      });
  }, []);

  const onClickCreateHandler = () => {
    const data = {
      isCreateProcess: true,
      name: name,
      actionIds: (() => {
        return [selectedProcess.id];
      })(),
      description: '',
      // color: '$123456',
      isActive: false,
      highlightKeyFields: [],
      hourReminderEvery: null,
      isAppNoti: false,
      isEmailNoti: false,
      isLockFieldsOnApproval: false,
      isMandateComments: false,
      isResetApprovalOnChangeOfFields: false,
      isWhatsAppNoti: false,
      triggeredKeyFields: (() => {
        let result = selectedFields.map(({ value }) => value);
        if (selectedFields.some(({ id }) => id === uniqueId)) {
          result = selectedProcess.tableFields;
        }
        return result;
      })(),
      processAction: [
        {
          approvalAction: selectedProcess.info,
        },
      ],
      approvalProcessStage: [
        {
          level: 1,
          name: ' ',
          description: ' ',
          approvalType: 'single',
          processStageApprover: [
            {
              approver: {},
            },
          ],
          escalatedToRoleIds: [],
          escalatedToRoles: [],
        },
      ],
    };
    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/check-permission-add`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: data,
    // })
    approvalApi.createApprovalProcess({}, data)
      .then((res) => {
        if (res.data.success) {
          setListApprovalProcess([...listApprovalProcess, data]);
          isOpen(false);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Check Permission Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
        handleClose();
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Check Permission Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Action Modal Error', error);
      });
  };

  const handleChangeProcess = useCallback((e) => {
    setSelectedProcess(e);
    const tableFields = e.tableFields.map((item) => {
      return {
        value: item,
        label: (() => {
          const temp = item.split(/(?=[A-Z])/).join(' ');
          return temp.charAt(0).toUpperCase() + temp.slice(1);
        })(),
      };
    });
    // if (tableFields.length !== 0) {
    //   tableFields.unshift({ id: uniqueId, value: 'all', label: 'All Field' });
    // }
    setOptionFields(tableFields);
    setSelectedFields([]);
  }, []);

  const handleChangeField = useCallback((e) => {
    setSelectedFields(e);
  }, []);

  return (
    <div className={open ? 'action-modal action-modal-active' : 'action-modal'}>
      <div
        className="action-modal_bg"
        onClick={() => {
          isOpen();
          allClose();
        }}
      />
      {/* <div className='action-modal-card' onClick={tableOpen === true || programOpen === true ? allClose : null}> */}
      <div className="action-modal-card" onClick={tableOpen === true ? allClose : null}>
        <div className="action-modal-card-header">
          <h2 className="text-2xl ml-6">Create New Approval Process</h2>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
              allClose();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="action-modal-card-row">
          <div className="action-modal-card-row_label"></div>
          <div className="action-modal-card-row_selects">
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              // classNameSelect='max-h-16 overflow-auto'
              title="Select the Process/Table"
              value={Object.keys(selectedProcess).length !== 0 ? selectedProcess : ''}
              defaultValue={null}
              styles={selectStylesWithTitle}
              options={optionProcess}
              isSearchable={true}
              onChange={handleChangeProcess}
            />
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              title="Select Fields"
              value={selectedFields.length !== 0 ? selectedFields : ''}
              defaultValue={null}
              styles={selectStylesWithTitle}
              options={optionFields}
              isMulti={true}
              isSearchable={true}
              onChange={handleChangeField}
            />
            <div className="ml-10 w-80">
              <Input
                label="Approval name"
                value={name || ''}
                onChange={handleChangeName}
                customStyle={{
                  paddingLeft: '1rem',
                  width: '100%',
                  height: '2.65rem',
                  fontSize: '1rem',
                }}
              />
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button
            text="Close"
            customStyle={styles.closeBtn}
            onClick={() => {
              isOpen();
              allClose();
              handleClose();
            }}
          />
          <Button
            text="Create"
            customStyle={styles.createBtn}
            onClick={onClickCreateHandler}
          />
        </div>
      </div>
    </div>
  );
}

ActionModal.propTypes = {
  open: PropTypes.bool,
  isOpen: PropTypes.func,
};
