import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { FeeBookCard, FeeBookModal } from '@components/finance-module';
import { NavLinks } from '@pages/Header/components';
import { SelectGroup } from '@stories/index';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { selectStylesWithNoTitle } from 'entities/select-style';
import {
  getCurrentAcademicYearNameSelector,
  getPreviousAcademicYearNameSelector,
} from 'store/selector/navbarSelector';
import './feebooks.scss';
import financeApi from 'api/finance';
import useStorage from 'store/storage';

const options = [
  { value: '0', label: 'All Active Feebooks' },
  { value: '1', label: 'All Feebooks' },
  { value: '2', label: 'Drafts' },
];

export default function FeeBooks(props) {
  const { burger } = props;
  const [open, openModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [listCurrentFeeBook, setListCurrentFeeBook] = useState();
  const [listPreviousFeeBook, setListPreviousFeeBook] = useState();
  const [filter, setFilter] = useState(options[1]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const currentYear = useStorage((state) => state.currentYear);
  const previousYear = useStorage((state) => state.previousYear);

  const isOpen = () => {
    openModal(!open);
  };

  // const academicYearChange = useSelector(getCurrentAcademicYearNameSelector) || '';
  // const previousAcademicYearName = useSelector(getPreviousAcademicYearNameSelector) || '';

  useEffect(() => {
    /**
     * Fetch list Feebooks for current and previous academic years
     */
    const reqCurrent = financeApi.getListFeebook({
      campusId: campus?.isCentral ? undefined : campus?.id,
      academicYearName: currentYear,
      filter: filter.value,
    }
    );
    const reqPrev = financeApi.getListFeebook({
      campusId: campus?.isCentral ? undefined : campus?.id,
      academicYearName: previousYear,
      filter: filter.value,
    });
    const reqData = !!previousYear ? [reqCurrent, reqPrev] : [reqCurrent];

    Promise.all(reqData)
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const listCurrent = res[0].data.data.objects;
          setListCurrentFeeBook(listCurrent);

          if (reqData.length === 1) {
            setListPreviousFeeBook([]);
          } else {
            const listPrev = res[1].data.data.objects;
            setListPreviousFeeBook(listPrev);
          }
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Admission Team Settings', error);
      });
  }, [currentYear, previousYear, refresh, filter, campus]);

  return (
    <React.Fragment>
      {open && <FeeBookModal open={open} isOpen={isOpen} setRefresh={setRefresh} />}
      <div className={clsx('feebooks', burger ? 'ml-20 1400px:ml-72' : 'ml-32')}>
        <NavLinks urls={dataLinks.feebooksLinks} />
        <div className="flex items-center justify-start">
          <h1 className="namepage">Fee Books</h1>
          <div className="ml-80">
            <SelectGroup
              value={filter}
              isMulti={false}
              onChange={(e) => {
                setFilter(e);
              }}
              options={options}
              styles={selectStylesWithNoTitle}
              classNameGroup="w-56"
            />
          </div>
        </div>
        <p className="feebooks_p">{`Academic Year ${currentYear}`}</p>
        <div className="flex flex-wrap">
          {listCurrentFeeBook &&
            listCurrentFeeBook.map((feeBooks) => {
              return (
                <FeeBookCard
                  key={feeBooks.id}
                  title={feeBooks.program?.name || ''}
                  date={feeBooks.academicYear?.name || ''}
                  variant={feeBooks.status}
                  feeBookId={feeBooks.id}
                />
              );
            })}
          <FeeBookCard type="button" onClick={isOpen} />
        </div>
        <h3 className="feebooks_h3">Previous Feebooks</h3>
        {previousYear && <p className="feebooks_p">{`Academic Year ${previousYear}`}</p>}
        <div className="flex flex-wrap">
          {listPreviousFeeBook &&
            listPreviousFeeBook.map((feeBooks) => {
              return (
                <FeeBookCard
                  key={feeBooks.id}
                  title={feeBooks.program?.name || ''}
                  date={feeBooks.academicYear?.name || ''}
                  feeBookId={feeBooks.id}
                  variant={feeBooks.status}
                />
              );
            })}
        </div>
      </div>
    </React.Fragment>
  );
}

FeeBooks.propTypes = {
  burger: PropTypes.bool,
};
