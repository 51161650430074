import { useLocation } from 'react-router-dom';
import CampusUsers from './components/campus-users/campus-users';
import useStorage from 'store/storage';

export default function CampusListUsers({ burger }) {
  const location = useLocation();
  // const stateRedux = useSelector((state) => state);
  const currentCampus = useStorage((state) => state.currentCampus);
  const campus = location?.state?.selectCampus || currentCampus;

  // const campus = useMemo(() => {
  //   if (location?.state?.selectCampus) {
  //     return location.state.selectCampus;
  //   } else {
  //     if (stateRedux?.auth?.campusId) {
  //       return stateRedux.company.info;
  //     } else {
  //       return currentCampus;
  //     }
  //   }
  // }, [
  //   currentCampus,
  //   location?.state?.selectCampus,
  //   stateRedux?.auth?.campusId,
  //   stateRedux.company.info,
  // ]);

  // return useMemo(() => {
  //   return <CampusUsers campus={campus} burger={burger} />;
  // }, [currentCampus, burger]);

  return <CampusUsers campus={campus} burger={burger} />;
}
