import React, { useState } from 'react'
import { Slider } from '@mui/material'

const InputSlider = ({
    min, max, gap, step, defaultValue, getValue,
    valueLabelDisplay, // "on" || "auto" || "off" (string)
    size, // "small" || "medium"
    minDistance // number
}) => {
    const [value, setValue] = useState(defaultValue) // [number, number];
    const [minDistanceState, setMinDistanceState] = useState(minDistance ?? 0.5)

    const onChange = (
        event,
        newValue,
        activeThumb,
    ) => {
        if (!Array.isArray(newValue)) {
            return;
        }

        if (activeThumb === 0) {
            setValue([Math.min(newValue[0], value[1] - minDistanceState), value[1]]);
        } else {
            setValue([value[0], Math.max(newValue[1], value[0] + minDistanceState)]);
        }

        getValue(newValue)
    };

    return (
        <Slider
            min={min}
            max={max}
            step={step}
            gap={gap}
            value={value}
            onChange={onChange}
            valueLabelDisplay={valueLabelDisplay || "on"} // default is on
            sx={
                {
                    '& .MuiSlider-valueLabel': {
                        borderRadius: "9999px",
                        backgroundColor: "#1976d2",
                        width: "32px",
                        height: "32px"

                    },
                    '.MuiSlider-valueLabel:before': {
                        bottom: "0.3px",
                    }
                }
            }
            size={size}
            disableSwap={true}
        />
    )
}

export default InputSlider