import clsx from 'clsx';
import React, { useCallback, useRef, useState, useContext } from 'react';
import Fade from 'react-reveal/Fade';
import Zoom from 'react-reveal/Zoom';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import {
  google,
  icsettings,
  icuser,
  icusergroup,
  microsoft,
  visible,
  invisible,
} from '../image';
import './index.scss';
// import { useDispatch } from 'react-redux';
import { Button, Checkbox } from '../../../../stories';
import Input from '../../../../components/input/input.jsx';
import { dodles, logoLogin } from '../../../../static/image/index.jsx';
import { saveConfirmCodeSms } from '../../../../store/action-creators/confirmCodeSMSAction';
import { Logo } from '../../index.jsx';
import firebase from './firebase';
import { useAuth } from './firebaseSignin';
import { ToastMessageContext } from '../../../../context/toast-context';
import BloomInput from '../../../../components/bloomingdale/input/input';
import { man } from '../../../bloomingdale/login/images';
import { validPhone } from '@utils/validate';
import useStorage from 'store/storage';

export default function UserLogin({ tenantId }) {
  const {
    logInWithEmailAndPassword,
    signInWithGoogle,
    signInWithMicrosoft,
    notifyError,
  } = useAuth(tenantId);
  const history = useHistory();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [account, setAccount] = useState(true);
  const [inputIsRendering, setInputIsRendering] = useState(false);
  const [errors, setErrors] = useState({
    gybridInput: false,
    passswordInput: false,
  });
  const [visiblePass, setVisiblePass] = useState(false);
  const [info, setInfo] = useState({ inputValue: '', rememberMe: false, password: '' })
  // const dispatch = useDispatch();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [verify, setVerify] = useState(); // check verified
  const validEmail = new RegExp('^[a-zA-Z0-9._:$!%-]+@[a-zA-Z0-9.-]+.[a-zA-Z]$');
  const [isShowParentPortal, setIsShowParentPortal] = useState(
    searchParams.get('loginTypeId') === '3',
  );
  const { updateConfirmCodeSms } = useStorage();

  function useDebounce(cb, ms) {
    const time = useRef(null);
    const debounce = useCallback(
      (...args) => {
        if (time.current) {
          clearTimeout(time.current);
        }
        time.current = setTimeout(() => {
          cb(...args);
        }, ms);
      },
      [cb, ms],
    );

    return debounce;
  }

  const changeHandler = useCallback((e) => {
    e.preventDefault();
    setInfo(prev => ({ ...prev, inputValue: e.target.value }));
    renderInput(e.target.value);
  }, []);

  const loginWithPhone = (rememberMe) => {
    if (!info.inputValue) {
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: 'Please enter a valid phone number!',
      });
      setIsShowToastMessage(true);
      return;
    }
    firebase.auth().tenantId = null;
    let temp = {};
    if (!verify) {
      temp = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
        size: 'invisible',
      });
      setVerify(temp);
    }
    firebase.auth()
      .signInWithPhoneNumber(info.inputValue, verify || temp)
      .then((confirmationResult) => {
        // dispatch(saveConfirmCodeSms({ confirmationResult }));
        updateConfirmCodeSms(confirmationResult);
        const loginTypeId = searchParams.get('loginTypeId');
        history.push({
          pathname: '/login/otp',
          search: `?loginTypeId=${loginTypeId}&phoneNumber=${info.inputValue.slice(
            info.inputValue.length - 3,
            info.inputValue.length,
          )}`,
          state: { phoneNumber: info.inputValue, rememberMe: rememberMe },
        });
      })
      .catch((error) => {
        console.log('error', error);
        notifyError(error);
      });
  };

  const onClickLogin = (e) => {
    e.preventDefault();
    if (validPhone(info.inputValue)) {
      loginWithPhone(info.rememberMe);
    } else if (validEmail.test(info.inputValue)) {
      if (info.password === '') {
        setToastMessage({
          status: 'error',
          title: 'Validation Rule',
          message: 'Missing password field',
        });
        setIsShowToastMessage(true);
      }
      logInWithEmailAndPassword(info.inputValue, info.password, info.rememberMe);
    } else {
      setToastMessage({
        status: 'error',
        title: 'Invalid Data',
        message: 'Invalid login type',
      });
      setIsShowToastMessage(true);
    }
  };

  const renderInput = useDebounce((value) => {
    if (!validEmail.test(value) && !validPhone(value)) {
      setErrors({ gybridInput: true });
      setInputIsRendering(false);
    } else {
      setErrors({ gybridInput: false });
      // if (validPhone(value))
      setInputIsRendering(true)
      // if (
      //   validPhone.test(value) ||
      //   (!validEmail.test(value) && !validPhone.test(value))
      // ) {
      //   setInputIsRendering(false);
      // } else if (validEmail.test(value)) {
      //   setInputIsRendering(true);
      // }
    }
  }, 800);

  if (isShowParentPortal)
    return (
      <div>
        <div className="bloom-login">
          <div className="bloom-login-header">
            <h1 className="bloom-login-header_title">Parent Login</h1>
          </div>
          <div className="bloom-login-body">
            <div className="bloom-login-body_greet">
              <img src={man} alt="man with a kid" className="bloom-login-body_img" />
              <h3 className="bloom-login-body_title">Parent Login</h3>
              <p className="bloom-login-body_subtitle">Be a part of your child's journey</p>
            </div>
            <form className="bloom-login-body_form bloom-login-form">
              <BloomInput
                title="Email address"
                id="email"
                placeholder="Type your email address"
                value={info.inputValue}
                onChange={(e) => {
                  setInfo(prev => ({ ...prev, inputValue: e.target.value }));
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickLogin(e);
                  }
                }}
              />
              <BloomInput
                title="Password"
                type="password"
                id="password"
                value={info.password}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    onClickLogin(e);
                  }
                }}
                onChange={(e) => {
                  setInfo(prev => ({ ...prev, password: e.target.value }))
                }}
              />
              <Button
                text="Login"
                onClick={onClickLogin}
                className="bloom-login-form_submit"
              />
            </form>
          </div>
          <p className="bloom-login-contact">
            Facing trouble? Contact your administrator:{' '}
            <a href="mailto: support@blooomingdale.edu.in">support@blooomingdale.edu.in</a>
          </p>
        </div>
      </div>
    )

  return (
    <div className="login account-type flex">
      <div className="m-auto">
        <NavLink to="/">
          {/* <Logo fill="#BDB8B5" /> */}
          <img src={logoLogin} className="m-auto" />
        </NavLink>
        <Fade clear duration={300}>
          <div className="box mt-4">
            <h1 className="text-3xl font-black">Welcome</h1>
            <p className="text-second-gray font-normal text-base mt-1">
              {!account
                ? 'Choose an account type to proceed.'
                : 'Sign in to your account.'}
            </p>
            <div
              className="flex mt-5 justify-between"
            >
              {!account ? (
                <div>
                  {buttonsData.map((item, key) => (
                    <button
                      className="box-link"
                      key={key}
                      onClick={() => setAccount(!account)}
                    >
                      <img src={item.img} alt={item.alt} className="pl-6" />
                      <div className="flex flex-col items-start pl-6">
                        <p className="text-base font-medium text-main-black">
                          {item.account}
                        </p>
                        <p className="text-sm font-normal text-second-gray">
                          {item.about}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              ) : (
                <div style={{ width: '25rem' }}>
                  <button
                    className="box-signin pr-2.5"
                    onClick={() => signInWithGoogle(info.rememberMe)}
                  >
                    <img src={google} alt="google" className="mr-1" />
                    Signin with Google
                  </button>
                  <button
                    className="box-signin"
                    onClick={() => signInWithMicrosoft(info.rememberMe)}
                  >
                    <img src={microsoft} alt="microsoft" />
                    Signin with Microsoft
                  </button>
                  <div className="flex items-center justify-between mt-5 mb-5">
                    <span className="span-line" />
                    <p className="text-main-black text-sm">OR</p>
                    <span className="span-line" />
                  </div>
                  <div className="relative">
                    <Input
                      value={info.inputValue}
                      onChange={changeHandler}
                      error={errors.gybridInput}
                      label="Email or Phone Number"
                      customStyle={{ width: '100%', marginBottom: '1rem' }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onClickLogin(e);
                        }
                      }}
                    />
                  </div>
                  {/* <Zoom duration={300} when={inputIsRendering}> */}
                  <div className={clsx('relative', inputIsRendering ? '' : 'hidden')}>
                    <input
                      placeholder="Password"
                      type={visiblePass ? 'text' : 'password'}
                      name="password"
                      className={clsx(
                        'box-input box-input__pass w-full',
                        errors.passswordInput ? `error` : ``,
                      )}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onClickLogin(e);
                        }
                      }}
                      onChange={(e) => {
                        setInfo(prev => ({ ...prev, password: e.target.value }))
                      }}
                    />
                    <div
                      className="absolute right-5 cursor-pointer"
                      style={{ top: '0.875rem' }}
                      onClick={() => setVisiblePass(!visiblePass)}
                    >
                      <img src={visiblePass ? visible : invisible} alt="visible" />
                    </div>
                  </div>
                  {/* </Zoom> */}
                  <div
                    className="flex justify-between items-center"
                    style={{ width: '100%' }}
                  >
                    <div className="flex items-center ml-2">
                      <div className="1920px:pt-[0.5rem]">
                        <Checkbox
                          value={info.rememberMe}
                          onChange={(e) => {
                            setInfo(prev => ({ ...prev, rememberMe: e.targe.checked }))
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter') {
                              onClickLogin(e);
                            }
                          }}
                        />
                      </div>
                      <p className="text-sm text-main-black">Remember me</p>
                    </div>
                    <NavLink
                      to="/login/forgot"
                      className="font-medium text-sm"
                      style={{ color: '#7A0C2E' }}
                    >
                      Forgot password?
                    </NavLink>
                  </div>
                  <div id="recaptcha-container"></div>
                  <button className="box-submit" onClick={(e) => onClickLogin(e)}>
                    Login
                  </button>

                  <Zoom duration={300} when={inputIsRendering}>
                    <div
                      className="flex justify-center items-center mb-8"
                      style={{ width: '27.688rem' }}
                    >
                      <button disabled={!inputIsRendering}
                        onClick={() => history.push('/login/otp', { info })}
                        className='font-medium text-sm text-[#7A0C2E]'
                      >
                        Or login with OTP
                      </button>
                    </div>
                  </Zoom>
                </div>
              )}
              <div>
                <img src={dodles} alt="dodles" className="dodles pr-4" />
              </div>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  )
}

const buttonsData = [
  {
    img: icuser,
    alt: 'teacher',
    account: 'Teacher account',
    about: 'Educator, campus admin, IT specialist',
  },
  {
    img: icusergroup,
    alt: 'parent',
    account: 'Parent account',
    about: 'Family member',
  },
  {
    img: icsettings,
    alt: 'global',
    account: 'Global admin',
    about: 'Master admin, school admin etc.',
  },
];
