import React, { useMemo, useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';

import { ToastMessageContext } from 'context/toast-context';
import { callApi } from '@helper/call-api';
import { Input } from '@stories/index';
import { urls } from 'entities/urls';
import { staticsize } from '@static/image';
import centralAdminApi from 'api/central-admin';
import authApi from 'api/auth';

export default function General({ state, burger }) {
  const [company, setCompany] = useState({ ...state });
  const [imgPreview, setImgPreview] = useState(state.logo);
  const [formData, setFormData] = useState(new FormData());
  const [isLogo, setIsLogo] = useState(false);
  const history = useHistory();

  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const updateCompany = (companyUpdate) => {
    const company = {
      ...(({
        companyBilling,
        companyIntegrations,
        companyPlan,
        dateCreated,
        dateUpdated,
        domainName,
        parentCompanyId,
        tenantId,
        topNavigationImage,
        ...o
      }) => o)(companyUpdate),
    };

    // console.log('data company update payload', company);
    // const req = callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v2/company`,
    //   data: company,
    // });
    // req
    authApi.updateCompany(company)
      .then((response) => {
        if (response.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Update Company Successfully',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
          history.push(urls.giiki.institutions);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update Company Failed',
            message: response.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Company Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Company Settings General Error', error);
      });
  };

  const onClickUpdateCompanyDetail = (e, company) => {
    e.preventDefault();
    if (isLogo) {
      // const res = callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/media`,
      //   data: formData,
      //   contentType: 'multipart/form-data',
      // });
      // res
      centralAdminApi.uploadMedia()
        .then((res) => {
          const { data } = res;
          if (data.success) {
            company.logo = data.data;
            updateCompany(company);
            // return company
          } else {
            setToastMessage({
              status: 'error',
              title: 'Upload Image Failed',
              message: data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Upload Image Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Company Settings General Error', error);
        });
    } else {
      updateCompany(company);
    }
  };

  const changeImageHandler = (e) => {
    let selected = e.target.files;
    if (selected && selected[0]) {
      let file = selected[0];
      formData.set('file', file);
      formData.set('mediaId', file.name);
      setFormData(formData);
      setIsLogo(true);
      let reader = new FileReader();
      reader.onload = (e) => {
        setImgPreview(e.target.result);
        setCompany({ ...company, logo: e.target.result });
      };
      reader.readAsDataURL(selected[0]);
      reader.onerror = function (error) {
        console.log('Error: ', error);
      };
    }
  };
  const handleClickCancel = () => {
    setCompany(state);
    setImgPreview(state.logo);
    formData.delete('file');
    formData.delete('mediaId');
    setFormData(formData);
  };

  const styleInput = useMemo(
    () => ({
      width: '18.5rem',
      fontSize: '0.875rem',
      marginRight: '1.5rem',
      height: 'unset'
    }),
    [],
  );
  return (
    <React.Fragment>
      <div className="flex items-center justify-between w-full mb-10">
        <div>
          <h1 className="mb-10 font-bold text-base">Company Name</h1>
          <Input
            onChange={(e) => {
              setCompany({ ...company, name: e.target.value });
            }}
            label="Name"
            name="name"
            value={company.name}
            customStyle={{ width: '30.25rem', fontSize: styleInput.fontSize, height: styleInput.height }}
          />
        </div>
        <div style={{ marginLeft: burger ? '3rem' : '' }}>
          <h1 className="mb-10 font-bold text-base">Company Logo</h1>
          <div
            className="flex items-center"
            style={{ width: '28.25rem', height: '3.125rem' }}
          >
            <img
              src={imgPreview ? imgPreview : staticsize}
              className="ml-1 mr-6 w-10 h-10 rounded-full"
              alt="uploaded"
            />
            <div>
              <input
                className="hidden"
                type="file"
                id="upload-file"
                name="upload-file"
                accept="image/*"
                onChange={changeImageHandler}
              />
              <label
                className="cursor-pointer pr-4 pb-2 pl-4 pt-2 rounded-lg border"
                htmlFor="upload-file"
              >
                Upload Logo
              </label>
            </div>
          </div>
        </div>
      </div>
      <hr className='mb-14' />
      <div>
        <h1 className="mb-10 font-bold text-base">Address Details</h1>
        <Input
          onChange={(e) => {
            const arr = [...company.address];
            arr[0] = e.target.value;
            setCompany({ ...company, address: arr });
          }}
          label="Address line 1"
          name="address-line-1"
          value={company?.address[0] ? company.address[0] : ''}
          customStyle={{
            ...styleInput,
            marginBottom: '1.5rem',
          }}
        />
        <Input
          onChange={(e) => {
            const arr = [...company.address];
            arr[1] = e.target.value;
            setCompany({ ...company, address: arr });
          }}
          label="Address line 2"
          name="address-line-2"
          value={company.address[1] || ''}
          customStyle={{
            ...styleInput,
            marginBottom: '1.5rem',
          }}
        />
        <div className="flex items-center" style={{ marginBottom: '1.875rem' }}>
          <Input
            onChange={(e) => {
              setCompany({ ...company, city: e.target.value });
            }}
            label="City"
            name="city"
            value={company.city || ''}
            customStyle={styleInput}
          />
          <Input
            onChange={(e) => {
              setCompany({ ...company, state: e.target.value });
            }}
            label="State"
            name="state"
            value={company.state || ''}
            customStyle={styleInput}
          />
          <Input
            onChange={(e) => {
              setCompany({ ...company, country: e.target.value });
            }}
            label="Country"
            name="country"
            value={company.country || ''}
            customStyle={styleInput}
          />
        </div>
        <div className="flex items-center">
          <Input
            onChange={(e) => {
              const arr = [...company.phoneNumber];
              arr[0] = e.target.value;
              setCompany({ ...company, phoneNumber: arr });
            }}
            label="Contact Number 1"
            name="contact-number-1"
            value={company.phoneNumber[0] || ''}
            customStyle={styleInput}
          />
          <Input
            onChange={(e) => {
              const arr = [...company.phoneNumber];
              arr[1] = e.target.value;
              setCompany({ ...company, phoneNumber: arr });
            }}
            label="Contact Number 2"
            name="contact-number-2"
            value={company.phoneNumber[1] || ''}
            customStyle={styleInput}
          />
          <Input
            onChange={(e) => {
              setCompany({ ...company, email: e.target.value });
            }}
            label="Email"
            name="email"
            value={company.email || ''}
            customStyle={styleInput}
          />
        </div>
        <div className="mt-4 mb-8">
          <button
            className="pl-4 pr-4 pt-1 pb-1 none-after rounded-lg mr-2"
            style={{ background: '#3271a8', color: '#FFF' }}
            onClick={(e) => {
              onClickUpdateCompanyDetail(e, company);
            }}
          >
            Save
          </button>
          <button onClick={handleClickCancel} className="pl-4 pr-4 pt-1 pb-1 none-after">
            Cancel
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
