import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { students } from '@components/admission-module/application/exam/image';
import { ButtonDatePicker } from '@components/admission-module/application/exam/sub-components';
import { icchevron } from '@components/admission-module/application/principal-approval/image';
import { listTime } from '@constant/list-time';
import { exam_pp } from '@static/image';
import { Button, StaticPicker } from '@stories/index';
import { checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { child } from '../image';
import Scheduled from './schedule';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

export default function BloomChildAssessment(props) {
  const { handleNextStage, enquiry } = props;
  // const [type, setType] = useState('scheldued');
  // const [enquiry, setEnquiry] = useState({});
  // const [admissionResult, setAdmissionResult] = useState({});
  // const [latestEnquiry, setLatestEnquiry] = useState({});
  const admissionResult = JSON.parse(enquiry.admissionResult.info);
  const [start, setStart] = useState(false);
  const [exam, setExam] = useState(admissionResult.exam);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  // const location = useLocation();
  // const initDataUrls = location?.state?.initDataUrls;
  // const index = location?.state?.index;
  // const history = useHistory();
  // const exam = admissionResult.exam

  // useEffect(() => {
  //   if (location.state?.enquiry) {
  //     setEnquiry(location.state.enquiry);
  //   }
  // }, [location.state]);

  // useEffect(() => {
  //   if (enquiry?.currentStep === EnquiryStatus.COMPLETE) {
  //     setStart(false);
  //     setLatestEnquiry(enquiry);
  //     const admissionResult = JSON.parse(enquiry.admissionResult.info);
  //     setExam(admissionResult.exam);
  //     setAdmissionResult(admissionResult);
  //     return;
  //   }
  //   enquiry.id &&
  //     callApi({
  //       method: 'get',
  //       url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry/parent/${enquiry.id}`,
  //     })
  //       .then((res) => {
  //         const { data } = res;
  //         if (data.success) {
  //           setStart(false);
  //           const admissionResult = JSON.parse(data.data.admissionResult.info);
  //           setAdmissionResult(admissionResult);
  //           setExam(admissionResult.exam);
  //           setLatestEnquiry(data.data);
  //         } else {
  //           setToastMessage({
  //             status: 'error',
  //             title: 'Get Enquiry Failed',
  //             message: data.message,
  //           });
  //           setIsShowToastMessage(true);
  //         }
  //       })
  //       .catch((error) => {
  //         setToastMessage({
  //           status: 'error',
  //           title: 'Get Enquiry Failed',
  //           message: error.response?.data?.message || error,
  //         });
  //         console.log('Get Enquiry', error);
  //       });
  // }, [enquiry]);

  const appStyle = document.getElementsByClassName('app');

  useEffect(() => {
    if (appStyle && appStyle[0]) appStyle[0].style.overflowX = 'hidden';
  }, []);

  return (
    <div>
      {exam?.testResult ? (
        <ChildAssessmentDone
          result={exam?.testResult}
          handleNextStage={handleNextStage}
        />
      ) : (
        <ChildAssessmentScheduled
          isHaveExam={!!exam}
          exam={exam}
          listTime={listTime}
          setExam={setExam}
          applicationId={admissionResult?.application?.id}
        />
      )}
      {/* <div className="w-full flex items-start">
        <div className={clsx('w-full flex', 'items-center')}>
          {exam?.testResult ? (
            <ChildAssessmentDone
              // index={index}
              // initDataUrls={initDataUrls}
              result={exam.testResult}
              enquiry={enquiry}
            />
          ) : (
            <ChildAssessmentScheduled
              isHaveExam={!!exam}
              exam={exam}
              listTime={listTime}
              setExam={setExam}
              applicationId={admissionResult?.application?.id}
            />
          )}

        </div>
      </div> */}
    </div>
  );

  // return (
  //   <div>
  //     <div className="w-full flex items-start">
  //       <div className={clsx('w-full flex', 'items-center')}>
  //         {exam?.testResult ? (
  //           <ChildAssessmentDone
  //             // index={index}
  //             // initDataUrls={initDataUrls}
  //             result={exam.testResult}
  //             enquiry={enquiry}
  //           />
  //         ) : (
  //           <ChildAssessmentScheduled
  //             isHaveExam={!!exam}
  //             exam={exam}
  //             listTime={listTime}
  //             setExam={setExam}
  //             applicationId={admissionResult?.application?.id}
  //           />
  //         )}

  //       </div>

  //     {!start && (
  //         <div className={clsx('w-full flex', 'items-center')}>
  //           {exam?.testResult && (
  //             <ChildAssessmentDone
  //               // index={index}
  //               // initDataUrls={initDataUrls}
  //               result={exam.testResult}
  //               enquiry={enquiry}
  //             />
  //           )}
  //           {!exam?.testResult && (
  //             <ChildAssessmentScheduled
  //               isHaveExam={!!exam}
  //               exam={exam}
  //               listTime={listTime}
  //               setExam={setExam}
  //               applicationId={admissionResult?.application?.id}
  //             />
  //           )}

  //     {/* {type === 'congratulations' && <ChildAssessmentDone setType={setType} />}
  //         {type === 'scheldued' && (
  //           <ChildAssessmentScheduled setType={setType} isHaveExam={!!exam} exam={exam} />
  //         )} */}
  //     {/* {type === 'test' && <ChildAssessmentTestDate setType={setType} />} */}
  //   </div>
  //       // )}
  //   </div >
  // );
}

const ChildAssessmentScheduled = (props) => {
  const { isHaveExam, exam, applicationId, setExam, listTime } = props;
  const [isUpdateExam, setIsUpdateExam] = useState(false);
  const [testDate, setTestDate] = useState(new Date());
  const [time, setTime] = useState(listTime[0]);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const styles = {
    button: {
      paddingLeft: '3rem',
      paddingRight: '1.875rem',
      position: 'absolute',
      right: '0.313rem',
    },
  };

  const getTimeLabel = useCallback(
    (time) => {
      const tmp = listTime.find((item) => item.value[0] === time[0]);
      if (tmp) return tmp.label;
      return '';
    },
    [listTime],
  );

  const createExamHandle = () => {
    const data = {
      applicationId: applicationId,
      testDate: testDate,
      startTimeSlot: time[0],
      endTimeSlot: time[1],
    };
    admissionApi.createApplicationExamParent({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((response) => {
        if (
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Scheduled Exam',
          )
        ) {
          setIsUpdateExam(false);
          setExam(response.data.data);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Scheduled Exam Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Scheduled Exam Error', error);
      });
  };

  const updateExamHandle = () => {
    const data = {
      testDate: testDate,
      startTimeSlot: time[0],
      endTimeSlot: time[1],
    };
    admissionApi.updateApplicationExamParent({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((response) => {
        if (
          checkErrorSingleApi(
            response,
            setToastMessage,
            setIsShowToastMessage,
            'Update Exam Schedule',
          )
        ) {
          setIsUpdateExam(false);
          setExam(response.data.data);
          // setIsCallAPI((prev) => !prev);
          // setRefreshEnquiries((prev) => !prev);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Exam Schedule Error',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Update Exam Error', error);
      });
  };

  const stringTestDate = useMemo(() => {
    if (!testDate) return '';
    const monthNames = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];
    const date = new Date(testDate);
    return `${date.getDate()} ${monthNames[date.getMonth()]} ${date.getFullYear()}`;
  }, [testDate]);

  return (
    <div className="mb-10">
      {isHaveExam && !isUpdateExam ? (
        <WaitingResult setIsUpdateExam={setIsUpdateExam} exam={exam} />
      ) : (
        <>
          <p className="font-semibold text-lg mb-10 text-main-blue">Schedule Test Date</p>
          <div className="flex justify-center">
            <div className="relative top-32 h-full mr-20">
              <div className="date_details">
                <div className="date_details-date">
                  <p className="date_details-text">Test Date</p>
                  <p className="date_details-schedule">{stringTestDate}</p>
                </div>
                <div className="date_details-time">
                  <p className="date_details-text">Time</p>
                  <p className="date_details-schedule">{getTimeLabel(time)}</p>
                </div>
              </div>
              <img
                src={exam_pp}
                alt=""
                className="absolute"
                style={{ top: '147px', left: '130px', width: '255px', height: '200px' }}
              />
            </div>
            <div className="flex flex-col items-center mt-5 relative">
              <div className="w-full">
                <div className="exam-card flex h-fit">
                  <div
                    className="flex flex-col items-center pt-6 2xl:ml-10 border-r"
                    style={{ width: '24.375rem' }}
                  >
                    <p className="text-third-gray font-semibold mb-4">
                      Select Date From Below
                    </p>
                    <StaticPicker
                      pValue={
                        testDate
                          ? new Date(testDate).toUTCString()
                          : new Date().toUTCString()
                      }
                      psetValue={setTestDate}
                    />
                  </div>
                  <div
                    className="flex flex-col items-center pt-6"
                    style={{ width: '24.375rem' }}
                  >
                    <p className="text-third-gray font-semibold mb-4">Select time slot</p>
                    <ButtonDatePicker time={time} setTime={setTime} listTime={listTime} />
                  </div>
                </div>
              </div>
              <div className="relative h-16 w-full">
                <Button
                  text="Schedule"
                  onClick={() => {
                    if (!isUpdateExam) {
                      createExamHandle();
                    } else {
                      updateExamHandle();
                    }
                  }}
                  leftIcon={icchevron}
                  customStyle={styles.button}
                />
              </div>
            </div>
            {/* {isHaveExam && !isUpdateExam && (
          <p
            onClick={() => { }}
            className="font-semibold flex justify-center w-full"
            style={{ color: '#f9aa8a' }}
          >
            You child's results will be updated soon!
          </p>
        )} */}

            {/* {isHaveExam && !isUpdateExam ? (
          <div>
            <Scheduled exam={exam} />
            <button
              onClick={() => {
                setIsUpdateExam(true);
              }}
              className="mb-2 py-2 text-[#2E72D2]"
            >
              Reschedule Test
            </button>
          </div>
        ) : (
          <div className="flex flex-col items-center mt-5 relative">
            <div className="w-full">
              <div className="exam-card flex h-fit">
                <div
                  className="flex flex-col items-center pt-6 2xl:ml-10 border-r"
                  style={{ width: '24.375rem' }}
                >
                  <p className="text-third-gray font-semibold mb-4">
                    Select Date From Below
                  </p>
                  <StaticPicker
                    pValue={
                      testDate ? new Date(testDate).toUTCString() : new Date().toUTCString()
                    }
                    psetValue={setTestDate}
                  />
                </div>
                <div
                  className="flex flex-col items-center pt-6"
                  style={{ width: '24.375rem' }}
                >
                  <p className="text-third-gray font-semibold mb-4">
                    Select time slot
                  </p>
                  <ButtonDatePicker time={time} setTime={setTime} listTime={listTime} />
                </div>
              </div>
            </div>
            <div className="relative h-16 w-full">
              <Button
                text="Schedule"
                onClick={() => {
                  if (!isUpdateExam) {
                    createExamHandle();
                  } else {
                    updateExamHandle();
                  }
                }}
                leftIcon={icchevron}
                customStyle={styles.button}
              />
            </div>
          </div>
        )} */}
          </div>
        </>
      )}
    </div>
  );
};

const WaitingResult = (props) => {
  const { exam, setIsUpdateExam } = props;

  return (
    <div className="grid place-items-center">
      <p className="font-semibold mb-10 text-main-blue">Child Assessment</p>
      <img src={students} alt="" className="mb-5" />
      <p
        className="font-semibold flex justify-center w-full mb-6"
        style={{ color: '#f9aa8a' }}
      >
        You child's results will be updated soon!
      </p>
      <div className="flex items-center">
        <Scheduled exam={exam} />
        <div className="flex ml-8 font-bold">
          <p style={{ color: 'grey' }}>wanting re-schedule ?</p>
          <button
            onClick={() => setIsUpdateExam(true)}
            style={{
              color: '#1d0bdc',
            }}
          >
            &nbsp;click here
          </button>
        </div>
      </div>
    </div>
  );
};

const ChildAssessmentDone = (props) => {
  const { result, handleNextStage } = props;

  return (
    <React.Fragment>
      <p className="font-semibold mb-10">Child Assessment</p>
      <div className="grid place-items-center">
        <img
          src={child}
          alt="payment"
          className="mb-10"
          style={{ width: '25.25rem', height: '20.875rem' }}
        />
        <div
          className="flex items-center font-semibold mb-2.5"
          style={{ color: '#00a86b' }}
        >
          {result === 'Pass' ? <Done /> : <Fail />}
        </div>
        <p
          className="font-semibold text-base mb-2.5"
          style={{ color: result === 'Pass' ? '#00a86b' : '#7A0C2E' }}
        >
          {result === 'Pass'
            ? '🎉 Congratulation! Your child as cleared the assessment'
            : 'Sorry! Your child failed the assessment'}
        </p>
      </div>
      <div className="w-full flex items-end justify-end mt-12" onClick={handleNextStage}>
        <button className="flex items-center bg-main-blue pl-10 pr-8 pt-1 pb-1 text-sm text-white font-semibold mr-3.5 mb-4">
          Proceed Next
          <Arrow />
        </button>
      </div>
    </React.Fragment>
  );
};

const Done = () => {
  return (
    <svg
      width="24"
      height="24"
      className="mr-2"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.66602 10C1.66602 5.39763 5.39698 1.66667 9.99935 1.66667C12.2095 1.66667 14.3291 2.54464 15.8919 4.10744C17.4547 5.67025 18.3327 7.78986 18.3327 10C18.3327 14.6024 14.6017 18.3333 9.99935 18.3333C5.39698 18.3333 1.66602 14.6024 1.66602 10ZM9.77435 13.0083L13.5827 8.00834V7.98334C13.7643 7.74516 13.805 7.42819 13.6896 7.15182C13.5741 6.87545 13.32 6.68168 13.0229 6.64349C12.7258 6.60529 12.431 6.72849 12.2493 6.96667L9.09935 11.1333L7.74102 9.4C7.55792 9.1648 7.26325 9.04515 6.96801 9.08612C6.67277 9.12709 6.42182 9.32245 6.30968 9.59862C6.19754 9.87479 6.24125 10.1898 6.42435 10.425L8.45768 13.0167C8.6167 13.2179 8.85953 13.3347 9.11602 13.3333C9.37393 13.3327 9.61702 13.2127 9.77435 13.0083Z"
        fill="#00a86b"
      />
    </svg>
  );
};

const Fail = () => {
  return (
    <svg
      width="28"
      height="29"
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0.5C6.258 0.5 0 6.758 0 14.5C0 22.242 6.258 28.5 14 28.5C21.742 28.5 28 22.242 28 14.5C28 6.758 21.742 0.5 14 0.5ZM20.02 20.52C19.8905 20.6498 19.7366 20.7528 19.5673 20.823C19.3979 20.8933 19.2164 20.9294 19.033 20.9294C18.8496 20.9294 18.6681 20.8933 18.4987 20.823C18.3294 20.7528 18.1755 20.6498 18.046 20.52L14 16.474L9.954 20.52C9.69223 20.7818 9.3372 20.9288 8.967 20.9288C8.5968 20.9288 8.24177 20.7818 7.98 20.52C7.71823 20.2582 7.57117 19.9032 7.57117 19.533C7.57117 19.3497 7.60728 19.1682 7.67742 18.9988C7.74757 18.8295 7.85038 18.6756 7.98 18.546L12.026 14.5L7.98 10.454C7.71823 10.1922 7.57117 9.8372 7.57117 9.467C7.57117 9.0968 7.71823 8.74177 7.98 8.48C8.24177 8.21823 8.5968 8.07117 8.967 8.07117C9.3372 8.07117 9.69223 8.21823 9.954 8.48L14 12.526L18.046 8.48C18.1756 8.35038 18.3295 8.24757 18.4988 8.17742C18.6682 8.10728 18.8497 8.07117 19.033 8.07117C19.2163 8.07117 19.3978 8.10728 19.5672 8.17742C19.7365 8.24757 19.8904 8.35038 20.02 8.48C20.1496 8.60961 20.2524 8.76349 20.3226 8.93284C20.3927 9.10219 20.4288 9.2837 20.4288 9.467C20.4288 9.6503 20.3927 9.83181 20.3226 10.0012C20.2524 10.1705 20.1496 10.3244 20.02 10.454L15.974 14.5L20.02 18.546C20.552 19.078 20.552 19.974 20.02 20.52Z"
        fill="#FD5749"
      />
    </svg>
  );
};

const Refresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="mr-2"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#9fa9b3"
    >
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M17.65 6.35C16.2 4.9 14.21 4 12 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08c-.82 2.33-3.04 4-5.65 4-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z" />
    </svg>
  );
};

const Arrow = () => {
  return (
    <svg
      width="24"
      height="24"
      className="ml-1.5"
      viewBox="0 -5 24 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.55 2.5L7 4.05L14.95 12L7 19.95L8.55 21.5L18 12L8.55 2.5Z"
        fill="white"
      />
    </svg>
  );
};

/************ NO DELETE ****************/

// const ChildAssessmentTestDate = ({ setType }) => {
//   return (
//     <div className="relative">
//       <p className="font-semibold mb-10">Schedule a Test Date</p>
//       <div className="w-full flex mt-1">
//         <TestCardRender />
//         <div
//           className="mb-20 ml-32 grid grid-cols-2 items-center w-full shadow-xl"
//           style={{ height: 640 }}
//         >
//           <div className="h-full flex items-center flex-col justify-start border-r">
//             <p className="text-third-gray font-semibold mb-10 mt-20">
//               Select a Date from below
//             </p>
//             <StaticPicker color="#1611da" />
//           </div>
//           <div className="h-full flex items-center flex-col justify-start">
//             <p className="text-third-gray font-semibold mb-10 mt-20">
//               Select a Time from below
//             </p>
//             <ButtonDatePicker color="#1611da" />
//           </div>
//         </div>
//       </div>
//       <div className="w-full flex items-end justify-end mb-10">
//         <button
//           onClick={() => setType('scheldued')}
//           className="flex items-center bg-main-blue pl-10 pr-8 pt-1 pb-1 text-sm text-white font-semibold mr-3.5"
//         >
//           Proceed Next
//           <Arrow />
//         </button>
//       </div>
//     </div>
//   );
// };

/************ NO DELETE ****************/
// const TestCardRender = () => {
//   return (
//     <div className="relative top-32 h-full">
//       <div className="date_details">
//         <div className="date_details-date">
//           <p className="date_details-text">Test Date</p>
//           <p className="date_details-schedule">21 January 2020</p>
//         </div>
//         <div className="date_details-time">
//           <p className="date_details-text">Time</p>
//           <p className="date_details-schedule">12:00 - 12:30 PM</p>
//         </div>
//       </div>
//       <img
//         src={woman}
//         className="absolute"
//         style={{ top: 147, left: 130, width: 255, height: 200 }}
//         alt=""
//       />
//     </div>
//   );
// };
