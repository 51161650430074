import { useContext, useMemo, useState } from 'react';
import Loading from '@components/loading';
import { back, bank, card, cash, cash_active, upi } from '@pages/cashier/image';
import { ToastMessageContext } from 'context/toast-context';
import UserCard from '../UserCard';
import BillInformation from '../bill/BillInformation';
import './payment-method-sidebar.scss';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const paymentMethod = [
  {
    title: 'Cash',
    icon: cash,
    iconActive: cash_active,
    method: 'cash',
  },
  {
    title: 'Card',
    icon: card,
    iconActive: card,
    method: 'card',
  },
  {
    title: 'UPI',
    icon: upi,
    iconActive: upi,
    method: 'upi',
  },
  {
    title: 'Bank',
    icon: bank,
    iconActive: bank,
    method: 'bank',
  },
];

function PaymentMethodSideBar(props) {
  const {
    user = {},
    fee = [],
    listScholarships = [],
    applicationId,
    handlClickBack = () => { },
    handleClickCancel = () => { },
  } = props;
  const [method, setMethod] = useState(paymentMethod[0].method);
  const [isLoading, setIsLoading] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);

  const totalFee = useMemo(() => {
    const total = fee.reduce((sum, item) => {
      if (item.isSelected) {
        return sum + item.price;
      }
      return sum;
    }, 0);
    return total;
  }, [fee]);
  const totalFeeScholarship = useMemo(() => {
    const total = listScholarships.reduce((sum, item) => {
      if (item.isApproved && !item.isUsed) {
        return sum + item.price;
      }
      return sum;
    }, 0);
    return total;
  }, [listScholarships]);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const handleConfirm = (orderId) => {
    setIsLoading(true);
    const listFeesCallAPI = {};
    fee.forEach((f) => {
      listFeesCallAPI[f.name] = {
        fee: f.price,
        isPaid: f.isSelected || f.isPaid,
      };
    });
    const listScholarshipsCallAPI = {};
    listScholarships.forEach((sch) => {
      listScholarshipsCallAPI[sch.id] = {
        isUsed: sch.isApproved,
      };
    });
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/fee-payment/payment-information/${applicationId}`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: {
    //     data: JSON.stringify({
    //       fees: listFeesCallAPI,
    //       scholarships: listScholarshipsCallAPI,
    //     }),
    //     orderId,
    //   },
    // })
    admissionApi.updatePaymentInfo({ campusId: campus?.isCentral ? undefined : campus?.id }, {
      data: JSON.stringify({
        fees: listFeesCallAPI,
        scholarships: listScholarshipsCallAPI,
      }),
      orderId,
    }, applicationId)
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Payment Successfully',
            message: 'Payment and information update successfully',
          });
          setIsShowToastMessage(true);
          handleClickCancel();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update Payment Information Failed',
            message: res.data?.message || '',
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((err) => {
        setToastMessage({
          status: 'error',
          title: 'Update Payment Information Failed',
          message: err,
        });
        setIsShowToastMessage(true);
      }).finally(() => {
        setIsLoading(false);
      });
  };
  const handlePayment = async () => {
    const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js');
    if (!res) {
      setToastMessage({
        status: 'error',
        title: 'Connect to razorpay failed',
      });
      setIsShowToastMessage(true);
      setIsLoading(false);
      return;
    }
    const amount = totalFee - totalFeeScholarship;
    console.log('amount', amount);
    let infoPayment = []
    fee.forEach(f => {
      if (!f.isPaid && f.isSelected) {
        infoPayment.push({ name: f.name, fee: f.price, razorpayBankAccountId: f.razorpayBankAccountId })
      }
    })
    let infoScholarship = []
    listScholarships.forEach(sch => {
      if (sch.isApproved && !sch.isUsed) {
        infoScholarship.push({ [sch.title]: sch.price });
      }
    })
    const orderInfo = {
      feeInfo: JSON.stringify(infoPayment),
      scholarship: JSON.stringify(infoScholarship)
    }
    // const order = await callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/fee-payment/payment-oder`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id, applicationId, amount: +amount },
    //   data: {
    //     ...orderInfo
    //   }
    // });
    const order = await admissionApi.paymentOrder({ campusId: campus?.isCentral ? undefined : campus?.id, applicationId, amount: +amount }, { ...orderInfo })
    if (!order.data?.success) {
      setToastMessage({
        status: 'error',
        title: 'Create payment failed',
        message: order.data?.message,
      });
      setIsShowToastMessage(true);
      setIsLoading(false);
      return;
    }
    const orderId = order.data?.data?.data?.id;
    if (!orderId) {
      setToastMessage({
        status: 'error',
        title: 'Create payment failed',
      });
      setIsShowToastMessage(true);
      setIsLoading(false);
      return;
    }

    const options = {
      key: process.env.REACT_APP_RAZORPAY_KEY, // Enter the Key ID generated from the Dashboard
      amount: amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: 'INR',
      name: user.name || '',
      description: 'Application Fee',
      image:
        'https://storage.googleapis.com/giiki-bucket/media/$2b$10$3CMz6gAGxOoaYWHeAAmgcezNTmUsSAVU3kkojv9mvz0kg1cPblPjy',
      order_id: orderId, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
      handler: function (response) {
        handleConfirm(orderId);
      },
      prefill: {
        name: user.name || '',
        // "email": "gaurav.kumar@example.com",
      },
      notes: {
        applicationId: applicationId,
      },
      theme: {
        color: '#3399cc',
      },
    };
    setIsLoading(false);
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  };

  const handleClickPay = async (method) => {
    setIsLoading(true);
    switch (method) {
      case 'upi':
        break;
      case 'cash':
        await handlePayment();
        break;
      default:
        return;
    }
  };

  return (
    <div className="pt-16 px-8 w-96 min-w-[24rem] payment-method-sidebar">
      <div className="flex items-center justify-between mt-6 mb-8">
        <button onClick={handlClickBack}>
          <img src={back} alt="Go back" />
        </button>
        <div className="w-72">
          <UserCard user={user} applicationId={applicationId} />
        </div>
      </div>
      <div>
        <BillInformation fee={fee} listScholarships={listScholarships} />
      </div>
      <p className="payment-method-method">Payment Method</p>
      <div className="flex mb-6">
        {paymentMethod.map((mt) => {
          return (
            <div
              key={mt.method}
              className="flex flex-col items-center cursor-pointer"
              onClick={() => {
                handleClickPay(mt.method);
              }}
            >
              <img src={method === mt.method ? mt.iconActive : mt.icon} alt={mt.title} />
              <p className="payment-method-title">{mt.title}</p>
            </div>
          );
        })}
      </div>
      {isLoading && (
        <div className="fixed inset-0 bg-[#33333390] z-30">
          <div className="flex items-center justify-center w-full h-full">
            <Loading />
          </div>
        </div>
      )}
    </div>
  );
}

export default PaymentMethodSideBar;
