import { CALL_RESULT_TYPE } from "@constant/index"

const getStatus = (businessCallType) => {
  if (!businessCallType) return ''

  if (businessCallType.startsWith(CALL_RESULT_TYPE['Agent Missed'])) return CALL_RESULT_TYPE['Agent Missed']

  if (businessCallType.startsWith(CALL_RESULT_TYPE['Customer Missed'])) return CALL_RESULT_TYPE['Customer Missed']

  if (businessCallType === CALL_RESULT_TYPE.Phone) return CALL_RESULT_TYPE['Completed']
}

export { getStatus }