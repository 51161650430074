import { callApi } from '@helper/call-api';
import { isEmpty } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './enquiryForm.scss';
import ReCAPTCHA from 'react-google-recaptcha';
import centralAdminApi from 'api/central-admin';

export default function EnquiryForm() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const companyName = searchParams.get('company');
  const [companyId, setCompanyId] = useState();
  const [campus, setCampus] = useState('');
  const [campusList, setCampusList] = useState([]);
  const [year, setYear] = useState();
  const [yearList, setYearList] = useState([]);
  const [grade, setGrade] = useState();
  const [gradeList, setGradeList] = useState([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [disableCampus, setDisableCampus] = useState(true);
  const [disableYear, setDisableYear] = useState(true);
  const [disableGrade, setDisableGrade] = useState(true);
  const [token, setToken] = useState('');
  const [finished, setFinished] = useState(false);
  const recaptchaRef = useRef();
  const handleRecaptchaChange = (token) => {
    setToken(token);
  };

  useEffect(() => {
    window.recaptchaOptions = {
      // useRecaptchaNet: true,
      enterprise: true,
    };
  }, []);
  useEffect(() => {
    (async () => {
      // const res = await callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company/search`,
      //   params: { name: companyName },
      // });
      const res = await centralAdminApi.getCompanyById({ name: companyName })
      let id;
      if (res.data.success) {
        setCompanyId(res.data.data[0].id);
        id = res.data.data[0].id;
        // const res2 = await callApi({
        //   method: 'get',
        //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/campus/company`,
        //   params: { companyId: id },
        // });
        const res2 = await centralAdminApi.getListCampusOfCompany({ companyId: id })
        if (res2.data.success) {
          setCampusList(res2.data.data.objects);
          setCampus(res2.data.data.objects[0].id);
        }
      } else {
        setCompanyId('');
        setCampusList([]);
        setCampus('');
      }
      setDisableCampus(false);
      setDisableYear(false);
      setDisableGrade(false);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      setDisableYear(true);
      setDisableGrade(true);
      if (campus) {
        // const res = await callApi({
        //   method: 'get',
        //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/academic/list-for-enquiry`,
        //   params: { campusId: campus },
        // });
        const res = await centralAdminApi.getListYearForEnquiry({ campusId: campus })
        if (res.data.success) {
          setYearList(res.data.data.objects);
          setYear(!isEmpty(res.data.data.objects) ? res.data.data.objects[0].name : '');
        } else {
          setYearList([]);
          setYear('');
        }
      } else {
        setYearList([]);
        setYear('');
      }

      setDisableYear(false);
      setDisableGrade(false);
    })();
  }, [campus]);

  useEffect(() => {
    (async () => {
      setDisableGrade(true);
      if (year) {
        // const res = await callApi({
        //   method: 'get',
        //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/grade/academic`,
        //   params: { campusId: campus, academicName: year },
        // });
        const res = await centralAdminApi.getListGradeOfAcademicYear({ campusId: campus, academicName: year })
        if (res.data.success) {
          const sortedArray = res.data.data.objects ?? [];
          setGradeList(sortedArray);
          setGrade(sortedArray[0]?.id || '');
        }
      } else {
        setGradeList([]);
        setGrade('');
      }
      setDisableGrade(false);
    })();
  }, [year]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const data = {
      token: recaptchaRef.current.getValue(),
      campusId: campus,
      academicName: year,
      grade: grade,
      firstName: firstName,
      lastName: lastName,
      phone: phoneNumber,
      email: email,
    };
    // const res = await callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_CENTRAL_ADMIN}/api/v1/admission/company/submit-enquiry-form`,
    //   data: data,
    // });
    const res = await centralAdminApi.submitEnquiryForm(data)
    console.log('res: ', res);

    if (res.data.success) {
      setFinished(true);
    }
  };

  const onChangeToken = (t) => {
    console.log('token: ', t);
    setToken(t);
  };

  const handleClickReset = () => {
    setFinished(false);
    setFirstName('');
    setLastName('');
    setPhoneNumber('');
    setEmail('');
  };

  return (
    <div className={`enquiry-form-wrapper`}>
      {!finished ? (
        <>
          <h1>Enquiry Form</h1>
          <div className="form-wrapper">
            <form
              onSubmit={(e) => {
                handleSubmit(e);
              }}
              id="f_form"
            >
              <div className="campus-select-wrapper">
                <label htmlFor="campus">Campus: </label>
                <select
                  name="campus"
                  id="f_campus"
                  required
                  value={campus}
                  onChange={(e) => {
                    setCampus(e.target.value);
                  }}
                  disabled={disableCampus}
                >
                  {!isEmpty(campusList) &&
                    campusList.map((campus, index) => (
                      <option value={campus.id} key={index}>
                        {campus.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="academic-year-wrapper">
                <label htmlFor="academic_year">Academic Year: </label>
                <select
                  name="academic_year"
                  id="f_academic_year"
                  required
                  value={year}
                  onChange={(e) => {
                    setYear(e.target.value);
                  }}
                  disabled={disableYear}
                >
                  {!isEmpty(yearList) &&
                    yearList.map((y, index) => (
                      <option value={y.name} key={index}>
                        {y.name}
                      </option>
                    ))}
                </select>
              </div>
              <div className="grade-wrapper">
                <label htmlFor="grade">Grade: </label>
                <select
                  name="grade"
                  id="f_grade"
                  required
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                  disabled={disableGrade}
                >
                  {gradeList.map((g) => (
                    <option value={g.id} key={g.id}>
                      {g.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="input-wrapper">
                <label htmlFor="first_name">Student First name: </label>
                <input
                  type="text"
                  name="first_name"
                  id="first_name"
                  required
                  value={firstName}
                  onChange={(e) => {
                    setFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="input-wrapper">
                <label htmlFor="last_name">Student Last name: </label>
                <input
                  type="text"
                  name="last_name"
                  id="last_name"
                  required
                  value={lastName}
                  onChange={(e) => {
                    setLastName(e.target.value);
                  }}
                />
              </div>
              <div className="phone-wrapper">
                <label htmlFor="phone">Phone: </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  required
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </div>
              <div className="email-wrapper">
                <label htmlFor="email">Email: </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="captcha-wrapper">
                <ReCAPTCHA
                  ref={recaptchaRef}
                  sitekey={process.env.REACT_APP_RECAPTCHA_ENTERPRISE_SITE_KEY}
                  onChange={onChangeToken}
                />
              </div>
              <div className="submit-button-wrapper">
                <button type="submit">Submit an Enquiry</button>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <h1>The Enquiry is submited</h1>
          <div className={`reset-button-wrapper`}>
            <button onClick={handleClickReset}>Fill another Enquiry</button>
          </div>
        </>
      )}
    </div>
  );
}
