import BankItem from './bank-account-item';

function BankAccountList(props) {
  const { listCampus = [], listBankAccounts = [] } = props;
  return (
    <>
      {listBankAccounts.length > 0 ? (
        <div>
          {listBankAccounts.map((bankAccount, idx) => {
            return (
              <BankItem
                listCampus={listCampus}
                bankAccount={bankAccount}
                key={bankAccount?.id || idx}
              />
            );
          })}
        </div>
      ) : (
        <div>Have no bank account</div>
      )}
    </>
  );
}

export default BankAccountList;
