import React, { useState } from 'react';
import Zoom from 'react-reveal/Zoom';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { graphstats, calls, } from './image';
import { getCampusRecent, getCompany, getCurrentCampusOrCompany, getMenu, objFieldGiikiInfo, updateGiikiInfo } from '../../../../../../utils/storage';
import { useEffect } from 'react';
import { campusLinkDefault, centralLinkDefault, comingSoonDefault } from 'entities/data';
// import { useDispatch } from 'react-redux';
import { setCampusRecent, setCurrentCampusOrCompany, setIsCentral, setIsFinance, setRoutes, setTitle } from 'store/action-creators/navbarActions';
import { useSelector } from 'react-redux';
import { getCampusRecentSelector, getCompanySelector, getCurrentCampusOrCompanySelector } from 'store/selector/navbarSelector';


export default function NavApp(props) {
    const { visible } = props;
    const [centralLink, setCentralLink] = useState(centralLinkDefault);
    const [campusLink, setCampusLink] = useState(campusLinkDefault);
    // const dispatch = useDispatch()
    const menu = getMenu()
    const recentCampus = useSelector(getCampusRecentSelector) || getCampusRecent() || []
    const company = useSelector(getCompanySelector) || getCompany()
    const current = useSelector(getCurrentCampusOrCompanySelector) || getCurrentCampusOrCompany()

    useEffect(() => {
        if (menu) {
            setCentralLink(prev => {
                const tmp = [...prev]
                tmp.map(state => ({
                    ...state,
                    isActive: menu?.listMenu?.[state.fieldCheck]
                }))
                return tmp
            })
            setCampusLink(prev => {
                const tmp = [...prev]
                tmp.map(state => ({
                    ...state,
                    isActive: menu?.listMenu?.[state.fieldCheck]
                }))
                return tmp
            })
        }
    }, [visible])

    const updateNavbar = (item) => {
        // onClosed()
        // dispatch(setRoutes(item.routes));
        // dispatch(setTitle(item.label));
        // dispatch(setIsFinance(!!item.isFinance))
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { routes: item.routes });
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { title: item.label });
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { isFinance: !!item.isFinance })
    }

    const onClickCampusLink = (item) => {
        // dispatch(setIsCentral(false))
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { isCentral: false });
        // updateNavbar(item)

        // let campus
        // if (recentCampus && recentCampus?.length !== 0) {
        //     campus = campuses.find(item => item.id === recentCampus[0])
        //     if (campus) recentCampus.shift()
        // } else {
        //     campus = campuses[0]
        // }
        // dispatch(setCurrentCampusOrCompany(campus))
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { currentCampusOrCompany: campus })
    }

    const onClickCentralLink = (item) => {
        // if (!current?.isCentral && current?.id) {
        //     const tmp = [...recentCampus, current?.id].filter(item => item !== current?.id);
        //     dispatch(setCampusRecent(tmp))
        //     updateGiikiInfo(objFieldGiikiInfo.navbar, { campusRecent: tmp })
        // }

        // dispatch(setIsCentral(true));
        // dispatch(setCurrentCampusOrCompany(company))
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { currentCampusOrCompany: company })
        // updateGiikiInfo(objFieldGiikiInfo.navbar, { isCentral: true });
        // updateNavbar(item)
    }

    const renderSingle = (item, onClick) => {
        return (
            <React.Fragment key={item.label}>
                {item.isActive ? (
                    <NavLink
                        className="flex flex-col transform hover:-translate-y-1 transition-all mb-4 items-center overflow-ellipsis justify-center w-28 h-28 rounded-xl shadow-mini"
                        to={item.link}
                        onClick={() => onClick(item)}
                    >
                        <img
                            src={item.img}
                            alt="settings"
                            className={` ${item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
                                } h-14`}
                        />
                        <p className="overflow-ellipsis overflow-hidden whitespace-pre w-24 text-center text-sm mt-2">
                            {item.label}
                        </p>
                    </NavLink>
                ) : (
                    <div
                        className="relative flex flex-col cursor-not-allowed mb-10 items-center overflow-ellipsis justify-center rounded-xl shadow-mini w-28 h-28"
                    >
                        <img
                            src={item.img}
                            alt="settings"
                            className={` ${item.img === graphstats || item.img === calls ? 'w-20' : 'w-14'
                                } h-14 opacity-50`}
                        />
                        <p className="whitespace-pre-wrap w-26 text-center text-sm mt-4 opacity-50 break-words">
                            {item.label}
                        </p>
                    </div>

                )}
            </React.Fragment>
        )
    }

    const renderCentralLayout = () => {
        return (
            <div>
                <p className="text-second-gray font-bold text-sm p-1 pr-5 pl-5 mb-4">
                    Central Apps
                </p>
                <div className="grid grid-cols-3 ">
                    {centralLink.map((item) => (
                        renderSingle(item, onClickCentralLink)
                    ))}
                </div>
            </div>
        )
    }

    const renderCampusLayout = () => {
        return (
            <>
                <p className="text-second-gray font-bold text-sm p-1 pr-5 pl-5 mb-4">
                    Campus Apps
                </p>
                <div className="grid grid-cols-3">
                    {campusLink.map((item) => (
                        renderSingle(item, onClickCampusLink)
                    ))}
                </div>

                {renderComingSoon()}
            </>
        )
    }

    const renderComingSoon = () => {
        return (
            <div className="grid grid-cols-3">
                {comingSoonDefault.map((item, key) => (
                    <NavLink
                        className="flex flex-col transition-all mb-4 items-center overflow-ellipsis justify-center w-28 h-28 rounded-xl shadow-mini cursor-not-allowed"
                        to={item?.link || ''}
                        // onClick={onClosed}
                        key={key}
                    >
                        <img src={item.img} alt="settings" className="w-14 h-14 opacity-50" />
                        <p className="overflow-ellipsis overflow-hidden whitespace-pre w-24 text-center text-sm mt-2 opacity-50">
                            {item.label}
                        </p>
                        <p className="overflow-ellipsis overflow-hidden whitespace-pre w-26 text-center text-sm opacity-50">
                            Coming soon...
                        </p>
                    </NavLink>
                ))}
            </div>
        )
    }


    return (
        <Zoom duration={300} when={visible}>
            <div
                className={clsx(
                    'profile-box-card',
                    'absolute pl-4 pt-4 pb-4 top-14 right-0 overflow-y-scroll w-[26rem] h-[20.063rem]',
                    visible ? '' : 'hidden',
                )}
                style={{ width: '26rem', height: '20.063rem' }}
            >

                {menu?.isCentral && renderCentralLayout()}

                {renderCampusLayout()}
            </div>
        </Zoom>
    );
}

NavApp.propTypes = {
    visible: PropTypes.bool,
    onCLosed: PropTypes.func,
};