const DefaultAvatar = (props) => {
  const { title, className } = props
  let char = title?.[0] || ''
  const class_ = className ? className : `w-5 aspect-square rounded-full`

  const colors = {
    A: '#f87171',
    B: '#fb923c',
    C: '#fbbf24',
    D: '#facc15',
    E: '#a3e635',
    F: '#4ade80',
    G: '#2dd4bf',
    H: '#22d3ee',
    I: '#38bdf8',
    J: '#60a5fa',
    K: '#818cf8',
    L: '#a78bfa',
    M: '#c084fc',
    N: '#e879f9',
    O: '#f472b6',
    P: '#fb7185',
    Q: '#6366f1',
    R: '#0ea5e9',
    S: '#3b82f6',
    T: '#06b6d4',
    U: '#14b8a6',
    V: '#10b981',
    W: '#737373',
    X: '#0284c7',
    Y: '#0d9488',
    Z: '#ca8a04',

  }

  return (
    <div className={`uppercase font-medium text-white flex items-center justify-center ${class_}`}
      style={{ backgroundColor: colors[char?.toUpperCase()] }}
    >
      {char}
    </div>
  )
}

export default DefaultAvatar;