export function compareObjects(obj1, obj2) {
  const result = [];
  const now = new Date();
  const timeString = now.toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  });

  // Check for new or updated fields
  Object.keys(obj2).forEach((key) => {
    // const key = camelToKebabCase(key)
    if (!obj1.hasOwnProperty(key)) {
      // Field was added
      if (Array.isArray(obj2[key])) {
        if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
          const changes = compareObjects(obj1[key], obj2[key]);
          for (const change of changes) {
            result.push({
              title: `Update ${key}`,
              subtitle: change.subtitle,
              time: timeString,
              type: 'Edit',
            });
          }
        } else {
          result.push({
            title: `Add new ${key}`,
            subtitle: `Add ${obj2[key].join(', ')} to ${key}`,
            time: timeString,
            type: 'Add',
          });
        }
      } else {
        result.push({
          title: `Add new ${key}`,
          subtitle: `Add ${obj2[key]} to ${key}`,
          time: timeString,
          type: 'Add',
        });
      }
    } else if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
      // Field was updated
      if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
        const removed = obj1[key].filter((x) => !obj2[key].includes(x));
        const added = obj2[key].filter((x) => !obj1[key].includes(x));
        if (added.length > 0) {
          result.push({
            title: `Add ${key}`,
            subtitle: `Add ${added.join(', ')} to ${key}`,
            time: timeString,
            type: 'Add',
          });
        }
        if (removed.length > 0) {
          result.push({
            title: `Remove ${key}`,
            subtitle: `Remove ${removed.join(', ')}`,
            time: timeString,
            type: 'Delete',
          });
        }
        // }
      } else if (typeof obj1[key] === 'object' && typeof obj2[key] === 'object') {
        const changes = compareObjects(obj1[key], obj2[key]);
        for (const change of changes) {
          result.push({
            title: `Update ${key}`,
            subtitle: change.subtitle,
            time: timeString,
            type: 'Edit',
          });
        }
      } else {
        result.push({
          title: `Update ${key}`,
          subtitle: `Change ${key} from ${obj1[key]} to ${obj2[key]}`,
          time: timeString,
          type: 'Edit',
        });
      }
    }
  });

  // Check for Removed fields
  Object.keys(obj1).forEach((key) => {
    // const key = camelToKebabCase(key)
    if (!obj2.hasOwnProperty(key)) {
      if (Array.isArray(obj1[key])) {
        result.push({
          title: `Remove ${key}`,
          subtitle: `Remove ${obj1[key].join(', ')} from ${key}`,
          time: timeString,
          type: 'Delete',
        });
      } else {
        result.push({
          title: `Remove ${key}`,
          subtitle: `Remove ${obj1[key]} from ${key}`,
          time: timeString,
          type: 'Delete',
        });
      }
    }
  });

  return result;
}
