import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Input } from '@stories/index';
import { localStorageKey, sessionStorageKey } from '@utils/storage';
import { ToastMessageContext } from 'context/toast-context';
import { deleteIcon, editIcon } from './icon';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const CampusName = (props) => {
  const {
    id,
    index,
    isBuild,
    deletable,
    isFocus,
    isEdit,
    component,
    label,
    value,
    error,
    textForError,
    formStyle,
    handleFocus,
    handleDelete,
    handleDragStart,
    handleDragOver,
    ...rest
  } = props;
  const { width, height, ...restFormStyle } = formStyle;
  const [name, setName] = useState('');
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const campus = useStorage((state) => state.currentCampus);
  const campusId = useMemo(() => {
    let auth = {};
    auth = sessionStorage.getItem(sessionStorageKey);
    if (!auth) auth = localStorage.getItem(localStorageKey);
    return JSON.parse(auth).campusId || campus?.id;
  }, [campus]);

  const fetchData = useCallback(() => {
    centralAdminApi.getCampus({ campusId })
      .then((res) => {
        if (res.data.success) {
          const { name } = res.data.data;
          setName(name);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Get Campus Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Get Campus Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Campus Name Form Builder', error);
      });
  }, [campusId]);

  useEffect(() => {
    if (campus?.name) {
      setName(campus.name);
    } else {
      fetchData();
    }
  }, [campusId]);

  const attributes = {
    label,
    value: name,
    customStyle: { width: '100%', height },
    inputSize: 'small',
    onChange: () => { },
    ...rest,
  };

  return (
    <div
      className="component"
      style={{
        width,
        ...restFormStyle,
        outline: error ? '1px #ff4842 solid' : isFocus ? '1px black solid' : '',
        outlineOffset: '0rem',
      }}
    >
      <div className="setting items-center absolute bg-white px-[0.15rem] right-2 top-[-0.5rem] z-10">
        {isBuild && (
          <>
            <img
              alt=''
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            <img
              alt=''
              className="ml-2 cursor-pointer"
              title="delete"
              src={deleteIcon}
              onClick={() => {
                handleDelete(index);
              }}
            />
          </>
        )}
        {isEdit && (
          <>
            <img
              alt=''
              className="cursor-pointer"
              title="edit"
              src={editIcon}
              onClick={() => {
                handleFocus(index);
              }}
            />
            {deletable && (
              <img
                alt=''
                className="ml-2 cursor-pointer"
                title="delete"
                src={deleteIcon}
                onClick={() => {
                  handleDelete(index);
                }}
              />
            )}
          </>
        )}
      </div>
      <Input {...attributes} disabled={true} />
      {error && textForError && (
        <p className="text-sm" style={{ color: '#ff4842', marginLeft: 10 }}>
          {textForError}
        </p>
      )}
    </div>
  );
};
export default CampusName;
