import moment from 'moment';
import {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import { messaging } from '@pages/Login/components/UserLogin/firebase';
import { checkErrorApiFetch, checkErrorSingleApi } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { onMessage } from 'firebase/messaging';
import AreaCommenting from '../area-commenting';
import SingleComment from './SingleComment';
import './style.scss';
import notificationApi from 'api/notification';
import taskApi from 'api/task';
import useStorage from 'store/storage';

const pageSize = 10;

export default function TaskComment(props) {
  const { taskId, customStyle, customClass } = props;
  // const taskId = '88feebec-98be-4efc-9a51-2c613491cf2e';
  const [data, setData] = useState([]);
  const [listConversation, setListConversation] = useState();
  const [refresh, setRefresh] = useState(0);
  const [clear, setClear] = useState(false);
  const [infoAction, setInfoAction] = useState({});
  const [isFinish, setIsFinish] = useState(false);
  const [page, setPage] = useState(0);
  const [pageRefresh, setPageRefresh] = useState();
  const [isFinishFetchMore, setIsFinishFetchMore] = useState(true);
  const [outOfData, setOutOfData] = useState(false);
  const refElement = useRef();
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const authId = useStorage((state) => state.auth).uid;
  const campus = useStorage((state) => state.currentCampus);
  const refInfoAction = useRef();
  refInfoAction.current = infoAction;
  const refOutOfData = useRef();
  refOutOfData.current = outOfData;

  const handleScroll = useCallback((event) => {
    const { scrollTop, clientHeight, scrollHeight } = event;
    let isTop = Math.ceil(scrollTop + clientHeight) > scrollHeight * 0.998;
    if (isTop && !refOutOfData.current) {
      isTop = false;
      setPage((prev) => prev + 1);
    }
  }, []);

  useEffect(() => {
    if (refElement.current) {
      refElement.current.addEventListener('scroll', (e) => handleScroll(e.target));
    }

    return () => {
      if (refElement.current) {
        refElement.current.removeEventListener('scroll', (e) => handleScroll(e.target));
      }
    };
  }, [isFinish]);

  useEffect(() => {
    const userInfo = JSON.parse(
      sessionStorage.getItem('giiki-web') || localStorage.getItem('giiki-web'),
    );
    const deviceToken = userInfo?.deviceToken;
    if (deviceToken !== 'turnoff') {
      // callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_NOTIFICATION}/api/v1/notification/subscribeTopic`,
      //   data: { topic: taskId },
      // })
      notificationApi.subscribeTopicNotification({ topic: taskId })
        .then((results) => {
          if (results.data.success) {
            setRefresh((prev) => prev + 1);
            const broadcast = new BroadcastChannel('background-message');
            broadcast.onmessage = (event) => {
              console.log('message BACKGROUND Topic', event);
              setRefresh((prev) => prev + 1);
            };

            onMessage(messaging, (payload) => {
              console.log('message force background Topic', payload);
              setRefresh((prev) => prev + 1);
            });
          } else {
            setToastMessage({
              status: 'error',
              title: 'Subscribe Topic Failed',
              message: results.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Subscribe Topic Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('Subscribe Topic Error', error);
        });

    } else {
      setToastMessage({
        status: 'warning',
        title: 'Notification Permission',
        message:
          'Browser notifications have been turned off. Please turn on to receive notifications',
      });
      setIsShowToastMessage(true);
    }
  }, []);

  //comment
  // run first time and every time refresh
  useEffect(() => {
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/conversation/list`,
    //   params: { taskId, page: pageRefresh || 0, pageSize, campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    taskApi.getListConversation({ taskId, page: pageRefresh || 0, pageSize, campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (
          checkErrorApiFetch(
            res,
            setToastMessage,
            setIsShowToastMessage,
            'Get Task Comment',
          )
        ) {
          if (pageRefresh) {
            setData((prev) => {
              const head = prev.slice(0, pageRefresh * pageSize);
              const tail = prev.slice(pageRefresh + 1);
              return [...head, ...res.data.data.objects, ...tail];
            });
          } else {
            setData((prev) => res.data.data.objects);
          }
          setIsFinish(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('error', error);
      });
  }, [refresh]);

  // run every time load more
  useEffect(() => {
    if (page > 0) {
      setIsFinishFetchMore(false);
      // callApi({
      //   method: 'get',
      //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/conversation/list`,
      //   params: { taskId, page: page, pageSize, campusId: campus?.isCentral ? undefined : campus?.id },
      // })
      taskApi.getListConversation({ taskId, page: page, pageSize, campusId: campus?.isCentral ? undefined : campus?.id })
        .then((res) => {
          if (
            checkErrorApiFetch(
              res,
              setToastMessage,
              setIsShowToastMessage,
              'Get Task Comments',
            )
          ) {
            if (res.data.data.length === 0) {
              setOutOfData(true);
            }
            setData((prev) => [...prev, ...res.data.data]);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Fetch Data Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('error', error);
        })
        .finally(() => {
          setIsFinishFetchMore(true);
        });
    }
  }, [page]);

  // run every time the data and infoAction changes
  useEffect(() => {
    setListConversation(render(data, 0));
  }, [data, infoAction]);

  // get duration time
  const durationTime = useCallback((date) => {
    if (!date) return '';
    const dateCurrent = moment(new Date());
    const date1 = new Date(date);
    const dateBefore = moment(date1);
    const years = dateCurrent.diff(dateBefore, 'years');
    const months = dateCurrent.diff(dateBefore, 'months');
    const days = dateCurrent.diff(dateBefore, 'days');
    const hours = dateCurrent.diff(dateBefore, 'hours');
    const minutes = dateCurrent.diff(dateBefore, 'minutes');
    const seconds = dateCurrent.diff(dateBefore, 'seconds');
    const obj = { years, months, days, hours, minutes, seconds };

    for (const key of Object.keys(obj)) {
      if (!obj.hasOwnProperty(key)) continue;
      if (key === 'years' && obj[key] >= 2)
        return date1
          .toLocaleDateString('en-GB', {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
          })
          .replace(/ /g, ' ');
      if (obj[key] > 0) return `${obj[key] + 1} ${key} ago`;
    }
  }, []);

  const render = useCallback(
    (data, level) => {
      if (data.length !== 0)
        return data.map((item, index) => {
          const {
            id,
            message,
            emotion,
            mentions,
            filesURL,
            createdBy,
            dateCreated,
            dateUpdated,
            subConversation,
          } = item;
          const { name, photoURL, id: idUser } = createdBy;
          const duration = durationTime(dateCreated);
          const info = {
            edited: dateCreated !== dateUpdated,
            owner: idUser === authId,
            message,
            emotion,
            mentions,
            filesURL,
            name,
            photoURL,
            duration,
            taskId,
            id,
          };
          let children = [];
          if (subConversation && subConversation.length !== 0) {
            children = render(subConversation, level + 1);
          }
          return (
            <SingleComment
              key={id}
              index={index}
              setInfoAction={setInfoAction}
              backgroundColor={id === infoAction?.info?.id ? '#80808029' : 'white'}
              info={info}
              children={children}
              customStyle={{
                width: `${100 - level * 5}%`,
                marginLeft: `${level * 5}%`,
              }}
            />
          );
        });
    },
    [infoAction],
  );

  const Reply = useCallback((value) => {
    // const { info } = infoAction;
    const { info, index } = refInfoAction.current;
    const page = Math.floor(index % pageSize);
    const { id, taskId } = info;
    const data = {
      taskId,
      fatherId: id,
      message: value.message,
    };

    // callApi({
    //   method: 'post',
    //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/conversation`,
    //   data: data,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    taskApi.createNewMessage({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
      .then((res) => {
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
          setToastMessage({
            status: 'success',
            title: 'Reply Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Replying Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('error', error);
      })
      .finally(() => {
        setPageRefresh(page);
        setRefresh((prev) => prev + 1);
        setClear(true);
        setInfoAction({});
      });
    // "taskId": "88feebec-98be-4efc-9a51-2c613491cf2e",
    // "message" : "Everything seems to be good we can goahead",
    // "fatherId": "78953520-75fd-4830-84f2-a36d7fd823e9",
    // "emotion": [":)"],
    // "filesURL": ["fileData"],
    // "mentions": [":darkit22"]
  }, []);

  const Edit = useCallback((value) => {
    // const { info } = infoAction;
    const { info, index } = refInfoAction.current;
    const page = Math.floor(index % pageSize);
    const { id } = info;
    const data = {
      message: value.message,
    };
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/conversation/${id}`,
    //   data,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    taskApi.editMessage({ campusId: campus?.isCentral ? undefined : campus?.id }, data, id)
      .then((res) => {
        if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
          // setClear(true);
          // setInfoAction({});
          // setRefresh((prev) => prev + 1);
          setToastMessage({
            status: 'success',
            title: 'Update Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Updating Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('error', error);
      })
      .finally(() => {
        setPageRefresh(page);
        setRefresh((prev) => prev + 1);
        setClear(true);
        setInfoAction({});
      });
  }, []);

  const Publish = useCallback(
    (value) => {
      console.log('Publish', value)
      const data = {
        message: value.message,
        taskId,
      };
      // callApi({
      //   method: 'post',
      //   url: `${process.env.REACT_APP_URL_API_TASK}/api/v1/task/conversation`,
      //   data: data,
      //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
      // })
      taskApi.createNewMessage({ campusId: campus?.isCentral ? undefined : campus?.id }, data)
        .then((res) => {
          if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage)) {
            // setRefresh((prev) => prev + 1);
            // setClear(true);
            setToastMessage({
              status: 'success',
              title: 'Publish Successfully',
              message: res.data.message,
            });
            setIsShowToastMessage(true);
          }
        })
        .catch((error) => {
          setToastMessage({
            status: 'error',
            title: 'Publishing Failed',
            message: error.response?.data?.message || error,
          });
          setIsShowToastMessage(true);
          console.log('error', error);
        })
        .finally(() => {
          setPageRefresh(0);
          setOutOfData(false);
          setRefresh((prev) => prev + 1);
          setClear(true);
          refElement.current.scrollTop = 0;
        });
    },
    [taskId, refElement],
  );

  const handlePublish = (value) => {
    if (!value) return;
    const { action } = refInfoAction.current;
    switch (action) {
      case 'edit':
        Edit(value);
        break;
      case 'reply':
        Reply(value);
        break;
      default:
        Publish(value);
    }
  };

  if (!isFinish) return <p>Loading...</p>;

  if (isFinish && data.length === 0)
    return (
      <div
        id="task-comment"
        style={customStyle}
        className={`relative overflow-y-hidden ${customClass}`}
      >
        <div ref={refElement} className="w-full h-[72%] overflow-y-auto pt-10">
          Don't have conversation
        </div>
        <AreaCommenting
          actions={{ emoji: true, enclose: false, record: false }}
          handlePublish={handlePublish}
          infoAction={infoAction}
          clear={clear}
          setClear={setClear}
          customStyle={{
            width: '100%',
            position: 'absolute',
            bottom: '0px',
            left: '0px',
          }}
        />
      </div>
    );

  return (
    <div
      id="task-comment"
      style={customStyle}
      className={`relative overflow-y-hidden ${customClass}`}
    >
      <div
        ref={refElement}
        className="w-full h-[72%] overflow-y-auto flex flex-col-reverse"
      >
        {listConversation}
        {!isFinishFetchMore && <p>Loading...</p>}
      </div>
      {Object.keys(infoAction).length !== 0 ? (
        <div
          className="flex pl-4 h-[5%] w-full rounded justify-between items-center"
          style={{ backgroundColor: 'beige' }}
        >
          {infoAction?.action === 'edit' && <p className="text-sm text-black">edit</p>}
          {infoAction?.action === 'reply' && (
            <p className="text-sm text-black">
              reply <span className="text-emerald-500">@{infoAction?.info?.name}</span>
            </p>
          )}
          <svg
            className="cursor-pointer"
            onClick={() => {
              setInfoAction({});
            }}
            width="22"
            height="22"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="m13.41 12 4.3-4.29a1.004 1.004 0 1 0-1.42-1.42L12 10.59l-4.29-4.3a1.004 1.004 0 0 0-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 0 0 0 1.42 1 1 0 0 0 1.42 0l4.29-4.3 4.29 4.3a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42L13.41 12Z"
              fill="#212B36"
            />
          </svg>
        </div>
      ) : (
        <div className="h-[5%] w-full"></div>
      )}
      <AreaCommenting
        actions={{ emoji: true, enclose: false, record: false }}
        handlePublish={handlePublish}
        infoAction={infoAction}
        clear={clear}
        setClear={setClear}
        customStyle={{
          width: '100%',
          position: 'absolute',
          bottom: '0px',
          left: '0px',
        }}
      />
    </div>
  );
}
