import * as _ from 'lodash';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Fade } from 'react-bootstrap';
import { colorOptionForUserSelect } from '../../entities/colorOption';
import { dataLinks } from '../../entities/routes';
import { selectStylesWithNoTitle2 } from '../../entities/select-style';
import { CompanyTabs, SelectGroup } from '../../stories';
import { NavLinks } from '../Header/components/index';
import AddGradeModal from './components/add-grade-modal';
import Footer from '@components/footer/footer';
import { compareObjects } from '@helper/compare-objects';
import { cloneDeep } from 'lodash';
import { ToastMessageContext } from '../../context/toast-context';
import { checkErrorMultipleApiFetch } from '../../utils/check-error/api-error';
import './admission-team-settings.scss';
import { GradeSetting } from './components/grade-setting';
import clsx from 'clsx';
import approvalApi from 'api/approval';
import centralAdminApi from 'api/central-admin';
import useStorage from 'store/storage';

const dividedOptions = [
  { value: 'Grades', label: 'Grades' },
  { value: 'Programs', label: 'Programs' },
];
const userWorkTypeOptions = [
  { value: 'In a group', label: 'In a group' },
  { value: 'Individually', label: 'Individually' },
];

export default function AdmissionTeamSettings({ burger }) {
  const [open, openModal] = useState(false);
  const [programsTab, setProgramsTab] = useState([]);
  const [programTabCurrent, setProgramTabCurrent] = useState();
  const [counselors, setCounselors] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [grades, setGrades] = useState([]);
  const [gradesOrigin, setGradesOrigin] = useState([]);
  const [settingsOfProgram, setSettingsOfProgram] = useState();
  const [previousSettingOfProgram, setPreviousSettingOfProgram] = useState();
  const [selectedDivided, setSelectedDivided] = useState();
  const [selectUserWorkType, setSelectUserWorkType] = useState();
  const [edited, setEdited] = useState();
  const [numberOfEdits, setNumberOfEdits] = useState(0);
  const [draftsList, setDraftsList] = useState([]);
  const [isRenderDraft, setIsRenderDraft] = useState(false);
  const [draftId, setDraftId] = useState();
  const [refresh, setRefresh] = useState(false);
  const campus = useStorage((state) => state.currentCampus);
  const userLogin = useStorage((state) => state.auth);
  const [isShowTextApproval, setIsShowTextApproval] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const isOpen = () => {
    openModal(!open);
  };

  const getDataFormApi = () => {
    const campusId = campus?.isCentral ? undefined : campus?.id
    const getCounselors = centralAdminApi.getUserOfCompanyOrCampus({
      campusId,
      companyId: userLogin?.companyId,
      excludeRoleNames: 'student;parent',
    });
    const getPrograms = centralAdminApi.getListProgram({ campusId })
    const getDrafts = centralAdminApi.getListTeamSettingDraft({ campusId });

    Promise.all([getCounselors, getPrograms, getDrafts])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const counselors = res[0].data.data.objects;
          const programs = res[1].data.data.objects;
          const tabData = programs.map((item, key) => {
            return item.name;
          });
          setProgramsTab(tabData);
          setProgramTabCurrent(tabData[0]);
          setCounselors(counselors);
          setPrograms(programs);

          const drafts = res[2].data.data.objects;
          setDraftsList(drafts);
          setNumberOfEdits(res[2].data.data.total);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed (Counselors, Program, Draft)',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Admission Team Settings', error);
      });
  };

  const getProgramId = useMemo(() => {
    const program = programs.find((item) => item.name === programTabCurrent);
    return program?.id;
  }, [programTabCurrent]);

  const getProgramName = useCallback((programId, programs) => {
    const result = programs.find((item) => item.id === programId);
    result && setProgramTabCurrent(result.name);
  }, []);

  const getAdmissionTeamSetting = () => {
    let programId = getProgramId;
    const campusId = campus?.isCentral ? undefined : campus?.id

    if (isRenderDraft) {
      const draftObject = draftsList.find((o) => o.id === draftId);
      programId = JSON.parse(draftObject?.data).programId;
    }

    const getListAvailableGrade = centralAdminApi.getListAvailableGrade({ campusId }, programId)
    const getListAdmissionTeamSetting = centralAdminApi.getListTeamSetting({ programId, campusId })
    const listAPI = [getListAvailableGrade, getListAdmissionTeamSetting];
    if (isRenderDraft) {
      const getDraftAdmissionTeamSetting = centralAdminApi.getDraftTeamSetting({ campusId }, draftId);
      listAPI.push(getDraftAdmissionTeamSetting);
    }

    Promise.all(listAPI)
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          setGrades(res[0].data.data.objects);
          setGradesOrigin(res[0].data.data.objects);

          const settings = res[1].data.data[0];
          const divided = { value: settings.divided, label: settings.divided };
          const userWorkType = {
            value: settings.userWorkType,
            label: settings.userWorkType,
          };
          setSelectedDivided(divided);
          setSelectUserWorkType(userWorkType);

          if (isRenderDraft) {
            const dataGetDraft = res[2].data.data;
            const admissionTeamSettingId = dataGetDraft.admissionTeamSettingId;
            const draftAdmissionTeamSetting = JSON.parse(
              dataGetDraft.info,
            ).admissionTeamSetting;
            const grade = JSON.parse(dataGetDraft.info).grade;

            const changes = JSON.parse(dataGetDraft.data);
            const divided = changes.divided;
            const useWorkType = changes.useWorkType;

            if (!admissionTeamSettingId) {
              const newSettings = {
                ...settings,
                divided,
                useWorkType,
              };

              const findGrade = newSettings.programGrade.find(
                (o) => o.grade.id === grade.id,
              );

              if (findGrade) {
                const dataProgramGrade = cloneDeep(settings.programGrade);

                newSettings.programGrade = dataProgramGrade.map((o) => {
                  if (o.admissionTeamSetting.id === admissionTeamSettingId) {
                    return {
                      grade: { ...o.grade },
                      admissionTeamSetting: {
                        ...o.admissionTeamSetting,
                        ...draftAdmissionTeamSetting,
                      },
                    };
                  } else {
                    return o;
                  }
                });
              } else {
                newSettings.programGrade.push({
                  admissionTeamSetting: { ...draftAdmissionTeamSetting },
                  grade: { ...grade },
                });
              }

              setSettingsOfProgram(newSettings);
              setPreviousSettingOfProgram(newSettings);
            } else {
              const dataProgramGrade = cloneDeep(settings.programGrade);

              const newSettings = {
                ...settings,
                divided,
                useWorkType,
                programGrade: ((arr) => {
                  return arr.map((o) => {
                    if (o.admissionTeamSetting.id === admissionTeamSettingId) {
                      return {
                        grade: { ...o.grade },
                        admissionTeamSetting: {
                          ...o.admissionTeamSetting,
                          ...draftAdmissionTeamSetting,
                        },
                      };
                    } else {
                      return o;
                    }
                  });
                })(dataProgramGrade),
              };

              setSettingsOfProgram(newSettings);
              setPreviousSettingOfProgram(newSettings);
            }
            getProgramName(programId, programs);
            getProgramId === programId && setIsRenderDraft(false);
          }
          if (!isRenderDraft) {
            setSettingsOfProgram(settings);
            setPreviousSettingOfProgram(settings);
          }
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Admission Team Settings', error);
      });
  };

  const counselorsOption = useMemo(() => {
    return counselors?.map((item, key) => {
      return {
        value: item.name,
        label: item.name,
        color: colorOptionForUserSelect[key % colorOptionForUserSelect.length],
        info: {
          photoURL: item.photoURL,
          counselor: { ...item },
        },
      };
    });
  }, [counselors]);

  const gradesOption = useMemo(() => {
    return grades?.map((item, key) => {
      return {
        value: item.name,
        label: item.name,
        info: {
          ...item,
        },
      };
    });
  }, [grades]);

  const programGrades = useMemo(() => {
    setEdited(
      JSON.stringify(settingsOfProgram) !== JSON.stringify(previousSettingOfProgram),
    );
    return settingsOfProgram?.programGrade;
  }, [settingsOfProgram]);

  const getDraftsList = useCallback(async () => {
    await centralAdminApi.getListDraftTeamSetting({ campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (res.data.success) {
          const drafts = res.data.data.objects;
          setDraftsList(drafts);
          setNumberOfEdits(res.data.data.total);
        }
      })
      .catch((error) => {
        console.log('Get list draft fail', error);
      });
  }, [campus]);

  const sendDraft = useCallback(async (draft) => {
    await centralAdminApi.createDraftTeamSetting({ campusId: campus?.isCentral ? undefined : campus?.id }, draft)
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Save as Draft Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Save as Draft Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      });
  }, [campus]);

  // neu bam nut cancel thi huy bo het cac thay doi => setting ve previous, cac grade cung ve origin
  const handleClickCancel = () => {
    setSettingsOfProgram(previousSettingOfProgram);
    setGrades(gradesOrigin);
  };

  const handelSendForApproval = () => {
    let data;
    const campusId = campus?.isCentral ? undefined : campus?.id
    const lengthCurrent = settingsOfProgram?.programGrade?.length;
    const lengthPrevious = previousSettingOfProgram?.programGrade?.length;

    let newProgramGrades;
    let oldProgramGrades;
    if (lengthCurrent > lengthPrevious) {
      const indexSlice = lengthPrevious;
      newProgramGrades = settingsOfProgram.programGrade.slice(indexSlice);
      oldProgramGrades = settingsOfProgram.programGrade.slice(0, indexSlice);
    } else if (lengthCurrent === lengthPrevious) {
      newProgramGrades = [];
      oldProgramGrades = settingsOfProgram.programGrade;
    }

    const dataNewProgramGrades = newProgramGrades.map((programGrade) => {
      const { admissionTeamSetting } = programGrade;
      return {
        gradeId: programGrade.grade.id,
        isVisible: admissionTeamSetting.isVisible,
        counselors: (() => {
          const counselors = admissionTeamSetting.counselors.map((counselor) => {
            return { userId: counselor.counselor.id };
          });
          return counselors;
        })(),
      };
    });

    const dataOldProgramGrades = oldProgramGrades.map((programGrade, index) => {
      const { admissionTeamSetting } = programGrade;
      return {
        id: admissionTeamSetting.id,
        gradeId: programGrade.grade.id,
        isVisible: admissionTeamSetting.isVisible,
        counselors: ((arr) => {
          return arr.map((o) => {
            return {
              userId: o.counselor.id,
            };
          });
        })(admissionTeamSetting.counselors),
      };
    });

    data = {
      programId: settingsOfProgram.id,
      divided: selectedDivided.value,
      userWorkType: selectUserWorkType.value,
      data: [...dataOldProgramGrades, ...dataNewProgramGrades],
    };

    const dataExtraInfo = settingsOfProgram;
    dataExtraInfo.programGrade = [...newProgramGrades];
    previousSettingOfProgram.programGrade.forEach((objOrigin, index) => {
      const objUpdate = oldProgramGrades[index];
      if (!_.isEqual(objOrigin, objUpdate)) {
        dataExtraInfo.programGrade.unshift(objUpdate);
      }
    });

    const dataApi = {
      name: `Edit admission team setting of ${settingsOfProgram.name}`,
      actionId: 3, // action id update
      changeContent: {
        actionName: 'Admissions Team Settings',
        apiURL: process.env.REACT_APP_URL_API_CENTRAL_ADMIN,
        endpoint: '/api/v1/admission/program/team-setting',
        apiMethod: 'POST',
        content: {
          ...data,
          extraInfo: dataExtraInfo,
        },
        params: { campusId },
      },
      keyFields: [],
    };

    approvalApi.createApprovalRequest({ campusId }, dataApi)
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Send For Approval Successfully',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
          setRefresh((prev) => !prev);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Send For Approval Failed',
            message: res.data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Send For Approval Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
      });
  };

  const sortProgramGrade = (programGrade) => {
    programGrade.sort((a, b) => a.grade.name < b.grade.name);
    programGrade.forEach((grade) => {
      grade.admissionTeamSetting.counselors.sort((x, y) => {
        return x.counselor.name < y.counselor.name ? -1 : 0;
      });
    });
  };

  const handleSaveAsDraft = async () => {
    const oldProgramGrade = JSON.parse(
      JSON.stringify(previousSettingOfProgram.programGrade),
    );
    const newProgramGrade = JSON.parse(JSON.stringify(settingsOfProgram.programGrade));
    sortProgramGrade(oldProgramGrade);
    sortProgramGrade(newProgramGrade);

    await Promise.all(
      newProgramGrade.map(async (grade, index) => {
        if (index < oldProgramGrade.length) {
          if (JSON.stringify(grade) !== JSON.stringify(oldProgramGrade[index])) {
            const draft = {};
            draft.admissionTeamSettingId = grade.admissionTeamSetting.id;
            draft.data = {};
            draft.data.programId = getProgramId;
            draft.data.divided = selectedDivided.value;
            draft.data.useWorkType = selectUserWorkType.value;
            draft.data.data = [];
            const draftGrade = {};
            draftGrade.gradeId = grade.grade.id;
            draftGrade.isVisible = grade.admissionTeamSetting.isVisible;
            draftGrade.counselors = grade.admissionTeamSetting.counselors.map(
              (counselor) => {
                return { userId: counselor.counselor.id };
              },
            );
            draft.data.data = draftGrade;
            draft.info = {};
            draft.info.changes = [];
            const draftGradeOld = {};
            const oldGrade = oldProgramGrade[index];
            draftGradeOld.gradeId = oldGrade.grade.name;
            draftGradeOld.isVisible = oldGrade.admissionTeamSetting.isVisible;
            draftGradeOld.counselors = oldGrade.admissionTeamSetting.counselors.map(
              (counselor) => counselor.counselor.name,
            );
            const draftGradeToCompare = cloneDeep(draftGrade);
            draftGradeToCompare.counselors = grade.admissionTeamSetting.counselors.map(
              (counselor) => counselor.counselor.name,
            );
            draftGradeToCompare.gradeId = grade.grade.name;

            draft.info.changes = compareObjects(draftGradeOld, draftGradeToCompare);
            draft.info.name = settingsOfProgram.name;
            await sendDraft(draft);
            await getDraftsList();
          }
        } else {
          const draft = {};
          if (grade.admissionTeamSetting.admissionTeamSettingId) {
            draft.admissionTeamSettingId = grade.admissionTeamSetting.id;
          }
          draft.data = {};
          draft.data.programId = getProgramId;
          draft.data.divided = selectedDivided.value;
          draft.data.useWorkType = selectUserWorkType.value;
          draft.data.data = [];
          const draftGrade = {};
          draftGrade.gradeId = grade.grade.id;
          draftGrade.isVisible = grade.admissionTeamSetting.isVisible;
          draftGrade.counselors = grade.admissionTeamSetting.counselors.map(
            (counselor) => {
              return { userId: counselor.counselor.id };
            },
          );
          draft.data.data = draftGrade;
          draft.info = {};
          draft.info.changes = [];
          const draftGradeOld = {};
          const draftGradeToCompare = cloneDeep(draftGrade);
          draftGradeToCompare.counselors = grade.admissionTeamSetting.counselors.map(
            (counselor) => counselor.counselor.name,
          );
          draftGradeToCompare.gradeId = grade.grade.name;
          draft.info.changes = compareObjects(draftGradeOld, draftGradeToCompare);
          draft.info.name = settingsOfProgram.name;

          await sendDraft(draft);
          await getDraftsList();
        }
      }),
    );
  };

  useEffect(() => {
    getDataFormApi();

    // handle Show Text Approval
    // callApi({
    //   method: 'get',
    //   url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v2/approval/process/search`,
    //   params: { actionId: 3, campusId: campus?.isCentral ? undefined : campus?.id },
    // })
    approvalApi.getApprovalProcess({ actionId: 3, campusId: campus?.isCentral ? undefined : campus?.id })
      .then((res) => {
        if (res.data.success) {
          setIsShowTextApproval(!!Object.keys(res.data.data || {}).length);
        }
      })
      .catch((error) => {
        console.log('Get Process Failed', error);
      });
  }, [campus]);

  useEffect(() => {
    if (isRenderDraft) {
      setIsRenderDraft(false);
      return;
    }

    setSelectUserWorkType();
    setSettingsOfProgram();
    setPreviousSettingOfProgram();
    setSelectedDivided();

    if (!programTabCurrent) return;
    getAdmissionTeamSetting();
  }, [programTabCurrent, refresh]);

  useEffect(() => {
    if (!isRenderDraft || !draftId) return;

    setSelectUserWorkType();
    setSettingsOfProgram();
    setPreviousSettingOfProgram();
    setSelectedDivided();

    getAdmissionTeamSetting();
  }, [isRenderDraft, refresh, campus]);

  return (
    <>
      <AddGradeModal
        open={open}
        isOpen={isOpen}
        programId={getProgramId}
        programsTabCurrent={programTabCurrent}
        gradesOption={gradesOption}
        setGrades={setGrades}
        setSettingsOfProgram={setSettingsOfProgram}
      />

      <div className={clsx('settings pr-20 pl-20 transition-all-300', burger ? '1400px:pl-80' : '1400px:pl-40')}>
        <div className="mb-20">
          <NavLinks urls={dataLinks.admissionTeamSettingsLinks} />
          <div className="settings__tittle">
            <div className="namepage">Admission Team Settings</div>
            <button
              className="bg-main-blue text-white px-[22px] py-[11px] h-fit self-center rounded"
              onClick={isOpen}
            >
              Add Grade
            </button>
          </div>
          <div className="settings__tabs flex w-full mb-[22px] mt-6">
            <CompanyTabs
              types={programsTab}
              setTypeState={setProgramTabCurrent}
              typeState={programTabCurrent}
            />
          </div>
          {programGrades?.length > 0 && (
            <div className="settings__content">
              <div className="content__wrapper flex ">
                <div className="content__bottom">
                  <span className="mr-[9px] text-sm leading-[22px]">
                    The admissions duties will be divided between{' '}
                  </span>
                  <div className="">
                    <SelectGroup
                      defaultValue={selectedDivided ?? null}
                      value={selectedDivided ?? null}
                      styles={selectStylesWithNoTitle2}
                      options={dividedOptions}
                      isSearchable={true}
                      onChange={(e) => {
                        setSelectedDivided(e);
                      }}
                    />
                  </div>
                  <span className="mx-[9px] text-sm leading-[22px]">
                    and users can work on applications{' '}
                  </span>
                  <div className="area grow">
                    <SelectGroup
                      defaultValue={selectUserWorkType ?? null}
                      value={selectUserWorkType ?? null}
                      styles={selectStylesWithNoTitle2}
                      options={userWorkTypeOptions}
                      isSearchable={true}
                      onChange={(e) => {
                        setSelectUserWorkType(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}

          {programGrades?.map((item, key) => (
            <GradeSetting
              key={key}
              index={key}
              settingsOfGrade={item}
              counselorsOption={counselorsOption}
              programsTabCurrent={programTabCurrent}
              setSettingsOfProgram={setSettingsOfProgram}
            />
          ))}
        </div>
      </div>
      {(numberOfEdits > 0 || edited) && (
        <Fade duration={300}>
          <Footer
            isStorybook={true}
            burger={burger}
            numberOfEdits={numberOfEdits}
            draftsList={draftsList}
            isApproval={isShowTextApproval}
            handleClickCancel={handleClickCancel}
            handleSaveAsDraft={handleSaveAsDraft}
            handelSendForApproval={handelSendForApproval}
            setDraftId={setDraftId}
            setIsRenderDraft={setIsRenderDraft}
          />
        </Fade>
      )}
    </>
  );
}
