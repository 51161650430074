import { callApi } from '@helper/call-api';

class AdmissionCallApi {
  constructor() {
    this.baseUrl = `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/call`;
  }

  list = async ({ campusId, enquiryId, signal }) => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/list`,
      params: { campusId, enquiryId },
      signal,
    });
  };

  create = async ({ campusId, data }) => {
    try {
      const res = await callApi({
        method: 'post',
        url: this.baseUrl,
        params: { campusId },
        data,
      });

      return res;
    } catch (error) {
      return { error };
    }
  };

  update = async ({ callId, campusId }) => {
    try {
      const res = await callApi({
        method: 'patch',
        url: `${this.baseUrl}/${callId}`,
        params: { campusId },
      });

      return res;
    } catch (error) {
      return { error };
    }
  };
}

const admissionCallApi = new AdmissionCallApi();
export default admissionCallApi;
