import React from "react";

const ButtonBottom = (props) => {
  const { titleButton, onClick } = props

  return (
    <div className='w-full text-center absolute bottom-[4rem] left-0'>
      <button className='bg-[#2E3338] text-white font-semibold w-[90%] py-2' onClick={onClick}>
        {titleButton}
      </button>
    </div>
  )
}

export default ButtonBottom;