import { isArray, isEmpty } from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';

const DistributionInfo = ({ index, title, roles, users, content, criteria }) => {
  const cStyle = useMemo(() => {
    switch (index) {
      case 0:
        return '108px';
      case 1:
        return '117px';
      case 2:
        return '60px';
      case 3:
        return '82px';
      default:
        return 'fit-content'; 
    }
  }, [index]);

  return (
    <li className={`distribution-stages_column`} style={{ width: cStyle }}>
      <h4 className="distribution-stages_title">{title}</h4>
      <h5 className="distribution-stages_content">{content}</h5>
      <div className="distribution-stages_list">
        {/* <div className="distribution-stages_list-item">
          {roles?.map((role, idx) => {
            return <p key={idx}>{role}</p>;
          })}
        </div> */}
        <div className="">
          {users?.map((item, idx) => {
            return (
              <img
                key={idx}
                src={item.avatar}
                alt="avatar"
                className="distribution-stages_avatar"
              />
            );
          })}
        </div>
        <div className="distribution-stages_list-item">
          {
            criteria?.length == 0 ? 
              (<p>None</p>) : criteria===undefined?<></>: <p>Category</p>
          }
        </div>
      </div>
    </li>
  );
};

export default DistributionInfo;
