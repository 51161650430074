import { useState } from 'react';
import clsx from 'clsx';
import { mainavatar } from '@static/image';

const SingleMail = (props) => {
  const { emailData = {} } = props;
  const [isReadMore, setIsReadMore] = useState(false);

  const onReadMore = () => {
    setIsReadMore((prev) => !prev);
  };

  const handleDateTime = (date) => {
    if (date) {
      const day = new Date(date).toUTCString().slice(5, 16);
      const time = new Date(date).toLocaleTimeString('en-US');
      return { day, time };
    }
    // } else {
    //   day = new Date().toUTCString().slice(5, 16)
    //   time = new Date().toLocaleTimeString('en-US')
    // }
    return { day: '', time: '' };
  };

  const { day, time } = handleDateTime(emailData?.dateCreated);
  const isSeen = (emailData?.email_user_receive || []).some((dt) => dt?.isSeen);
  const userRole =
    (emailData?.users?.userRoles || []).find((dt) => {
      return dt?.roles?.name?.toLowerCase()?.includes('admission');
    })?.roles?.name ||
    emailData?.users?.userRoles?.[0]?.roles?.name ||
    '';

  return (
    <div className="single-mail rounded-lg bg-white p-2.5 my-2.5 flex flex-col gap-2.5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2.5">
          <img
            className="block w-6 h-6 rounded-full"
            alt=""
            src={emailData?.users?.photoURL || mainavatar}
          />
          <div>
            <p className="text-[10px] font-medium leading-[14px] text-main-black">
              {emailData?.users?.name || ''}
            </p>
            <p className="text-[10px] font-medium leading-[14px] text-second-gray">
              {userRole}
            </p>
          </div>
        </div>
        <div className="min-w-[6rem] text-right">
          <p className="text-xs">
            {day.slice(0, 7)}
            <span style={{ fontSize: '0.5rem' }}>{day.slice(7)}</span>
          </p>
          <p style={{ color: '#BABEC3', fontSize: '0.625rem' }}>{time}</p>
        </div>
      </div>
      <div className="relative">
        <p className="text-xs text-[#161C24] mb-3">
          <span className="font-bold">Subject:&nbsp;</span>
          <span className="font-normal">{emailData?.subject || ''}</span>
        </p>
        <p
          className={clsx(
            'text-xs text-[#808284] overflow-hidden duration-1000',
            isReadMore ? ' h-fit' : 'h-[2rem]',
          )}
        >
          <p
            dangerouslySetInnerHTML={{
              __html: isReadMore ? emailData.content : emailData.content.slice(0, 100),
            }}
          ></p>
          {/* {isReadMore ? emailData.content : `${emailData.content.slice(0, 100)}...`} */}
        </p>
        {!isReadMore && <span>...</span>}
        <span
          className="text-[#2563eb] text-xs font-medium cursor-pointer"
          onClick={onReadMore}
        >
          &nbsp;{isReadMore ? 'Show Less' : 'Read More'}
        </span>
      </div>
      <div className="w-full h-[0.5px] bg-[#B0B6BE]"></div>
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <p className="text-second-gray text-[10px] font-medium mr-2">To:</p>
          <div className="flex -space-x-0.5 items-center">
            {(emailData?.email_user_receive || []).map((item, index) => {
              return (
                <img
                  title={item.users?.name}
                  className="inline-block h-4 w-4 rounded-full ring-2 ring-white"
                  key={index}
                  alt={item.users?.name}
                  src={item.users?.photoURL || mainavatar}
                />
              );
            })}
          </div>
        </div>
        <p className="text-second-gray text-[10px] font-medium">
          {isSeen ? 'Seen' : 'Not Seen'}
        </p>
      </div>
    </div>
  );
};

export default SingleMail;
