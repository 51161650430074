import PlayAudio from "@components/application-panel/sub-components/play-audio";
import { audio_note, document, note } from "@components/application-panel/image";

const SingleNote = (props) => {
  const { data, className } = props

  const formatTimeAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;

    return strTime;
  }

  const getDateTimeString = (dateString) => {
    if (!dateString) return ''

    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");

    return `${day}/${month}/${year} ${formatTimeAMPM(date)}`
  }


  return (
    <div className={`maincard h-fit w-full p-4 block text-[10px] text-[#212B36] ${className}`}>
      <div className={'flex items-center'}>
        <img className={'aspect-square h-6 mr-2'} src={data?.noteType === 'Audio Notes' ? audio_note : note} />
        <div className={'font-medium text-[10px]'}>
          <p>{data?.noteType} </p>
          <p className={'text-[#637381]'}>{`${getDateTimeString(data?.dateCreated)} | ${data?.owner?.name || ''}`}</p>
        </div>
      </div>

      {data?.data?.message &&
        <p className={'mt-2'}>{data?.data?.message}</p>}

      {data?.data?.files && data?.data?.files?.length !== 0 && (
        <div className={'mt-2'}>
          {data.data.files.map((file, index) => {
            return (
              <a key={`file${index}`} download href={file?.fileURL} target="_blank" className={'flex items-center'}>
                <img className={'h-6 w-[18px] mr-2'} src={document} />
                <div className={'font-medium text-[10px] text-[#212B36] text-left'}>
                  <p>{file?.filename}</p>
                  <p>{file?.size}</p>
                </div>
              </a>
            )
          })}
        </div>
      )}


      {data?.data?.audioURL && (
        <PlayAudio className={'mt-2'} audio={data.data.audioURL} />
      )}

    </div>
  )
}

export default SingleNote;