import { close_red, mainavatar } from '@static/image';
import './user-card.scss';

function UserCard(props) {
  const { applicationId, user = {}, isShowClose, handleClickCancel = () => {} } = props;
  return (
    <div className={`flex items-center p-3 flex-auto rounded-lg user-card`}>
      <img
        className="w-8 h-8 mr-4 rounded-full"
        alt="user"
        src={user.avatar || mainavatar}
      />
      <div className="flex flex-col mr-auto">
        <p className="user-card-name">{user.name}</p>
        {applicationId && <p className="user-card-id">id # {applicationId}</p>}
      </div>
      {isShowClose && (
        <div onClick={handleClickCancel} className="cursor-pointer p-2">
          <img src={close_red} alt="close" />
        </div>
      )}
    </div>
  );
}

export default UserCard;
