import { COMPANY } from './types';

export const editCompany = (company) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.edit,
      payload: company,
    });
  };
};

export const saveInfoCompanyCampus = (info) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.saveInfo,
      payload: info,
    });
  };
};

export const saveListCampus = (payload) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.listCampus,
      payload,
    });
  };
};

export const saveCurrent = (payload) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.current,
      payload,
    });
  };
};

export const saveRoutes = (payload) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.saveRoutes,
      payload,
    });
  };
};

export const saveCentral = (payload) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.saveCentral,
      payload,
    });
  };
};

export const saveAcademicYear = (payload) => {
  return (dispatch) => {
    dispatch({
      type: COMPANY.saveCentral,
      payload,
    });
  };
};
