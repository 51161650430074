import { callApi } from "@helper/call-api";

class KnowlarityApi {
  constructor() {
    this.sr_number = process.env.REACT_APP_KNOWLAIRTY_SR_NUMBER
    this.agent_number = process.env.REACT_APP_KNOWLAIRTY_AGENT_NUMBER
    this.baseUrl = 'https://kpi.knowlarity.com/Basic/v1/account'
    this.streamingUrl = `https://konnect.knowlarity.com:8100/update-stream/${process.env.REACT_APP_KNOWLAIRTY_AUTHORIZATION_KEY}/konnect`
    this.headers = {
      'x-api-key': process.env.REACT_APP_KNOWLAIRTY_ACCESS_KEY,
      Authorization: process.env.REACT_APP_KNOWLAIRTY_AUTHORIZATION_KEY,
    }
  }

  listRegisterNotification = () => {
    return callApi({
      method: 'get',
      url: `${this.baseUrl}/notifications`,
      headers: this.headers
    })
  }

  registerNotification = () => {
    return callApi({
      method: 'post',
      url: `${this.baseUrl}/notifications`,
      headers: this.headers,
      data: {
        knowlarity_number: [this.sr_number]
      }
    })
  }

  makeACall = (customer_number) => {
    return callApi({
      method: "post",
      url: `${this.baseUrl}/call/makecall`,
      headers: this.headers,
      data: {
        k_number: this.sr_number,
        agent_number: this.agent_number,
        customer_number: customer_number
        // customer_number: "+918297266111"
      },
    })
  }

  streaming = () => {
    return new EventSource(this.streamingUrl);
  }

  callLog = async (params = {}, signal) => {
    // params {
    //   start_time: '2023-08-13 12:00:00+05:30',
    //   end_time: '2023-10-14 12:00:00+05:30',
    //   call_type: 1 for outgoing, 0 for incoming
    //   agent_number: '917799066666',
    //   customer_number: '+918297266111',
    //   knowlarity_number:'+919513632659',
    //   business_call_type:'',
    // }

    return callApi({
      method: 'get',
      url: `${this.baseUrl}/calllog?${new URLSearchParams(params).toString()}`,
      headers: this.headers,
      signal
    })
  }
}

const knowlarity = new KnowlarityApi();
export default knowlarity