import React from 'react';
import { useContext, useEffect, useRef, useState } from 'react';
import AccountTypeHolder from './account-type-holer';
import { ToastMessageContext } from 'context/toast-context';
import { callApi } from '@helper/call-api';
import authApi from 'api/auth';
import useStorage from 'store/storage';

const CreateRoleModal = ({
  roleDetail,
  showPopupCreate,
  handleClickClose,
  optionTypes,
  isAdmin,
  setRefresh,
}) => {
  const [data, setData] = useState(roleDetail || {}); // {name, loginTypeId, id?}
  const [dataRole, setDataRole] = useState([]);
  const [enableButton, setEnableButton] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const ref = useRef();
  const campus = useStorage((state) => state.currentCampus);
  const userLogin = useStorage((state) => state.auth);

  const handleChange = (key, value) => {
    setData((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  useEffect(() => {
    const { name, loginTypeId } = data;
    if (roleDetail) {
      setEnableButton(
        (name && loginTypeId && name !== roleDetail.name) ||
        loginTypeId !== roleDetail.loginTypeId,
      );
    } else {
      setEnableButton(name && loginTypeId);
    }
  }, [data]);

  const getDataPermissionCreate = (dataRole) => {
    const result = [];
    dataRole.forEach(({ pages }) => {
      pages.forEach((page) => {
        const { pageApiPermission } = page;
        if (pageApiPermission.length !== 0) {
          pageApiPermission.forEach(({ permissions }) => {
            result.push({
              pageId: page.id,
              permissionId: permissions.id,
              active: false,
            });
          });
        }
      });
    });
    return result;
  };

  const handleClickCreate = () => {
    if (!data.name?.trim()) {
      setToastMessage({
        status: 'warning',
        title: 'Missing Data',
        message: 'Please enter role name',
      });
      setIsShowToastMessage(true);
      return;
    }
    if (!data.loginTypeId) {
      setToastMessage({
        status: 'warning',
        title: 'Missing Data',
        message: 'Please select login type',
      });
      setIsShowToastMessage(true);
      return;
    }
    const dataContent = {
      name: data.name,
      loginTypeId: data.loginTypeId,
      companyId: userLogin?.companyId,
    };

    let api = {};
    if (true) { // >>> sẽ fix khi có module Approval if (isAdmin) {
      api = authApi.createCustomRole({ campusId: campus?.isCentral ? undefined : campus?.id }, dataContent);
    } else {
      const dataApi = {
        name: `Create role ${data.name}`,
        actionId: 1, //1: Create role
        changeContent: {
          actionName: 'Create role',
          apiURL: process.env.REACT_APP_URL_API_AUTH,
          endpoint: '/api/v2/role/add-custom-role',
          apiMethod: 'POST',
          content: dataContent,
        },
      };
      api = callApi({
        method: 'post',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v1/approval/request`,
        params: { campusId: campus?.isCentral ? undefined : campus?.id },
        data: dataApi,
      });
    }

    api
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setRefresh((pre) => !pre);
          setToastMessage({
            status: 'success',
            title: 'Send Approval Successfully',
            message: data.message,
          });
          setIsShowToastMessage(true);
          handleClickClose();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Send Approval Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Send Approval Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Campus Information Role Permissions Popup Error', error);
      });
  };

  const updateRoleDetail = () => {
    // Call api to update the role here
  };

  return (
    <div>
      <div className="my-6">
        <div
          style={{
            boxShadow: '0 0.5rem 1rem rgba(0,0,0,.2)',
          }}
          className="w-full rounded-md p-2"
          onClick={() => {
            if (ref.current) ref.current.focus();
          }}
        >
          <p className="text-sm font-bold leading-4 text-button-disable">Role Name</p>
          <input
            type="text"
            ref={ref}
            onChange={(e) => {
              handleChange('name', e.target.value);
            }}
            name="campus-name"
            value={data.name || ''}
            className="w-60 h-6 text-base font-semibold bg-white outline-none border-none"
          />
          {/* <p className='ml-2' style={{ color: 'red' }}>{error.statusRoleName ? error.messageRoleName : ''}</p> */}
        </div>
        <div className="mt-4">
          <p className="ml-1 text-sm font-bold leading-4 text-button-disable">
            Access Levels
          </p>
          <div className="flex">
            {optionTypes.map((item, index) => {
              return (
                <AccountTypeHolder
                  isChosen={data.loginTypeId === item.id}
                  key={index}
                  image={item.image}
                  title={item.title}
                  subtitle={item.subtitle}
                  opinion={item.opinio}
                  handleChange={handleChange}
                  value={item.id}
                  label={item.title}
                />
              );
            })}
          </div>
        </div>
      </div>
      <div className="mt-24">
        <button
          style={{
            backgroundColor: enableButton ? '#00c48c' : 'rgb(0 196 140 / 58%)',
            cursor: enableButton ? 'pointer' : 'not-allowed',
            border: '1px solid #00c48c',
          }}
          onClick={roleDetail ? updateRoleDetail : handleClickCreate}
          className="w-full h-8 text-sm font-bold leading-4 text-white rounded"
        >
          {isAdmin ? (roleDetail ? 'Update role' : 'Create role') : 'Send a Approvals'}
        </button>
      </div>
    </div>
  );
};

export default CreateRoleModal;
