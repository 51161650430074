import react from 'react'

const Triangle = (props) => {
  const { style } = props

  return (
    <svg className={`${style.rotate}`} width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M.586 11.413a2.002 2.002 0 0 1 0-2.83L8.583.586a1.99 1.99 0 0 1 2.18-.431A2 2 0 0 1 12 2.005v15.993c0 .806-.487 1.537-1.237 1.85a2.002 2.002 0 0 1-2.18-.432L.586 11.419v-.006Z" fill={style.bg} />
    </svg>
  )
}

export default Triangle;