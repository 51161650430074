import clsx from 'clsx';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import { useCallback, useContext, useEffect, useState } from 'react';
import Fade from 'react-reveal/Fade';
import { NewAdmissionCard, PartnerCard, ReferralCard, SiblingCard, SportCard } from '@components/finance-module';
import PrincipalCard from '@components/finance-module/scholarship-card/principal-card';
import { callApi } from '@helper/call-api';
import { CircularProgress } from '@mui/material';
import { Button, SelectGroup, ToggleButton } from '@stories/index';
import {
  checkErrorMultipleApiFetch,
  checkErrorSingleApi
} from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import { dataLinks } from 'entities/routes';
import { selectStylesWithNoTitle2ArrowBlue } from 'entities/select-style';
import { NavLinks } from '../../Header/components';
import './scholarship.scss';
import financeApi from 'api/finance';
import approvalApi from 'api/approval';
import AddPartnerCard from '@components/finance-module/scholarship-card/add-partner-card';
import useStorage from 'store/storage';


const optionType = {
  Percentage: 'Percentage',
  Amount: 'Amount',
  'Fixed Percentage': 'Fixed Percentage',
  'Fixed Amount': 'Fixed Amount'
}

const optionsDiscountType = [
  { value: 'Percentage', label: 'Percentage' },
  { value: 'Amount', label: 'Amount' }
];

const optionsDiscountFixedType = [
  { value: 'Fixed Percentage', label: 'Fixed Percentage' },
  { value: 'Fixed Amount', label: 'Fixed Amount' },
]

const optionsPartnerType = [
  { value: 'Cooperate', label: 'Cooperate' },
  { value: 'Company', label: 'Company' },
]

const aboveLimitOption = [
  { value: 'Auto Reject', label: 'Auto Reject' },
  { value: 'Need Approval', label: 'Need Approval' },
]

// const ACTION_ID_BY_PROCESS_SCHOLARSHIP = 8
export default function Scholarship(props) {
  const { burger } = props;
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const campus = useStorage((state) => state.currentCampus);
  const auth = useStorage((state) => state.auth);
  const [scholarshipOriginal, setScholarshipOriginal] = useState();
  const [scholarship, setScholarship] = useState()
  const [processApprovals, setProcessApprovals] = useState()
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true)
    const campusId = campus?.id
    const getDiscount = financeApi.getListScholarship({ campusId });
    const approvalProcess = approvalApi.getListProcess({ companyId: auth.companyId, actionId: 9 });
    Promise.all([approvalProcess, getDiscount])
      .then((res) => {
        if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage)) {
          const scholarshipApi = res[1]?.data?.data || null
          let scholarship = scholarshipApi
          if (scholarshipApi?.id) {
            const { isAppliedTo, parentReferralDiscount, partnerDiscount, principalDiscount, sibblingDiscount, sportsScholarship, academicScholarship, boardDiscount } = scholarshipApi
            scholarship = {
              isAppliedTo,
              parentReferralDiscount: JSON.parse(parentReferralDiscount),
              sibblingDiscount: JSON.parse(sibblingDiscount),
              partnerDiscount: JSON.parse(partnerDiscount),
              principalDiscount: JSON.parse(principalDiscount),
              sportsScholarship: JSON.parse(sportsScholarship),
              academicScholarship: JSON.parse(academicScholarship),
              boardDiscount: JSON.parse(boardDiscount)
            }

            setScholarshipOriginal(JSON.stringify(scholarshipApi))
          }
          setScholarship(scholarship)

          const process = res[0].data.data.objects.map(item => ({ value: item.id, label: item.name }))

          setProcessApprovals(process)
          setIsLoading(false)
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Fetch Data Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Scholarship', error);
      });
  }, [campus]);

  const handleChange = useCallback(({ scholarship, index, name, value }) => {
    if (index >= 0) {
      setScholarship(prev => ({
        ...prev,
        [scholarship]: {
          ...prev[scholarship],
          items: ((items) => {
            items[index] = {
              ...items[index],
              [name]: value,
            };
            return items;
          })(prev[scholarship].items)
        }
      }))
    } else {
      setScholarship(prev => ({
        ...prev,
        [scholarship]: { ...prev[scholarship], [name]: value }
      }))
    }
  }, [])


  const handleCancel = () => {
    const dataOriginal = JSON.parse(scholarshipOriginal)
    setScholarship(dataOriginal)
  };

  const handleSaveAndNext = async () => {
    try {
      let objectCallApi;
      const { isAppliedTo, parentReferralDiscount, sibblingDiscount, partnerDiscount, principalDiscount, sportsScholarship, academicScholarship, boardDiscount } = scholarship
      const dataOriginal = JSON.parse(scholarshipOriginal)
      if (dataOriginal?.id) {
        if (
          !_.isEqual(JSON.parse(dataOriginal.parentReferralDiscount), parentReferralDiscount) ||
          !_.isEqual(JSON.parse(dataOriginal.sibblingDiscount), sibblingDiscount) ||
          !_.isEqual(JSON.parse(dataOriginal.partnerDiscount), partnerDiscount) ||
          !_.isEqual(JSON.parse(dataOriginal.principalDiscount), principalDiscount) ||
          !_.isEqual(JSON.parse(dataOriginal.sportsScholarship), sportsScholarship) ||
          !_.isEqual(JSON.parse(dataOriginal.academicScholarship), academicScholarship) ||
          !_.isEqual(JSON.parse(dataOriginal.boardDiscount), boardDiscount) ||
          isAppliedTo !== dataOriginal.isAppliedTo
        ) {
          objectCallApi = {
            method: 'patch',
            url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/scholarship/${dataOriginal.id}`,
          };
        } else
          return
      } else {
        objectCallApi = {
          method: 'post',
          url: `${process.env.REACT_APP_URL_API_FINANCE}/api/v2/finance/scholarship`,
          data: {
            campusId: campus?.isCentral ? undefined : campus?.id,
          },
        };
      }
      const res = await callApi({
        ...objectCallApi,
        params: { campusId: campus?.isCentral ? undefined : campus?.id },
        data: {
          ...objectCallApi.data,
          isAppliedTo,
          parentReferralDiscount: JSON.stringify(parentReferralDiscount),
          sibblingDiscount: JSON.stringify(sibblingDiscount),
          partnerDiscount: JSON.stringify(partnerDiscount),
          principalDiscount: JSON.stringify(principalDiscount),
          sportsScholarship: JSON.stringify(sportsScholarship),
          academicScholarship: JSON.stringify(academicScholarship),
          boardDiscount: JSON.stringify(boardDiscount)
        },
      })
      if (checkErrorSingleApi(res, setToastMessage, setIsShowToastMessage, 'Create/Update Scholarship',
      )) {
      }
    } catch (error) {
      setToastMessage({
        status: 'error',
        title: 'Submit Data Failed',
        message: error?.response?.data?.message || error,
      });
      setIsShowToastMessage(true);
      console.log('Scholarship Error', error);
    }
  };

  const optionApplies = [
    { label: 'Initial Payment', value: 'Initial Payment' },
    { label: 'Final Payment', value: 'Final Payment' }
  ]

  if (isLoading) return <CircularProgress />

  return (
    <div className={clsx('scholarship', burger ? 'ml-80' : 'ml-40')}>
      <Fade clear duration={300}>
        <NavLinks urls={dataLinks.scholashipsLinks} />
        <h1 className="namepage">Scholarship and Discounts</h1>
        <div className="mb-16">
          <div className='flex items-center w-[35%] min-w-[28rem] justify-between ml-3 my-10'>
            <p>The Scholarship is applied to</p>
            <SelectGroup
              title=""
              value={optionApplies.find(item => item.value === scholarship?.isAppliedTo)}
              classNameGroup='w-[45%]'
              styles={selectStylesWithNoTitle2ArrowBlue}
              isMulti={false}
              options={optionApplies}
              onChange={(value) => { setScholarship(prev => ({ ...prev, isAppliedTo: value.value })) }}
            />
          </div>
          <div className="flex text-xl items-center ml-3 mb-5">
            Parent Referral Discount
            <ToggleButton inputSize="small"
              value={!!scholarship?.parentReferralDiscount?.active}
              onChange={() => {
                handleChange({
                  scholarship: 'parentReferralDiscount',
                  name: 'active',
                  value: !scholarship?.parentReferralDiscount?.active
                })
              }}
              customStyle={{ marginLeft: '0.938rem' }}
            />
          </div>
          <div className={clsx('scholarship-row', scholarship?.parentReferralDiscount?.active ? 'card-row_active' : 'card-row',
          )}
          >
            <ReferralCard data={scholarship?.parentReferralDiscount?.items?.[0]}
              optionsDiscountFixedType={optionsDiscountFixedType}
              optionType={optionType}
              handleChange={({ index, name, value }) => {
                handleChange({
                  scholarship: 'parentReferralDiscount',
                  index, name, value
                })
              }}
            />
            <NewAdmissionCard data={scholarship?.parentReferralDiscount?.items?.[1]}
              optionsDiscountFixedType={optionsDiscountFixedType}
              optionType={optionType}
              listApprovalProcess={{}}
              setPrincipalDiscount={{}}
              handleChange={({ index, name, value }) => {
                handleChange({
                  scholarship: 'parentReferralDiscount',
                  index, name, value
                })
              }}
            />
          </div>

          {/* Sibling Discount */}
          <div className="flex text-xl items-center ml-3 mb-5">
            Sibling Discount
            <ToggleButton
              inputSize="small"
              value={scholarship?.sibblingDiscount?.active}
              onChange={(e) =>
                handleChange({
                  scholarship: 'sibblingDiscount',
                  name: 'active',
                  value: e.target.checked
                })
              }
              customStyle={{ marginLeft: '0.938rem' }}
            />
          </div>
          <div className={clsx('scholarship-row', scholarship?.sibblingDiscount?.active ? 'card-row_active' : 'card-row')}>
            {scholarship?.sibblingDiscount?.items?.map((item, index) => {
              return (
                <SiblingCard
                  key={index}
                  index={index}
                  data={item}
                  optionType={optionType}
                  optionsDiscountFixedType={optionsDiscountFixedType}
                  handleChange={({ index, name, value }) => {
                    handleChange({
                      scholarship: 'sibblingDiscount',
                      index, name, value
                    })
                  }}
                />
              );
            })}
          </div>

          {/* Partner Discount */}
          <div className="flex text-xl items-center ml-3 mb-5">Partner Discount</div>
          <div className="scholarship-row">
            {scholarship?.partnerDiscount?.items?.map((item, index) => {
              return (
                <PartnerCard
                  key={index}
                  index={index}
                  data={item}
                  processApprovals={processApprovals}
                  optionType={optionType}
                  optionsDiscountFixedType={optionsDiscountFixedType}
                  optionsPartnerType={optionsPartnerType}
                  handleChange={({ index, name, value }) => {
                    handleChange({
                      scholarship: 'partnerDiscount',
                      index, name, value
                    })
                  }}
                />
              );
            })}
            <AddPartnerCard optionType={optionType} setScholarship={setScholarship} />
          </div>

          <div className='flex'>

            {/* Principal Discount */}
            <div>
              <div className="flex text-xl items-center ml-3 mb-5">
                Principal Discount
                <ToggleButton
                  inputSize="small"
                  value={scholarship?.principalDiscount?.active}
                  onChange={(e) =>
                    handleChange({
                      scholarship: 'principalDiscount',
                      name: 'active',
                      value: e.target.checked
                    })
                  }
                  customStyle={{ marginLeft: '0.938rem' }}
                />
              </div>
              <div className={clsx('scholarship-row', scholarship?.principalDiscount?.active ? 'card-row_active' : 'card-row')}>
                <PrincipalCard data={scholarship?.principalDiscount?.items?.[0]}
                  optionType={optionType}
                  aboveLimitOption={aboveLimitOption}
                  processApprovals={processApprovals}
                  optionsDiscountType={optionsDiscountType}
                  handleChange={({ index, name, value }) => {
                    handleChange({
                      scholarship: 'principalDiscount',
                      index, name, value
                    })
                  }}
                />
              </div>
            </div>

            {/* Board Discount */}
            <div>
              <div className="flex text-xl items-center ml-3 mb-5">
                Board Discount
                <ToggleButton
                  inputSize="small"
                  value={scholarship?.boardDiscount?.active}
                  onChange={(e) =>
                    handleChange({
                      scholarship: 'boardDiscount',
                      name: 'active',
                      value: e.target.checked
                    })
                  }
                  customStyle={{ marginLeft: '0.938rem' }}
                />
              </div>
              <div className={clsx('scholarship-row', scholarship?.boardDiscount?.active ? 'card-row_active' : 'card-row')}>
                <PrincipalCard data={scholarship?.boardDiscount?.items?.[0]}
                  optionType={optionType}
                  aboveLimitOption={aboveLimitOption}
                  processApprovals={processApprovals}
                  optionsDiscountType={optionsDiscountType}
                  handleChange={({ index, name, value }) => {
                    handleChange({
                      scholarship: 'boardDiscount',
                      index, name, value
                    })
                  }}
                />
              </div>
            </div>
          </div>


          {/* Sports Scholarship */}
          <div className="flex text-xl items-center ml-3 mb-5">
            Sports Scholarship
            <ToggleButton
              inputSize="small"
              value={scholarship?.sportsScholarship?.active}
              onChange={(e) =>
                handleChange({
                  scholarship: 'sportsScholarship',
                  name: 'active',
                  value: e.target.checked
                })
              }
              customStyle={{ marginLeft: '0.938rem' }}
            />
          </div>
          <div className={clsx('scholarship-row', scholarship?.sportsScholarship?.active ? 'card-row_active' : 'card-row')}>
            <SportCard data={scholarship?.sportsScholarship?.items?.[0]}
              processApprovals={processApprovals}
              finalProcess={processApprovals.finalProcess}
              optionType={optionType}
              aboveLimitOption={aboveLimitOption}
              optionsDiscountType={optionsDiscountType}
              handleChange={({ index, name, value }) => {
                handleChange({
                  scholarship: 'sportsScholarship',
                  index, name, value
                })
              }}
            />
          </div>

          {/* Academic Scholarship */}
          <div className="flex text-xl items-center ml-3 mb-5">
            Academic Scholarship
            <ToggleButton
              inputSize="small"
              value={scholarship?.academicScholarship?.active}
              onChange={(e) =>
                handleChange({
                  scholarship: 'academicScholarship',
                  name: 'active',
                  value: e.target.checked
                })
              }
              customStyle={{ marginLeft: '0.938rem' }}
            />
          </div>
          <div className={clsx('scholarship-row', scholarship?.academicScholarship?.active ? 'card-row_active' : 'card-row')}>
            <SportCard data={scholarship?.academicScholarship?.items?.[0]}
              aboveLimitOption={aboveLimitOption}
              processApprovals={processApprovals}
              optionType={optionType}
              optionsDiscountType={optionsDiscountType}
              handleChange={({ index, name, value }) => {
                handleChange({
                  scholarship: 'academicScholarship',
                  index, name, value
                })
              }}
            />
          </div>

        </div>
      </Fade>
      <div className="scholarship-footer">
        <Button
          text="Cancel"
          buttonStyle="outlined"
          buttonSize="large"
          customStyle={{
            width: '20%',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: '2rem',
          }}
          onClick={handleCancel}
        />
        <Button
          text="Save"
          buttonStyle="contained"
          buttonSize="large"
          customStyle={{ width: '20%', justifyContent: 'center', alignItems: 'center' }}
          onClick={handleSaveAndNext}
        />
      </div>
    </div>
  );
}

Scholarship.propTypes = {
  burger: PropTypes.bool,
};
