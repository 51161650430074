import { useHistory, useLocation, useParams } from "react-router-dom";
import { useCallback, useContext, useState, useMemo, useEffect, useRef } from "react";
import { getListUrlStage } from "@helper/admission";
import { callApi } from "@helper/call-api";
import { checkErrorApiFetch, checkErrorMultipleApiFetch } from "@utils/check-error/api-error";
import { ToastMessageContext } from "context/toast-context";
import BloomReview from "./review/review";
import BloomDetails from "./details/details";
import BloomChildAssessment from "./child-assessment/child-assessment";
import BloomChildPrincipal from "./child-principal/child-principal";
import BloomChildDocument from "./child-document/child-document";
import BloomChildFinance from "./finance/child-finance-approval";
import BloomChildComplete from "./child-complete/child-complete";
import BloomDashboard from "./dashboard/dashboard"
import ChildCustomStage from "./custom-stage/child-custom-stage"
import { transformStringToLowercase } from "@utils/utils";
import BloomHeader from "@components/bloomingdale/header/header";
import BloomCrumbs from "@components/bloomingdale/crumbs/crumbs";
import { backarrow } from "./image";
import { Documents } from "@components/admission-module/application";
import { EnquiryStatus } from "entities/data";
import admissionApi from "api/admission";

const BloomingdaleStage = () => {
  const location = useLocation()
  const { state } = location;
  const { enquiryId, stageInit } = state;
  const [enquiry, setEnquiry] = useState();
  const [listStage, setListStage] = useState([])
  const [indexStageShow, setIndexStageShow] = useState()
  const [refresh, setRefresh] = useState(0);
  const refCurrentIndexStage = useRef()
  const history = useHistory()
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const fetchData = useCallback(async (signal) => {
    const res = await admissionApi.getEnquiryParent(enquiryId, signal)
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Fetch Enquiry')) {
      setEnquiry(res.data.data);
      const listUrl = getListUrlStage({
        enquiry: res.data.data,
        stages: stageInit,
      })
      setListStage(listUrl);
      let current = listUrl.findIndex(item => item.isCurrent)
      if (current === -1) current = 0
      refCurrentIndexStage.current = current
      setIndexStageShow(current)
      // setIndexStageShow(prev => prev + 1)
    }
  }, [enquiryId])

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal)
    return () => {
      controller.abort()
    }
  }, [])

  const fetchEnquiry = useCallback(async (signal) => {
    const res = await admissionApi.getEnquiryParent(enquiryId, signal)
    if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Fetch Enquiry')) {
      setEnquiry(res.data.data);
      const listUrl = getListUrlStage({
        enquiry: res.data.data,
        stages: stageInit,
      })
      setListStage(listUrl);
      let current = listUrl.findIndex(item => item.isCurrent)
      if (current === -1) current = 0
      refCurrentIndexStage.current = current
      // setIndexStageShow(prev => prev + 1)
    }
  }, [enquiryId])

  useEffect(() => {
    const controller = new AbortController();
    if (refresh > 0) {
      fetchEnquiry(controller.signal)
    }
    return () => {
      controller.abort()
    }
  }, [refresh])

  // const fetch = useCallback(async () => {
  //   const res = await callApi({
  //     method: 'get',
  //     url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry/parent/${refEnquiryId.current}`,
  //   });
  //   // const apiStage = callApi({
  //   //   method: 'get',
  //   //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v1/admission/enquiry/stage`,
  //   //   params: { campusId },
  //   // })

  //   // const res = await Promise.all([apiEnquiry, apiStage])

  //   if (checkErrorApiFetch(res, setToastMessage, setIsShowToastMessage, 'Fetch Data')) {

  //     setEnquiry(res.data.data);

  //     const listUrl = getListUrlStage({
  //       enquiry: res.data.data,
  //       stages: stageInit,
  //     })
  //     setListStage(listUrl);

  //     let current = listUrl.findIndex(item => item.isCurrent)
  //     if (current === -1) current = 0
  //     refCurrentIndexStage.current = current
  //   }
  // }, [refEnquiryId.current, refresh])

  // useEffect(() => {
  //   try {
  //     if (refresh > 0) {
  //       fetch()
  //     }
  //   } catch (error) {
  //     setToastMessage({
  //       status: 'error',
  //       title: 'Get List Applications Failed',
  //       message: error.response?.data?.message || error,
  //     });
  //     console.log('Get List Applications', error);
  //   }
  // }, [refresh]);

  const handleNextStage = useCallback(() => {
    setIndexStageShow(prev => prev + 1)
  }, [])

  const handleExit = useCallback(({ enquiryId, campusId }) => {
    history.push('/bloomingdale-dashboard', { enquiryId, campusId })
  }, [])

  const handleChangeIndexStage = useCallback((index) => {
    setIndexStageShow(index)
  }, [])

  const handleRefreshEnquiry = useCallback(() => {
    setRefresh(prev => prev + 1)
  }, [])

  const handleRefreshAndNext = useCallback(() => {
    handleNextStage()
    handleRefreshEnquiry()
    if (refCurrentIndexStage.current < listStage.length)
      refCurrentIndexStage.current += 1
  }, [])

  const isCompletedOrClosed = [EnquiryStatus.CLOSED, EnquiryStatus.COMPLETE].includes(enquiry?.status)

  const mapStepDefault = useMemo(() => ({
    Application: (stageName) =>
      <BloomDetails
        stageName={stageName}
        isCompletedOrClosed={isCompletedOrClosed}
        enquiry={enquiry}
        handleNextStage={handleNextStage}
        handleRefreshAndNext={handleRefreshAndNext}
      />,
    Signature: (stageName) =>
      <BloomReview
        stageName={stageName}
        isCompletedOrClosed={isCompletedOrClosed}
        enquiry={enquiry}
        handleNextStage={handleNextStage}
        handleRefreshAndNext={handleRefreshAndNext}
        handleRefreshEnquiry={handleRefreshEnquiry}
      />,
    Exam: (stageName) =>
      <BloomChildAssessment
        enquiry={enquiry}
        handleNextStage={handleNextStage}
        handleRefreshAndNext={handleRefreshAndNext}
      />,
    "Principal Approval": (stageName) =>
      <BloomChildPrincipal
        enquiry={enquiry}
        handleNextStage={handleNextStage}
      />,
    "Document Verification": (stageName) =>
      <div className="flex">
        <div className="w-[80%] mx-auto">
          <Documents
            isParent={true}
            enquiry={enquiry}
            isCompletedOrClosed={isCompletedOrClosed}
            handleNextStage={handleNextStage}
            handleRefreshEnquiry={handleRefreshEnquiry}
          />
        </div>
      </div>,
    // <BloomChildDocument
    //   stageName={stageName}
    //   enquiry={enquiry}
    //   // indexStage={indexStageTake}
    //   handleNextStage={handleNextStage}
    //   handleExit={handleExit}
    // />,
    "Fee Payment": (stageName) =>
      <BloomChildFinance
        enquiry={enquiry}
        isCompletedOrClosed={isCompletedOrClosed}
        handleNextStage={handleNextStage}
      />,
    Complete: (stageName) =>
      <BloomChildComplete
        stageName={stageName}
        enquiry={enquiry}
        isCompletedOrClosed={isCompletedOrClosed}
        indexStage={refCurrentIndexStage.current}
        handleNextStage={handleNextStage}
        handleExit={handleExit}
      />,
  }), [enquiry])

  const result = useCallback(() => {
    if (listStage.length !== 0) {
      const stage = listStage[indexStageShow]
      const { formId, name, textDefault } = stage
      if (textDefault) {
        return mapStepDefault[textDefault](name)
      } else {
        return <ChildCustomStage
          formId={formId}
          stageName={name}
          enquiry={enquiry}
          listStage={listStage}
          isCompletedOrClosed={isCompletedOrClosed}
          handleNextStage={handleNextStage}
          handleRefreshAndNext={handleRefreshAndNext}
        />
      }
    }
  }, [indexStageShow, listStage, enquiry])

  const listStageName = useMemo(() => {
    if (listStage.length !== 0)
      return listStage.map((item, index) => {
        const { name, isDone } = item
        return { name, isDone, isActive: index === indexStageShow }
      })
  }, [listStage, indexStageShow, refresh])

  if (listStage.length === 0) return;

  return (
    <section>
      <BloomHeader />
      <BloomCrumbs
        indexStageShow={indexStageShow}
        data={listStageName}
        enquiry={enquiry}
        indexStageTake={refCurrentIndexStage.current}
        handleChangeIndexStage={handleChangeIndexStage}
      />
      <div className="mt-16 relative">
        <button
          className="absolute top-[0.5rem] left-3"
          onClick={() => handleExit({ enquiryId: enquiry.id, campusId: enquiry.campusId })}
        >
          <img src={backarrow} className="w-4 h-3" alt="arrow" />
        </button>
        <div className="w-[100%] px-8">
          {result()}
        </div>
      </div>
    </section>
  )
}

export default BloomingdaleStage;