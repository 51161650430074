import React from 'react';
import ReactTooltip from 'react-tooltip';
import { listTime } from '../../../constant';
import './scheduled.scss';

export default function Scheduled({ exam }) {

  //   useEffect(() => {
  //     if (exam?.proctorUserId) {
  //       const res = callApi({
  //         method: 'get',
  //         url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/user/${exam?.proctorUserId}`,
  //       });
  //       res
  //         .then((res) => {
  //           const { data } = res;
  //           if (!data.success) {
  //             setToastMessage({
  //               status: 'error',
  //               title: 'Get User Of Company Or Campus Failed',
  //               message: res.data.message,
  //             });
  //             setIsShowToastMessage(true);
  //           } else {
  //             setPresentProctor(data.data);
  //           }
  //         })
  //         .catch((error) => {
  //           setToastMessage({
  //             status: 'error',
  //             title: 'Get User Of Company Or Campus Failed',
  //             message: error.response?.data?.message || error,
  //           });
  //           setIsShowToastMessage(true);
  //           console.log('Schedule Error', error);
  //         });
  //     }
  //   }, [exam]);

  console.log('exam ', exam);

  const handleDate = (date) => {
    if (!date) {
      return '';
    }
    const data = new Date(date).toUTCString();
    return data.slice(5, 16);
  };
  const handleTime = () => {
    const data = listTime.find((data) => {
      return data.value[0] === exam?.startTimeSlot && data.value[1] === exam?.endTimeSlot;
    });
    return data && data.label;
  };

  function titleCase(str) {
    return str.toLowerCase().replace(/(^|\s)\S/g, function (l) {
      return l.toUpperCase();
    });
  }

  return (
    // <div className={clsx('your-test mb-10')}>
    <div style={{ borderRadius: '16px' }} >
      <ReactTooltip id="main" place="top" type="dark" effect="float" multiline={true} />
      <div className="date">
        {/* <div className="date_scheduler">
          <p className="date_scheduler-text">Test Date is Schduled by</p>
          <img
            data-for="main"
            data-tip={'123'}
            data-iscapture="true"
            alt=""
            src={avatar}
            className="date_scheduler-image"
          />
        </div> */}
        <div className="date_details">
          <div className="date_details-date">
            <p className="date_details-text">Test Date</p>
            <p className="date_details-schedule">{handleDate(exam?.testDate)}</p>
          </div>
          <div className="date_details-time">
            <p className="date_details-text">Time</p>
            <p className="date_details-schedule">{handleTime()}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
