import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';

import { FeeTermsCard, ToggleButton, TypesOfFees } from '@stories';
import { anal, analarrow, arrow, anal_down, analarrow_down } from '@static/image';
import { feebookField } from 'entities/data';
import { formatMoney } from '@helper/format-money';
import FoodFeeCard from './FoodFeeCard';
import TransportFeeCard from './TransportFeeCard';

const SingleFoodAndTransportFee = memo((props) => {
  const {
    numberOfMonthsInAcademicyear = 1,
    termsLength = 1,
    options = [],
    foodFee,
    transportFee,
    foodFeeSave,
    transportFeeSave,
    setFoodFee = () => { },
    setTransportFee = () => { },
    refresh,
    burger,
    title,
    razorpayBankAccount,
    listBankAccount = [],
    listAcademicYearTerm = []
  } = props;

  const Card = useMemo(
    () => ({
      'Food Fee': (
        <FoodFeeCard
          numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
          options={options}
          termsLength={termsLength}
          foodFee={foodFee}
          foodFeeSave={foodFeeSave}
          setFoodFee={setFoodFee}
          refresh={refresh}
          burger={burger}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      ),
      'Transport Fee': (
        <TransportFeeCard
          numberOfMonthsInAcademicyear={numberOfMonthsInAcademicyear}
          options={options}
          termsLength={termsLength}
          transportFee={transportFee}
          transportFeeSave={transportFeeSave}
          setTransportFee={setTransportFee}
          refresh={refresh}
          burger={burger}
          razorpayBankAccount={razorpayBankAccount}
          listBankAccount={listBankAccount}
          listAcademicYearTerm={listAcademicYearTerm}
        />
      ),
    }),
    [numberOfMonthsInAcademicyear, transportFee, foodFee],
  );

  return (
    <div className={clsx('w-full')}>
      <p className="text-lg font-bold mt-8 mb-4">{title}</p>
      <div className={clsx('w-full')}>{Card[title]}</div>
    </div>
  );
});
SingleFoodAndTransportFee.displayName = 'SingleFoodAndTransportFee';
export default SingleFoodAndTransportFee;

