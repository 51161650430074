export const BASE_GIIKI_URL = '/giiki';
export const BASE_CENTRAL_URL = '/central';
export const BASE_CENTRAL_SETUP_URL = `${BASE_CENTRAL_URL}/setup`;
export const BASE_CENTRAL_FINANCE_URL = `${BASE_CENTRAL_URL}/finance`;
export const BASE_CENTRAL_COMMUNICATION_URL = `${BASE_CENTRAL_URL}/communication-notification`;
export const BASE_CENTRAL_INTEGRATION_URL = `${BASE_CENTRAL_URL}/integration`;
export const BASE_CENTRAL_ADMISSION_URL = `${BASE_CENTRAL_URL}/admission`;
export const BASE_CENTRAL_APPROVAL_URL = `${BASE_CENTRAL_URL}/approval`;
export const BASE_CAMPUS_ADMISSION_URL = '/campus/admission';
export const BASE_CAMPUS_SETTING_URL = '/campus/setting';
export const BASE_CAMPUS_FINANCE_URL = '/campus/finance';
export const urls = {
  home: '/home',
  approvals: '/approvals',
  createUser: '/create-user',
  importUser: '/import-user',
  // editUser: '/edit-user',
  // forms: '/forms',
  // formBuilder: '/form-builder',
  // createEnquiry: '/enquiry/create',
  createEnquiry: `${BASE_CAMPUS_ADMISSION_URL}/enquiry/create`,
  // approvalSetting: '/approval-setting',
  // documentSetting: '/document-setting',
  documentEdit: '/document-edit',
  myRequest: '/my-request',
  tasks: '/tasks',
  giiki: {
    home: `${BASE_GIIKI_URL}/home`,
    dashboard: `${BASE_GIIKI_URL}/dashboard`,
    institutions: `${BASE_GIIKI_URL}/institutions`,
    users: `${BASE_GIIKI_URL}/users`,
    roles: `${BASE_GIIKI_URL}/roles`,
    report: {
      user: `${BASE_GIIKI_URL}/reports/user`,
      issue: `${BASE_GIIKI_URL}/reports/issue`,
      bug: `${BASE_GIIKI_URL}/reports/bug`
    },
    companySetting: `${BASE_GIIKI_URL}/company/setting`
  },
  central: {
    approval: {
      approvalSettings: `${BASE_CENTRAL_APPROVAL_URL}/approval-settings`,
    },
    admission: {
      forms: `${BASE_CENTRAL_ADMISSION_URL}/forms`,
      formBuilder: `${BASE_CENTRAL_ADMISSION_URL}/form-builder`,
      documents: `${BASE_CENTRAL_ADMISSION_URL}/documents`,
      editDocument: `${BASE_CENTRAL_ADMISSION_URL}/edit-document`
    },
    setup: {
      roles: `${BASE_CENTRAL_SETUP_URL}/roles`,
      users: `${BASE_CENTRAL_SETUP_URL}/users`,
      editUser: `${BASE_CENTRAL_SETUP_URL}/edit-user`,
      campuses: `${BASE_CENTRAL_SETUP_URL}/campuses`
    },
    finance: {
      feebooks: `${BASE_CENTRAL_FINANCE_URL}/feebooks`,
      feebookEdit: `${BASE_CENTRAL_FINANCE_URL}/feebook-edit`,
      scholarships: `${BASE_CENTRAL_FINANCE_URL}/scholarships`,
      cashierScreen: `${BASE_CENTRAL_FINANCE_URL}/cashier-screen`,
      cashierReports: `${BASE_CENTRAL_FINANCE_URL}/cashier-reports`,
      bankAccounts: `${BASE_CENTRAL_FINANCE_URL}/bank-accounts`,
      newBankAccount: `${BASE_CENTRAL_FINANCE_URL}/new-bank-account`
    },
    communicationNotification: {
      communicationTemplates: `${BASE_CENTRAL_COMMUNICATION_URL}/communication-templates`,
      templateEdit: `${BASE_CENTRAL_COMMUNICATION_URL}/edit-templates`,
      notifications: `${BASE_CENTRAL_COMMUNICATION_URL}/notifications`
    },
    integration: {
      whatsapps: `${BASE_CENTRAL_INTEGRATION_URL}/whatsapps`,
      phones: `${BASE_CENTRAL_INTEGRATION_URL}/phones`
    }
  },
  campus: {
    admission: {
      dashboard: `${BASE_CAMPUS_ADMISSION_URL}/dashboard`,
      applications: `${BASE_CAMPUS_ADMISSION_URL}/application/application`,
      enquiriesOverview: `${BASE_CAMPUS_ADMISSION_URL}/enquiries-overview`,
      cashierPayments: `${BASE_CAMPUS_ADMISSION_URL}/cashier-payment`,
      setting: {
        distributions: `${BASE_CAMPUS_ADMISSION_URL}/setting/distributions`,
        teams: `${BASE_CAMPUS_ADMISSION_URL}/setting/admission-team`,
        documents: `${BASE_CAMPUS_ADMISSION_URL}/setting/documents`,
        editDocument: `${BASE_CAMPUS_ADMISSION_URL}/setting/edit-document`,
        approvals: `${BASE_CAMPUS_ADMISSION_URL}/setting/admission-approval`,
        forms: `${BASE_CAMPUS_ADMISSION_URL}/setting/forms`,
        formBuilder: `${BASE_CAMPUS_ADMISSION_URL}/setting/form-builder`,
        fields: `${BASE_CAMPUS_ADMISSION_URL}/setting/fields`,
        communicationTemplates: `${BASE_CAMPUS_ADMISSION_URL}/setting/communication-templates`,
        workflow: `${BASE_CAMPUS_ADMISSION_URL}/setting/workflow`
      }
    },
    setting: {
      academicYears: `${BASE_CAMPUS_SETTING_URL}/academic-year/middle`,
      programClasses: `${BASE_CAMPUS_SETTING_URL}/program-and-classes`,
      users: `${BASE_CAMPUS_SETTING_URL}/users`,
      editUser: `${BASE_CAMPUS_SETTING_URL}/edit-user`,
      roles: `${BASE_CAMPUS_SETTING_URL}/roles`,
      keyRoles: `${BASE_CAMPUS_SETTING_URL}/key-roles`
    },
    finance: {
      cashierPayment: `${BASE_CAMPUS_FINANCE_URL}/cashiers`,
      feebooks: `${BASE_CAMPUS_FINANCE_URL}/feebooks`,
      scholarships: `${BASE_CAMPUS_FINANCE_URL}/scholarships`,
      feebookEdit: `${BASE_CAMPUS_FINANCE_URL}/feebook-edit`
    },
    goals: {},
    fieldAPP: {}
  }
}