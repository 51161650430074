import { back_space } from '@pages/cashier/image';
import { useContext, useState } from 'react';
import { ToastMessageContext } from 'context/toast-context';
import './payment-method-section.scss';
import admissionApi from 'api/admission';
import useStorage from 'store/storage';

const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0'];

function PaymentMethodSection(props) {
  const {
    fee = [],
    listScholarships = [],
    applicationId,
    handleUpdateSuccess = () => { },
  } = props;
  const [price, setPrice] = useState('0');
  const campus = useStorage((state) => state.currentCampus);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);

  const handleClick = (value) => {
    if (price.includes('.') && value === '.') return;
    if (price.length === 0 && (value === '.' || value === '0')) return;
    setPrice((prev) => prev + value);
  };
  const handleClickBackspace = () => {
    setPrice((prev) => {
      const result = prev.slice(0, -1);
      return result;
    });
  };
  const handleClickConfirm = () => {
    const listFeesCallAPI = {};
    fee.forEach((f) => {
      listFeesCallAPI[f.name] = {
        fee: f.price,
        isPaid: f.isSelected || f.isPaid,
      };
    });
    const listScholarshipsCallAPI = {};
    listScholarships.forEach((sch) => {
      listScholarshipsCallAPI[sch.id] = {
        isUsed: sch.isApproved,
      };
    });
    // callApi({
    //   method: 'patch',
    //   url: `${process.env.REACT_APP_URL_API_ADMISSION}/api/v2/admission/fee-payment/payment-link/${applicationId}`,
    //   params: { campusId: campus?.isCentral ? undefined : campus?.id },
    //   data: {
    //     data: JSON.stringify({
    //       fees: listFeesCallAPI,
    //       scholarships: listScholarshipsCallAPI,
    //     }),
    //   },
    // })
    admissionApi.updatePaymentLink({ campusId: campus?.isCentral ? undefined : campus?.id }, {
      data: JSON.stringify({
        fees: listFeesCallAPI,
        scholarships: listScholarshipsCallAPI,
      }),
    })
      .then((res) => {
        if (res.data.success) {
          setToastMessage({
            status: 'success',
            title: 'Update Payment Information Successfully',
          });
          setIsShowToastMessage(true);
          handleUpdateSuccess();
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update Payment Information Failed',
            message: res.data?.message || '',
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((err) => {
        setToastMessage({
          status: 'error',
          title: 'Update Payment Information Failed',
          message: err,
        });
        setIsShowToastMessage(true);
      });
  };

  return (
    <div className="payment-method-section flex flex-col items-center pt-20 ml-5 w-full">
      <p className="payment-method-section-title">Payment Method</p>
      <p className="payment-method-section-price">{price}</p>
      <div className="flex flex-wrap items-center justify-between bg-transparent rounded-lg py-5 px-7 w-72">
        {numbers.map((num) => {
          return (
            <div
              onClick={() => {
                handleClick(num);
              }}
              className="payment-method-section-number"
              key={`payment-method-${num}`}
            >
              {num}
            </div>
          );
        })}
        <div
          onClick={handleClickBackspace}
          className="payment-method-section-number bg-transparent"
        >
          <img src={back_space} alt="Back space" />
        </div>
        <div className="w-full flex mt-8 items-center justify-between">
          <button className="payment-method-section-btn print">Print Receipt</button>
          <button
            // onClick={handleClickConfirm}
            className="payment-method-section-btn confirm"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default PaymentMethodSection;
