import React from 'react';

const FormDrag = (props) => {
  // console.log('FormDrag', props);
  const { formStyle } = props;
  const { width, height, ...rest } = formStyle;
  return (
    <div
      className="form-drag"
      style={{
        width,
        height,
        ...rest,
        border: '1px gray dashed',
      }}
    />
  );
};

export default FormDrag;
