import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';

import { cancel } from '../../../../static/image';
import { Button, Input, SelectGroup } from '../../../../stories';
import { selectStylesWithNoTitle, selectStylesWithTitle } from '../../../../entities/select-style';
import './add-grade-modal.scss';

export default function AddGradeModal({
  open,
  isOpen,
  programId,
  programsTabCurrent,
  gradesOption,
  setGrades,
  setSettingsOfProgram,
}) {
  const [tableOpen, setTableOpen] = useState(false);
  const [selectedGrade, setSelectedGrade] = useState([]);

  const styles = {
    closeBtn: {
      backgroundColor: '#fff',
      color: '#202223',
      border: '1px solid #E1E3E5',
      marginRight: '0.5rem',
    },
    createBtn: { backgroundColor: '#008060' },
  };

  const handleClose = useCallback(() => {
    setSelectedGrade([]);
  }, []);

  const allClose = () => {
    setTableOpen(false);
  };

  const onClickCreateHandler = () => {
    const data = selectedGrade.map((item) => {
      const settingOfGrade = {
        grade: {
          id: item?.info?.id,
          name: item?.info?.name,
          code: item?.info?.code,
        },
        admissionTeamSetting: {
          isVisible: true,
          counselors: [
            {
              counselor: {},
            },
          ],
        },
      };
      return settingOfGrade;
    });

    setSettingsOfProgram((prev) => {
      return {
        ...prev,
        programGrade: [...prev.programGrade, ...data],
      };
    });

    setGrades((prev) => {
      const result = prev.filter((item) => {
        return !selectedGrade.find((el) => el.value === item.name);
      });
      return result;
    });
    handleClose();
    isOpen();
  };

  return (
    <div className={open ? 'action-modal action-modal-active' : 'action-modal'}>
      <div
        className="action-modal_bg"
        onClick={() => {
          isOpen();
          handleClose();
        }}
      />
      <div className="action-modal-card" onClick={tableOpen === true ? allClose : null}>
        <div className="action-modal-card-header">
          <h2 className="text-2xl ml-6">Add new Grade</h2>
          <button
            className="mr-4"
            onClick={() => {
              isOpen();
              handleClose();
            }}
          >
            <img src={cancel} alt="closed modal" />
          </button>
        </div>
        <div className="action-modal-card-row">
          <div className="action-modal-card-row_label"></div>
          <div className="action-modal-card-row_selects">
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              // title="Program"
              value={[{ value: programsTabCurrent, label: programsTabCurrent }]}
              styles={selectStylesWithNoTitle}
              isDisabled={true}
            />
            <SelectGroup
              classNameGroup="ml-10 mb-7 w-80"
              title="Select Grade"
              value={selectedGrade}
              defaultValue={null}
              styles={selectStylesWithTitle}
              options={gradesOption}
              isMulti={true}
              isSearchable={true}
              onChange={(e) => {
                setSelectedGrade(e);
              }}
            />
            {/* <div className="ml-10 w-80">
              <Input
                label="Approval name"
                value={name || ''}
                // onChange={handleChangeName}
                customStyle={{
                  paddingLeft: '1rem',
                  width: '100%',
                  height: '2.65rem',
                  fontSize: '1rem',
                }}
              />
            </div> */}
          </div>
        </div>
        <div className="flex justify-end pt-4 pr-4">
          <Button
            text="Close"
            customStyle={styles.closeBtn}
            onClick={() => {
              isOpen();
              handleClose();
            }}
          />
          <Button text="Create" customStyle={styles.createBtn} onClick={onClickCreateHandler} />
        </div>
      </div>
    </div>
  );
}

AddGradeModal.propTypes = {
  open: PropTypes.bool,
  isOpen: PropTypes.func,
};
