import React from 'react';
import clsx from 'clsx';
import { useContext, useEffect, useMemo, useState } from 'react';
import ModuleSection from './ModuleSection';
import PermissionSection from './PermissionSection';
import Footer from '@components/footer/footer';
import { ToastMessageContext } from 'context/toast-context';
import { callApi } from '@helper/call-api';
import authApi from 'api/auth';
import useStorage from 'store/storage';

const UpdateRoleModal = ({
  burger,
  data,
  optionTypes,
  permissionOrigin,
  notifyMessage,
  isAdmin,
  setRefresh,
  handleClickClose,
  campusId,
}) => {
  const { role, modules, dataPermission } = data;
  const { roleName, loginTypeId } = role[0];
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [dataRoleLogs, setDataRoleLogs] = useState([]);
  const [dataRole, setDataRole] = useState(dataPermission);
  const [disableButton, setDisableButton] = useState(true);
  const openModule = {};
  modules.forEach(({ module }) => {
    openModule[module] = true;
  });
  const [openPage, setOpenPage] = useState(openModule);
  const [openPermission, setOpenPermission] = useState(openModule);
  const campus = useStorage((state) => state.currentCampus);

  const loginTypeName = useMemo(() => {
    const temp = optionTypes.find(({ id }) => id === loginTypeId);
    if (temp) return temp.title;
    return '';
  }, []);

  const handleClickArrow = (module) => {
    setOpenPage((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
    setOpenPermission((prev) => ({
      ...prev,
      [module]: !prev[module],
    }));
  };

  useEffect(() => {
    setDisableButton(permissionOrigin === JSON.stringify(dataRole));
  }, [dataRole]);

  const handleOnChange = (index, key) => {
    setDataRole((prev) => {
      const tmp = [...prev];
      const active = tmp[index][key].active;
      tmp[index][key].active = !active;
      return tmp;
    });
  };

  const getDataPermissionUpdate = (dataRole, draft = false) => {
    const result = [];
    dataRole.forEach((data) => {
      for (const value of Object.values(data)) {
        if (Object.keys(value).length !== 0) {
          const { uidPermission, active, pageId, permissionId } = value;
          if (draft)
            result.push({
              pagePermissionId: uidPermission,
              active,
              pageId,
              permissionId,
            });
          result.push({ id: uidPermission, active, pageId, permissionId });
        }
      }
    });
    return result;
  };

  const handelSendForApproval = () => {
    let dataPer = [];
    if (dataRole.length !== 0) dataPer = getDataPermissionUpdate(dataRole);

    let flagMessage = 'other';
    let api = {};
    if (isAdmin) {
      flagMessage = 'giikiAdmin';
      // api = callApi({
      //   method: 'patch',
      //   url: `${process.env.REACT_APP_URL_API_AUTH}/api/v1/role/update-active`,
      //   params: { campusId },
      //   data: { data: dataPer },
      // });
      api = authApi.updateActiveRole({ campusId }, { data: dataPer })
    } else {
      const dataApi = {
        name: `Update role ${roleName}`,
        actionId: 2, // action id update
        changeContent: {
          actionName: 'Update role',
          apiURL: process.env.REACT_APP_URL_API_AUTH,
          endpoint: '/api/v1/role/update-active',
          apiMethod: 'PATCH',
          content: {
            data: dataPer,
          },
        },
        keyFields: [],
      };

      api = callApi({
        method: 'post',
        url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v1/approval/request`,
        params: { campusId: campus?.id },
        data: dataApi,
      });
    }

    api
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setRefresh((prev) => prev + 1);
          handleClickClose({});
          setToastMessage({
            status: 'error',
            title: 'Update Role Successfully',
            message: data.message,
          });
          setIsShowToastMessage(true);
        } else {
          setToastMessage({
            status: 'error',
            title: 'Update Role Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Update Role Failed',
          message: data.message,
        });
        setIsShowToastMessage(true);
        console.log('Campus Information Role Permissions Popup Error', error);
      });
  };

  const handleSaveAsDraft = () => {
    if (isAdmin) {
      setToastMessage({
        status: 'info',
        title: 'Update Role Failed',
        message: 'Waiting for design',
      });
      setIsShowToastMessage(true);
      return;
    }

    let dataPer = [];
    if (dataRole.length !== 0) dataPer = getDataPermissionUpdate(dataRole, true);

    const dataApi = {
      actionName: 'Update role',
      apiURL: process.env.REACT_APP_URL_API_AUTH,
      endpoint: '/api/v1/role/update-active',
      apiMethod: 'PATCH',
      content: {
        data: dataPer,
      },
    };

    const req = callApi({
      method: 'post',
      url: `${process.env.REACT_APP_URL_API_APPROVAL}/api/v1/approval/draft`,
      data: dataApi,
    });
    req
      .then((res) => {
        const { data } = res;
        if (data.success) {
          setToastMessage({
            status: 'success',
            title: 'Create Role Draft Successfully',
            message: data.message,
          });
          setIsShowToastMessage(true);
          // history.push('/dashboard')
        } else {
          setToastMessage({
            status: 'error',
            title: 'Create Role Draft Failed',
            message: data.message,
          });
          setIsShowToastMessage(true);
        }
      })
      .catch((error) => {
        setToastMessage({
          status: 'error',
          title: 'Create Role Draft Failed',
          message: error.response?.data?.message || error,
        });
        setIsShowToastMessage(true);
        console.log('Campus Information Role Permissions Popup Error', error);
      });
  };

  const handleClickCancel = () => {
    setDataRole(JSON.parse(permissionOrigin));
  };

  return (
    <div
      className={clsx('campus-information mb-0', burger ? '' : '')}
      style={{
        marginTop: '0px',
        width: '100%',
        paddingLeft: '1.25rem',
      }}
    >
      {/* <NavLinks urls={dataLinks.campusRoleLinks} /> */}
      <div className="flex items-center justify-between">
        <h1 className="namepage">Update Role</h1>
      </div>
      <div
        className="flex"
        style={{
          width: 'fit-content',
          height: '24rem',
          overflowY: 'auto',
          overflowX: 'hidden',
        }}
      >
        <div
          className="grades-programs-search"
          style={{
            minWidth: '15rem',
            height: 'fit-content',
            paddingTop: '7.5rem',
          }}
        >
          <ModuleSection
            listModule={modules}
            handleClickArrow={handleClickArrow}
            openPage={openPage}
          />
        </div>
        <div className="grades-programs-row ml-8 w-64">
          <div>
            <p>Role Name</p>
            <input
              type="text"
              onChange={() => { }}
              disabled
              value={roleName}
              style={{
                boxShadow: '0 3px 5px #8080808c',
              }}
              className="w-60 h-9 text-base border border-solid border-gray-50 py-2 text-gray-50 bg-white"
            />
          </div>
          <div>
            <p>Login Type</p>
            <input
              type="text"
              onChange={() => { }}
              disabled
              value={loginTypeName}
              style={{
                boxShadow: '0 3px 5px #8080808c',
              }}
              className="w-60 h-9 text-base border border-solid border-gray-50 py-2 text-gray-50 bg-white"
            />
          </div>
          <div className="grades-programs-row flex overflow-x-auto">
            <PermissionSection
              listRole={role}
              listPermission={dataRole}
              listModule={modules}
              openPermission={openPermission}
              handleClickUpdate={() => { }}
              isPopup={true}
              handleOnChange={handleOnChange}
            />
          </div>
        </div>
      </div>
      {!disableButton && (
        <Footer
          isDisableButton={false}
          isCreateRole={true}
          // isAdmin={isAdmin}
          isApproval={!isAdmin}
          style={{ width: '100%', position: 'initial' }}
          burger={burger}
          handleSaveAsDraft={handleSaveAsDraft}
          dataRoleLogs={dataRoleLogs}
          handleClickCancel={handleClickCancel}
          handelSendForApproval={handelSendForApproval}
        />
      )}
    </div>
  );
};

export default UpdateRoleModal;
