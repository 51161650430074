import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar';
import { Button, ProofCard } from '@stories/index';
import { checkErrorMultipleApiFetch } from '@utils/check-error/api-error';
import { ToastMessageContext } from 'context/toast-context';
import Scholarship from './scholarship';
import { documentSettingStatus } from '@constant/index';
import { DOCUMENT_STATUS } from '@helper/admission';
import admissionApi from 'api/admission';
import centralAdminApi from 'api/central-admin';

export default function Documents(props) {
  const { burger, isParent, enquiry, isCompletedOrClosed, handleNextStage, handleRefreshEnquiry } = props;
  const [refresh, setRefresh] = useState(false);
  const [step, setStep] = useState(false);
  const { setToastMessage, setIsShowToastMessage } = useContext(ToastMessageContext);
  const [documentSettings, setDocumentSettings] = useState([])
  const [documentInfo, setDocumentInfo] = useState({});

  const { campusId, programId, applicationId } = useMemo(() => {
    const { campusId, programGrade, admissionResult } = enquiry
    const programId = programGrade.program.id
    let applicationId = ''
    if (admissionResult?.info) {
      const info = JSON.parse(admissionResult.info)
      applicationId = info.application.id
    }
    return { campusId, programId, applicationId }
  }, [enquiry])

  const styles = {
    circle: buildStyles({
      textSize: '1.5rem',
      pathTransitionDuration: 5,
      pathColor: `#008060`,
      textColor: 'black',
      trailColor: 'rgba(226,226,226,0.2)',
      backgroundColor: '#3e98c7',
    }),
  };

  const percent = useMemo(() => {
    if (Object.keys(documentInfo).length === 0) return 0;
    let total = 0
    let approved = 0
    for (let documentSetting of documentSettings) {
      const { status, name, atLeastDocument } = documentSetting
      if (status === documentSettingStatus.required) {
        total += atLeastDocument
        if (documentInfo[name]) {
          for (let document of documentInfo[name]) {
            if (document.status === DOCUMENT_STATUS.approved) {
              approved += 1
            }
          }
        }
      }
    }
    const per = Math.round((approved / total) * 100)
    return per
  }, [documentInfo]);

  const fetchData = useCallback(async (signal) => {
    let apiDocument = admissionApi.getListDocument({ applicationId }, signal)
    if (isParent) {
      apiDocument = admissionApi.getListDocumentParent({ applicationId }, signal)
    }
    const apiSetting = centralAdminApi.getListDocumentSetting({ campusId, programId }, signal)
    const res = await Promise.all([apiSetting, apiDocument])
    if (checkErrorMultipleApiFetch(res, setToastMessage, setIsShowToastMessage, 'Fetch Data')) {
      const settings = res[0].data.data.objects.filter(item => item.status !== documentSettingStatus.disabled)
      setDocumentSettings(settings)
      const info = {}
      for (let setting of settings) {
        const { name } = setting;
        const tmp = res[1].data.data.objects.filter(item => item.name === name)
        info[name] = tmp;
      }
      setDocumentInfo(info)
    }
  }, [campusId, programId, applicationId]);

  useEffect(() => {
    const controller = new AbortController();
    fetchData(controller.signal)
    return () => {
      controller.abort()
    }
  }, [refresh])

  const checkEnoughDocuments = useCallback(() => {
    try {
      for (let setting of documentSettings) {
        const { status, name, atLeastDocument } = setting
        if (status === documentSettingStatus.required) {
          if (!documentInfo[name]) {
            return { error: true, message: `${name} is required` }
          }
          let approved = 0
          for (let document of documentInfo[name]) {
            if (document.status === DOCUMENT_STATUS.approved)
              approved += 1
          }
          if (atLeastDocument > approved)
            return { error: true, message: `At least ${atLeastDocument} document for ${name}` }
        }
      }
      return { error: false }
    } catch (error) {
      console.log('handleNextStep', error)
    }
  }, [documentSettings, documentInfo])

  const handleNextStep = useCallback(() => {
    if (isCompletedOrClosed) {
      setStep(true)
      return
    }
    const { error, message } = checkEnoughDocuments()
    if (error) {
      setToastMessage({
        status: 'warning',
        title: 'Update Document',
        message,
      });
      setIsShowToastMessage(true);
      return
    }
    setStep(true)
  }, [checkEnoughDocuments])

  return (
    step ? (
      <Scholarship
        isParent={isParent}
        applicationId={applicationId}
        enquiry={enquiry}
        isCompletedOrClosed={isCompletedOrClosed}
        handleNextStage={handleNextStage}
        handleRefreshEnquiry={handleRefreshEnquiry}
      />
    ) : (
      <div className='grid place-items-center'>
        <div className='w-[540px]'>
          <div className="flex items-center justify-between">
            <div className="mb-10">
              <h3 className="font-medium text-2xl mb-3">Documents</h3>
              <p className="text-xs" style={{ width: '17.375rem' }}>
                Please upload mandatory Documents The documents will be verified in 2
                Working days
              </p>
            </div>
            <div className="w-20 h-20">
              <CircularProgressbarWithChildren value={percent} styles={styles.circle}>
                <div
                  className="flex flex-col items-center text-xs"
                  style={{ marginTop: '-0.313rem' }}
                >
                  <strong>{percent}%</strong>
                  <p>Complete</p>
                </div>
              </CircularProgressbarWithChildren>
            </div>
          </div>
          {(documentSettings.length !== 0 && Object.keys(documentInfo).length !== 0) && documentSettings.map((document, index) => {
            const { name } = document
            return (
              <ProofCard
                key={`document${index}`}
                isParent={isParent}
                num={index}
                setRefresh={setRefresh}
                applicationId={applicationId}
                data={documentInfo[name]}
                documentSetting={document}
              />

            )
          })}
          <div className="flex justify-end mb-6">
            <Button
              text='Next Step'
              // buttonDisabled={!isEdit}
              customStyle={{
                justifyContent: 'right',
                paddingLeft: '3.438rem',
                paddingRight: '3.438rem',
                marginLeft: '1.25rem',
              }}
              onClick={handleNextStep}
            />
          </div>
        </div>
      </div>
    )
  );
}