import { getCentralInfo, getCompany, getCurrentAcademicYearName, getCurrentCampusOrCompany, getCurrentRoutes, getIsCentral, getIsFinance, getIsGiikiAdmin, getPreviousAcademicYearName, getTitle } from '@utils/storage';
import { NAVBAR } from '../action-creators/types';

const campus = getCurrentCampusOrCompany();
const currentAcademicYearName = getCurrentAcademicYearName();
const previousAcademicYearName = getPreviousAcademicYearName();
const routes = getCurrentRoutes()
const isGiikiAdmin = getIsGiikiAdmin()
const companyInfo = getCompany();
const isCentral = getIsCentral();
const centralInfo = getCentralInfo();
const isFinance = getIsFinance();
const title = getTitle()

const initState = {
  currentCampusOrCompany: campus,
  currentAcademicYearName,
  previousAcademicYearName,
  routes,
  isGiikiAdmin,
  company: companyInfo,
  isCentral,
  isFinance,
  centralInfo,
  title
};

const navbarSlice = (state = initState, action) => {
  switch (action.type) {
    case NAVBAR.setRoutes:
      return { ...state, routes: action.payload };
    case NAVBAR.setCurrentCampusOrCompany:
      return { ...state, currentCampusOrCompany: action.payload }
    case NAVBAR.setCampusRecent:
      return { ...state, campusRecent: action.payload }
    case NAVBAR.setTitle:
      return { ...state, title: action.payload }
    case NAVBAR.setCompany:
      return { ...state, company: action.payload }
    case NAVBAR.setIsCentral:
      return { ...state, isCentral: action.payload }
    case NAVBAR.setIsFinance:
      return { ...state, isFinance: action.payload }
    case NAVBAR.setCentralInfo:
      return { ...state, centralInfo: action.payload }
    case NAVBAR.setCurrentAcademicYearName:
      return { ...state, currentAcademicYearName: action.payload }
    case NAVBAR.setPreviousAcademicYearName:
      return { ...state, previousAcademicYearName: action.payload }
    case NAVBAR.setListAcademicYearName:
      return { ...state, listAcademicYearName: action.payload }
    case NAVBAR.setAcademicYearName:
      return {
        ...state,
        currentAcademicYearName: action.payload?.currentAcademicYearName,
        previousAcademicYearName: action.payload?.previousAcademicYearName
      }
    case NAVBAR.setIsGiikiAdmin:
      return { ...state, isGiikiAdmin: action.payload }
    default:
      return state;
  };
};

export default navbarSlice;