import AddIcon from '@mui/icons-material/Add';
import SingleFee from './single-fee';
import TextArea from '@stories/textarea/textarea';
import UploadDocument from './upload';
import {useEffect, useState} from 'react';

const ScholarshipInfo = (props) => {
    const {
        data,
        isParent,
        startOrder,
        admissionFee,
        editFee,
        objValueType,
        onOpenDialog,
        idxScholarship,
        setScholarships
    } = props
    const {info, urlDocuments, documents} = data
    const [amount, setAmount] = useState('0')

    const onChangeFee = ({idxFee, name, value}) => {
        setScholarships(prev => {
            prev[idxScholarship].info.fees = prev[idxScholarship].info.fees.map((fee, index) => {
                if (index === idxFee) return {...fee, [name]: value}
                return fee
            })
            prev[idxScholarship].isUpdate = true;
            return [...prev]
        })
    }

    const onChangeComment = ({value}) => {
        setScholarships(prev => {
            prev[idxScholarship].info.comment = value
            return [...prev]
        })
    }

    const onChangeDocument = ({file}) => {
        setScholarships(prev => {
            prev[idxScholarship].documents = [...prev[idxScholarship].documents, file]
            prev[idxScholarship].urlDocuments = [...prev[idxScholarship].urlDocuments, URL.createObjectURL(file)]

            return [...prev]
        })
    }

    const onRemoveFee = (idxFee) => {
        setScholarships(prev => {
            prev[idxScholarship].info.fees = [...prev[idxScholarship].info.fees.slice(0, idxFee), ...prev[idxScholarship].info.fees.slice(idxFee + 1)]
            return [...prev]
        })
    }

    const onRemoveDocument = (idx) => {
        setScholarships(prev => {
            prev[idxScholarship].documents = [...prev[idxScholarship].documents.slice(0, idx), ...prev[idxScholarship].documents.slice(idx + 1)]

            if (prev[idxScholarship].urlDocuments[idx]) URL.revokeObjectURL(prev[idxScholarship].urlDocuments[idx])

            prev[idxScholarship].urlDocuments = [...prev[idxScholarship].urlDocuments.slice(0, idx), ...prev[idxScholarship].urlDocuments.slice(idx + 1)]

            return [...prev]
        })
    }

    const calculatePercent = (name, percent) => {
        switch (name) {
            case 'Admission Fee':
                return admissionFee?.admissionFee ? (percent * admissionFee.admissionFee) / 100 : 0
            case 'Tuition Fee':
                return admissionFee?.tuitionFees ? (percent * admissionFee.tuitionFees) / 100 : 0
            case 'Residential Fee':
                return admissionFee?.residentialFee ? (percent * admissionFee.residentialFee) / 100 : 0
            case 'Food Fee':
                return admissionFee?.foodFee ? (percent * admissionFee.foodFee) / 100 : 0
            case 'Transport Fee':
                return admissionFee?.transportFee ? (percent * admissionFee.transportFee) / 100 : 0
            case 'Custom Fee':
                return admissionFee?.customFees ? (percent * admissionFee.customFees) / 100 : 0
            default:
                return 0;
        }
    }

    const getAmount = () => {
        if (isParent) return 0

        let amount = 0

        for (let fee of info.fees) {
            const {active, name, value, valueType} = fee
            if (active) {
                if (valueType === objValueType.Amount) {
                    amount += value ? Number(value) : 0
                } else {
                    amount += calculatePercent(name, value)
                }
            }
        }

        return amount.toFixed(2)
    }

    useEffect(() => {
        setTimeout(() => {
            const amount = getAmount()
            setAmount(amount)

            setScholarships(prev => {
                prev[idxScholarship].info.total = amount
                return [...prev]
            })
        }, 300)
    }, [info.fees])

    return (
        <>
            <p className="text-sm mt-2">{startOrder} Enter Scholarship Expectations</p>
            <p className="text-sm mb-2">Please enter the percentage of concession</p>

            <div className='mx-8'>
                {info.fees.map((fee, index) => {
                    return (
                        <div className='mb-4' key={`fee${index}`}>
                            <SingleFee data={fee} key={`SingleFee${index}`}
                                       objValueType={objValueType}
                                       editFee={editFee}
                                       onChange={({name, value}) => onChangeFee({idxFee: index, name, value})}
                                       onRemove={() => onRemoveFee(index)}
                            />
                        </div>
                    )
                })}
            </div>

            {/* add other fee */}
            <div className='flex mt-6'>
                <button className='flex items-center m-auto' onClick={() => onOpenDialog(idxScholarship)}>
                    <AddIcon style={{color: '#404EED'}}/>
                    <p className='text-lg'>Add Other Fee </p>
                </button>
            </div>

            {/* amount */}
            {!isParent && (
                <div className='mt-8 text-center'>
                    <div className='flex items-center justify-center'>
                        <INR/>
                        <p className='ml-2 font-bold' style={{fontSize: '3.125rem'}}>{amount}</p>
                    </div>

                    <p className='text-lg mt-4'>Approx Scholarship Amount</p>
                </div>
            )}

            {/* comment */}
            <p className="text-sm mt-4">{startOrder + 1}. Enter Information</p>
            <p className="text-sm mb-2">Please describe or list your achievements in the space below</p>
            <TextArea
                value={info.comment}
                onChange={(e) => onChangeComment({value: e.target.value})}
                placeholder="Comment"
                customStyle={{width: '100%', height: '7rem'}}
            />

            {/* upload file */}
            <UploadDocument documents={documents}
                            urlDocuments={urlDocuments}
                            order={startOrder + 2}
                            onRemoveDocument={onRemoveDocument}
                            idxScholarship={idxScholarship}
                            handleChange={(file) => {
                                if (file) onChangeDocument({file})
                            }}
            />
        </>
    )

}

export default ScholarshipInfo;

const INR = () => {
    return (
        <svg width="27" height="41" viewBox="0 0 27 41" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M10.25 24.944H8.668l11.05 11.8c1.322 1.423.347 3.756-1.56 3.756a2.123 2.123 0 0 1-1.56-.689L3.533 25.856a3.138 3.138 0 0 1-.866-2.178c0-1.756 1.386-3.178 3.098-3.178h4.485c3.813 0 6.977-2.889 7.497-6.667H2.667c-1.192 0-2.167-1-2.167-2.222S1.475 9.39 2.667 9.39h14.43c-1.214-2.622-3.814-4.445-6.847-4.445H2.667C1.475 4.944.5 3.944.5 2.722S1.475.5 2.667.5h21.666c1.192 0 2.167 1 2.167 2.222s-.975 2.222-2.167 2.222h-4.896c1.04 1.29 1.82 2.8 2.275 4.445h2.621c1.192 0 2.167 1 2.167 2.222s-.975 2.222-2.167 2.222h-2.21c-.563 6.223-5.676 11.111-11.873 11.111Z"
                fill="#000"/>
        </svg>

    )
}