import { combineReducers } from 'redux';
import companyReducer from './companyReducer';
import authSlice from './authSlice';
import logoReducer from './logoReducer'
import confirmCodeSMSSlice from './confirmCodeSMSSlice';
import navbarSlice from './navbarSlice';

const reducers = combineReducers({
    company: companyReducer,
    auth: authSlice,
    formData: logoReducer,
    confirmSMS: confirmCodeSMSSlice,
    navbar: navbarSlice
});

export default reducers;