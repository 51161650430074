import { Modal } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import { mainavatar } from '../../../static/image';
import './distribution-item.scss';

import DistributionInfo from './distribution-stage';
import LeadsDistributionPanel from './leads-distribution/leads-distribution';

const DistributionItem = ({
  distribution,
  setListDistribution,
  indexOfDistribution,
  getDataFormAPi,
  currentPage,
  selectedRowsPerPage,
}) => {
  const [visible, setVisible] = useState(false);
  const [info, setInfo] = useState([]);

  useEffect(() => {
    const {
      distributionType,
      ordering,
      conditions,
      rotateBetweenUser,
      rotateBetweenRole,
    } = distribution;
    setInfo([
      {
        title: 'Rotate Between',
        users: ((users) => {
          const data = [];
          users.forEach((user) => {
            data.push({ avatar: user.users.photoURL || mainavatar });
          });
          return data;
        })(rotateBetweenUser),
        roles: ((roles) => {
          const data = [];
          roles.forEach((role) => {
            data.push(role.role.name);
          });
          return data;
        })(rotateBetweenRole),
      },
      {
        title: 'Distribution Type',
        content: distributionType,
      },
      {
        title: 'Ordering',
        content: ordering,
      },
      {
        title: 'Criteria',
        criteria: ((conditions) => {
          const data = [];
          conditions.forEach((condition) => {
            data.push(condition.field);
          });
          return data;
        })(conditions),
      },
    ]);
  }, [distribution]);

  function openModalHandler() {
    setVisible(!visible);
  }

  return (
    <div className="distribution-item">
      <div className="distribution-item_header">
        <h2 className="distribution-item_title">{distribution.name}</h2>
        <ul className="distribution-item_stages distribution-stages">
          {info.map((item, idx) => {
            return (
              <DistributionInfo
                key={idx}
                index={idx}
                title={item.title}
                users={item.users}
                roles={item.roles}
                criteria={item.criteria}
                content={item.content}
              />
            );
          })}
        </ul>
        <button onClick={openModalHandler} className="distribution-item_btn none-after">
          <span></span>
        </button>
      </div>
      <Modal open={visible} onClose={openModalHandler}>
        <div>
          <LeadsDistributionPanel
            visible={visible}
            setVisible={setVisible}
            distribution={distribution}
            setListDistribution={setListDistribution}
            indexOfDistribution={indexOfDistribution}
            getDataFormAPi={getDataFormAPi}
            currentPage={currentPage}
            selectedRowsPerPage={selectedRowsPerPage}
          />
        </div>
      </Modal>
    </div>
  );
};

DistributionItem.propTypes = {
  title: PropTypes.string,
};

DistributionItem.defaultProps = {
  title: 'Distribution Title',
};

export default DistributionItem;
