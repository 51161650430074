import { navbarName } from '@utils/utils';
import { mainavatar } from '../static/image';
import { urls } from './urls';
import {
  campusAdmissionRoutes,
  campusFinanceRoutes,
  campusSettingRoutes,
  centralAdmissionRoutes,
  centralApprovalRoutes,
  centralFinanceRoutes,
  centralIntegrationRoutes,
  centralSettingRoutes,
  centralSetupRoutes,
} from './routes';
import {
  building,
  calls,
  cash,
  directions,
  edu,
  family,
  graphstats,
  mobile,
  settings,
  stuck,
} from '@pages/Header/components/search/sub-components/nav-app/image';

export const initAdmissionsUrls = [
  { url: '/application/application', name: 'Application', key: 0 },
  { url: '/application/signature', name: 'Signature', key: 1 },
  { url: '/application/exam', name: 'Exam', key: 2 },
  { url: '/application/principal-approval', name: 'Principal Approval', key: 3 },
  { url: '/application/document-upload', name: 'Document Verification', key: 4 },
  { url: '/application/fee-payment', name: 'Fee Payment', key: 5 },
  { url: '/application/complete', name: 'Complete', key: 6 },
];

export const FeeBookStatus = {
  APPROVED: 'Approved',
  APPROVAL_PENDING: 'Approval Pending',
  REJECTED: 'Rejected',
  DRAFT: 'Draft',
  INACTIVE: 'Inactive',
};

export const EnquiryStatus = {
  NEW: 'New',
  APPLICATION: 'Application',
  APPLICATION_COMPLETE: 'Application Complete',
  WAITING_FOR_SIGNATURE: 'Waiting for Signature',
  WAITING_FOR_PAYMENT: 'Waiting for Payment',
  SIGNATURE: 'Signature',
  WAITING_FOR_EXAM_RESULT: 'Waiting for Exam Result',
  EXAM_PASS: 'Pass',
  EXAM_FAIL: 'Fail',
  EXAM: 'Exam',
  WAITING_FOR_PRINCIPAL_APPROVAL: 'Waiting',
  PRINCIPAL_APPROVAL_COMPLETE: 'Principal Approval Complete',
  PRINCIPAL_APPROVAL: 'Principal Approval',
  PRINCIPAL_MEETING: 'Principal Meeting',
  DOCUMENT_VERIFICATION: 'Document Verification',
  WAITING_FOR_DOCUMENT_VERIFICATION: 'Waiting for Verification',
  FINANCE_APPROVAL: 'Finance Approval',
  FEE_PAYMENT: 'Fee Payment',
  CLOSED: 'Closed',
  COMPLETE: 'Complete',
  FAILED: 'Failed',
};

export const initialTask = {
  teamId: '',
  name: '',
  status: 'To do',
  minutesEstimate: 0,
  minutesWorked: 0,
  startDate: '',
  endDate: '',
  goalsOrKRI: '',
  dependency: '',
  // dueDateDependency: '',
  owners: [],
  fatherTaskId: '',
};

export const goalsList = [
  {
    status: 'Better Experience',
    color: '#00c875',
  },
  {
    status: 'Cultural Develop',
    color: '#e2445c',
  },
];

export const statusList = [
  {
    status: 'To do',
    color: '#375777',
  },
  {
    status: 'In progress',
    color: '#fdab3d',
  },
  {
    status: 'Done',
    color: '#00c875',
  },
  {
    status: 'Pending',
    color: '#c4c4c4',
  },
  {
    status: 'Cancel',
    color: '#e2445c',
  },
];

export const numberOfSections = [
  { value: 5, label: 5 },
  { value: 6, label: 6 },
  { value: 7, label: 7 },
  { value: 8, label: 8 },
  { value: 9, label: 9 },
  { value: 10, label: 10 },
  { value: 11, label: 11 },
  { value: 12, label: 12 },
  { value: 13, label: 13 },
  { value: 14, label: 14 },
  { value: 15, label: 15 },
  { value: 16, label: 16 },
  { value: 17, label: 17 },
  { value: 18, label: 18 },
  { value: 19, label: 19 },
  { value: 20, label: 20 },
];

export const maxStudentPerSections = [
  { value: 10, label: 10 },
  { value: 15, label: 15 },
  { value: 20, label: 20 },
  { value: 25, label: 25 },
  { value: 30, label: 30 },
  { value: 35, label: 35 },
  { value: 40, label: 40 },
  { value: 45, label: 45 },
  { value: 50, label: 50 },
];

export const minAge = [
  { value: 1, label: '1 Year' },
  { value: 2, label: '2 Year' },
  { value: 3, label: '3 Year' },
  { value: 4, label: '4 Year' },
  { value: 5, label: '5 Year' },
  { value: 6, label: '6 Year' },
  { value: 7, label: '7 Year' },
  { value: 8, label: '8 Year' },
  { value: 9, label: '9 Year' },
  { value: 10, label: '10 Year' },
  { value: 11, label: '11 Year' },
  { value: 12, label: '12 Year' },
  { value: 13, label: '13 Year' },
  { value: 14, label: '14 Year' },
  { value: 15, label: '15 Year' },
  { value: 16, label: '16 Year' },
  { value: 17, label: '17 Year' },
  { value: 18, label: '18 Year' },
  { value: 19, label: '19 Year' },
  { value: 20, label: '20 Year' },
  { value: 21, label: '21 Year' },
  { value: 22, label: '22 Year' },
  { value: 23, label: '23 Year' },
  { value: 24, label: '24 Year' },
  { value: 25, label: '25 Year' },
];

export const dataProgCard = [
  {
    firstHeader: 'Programmes',
    firstName: 'IB Early Years',
    secondHeader: 'Age Group',
    secondName: '2-6 Years',
    opinio: 1,
    modal: true,
  },
  {
    firstHeader: 'Programmes',
    firstName: 'IB Primary Years',
    secondHeader: 'Age Group',
    secondName: '5-10 Years',
    opinio: 2,
    modal: true,
  },
  {
    firstHeader: 'Programmes',
    firstName: 'IB Middle Years',
    secondHeader: 'Age Group',
    secondName: '11-16 Years',
    opinio: 2,
    modal: true,
  },
  {
    firstHeader: 'Programmes',
    firstName: 'IB Diploma',
    secondHeader: 'Age Group',
    secondName: '2-6 Years',
    opinio: 0,
    modal: true,
  },
  {
    firstHeader: 'Programmes',
    firstName: 'CBSE',
    secondHeader: 'Age Group',
    secondName: '2-18 Years',
    opinio: 4,
    modal: true,
  },
];

export const gradesDataProgCard = [
  {
    firstHeader: 'Program',
    firstName: 'IB Primary Years',
    secondHeader: 'Age ',
    secondName: null,
    opinio: 0,
    modal: false,
  },
  {
    firstHeader: 'Program',
    firstName: 'IB Middle Years',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 2,
    modal: false,
  },
  {
    firstHeader: 'Program',
    firstName: 'IB Diploma',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 3,
    modal: false,
  },
  {
    firstHeader: 'Program',
    firstName: 'CBSE Primary',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 4,
    modal: false,
  },
];

export const rolesDataProgCard = [
  {
    firstHeader: 'Role',
    firstName: 'Global Admin',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 1,
    modal: false,
    crumbs: true,
    status: 'Low',
  },
  {
    firstHeader: 'Role',
    firstName: 'Campus Admin',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 2,
    modal: false,
    crumbs: true,
    status: 'Medium',
  },
  {
    firstHeader: 'Role',
    firstName: 'Admissions Manager',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 3,
    modal: false,
    crumbs: true,
    status: 'Medium +',
  },
  {
    firstHeader: 'Role',
    firstName: 'Admissions Incharge',
    secondHeader: 'Access Level',
    secondName: null,
    opinio: 4,
    modal: false,
    crumbs: true,
    status: 'High',
  },
];

export const gradesData = [
  { name: 'Pre K1', minAge: 1, maxAge: 2 },
  { name: 'Pre K2', minAge: 3, maxAge: 4 },
  { name: 'Kindergarten 1', minAge: 5, maxAge: 7 },
  { name: 'Kindergarten 2', minAge: 8, maxAge: 10 },
  { name: 'Grade 1', minAge: 10, maxAge: 12 },
];

export const feebookFieldRow = [
  { style: 'standart', text: 'Tution Fee, Admission and Book-kit' },
  { style: 'gray', text: 'Residential Fee' },
  { style: 'gray', text: 'Food and Transport' },
  { style: 'outline', text: 'Custom and Other Fee' },
  { style: 'outline', text: 'Review' },
];

export const feebookField = {
  'Tution Fee': 'tutionFees',
  'Residential Fee': 'residentialFees',
  'Food Fee': 'foodFee',
  'Transport Fee': 'transportFee',
  'Custom and Other Fee': 'customFees',
  Review: 'review',
};

export const academicYear = ['2021-22', '2022-23', '2023-24', '2024-25', '2025-26'];

export const program = ['International Becclaurate', 'CBSE', 'IGCSE'];

export const overviewData = [
  { title: 'Enquiries', link: '', number: '20' },
  { title: 'Application', link: '', number: '8' },
  { title: 'E Signature', link: '', number: '4' },
  { title: 'Exam', link: '', number: '2' },
  { title: 'Approval', link: '', number: '2' },
  { title: 'Closed', link: '', number: '3' },
  { title: 'Failed', link: '', number: '2' },
];

export const overviewTwoData = [
  { title: 'Enquiries', link: '', number: '42' },
  { title: 'Application', link: '', number: '4' },
  { title: 'E Signature', link: '', number: '10' },
  { title: 'Exam', link: '', number: '2' },
  { title: 'Approval', link: '', number: '2' },
  { title: 'Closed', link: '', number: '1' },
  { title: 'Failed', link: '', number: '0' },
];

export const admissionsUrls = [
  { url: '/application/application', name: 'Application', key: 0 },
  { url: '/application/signature', name: 'Signature', key: 1 },
  { url: '/application/exam', name: 'Exam', key: 2 },
  { url: '/application/principal-approval', name: 'Principal Approval', key: 3 },
  { url: '/application/document-upload', name: 'Document Verification', key: 4 },
  { url: '/application/fee-payment', name: 'Fee Payment', key: 5 },
  { url: '/application/complete', name: 'Complete', key: 6 },
];

export const enquririesWindowData = [
  { name: 'Richard Mathew', py: 'PY4', pattern: 'New', date: '2021-22' },
  { name: 'Uma Naga Shenkar...', py: 'PY4', pattern: 'Failed', date: '2021-22' },
  { name: 'Subhash Atluri', py: 'PY2', pattern: 'New', date: '2021-22' },
  { name: 'Meena Atluri', py: 'PY4', pattern: 'New', date: '2021-22' },
  { name: 'George Bush', py: 'PY3', pattern: 'Failed', date: '2021-22' },
  { name: 'Richard Mathew', py: 'PY3', pattern: 'Application', date: '2021-22' },
];

export const informationField = [
  { title: 'First Name', body: 'Thomas' },
  { title: 'Middle Name ', body: 'Mathew' },
  { title: 'Last Name', body: 'Fulloway ' },
  { title: 'Preferred Name', body: 'Thoma' },
  { title: 'Other Name', body: 'Thoma' },
  { title: 'Date of Birth', body: '02 June, 2015' },
  { title: 'Gender', body: 'Male' },
  { title: 'Grade of Entry', body: '4th Grade' },
  { title: 'Enrollement Year', body: '2019-2020' },
  { title: 'Campus', body: 'Galieleo Campus' },
  { title: 'Email', body: 'thoma@bis.edu.in' },
  { title: 'Mobile Number', body: '7013688211' },
  { title: 'Language', body: 'English' },
  { title: 'Nationality', body: 'Indian' },
  { title: 'Naitonal ID', body: '4567 8565 1232' },
];

export const parentField = [
  { title: 'First Name', body: 'Thomas' },
  { title: 'Middle Name ', body: 'Mathew' },
  { title: 'Last Name', body: 'Fulloway ' },
  { title: 'Preferred Name', body: 'Thoma' },
  { title: 'Relation with applicant', body: 'Father' },
  { title: 'Date of Birth', body: '02 June, 1985' },
  { title: 'Gender', body: 'Male' },
  { title: 'Email', body: 'radnik.osw@gmail.com' },
  { title: 'Mobile Number', body: '+91 3423 322121' },
];

export const parentFieldMother = [
  { title: 'First Name', body: 'Viktoria' },
  { title: 'Middle Name ', body: 'Sasha' },
  { title: 'Last Name', body: 'Fulloway ' },
  { title: 'Preferred Name', body: 'Vika' },
  { title: 'Relation with applicant', body: 'Mother' },
  { title: 'Date of Birth', body: '22 Jule, 1989' },
  { title: 'Gender', body: 'Female' },
  { title: 'Email', body: 'heroik.olw@gmail.com' },
  { title: 'Mobile Number', body: '+95 3223 376911' },
];

export const addressField = [
  { title: 'Address 1', body: 'Galieleo Campus' },
  { title: 'Address 2', body: 'Galieleo Campus' },
  { title: 'State', body: 'Andhra Pradesh' },
  { title: 'Country', body: 'India' },
];

export const chipData = [
  {
    label: 'Dharma Theja',
    styleChip: 'outlined',
    image: mainavatar,
    style: { height: 24 },
  },
  {
    label: 'Ramesh',
    styleChip: 'outlined',
    image: mainavatar,
    style: { color: '#8C9094', borderColor: '#8C9094', height: 24 },
  },
  {
    label: 'Gopal Kodali ',
    styleChip: 'outlined',
    image: mainavatar,
    style: { height: 24 },
  },
  {
    label: 'Radhika Kodali',
    styleChip: 'outlined',
    image: mainavatar,
    style: { color: '#8C9094', borderColor: '#8C9094', height: 24 },
  },
  {
    label: 'Gopal Kodali ',
    styleChip: 'outlined',
    image: mainavatar,
    style: { height: 24 },
  },
  {
    label: 'Radhika Kodali',
    styleChip: 'outlined',
    image: mainavatar,
    style: { color: '#8C9094', borderColor: '#8C9094', height: 24 },
  },
];

export const navbarTitle = {
  ADMIN: 'Admin',
  CENTRAL_ADMISSIONS: 'Central Admissions',
  CENTRAL_BASIC_SETUP: 'Central Basic Setup',
  CENTRAL_FINANCE: 'Central Finance',
  CENTRAL_APPROVALS: 'Central Approvals',
  CENTRAL_COMMUNICATION: 'Communication and Notification',
  CENTRAL_INTEGRATIONS: 'Central Integrations',
  CAMPUS_ADMISSIONS: 'Campus Admissions',
  CAMPUS_SETTINGS: 'Campus Settings',
  CAMPUS_FINANCE: 'Campus Finance',
};

export const centralLinkDefault = [
  {
    link: urls.central.admission.documentSettings,
    label: 'Central Admissions',
    img: calls,
    isActive: false,
    fieldCheck: navbarTitle.CENTRAL_ADMISSIONS,
    routes: centralAdmissionRoutes,
  },
  {
    link: urls.central.setup.campuses,
    label: 'Basic Setup',
    img: settings,
    isActive: false,
    fieldCheck: navbarTitle.CENTRAL_BASIC_SETUP,
    routes: centralSetupRoutes,
  },
  {
    link: urls.central.finance.bankAccounts,
    label: 'Central Finance',
    img: graphstats,
    isFinance: false,
    isActive: false,
    fieldCheck: navbarTitle.CENTRAL_FINANCE,
    routes: centralFinanceRoutes,
  },
  {
    link: urls.central.approval.approvalSettings,
    label: 'Central Approvals',
    img: edu,
    isActive: false,
    fieldCheck: navbarTitle.CENTRAL_APPROVALS,
    routes: centralApprovalRoutes,
  },
  {
    link: urls.central.communicationNotification.communicationTemplates,
    label: 'Communication and Notification Settings',
    img: stuck,
    isActive: false,
    fieldCheck: navbarTitle.CENTRAL_COMMUNICATION,
    routes: centralSettingRoutes,
  },
  {
    link: urls.central.integration.whatsapps,
    label: 'Integrations',
    img: calls,
    isActive: false,
    fieldCheck: navbarTitle.CENTRAL_INTEGRATIONS,
    routes: centralIntegrationRoutes,
  },
];

export const campusLinkDefault = [
  {
    link: urls.campus.admission.dashboard,
    label: 'Admissions',
    img: family,
    navbar: navbarName.admission,
    isActive: false,
    fieldCheck: navbarTitle.CAMPUS_ADMISSIONS,
    routes: campusAdmissionRoutes,
  },
  {
    link: urls.campus.setting.academicYears,
    label: 'Campus Settings',
    img: building,
    navbar: navbarName.campus,
    isActive: false,
    fieldCheck: navbarTitle.CAMPUS_SETTINGS,
    routes: campusSettingRoutes,
  },
  {
    link: urls.campus.finance.cashierPayment,
    label: 'Finance',
    img: cash,
    navbar: navbarName.finance,
    isFinance: true,
    isActive: false,
    fieldCheck: navbarTitle.CAMPUS_FINANCE,
    routes: campusFinanceRoutes,
  },
];

export const comingSoonDefault = [
  { label: 'Goals', img: directions },
  { label: 'Field APP', img: mobile },
];
