import React, { memo, useCallback, useEffect, useState, useRef } from 'react';
import clsx from 'clsx';
import _ from 'lodash';

import { FeeTermsCard, ToggleButton, TypesOfFees } from '@stories';
import { Checkbox, Input, SelectGroup } from '@stories/index';
import { anal, analarrow, analarrow_down, anal_down, arrow } from '@static/image';
import { formatMoney } from '@helper/format-money';
import { calcEstManualFee } from '@helper/finance';
import { optionFee } from '@utils/constant';
import { calcPercent } from '@utils/utils';

let isStart = true;

const styles = {
  p: { color: '#81878c', fontSize: '0.875rem', marginBottom: '0.313rem' },
  img: { marginBottom: '0.125rem', marginLeft: '0.313rem' },
  span: { fontSize: '0.75rem', marginBottom: '0.063rem' },
};

const listFee = [
  // 'Book fee',
  'Tution fee',
  // 'Admission fee',
  // 'Application fee',
];

const TutionCardFee = memo((props) => {
  const {
    admissionFee = {},
    applicationFee = {},
    options = [],
    classFeeInfo,
    classOldFeeInfo,
    classId,
    setTutionFees,
    termsLength = 0,
    types,
    data,
    burger,
    title,
    refresh,
    oldTerms = [],
    listBankAccount = [],
    razorpayBankAccount = {},
    razorpayBankAccountId,
    listAcademicYearTerm = []
  } = props;

  const [isOpen, setIsOpen] = useState(false);
  const [feeType, setFeeType] = useState(types && types[0]);
  const [isAdmissionFeeActive, setIsAdmissionFeeActive] = useState(false);
  const [isApplicationFeeActive, setIsApplicationFeeActive] = useState(false);
  const [isBookFeeActive, setIsBookFeeActive] = useState(false);
  const [feeTypeFeeValue, setFeeTypeFeeValue] = useState(0);
  const [bookKitFeeValue, setBookKitFeeValue] = useState(0);
  const [admissionFeeValue, setAdmissionFeeValue] = useState(0);
  const [estAnnualFee, setEstAnnualFee] = useState(0);
  const [applicationFeeValue, setApplicationFeeValue] = useState(0);
  const [termsArray, setTermsArray] = useState([]);
  const [numberTerms, setNumberTerms] = useState(0);
  const [listBankAccountsSelected, setListBankAccountsSelected] = useState({
    // 'Book fee': null,
    'Tution fee': null,
    // 'Admission fee': null,
    // 'Application fee': null,
  });
  useEffect(() => {
    if (!isStart) {
      if (numberTerms === 0) {
      } else if (numberTerms > termsArray.length) {
        setTermsArray((prev) => {
          const arr = [...prev];
          for (let i = termsArray.length; i < numberTerms; i++) {
            arr.push({
              name: `Term ${i + 1} Fee`,
              fee: 0,
              dueFee: options[i]?.startDate || new Date(),
            });
          }
          return arr;
        });
      } else if (numberTerms < termsArray.length) {
        setTermsArray((prev) => {
          return prev.slice(0, numberTerms);
        });
      }
    } else {
      isStart = false;
    }
  }, [numberTerms]);

  useEffect(() => {
    const selectedBankAccount = listBankAccount.find(acc => acc.value === razorpayBankAccountId)
    selectedBankAccount && setListBankAccountsSelected(prev => {
      return {
        ...prev,
        "Tution fee": selectedBankAccount
      }
    })
  }, [razorpayBankAccountId]);
  useEffect(() => {
    if (!!classFeeInfo) {
      setFeeType(classFeeInfo.feeType);
      const feeDist = classFeeInfo.feeDistribution;
      if (feeDist) {
        setIsAdmissionFeeActive(!!feeDist.isAdmissionFeeActive);
        setIsApplicationFeeActive(!!feeDist.isApplicationFeeActive);
        setIsBookFeeActive(!!feeDist.isBookFeeActive);
      }
      classFeeInfo.feeTypeFee?.value && setFeeTypeFeeValue(classFeeInfo.feeTypeFee.value);
      classFeeInfo.bookKitFee?.value && setBookKitFeeValue(classFeeInfo.bookKitFee.value);
      classFeeInfo.admissionFee?.value &&
        setAdmissionFeeValue(classFeeInfo.admissionFee.value);
      classFeeInfo.applicationFee?.value &&
        setApplicationFeeValue(classFeeInfo.applicationFee.value);
      classFeeInfo.estAnnualFee?.value &&
        setEstAnnualFee(classFeeInfo.estAnnualFee.value);

      const dataTerm = _.cloneDeep(classFeeInfo.terms || []);
      if (classFeeInfo?.feeType === 'Custom') {
        const numberOfTerms = classFeeInfo.numberOfTerms || 0;
        numberOfTerms && setNumberTerms(numberOfTerms);
        setTermsArray(dataTerm);
      } else if (classFeeInfo?.feeType === 'Term Dates') {
        setNumberTerms(termsLength);
        setTermsArray(dataTerm);
      } else {
        const newDataTerm = listAcademicYearTerm?.map(academicYear => {
          return {
            fee: 0,
            name: `${academicYear.name} Fee`,
            dueFee: academicYear.startDate
          }
        }) || [];
        setTermsArray(newDataTerm);
        setNumberTerms(termsLength);
      }
    } else {
      const newDataTerm = listAcademicYearTerm?.map(academicYear => {
        return {
          fee: 0,
          name: `${academicYear.name} Fee`,
          dueFee: academicYear.startDate
        }
      }) || [];
      setTermsArray(newDataTerm);
      setNumberTerms(termsLength);
    }
  }, [classFeeInfo, refresh, listAcademicYearTerm]);

  const handleChangeTermsArray = useCallback((length, arr = []) => {
    if (length === 0) return [];
    if (length === arr.length) return arr;
    if (length > arr.length) {
      for (let i = arr.length; i < length; i++) {
        arr.push({
          name: `Term ${i + 1} Fee`,
          fee: 0,
          dueFee: options[i]?.startDate || new Date(),
        });
      }
      return arr;
    }
    if (length < arr.length) {
      return arr.slice(0, length);
    }
  }, []);

  const stringValidation = (val, title = '') => {
    // if(val === 0) {
    //   return '-'
    // }
    if (title === 'isBookFeeActive-fee' && !isBookFeeActive) {
      return '--';
    }
    if (title === 'admission-fee' && !isAdmissionFeeActive) {
      return '--';
    }
    return formatMoney(val);
  };

  const calcEstFeeChange = (property, value) => {
    switch (property) {
      case 'feeType':
        if (value === 'Term Dates') {
          return calcEstManualFee(
            feeTypeFeeValue,
            bookKitFeeValue,
            admissionFeeValue,
            value,
            isBookFeeActive,
            isAdmissionFeeActive,
            handleChangeTermsArray(termsLength, termsArray),
          );
        }
        return calcEstManualFee(
          feeTypeFeeValue,
          bookKitFeeValue,
          admissionFeeValue,
          value,
          isBookFeeActive,
          isAdmissionFeeActive,
          termsArray,
        );
      case 'feeTypeFee':
        return calcEstManualFee(
          value,
          bookKitFeeValue,
          admissionFeeValue,
          feeType,
          isBookFeeActive,
          isAdmissionFeeActive,
          termsArray,
        );
      case 'bookKitFee':
        return calcEstManualFee(
          feeTypeFeeValue,
          value,
          admissionFeeValue,
          feeType,
          isBookFeeActive,
          isAdmissionFeeActive,
          termsArray,
        );
      case 'admissionFee':
        return calcEstManualFee(
          feeTypeFeeValue,
          bookKitFeeValue,
          value,
          feeType,
          isBookFeeActive,
          isAdmissionFeeActive,
          termsArray,
        );
      case 'isBookFeeActive':
        return calcEstManualFee(
          feeTypeFeeValue,
          bookKitFeeValue,
          admissionFeeValue,
          feeType,
          value,
          isAdmissionFeeActive,
          termsArray,
        );
      case 'isAdmissionFeeActive':
        return calcEstManualFee(
          feeTypeFeeValue,
          bookKitFeeValue,
          admissionFeeValue,
          feeType,
          isBookFeeActive,
          value,
          termsArray,
        );
      case 'terms':
        return calcEstManualFee(
          feeTypeFeeValue,
          bookKitFeeValue,
          admissionFeeValue,
          feeType,
          isBookFeeActive,
          isAdmissionFeeActive,
          value,
        );
      default:
        return estAnnualFee;
    }
  };

  const handleSetTutionFee = (property, value, isDistribution, isHaveValueProperty) => {
    const est = calcEstFeeChange(property, value);
    setEstAnnualFee(est);
    const initDistribution = {
      isAdmissionFeeActive: isAdmissionFeeActive,
      isApplicationFeeActive: isApplicationFeeActive,
      isBookFeeActive: isBookFeeActive,
    };
    const initTTFee = {
      classId,
      info: {
        admissionFee: { value: admissionFeeValue },
        applicationFee: { value: applicationFeeValue },
        bookKitFee: { value: bookKitFeeValue },
        estAnnualFee: { value: est },
        feeTypeFee: { value: feeTypeFeeValue },
        feeDistribution: initDistribution,
        feeType: feeType,
        numberOfTerms: numberTerms,
        terms: termsArray,
      },
    };
    if (property === 'feeType') {
      setNumberTerms(termsLength);
      initTTFee.info.numberOfTerms = termsLength;
      initTTFee.info.terms = handleChangeTermsArray(termsLength, termsArray);
    }
    if (property === 'terms') {
      initTTFee.info.numberOfTerms = value.length;
    }
    let distribution;
    let ttFee;
    if (isDistribution) {
      distribution = { ...initDistribution, [property]: value };
      ttFee = {
        ...initTTFee,
        info: { ...initTTFee.info, feeDistribution: distribution },
      };
    } else if (isHaveValueProperty) {
      initTTFee.info[property] = { value: value };
      ttFee = { ...initTTFee };
    } else {
      ttFee = { ...initTTFee, info: { ...initTTFee.info, [property]: value } };
    }
    setTutionFees((prev) => {
      if (!prev) {
        return [ttFee];
      }
      const index = prev.findIndex((fee) => {
        return classId === fee.classId;
      });
      if (index === -1) return [...prev, ttFee];
      const ttFeeInfo = _.cloneDeep(prev);
      ttFeeInfo[index] = { ...ttFeeInfo[index], ...ttFee };
      return ttFeeInfo;
    });
  };

  // let termsArray = [...Array(Number(numberTerms)).keys()];
  return (
    <React.Fragment>
      <div
        className={clsx(
          'feebook-edit-card py-2',
          isOpen ? 'feebook-edit-card_open' : 'feebook-edit-card_closed',
        )}
      >
        <div className="feebook-edit-card_row w-full" onClick={() => setIsOpen(!isOpen)}>
          <p
            className={clsx(
              'font-normal text-base ml-8',
              burger ? 'min-w-[8rem]' : 'min-w-[9rem]',
            )}
          >
            {title === 'Food Fee' || title === 'Transport Fee' ? title : data.name}
          </p>
          <TypesOfFees
            title={`${feeType} Fee`}
            sum={
              feeType !== 'Custom' && feeType !== 'Term Dates'
                ? `${stringValidation(feeTypeFeeValue)}`
                : `${stringValidation(estAnnualFee)}`
            }
          />
          <TypesOfFees
            title="Book-Kit Fee"
            sum={`${stringValidation(bookKitFeeValue, 'isBookFeeActive-fee')}`}
          />
          <TypesOfFees
            title="Admission Fee"
            sum={`${stringValidation(admissionFeeValue, 'admission-fee')}`}
          />
          <TypesOfFees
            title="Est. Annual Fee"
            sum={`${stringValidation(estAnnualFee, '')}`}
          />
          <div className="flex items-center">
            {(!!classOldFeeInfo?.estAnnualFee?.percentageChange ||
              classOldFeeInfo?.estAnnualFee?.percentageChange == 0) && (
                <img
                  src={
                    calcPercent(
                      classOldFeeInfo.estAnnualFee.percentageChange,
                      classOldFeeInfo.estAnnualFee.value,
                      estAnnualFee,
                    ) < 0
                      ? anal_down
                      : anal
                  }
                  alt=""
                  className={clsx(
                    calcPercent(
                      classOldFeeInfo.estAnnualFee.percentageChange,
                      classOldFeeInfo.estAnnualFee.value,
                      estAnnualFee,
                    ) < 0
                      ? 'rotate-90'
                      : '',
                  )}
                />
              )}
            <span
              className={clsx(
                'ml-1',
                (!!classOldFeeInfo?.estAnnualFee?.percentageChange ||
                  classOldFeeInfo?.estAnnualFee?.percentageChange == 0) &&
                  calcPercent(
                    classOldFeeInfo.estAnnualFee.percentageChange,
                    classOldFeeInfo.estAnnualFee.value,
                    estAnnualFee,
                  ) < 0
                  ? 'text-[#ff4343]'
                  : '',
              )}
            >
              {!!classOldFeeInfo?.estAnnualFee?.percentageChange ||
                classOldFeeInfo?.estAnnualFee?.percentageChange == 0
                ? '' +
                calcPercent(
                  classOldFeeInfo.estAnnualFee.percentageChange,
                  classOldFeeInfo.estAnnualFee.value,
                  estAnnualFee,
                ) +
                '%'
                : '--'}
            </span>
          </div>
          <button
            className={clsx('feebook-edit-card_row_button', burger ? 'mr-4' : 'mr-8')}
          >
            <img src={arrow} alt="" />
          </button>
        </div>
        {isOpen && (
          <React.Fragment>
            <div className="feebook-edit-card_content w-[95%]">
              <h3 className=" font-normal text-sm">Fee Type</h3>
              <div className="feebook-edit-card_content_buttons flex">
                {types.map((type) => (
                  <button
                    key={type}
                    onClick={() => {
                      setFeeType(type);
                      handleSetTutionFee('feeType', type);
                    }}
                    className={clsx(
                      'tab-button',
                      type === feeType && 'tab-button_active',
                    )}
                  >
                    {type}
                  </button>
                ))}
              </div>
              <h3 className=" font-normal text-sm">Fee Distribution</h3>
              <div className="flex ml-1.5 mb-3">
                <div className="flex flex-col w-40">
                  <p style={styles.p}>
                    {feeType === 'Custom' || feeType === 'Term Dates'
                      ? 'Number of Terms'
                      : `${feeType} Fee`}
                  </p>
                  <p className="flex items-center">
                    {feeType === 'Custom' || feeType === 'Term Dates' ? (
                      <input
                        placeholder="Number of terms"
                        disabled={feeType === 'Term Dates'}
                        value={numberTerms || undefined}
                        type="number"
                        className="w-14 border-none outline-none"
                        onChange={(e) => {
                          setNumberTerms(+e.target.value);
                          if (+e.target.value > 0) {
                            const terms = handleChangeTermsArray(
                              +e.target.value,
                              termsArray,
                            );
                            const est = calcEstManualFee(
                              feeTypeFeeValue,
                              bookKitFeeValue,
                              admissionFeeValue,
                              feeType,
                              isBookFeeActive,
                              isAdmissionFeeActive,
                              terms,
                            );
                            setEstAnnualFee(est);
                            handleSetTutionFee('terms', terms);
                          }
                        }}
                      />
                    ) : (
                      <React.Fragment>
                        ₹{' '}
                        <input
                          value={feeTypeFeeValue || undefined}
                          type="number"
                          className="ml-1 border-none outline-none"
                          style={{ maxWidth: '3.5rem' }}
                          onChange={(e) => {
                            setFeeTypeFeeValue(+e.target.value);
                            handleSetTutionFee(
                              'feeTypeFee',
                              +e.target.value,
                              false,
                              true,
                            );
                          }}
                        />{' '}
                        {(!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                          classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                          !isNaN(
                            calcPercent(
                              classOldFeeInfo.feeTypeFee.percentageChange,
                              classOldFeeInfo.feeTypeFee.value,
                              feeTypeFeeValue,
                            ),
                          ) && (
                            <img
                              src={
                                calcPercent(
                                  classOldFeeInfo.feeTypeFee.percentageChange,
                                  classOldFeeInfo.feeTypeFee.value,
                                  feeTypeFeeValue,
                                ) < 0
                                  ? analarrow_down
                                  : analarrow
                              }
                              style={styles.img}
                              alt=""
                              className={clsx(
                                (!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                                  classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                                  calcPercent(
                                    classOldFeeInfo.feeTypeFee.percentageChange,
                                    classOldFeeInfo.feeTypeFee.value,
                                    feeTypeFeeValue,
                                  ) < 0
                                  ? 'rotate-180'
                                  : '',
                              )}
                            />
                          )}
                        <span
                          style={styles.span}
                          className={clsx(
                            (!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                              classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                              calcPercent(
                                classOldFeeInfo.feeTypeFee.percentageChange,
                                classOldFeeInfo.feeTypeFee.value,
                                feeTypeFeeValue,
                              ) < 0
                              ? 'text-[#ff4343]'
                              : 'text-[#2ED775]',
                          )}
                        >
                          {(!!classOldFeeInfo?.feeTypeFee?.percentageChange ||
                            classOldFeeInfo?.feeTypeFee?.percentageChange == 0) &&
                            !isNaN(
                              calcPercent(
                                classOldFeeInfo.feeTypeFee.percentageChange,
                                classOldFeeInfo.feeTypeFee.value,
                                feeTypeFeeValue,
                              ),
                            )
                            ? '' +
                            calcPercent(
                              classOldFeeInfo.feeTypeFee.percentageChange,
                              classOldFeeInfo.feeTypeFee.value,
                              feeTypeFeeValue,
                            ) +
                            '%'
                            : '--'}
                        </span>
                      </React.Fragment>
                    )}
                  </p>
                </div>
                <div className="flex flex-col w-40 mr-6">
                  <div className="flex items-center">
                    <p style={styles.p}>Admission Fee</p>
                    <ToggleButton
                      inputDisabled={
                        admissionFee.isActive &&
                        (admissionFee.type === optionFee[0].value ||
                          admissionFee.type === optionFee[1].value)
                      }
                      value={isAdmissionFeeActive}
                      onChange={() => {
                        setIsAdmissionFeeActive(!isAdmissionFeeActive);
                        handleSetTutionFee(
                          'isAdmissionFeeActive',
                          !isAdmissionFeeActive,
                          true,
                        );
                      }}
                      inputSize="smalling"
                      customStyle={styles.img}
                    />
                  </div>
                  {isAdmissionFeeActive && (
                    <p
                      className={clsx(
                        'flex items-center',
                        isAdmissionFeeActive ? ' card-row_active' : ' card-row',
                      )}
                    >
                      ₹{' '}
                      <input
                        value={admissionFeeValue || undefined}
                        type="number"
                        className="ml-1 border-none outline-none"
                        style={{ maxWidth: '3.5rem' }}
                        onChange={(e) => {
                          setAdmissionFeeValue(+e.target.value);
                          handleSetTutionFee(
                            'admissionFee',
                            +e.target.value,
                            false,
                            true,
                          );
                        }}
                      />{' '}
                      {(!!classOldFeeInfo.admissionFee?.percentageChange ||
                        classOldFeeInfo.admissionFee?.percentageChange == 0) &&
                        !isNaN(
                          calcPercent(
                            classOldFeeInfo.admissionFee.percentageChange,
                            classOldFeeInfo.admissionFee.value,
                            admissionFeeValue,
                          ),
                        ) && (
                          <img
                            src={
                              calcPercent(
                                classOldFeeInfo.admissionFee.percentageChange,
                                classOldFeeInfo.admissionFee.value,
                                admissionFeeValue,
                              ) < 0
                                ? analarrow_down
                                : analarrow
                            }
                            style={styles.img}
                            alt=""
                            className={clsx(
                              (!!classOldFeeInfo.admissionFee?.percentageChange ||
                                classOldFeeInfo.admissionFee?.percentageChange == 0) &&
                                calcPercent(
                                  classOldFeeInfo.admissionFee.percentageChange,
                                  classOldFeeInfo.admissionFee.value,
                                  admissionFeeValue,
                                ) < 0
                                ? 'rotate-180'
                                : '',
                            )}
                          />
                        )}
                      <span
                        style={styles.span}
                        className={clsx(
                          (!!classOldFeeInfo.admissionFee?.percentageChange ||
                            classOldFeeInfo.admissionFee?.percentageChange == 0) &&
                            calcPercent(
                              classOldFeeInfo.admissionFee.percentageChange,
                              classOldFeeInfo.admissionFee.value,
                              admissionFeeValue,
                            ) < 0
                            ? 'text-[#ff4343]'
                            : 'text-[#2ED775]',
                        )}
                      >
                        {(!!classOldFeeInfo.admissionFee?.percentageChange ||
                          classOldFeeInfo.admissionFee?.percentageChange == 0) &&
                          !isNaN(
                            calcPercent(
                              classOldFeeInfo.admissionFee.percentageChange,
                              classOldFeeInfo.admissionFee.value,
                              admissionFeeValue,
                            ),
                          )
                          ? '' +
                          calcPercent(
                            classOldFeeInfo.admissionFee.percentageChange,
                            classOldFeeInfo.admissionFee.value,
                            admissionFeeValue,
                          ) +
                          '%'
                          : '--'}
                      </span>
                    </p>
                  )}
                </div>
                <div className="flex flex-col mr-6">
                  <div className="flex items-center">
                    <p className="flex items-center" style={styles.p}>
                      BookFee Fee
                    </p>
                    <ToggleButton
                      value={isBookFeeActive}
                      onChange={() => {
                        setIsBookFeeActive(!isBookFeeActive);
                        handleSetTutionFee('isBookFeeActive', !isBookFeeActive, true);
                      }}
                      inputSize="smalling"
                      customStyle={styles.img}
                    />
                  </div>
                  {isBookFeeActive && (
                    <p
                      className={clsx(
                        'flex items-center',
                        isBookFeeActive ? ' card-row_active' : ' card-row',
                      )}
                    >
                      ₹{' '}
                      <input
                        value={bookKitFeeValue || undefined}
                        type="number"
                        className="ml-1 border-none outline-none"
                        style={{ maxWidth: '3.5rem' }}
                        onChange={(e) => {
                          setBookKitFeeValue(+e.target.value);
                          handleSetTutionFee('bookKitFee', +e.target.value, false, true);
                        }}
                      />{' '}
                      {(!!classOldFeeInfo?.bookKitFee?.percentageChange ||
                        classOldFeeInfo?.bookKitFee?.percentageChange == 0) &&
                        !isNaN(
                          calcPercent(
                            classOldFeeInfo.bookKitFee.percentageChange,
                            classOldFeeInfo.bookKitFee.value,
                            bookKitFeeValue,
                          ),
                        ) && (
                          <img
                            src={
                              calcPercent(
                                classOldFeeInfo.bookKitFee.percentageChange,
                                classOldFeeInfo.bookKitFee.value,
                                bookKitFeeValue,
                              ) < 0
                                ? analarrow_down
                                : analarrow
                            }
                            style={styles.img}
                            alt=""
                            className={clsx(
                              (!!classOldFeeInfo?.bookKitFee?.percentageChange ||
                                classOldFeeInfo?.bookKitFee?.percentageChange == 0) &&
                                calcPercent(
                                  classOldFeeInfo.bookKitFee.percentageChange,
                                  classOldFeeInfo.bookKitFee.value,
                                  bookKitFeeValue,
                                ) < 0
                                ? 'rotate-180'
                                : '',
                            )}
                          />
                        )}
                      <span
                        style={styles.span}
                        className={clsx(
                          (!!classOldFeeInfo?.bookKitFee?.percentageChange ||
                            classOldFeeInfo?.bookKitFee?.percentageChange == 0) &&
                            calcPercent(
                              classOldFeeInfo.bookKitFee.percentageChange,
                              classOldFeeInfo.bookKitFee.value,
                              bookKitFeeValue,
                            ) < 0
                            ? 'text-[#ff4343]'
                            : 'text-[#2ED775]',
                        )}
                      >
                        {(!!classOldFeeInfo?.bookKitFee?.percentageChange ||
                          classOldFeeInfo?.bookKitFee?.percentageChange == 0) &&
                          !isNaN(
                            calcPercent(
                              classOldFeeInfo.bookKitFee.percentageChange,
                              classOldFeeInfo.bookKitFee.value,
                              bookKitFeeValue,
                            ),
                          )
                          ? '' +
                          calcPercent(
                            classOldFeeInfo.bookKitFee.percentageChange,
                            classOldFeeInfo.bookKitFee.value,
                            bookKitFeeValue,
                          ) +
                          '%'
                          : '--'}
                      </span>
                    </p>
                  )}
                </div>
                <div className="flex flex-col w-40 mr-6">
                  <div className="flex items-center">
                    <p style={styles.p}>Application Fee</p>
                    <ToggleButton
                      inputDisabled={
                        applicationFee.isActive &&
                        (applicationFee.type === optionFee[0].value ||
                          applicationFee.type === optionFee[1].value)
                      }
                      value={isApplicationFeeActive}
                      onChange={() => {
                        setIsApplicationFeeActive(!isApplicationFeeActive);
                        handleSetTutionFee(
                          'isApplicationFeeActive',
                          !isApplicationFeeActive,
                          true,
                        );
                      }}
                      inputSize="smalling"
                      customStyle={styles.img}
                    />
                  </div>
                  {isApplicationFeeActive && (
                    <p
                      className={clsx(
                        'flex items-center',
                        isApplicationFeeActive ? ' card-row_active' : ' card-row',
                      )}
                    >
                      ₹{' '}
                      <input
                        value={applicationFeeValue || undefined}
                        type="number"
                        className="ml-1 border-none outline-none"
                        style={{ maxWidth: '3.5rem' }}
                        onChange={(e) => {
                          setApplicationFeeValue(+e.target.value);
                          handleSetTutionFee(
                            'applicationFee',
                            +e.target.value,
                            false,
                            true,
                          );
                        }}
                      />{' '}
                      {(!!classOldFeeInfo?.applicationFee?.percentageChange ||
                        classOldFeeInfo?.applicationFee?.percentageChange == 0) &&
                        !isNaN(
                          calcPercent(
                            classOldFeeInfo.applicationFee.percentageChange,
                            classOldFeeInfo.applicationFee.value,
                            applicationFeeValue,
                          ),
                        ) && (
                          <img
                            src={
                              calcPercent(
                                classOldFeeInfo.applicationFee.percentageChange,
                                classOldFeeInfo.applicationFee.value,
                                applicationFeeValue,
                              ) < 0
                                ? analarrow_down
                                : analarrow
                            }
                            style={styles.img}
                            alt=""
                            className={clsx(
                              (!!classOldFeeInfo?.applicationFee?.percentageChange ||
                                classOldFeeInfo?.applicationFee?.percentageChange == 0) &&
                                calcPercent(
                                  classOldFeeInfo.applicationFee.percentageChange,
                                  classOldFeeInfo.applicationFee.value,
                                  applicationFeeValue,
                                ) < 0
                                ? 'rotate-180'
                                : '',
                            )}
                          />
                        )}
                      <span
                        style={styles.span}
                        className={clsx(
                          (!!classOldFeeInfo?.applicationFee?.percentageChange ||
                            classOldFeeInfo?.applicationFee?.percentageChange == 0) &&
                            calcPercent(
                              classOldFeeInfo.applicationFee.percentageChange,
                              classOldFeeInfo.applicationFee.value,
                              applicationFeeValue,
                            ) < 0
                            ? 'text-[#ff4343]'
                            : 'text-[#2ED775]',
                        )}
                      >
                        {(!!classOldFeeInfo?.applicationFee?.percentageChange ||
                          classOldFeeInfo?.applicationFee?.percentageChange == 0) &&
                          !isNaN(
                            calcPercent(
                              classOldFeeInfo.applicationFee.percentageChange,
                              classOldFeeInfo.applicationFee.value,
                              applicationFeeValue,
                            ),
                          )
                          ? '' +
                          calcPercent(
                            classOldFeeInfo.applicationFee.percentageChange,
                            classOldFeeInfo.applicationFee.value,
                            applicationFeeValue,
                          ) +
                          '%'
                          : '--'}
                      </span>
                    </p>
                  )}
                </div>
              </div>
              {(feeType === 'Custom' || feeType === 'Term Dates') &&
                termsArray.map((item, key) => (
                  <FeeTermsCard
                    isSetTime={feeType === 'Term Dates' ? false : true}
                    handleSetFee={handleSetTutionFee}
                    setTermsArray={setTermsArray}
                    termsArray={termsArray}
                    item={item}
                    key={key}
                    index={key}
                    maxValue={termsArray.length}
                    oldFee={key >= oldTerms.length ? {} : oldTerms[key]}
                  />
                ))}
            </div>
            {razorpayBankAccount.type === 'Different' && (
              <div className="px-8 flex flex-col gap-2.5 my-5">
                <p className="text-[#81878C] text-sm font-semibold">Bank Account</p>
                {listFee.map((fee) => (
                  <div key={fee} className="flex gap-5 items-center justify-start">
                    <p className="min-w-[120px] text-[#919EAB] font-normal text-sm">
                      {fee}
                    </p>
                    <SelectGroup
                      value={listBankAccountsSelected[fee]}
                      options={listBankAccount}
                      onChange={(e) => {
                        setListBankAccountsSelected(prev => ({ ...prev, [fee]: e }));
                        setTutionFees(prev => {
                          const index = prev.findIndex(f => f.classId === classId)
                          if (index === -1) return prev
                          const ttFee = _.cloneDeep(prev)
                          ttFee[index].razorpayBankAccountId = e.value
                          return ttFee
                        })
                      }}
                      classNameGroup="w-[20rem] mx-3"
                    />
                  </div>
                ))}
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
});

export default TutionCardFee;

TutionCardFee.displayName = 'TutionCardFee';
