import {CONFIRM_CODE_SMS} from '../action-creators/types';

const initState = {};

const confirmCodeSMSSlice = (state = initState, action) =>{
  switch (action.type) {
  case CONFIRM_CODE_SMS.saveConfirmCodeSms:
    return {...state, ...action.payload};
  default:
    return state;
  };
};

export default confirmCodeSMSSlice;